import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

const NAVIGATION_QUERY_PARAM = 'navigation';
const NO_NAVIGATION_VALUE = 'no';

export const useShowNavigation = () => {
  const router = useRouter();
  const [showNavigation, setShowNavigation] = useState(true);

  useEffect(() => {
    if (router.isReady) {
      const navigationParam = router.query[NAVIGATION_QUERY_PARAM];
      const shouldShowNavigation = !(
        typeof navigationParam === 'string' &&
        navigationParam.toLowerCase() === NO_NAVIGATION_VALUE
      );

      if (shouldShowNavigation !== showNavigation) {
        setShowNavigation(shouldShowNavigation);
      }
    }
  }, [router.isReady, router.query, showNavigation]);

  return { showNavigation };
};

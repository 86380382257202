import Head from 'next/head';
import { useRouter } from 'next/router';

import RouteNames from '@constants/RouteNames';

const Meta = () => {
  const router = useRouter();

  const faviconHref = router.pathname.includes(RouteNames.B2B_LANDING)
    ? '/b2b_favicon.svg'
    : '/favicon.svg';

  return (
    <Head>
      <link rel="icon" href={faviconHref} />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href={faviconHref} />
      <link rel="shortcut icon" href={faviconHref} />
      <link rel="alternate" type="application/rss+xml" href="/feed.xml" />

      <meta charSet="utf-8" />
      <meta
        httpEquiv="Content-Type"
        content="text/html; charset=utf-8; X-Content-Type-Options=nosniff"
      ></meta>
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, height=device-height"
      />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
};

export default Meta;

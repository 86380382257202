import { ReactNode } from 'react';

import { trackEvent } from '@lib/rudderstack';
import { EventSpec, TrackEventSpec } from '@lib/rudderstack/EventSpecs';

import * as S from './Tracked.styled';

type TrackedProps<T extends EventSpec> = {
  event: TrackEventSpec<T>;
  children?: ReactNode;
  className?: string;
};

const Tracked = <T extends EventSpec>({
  children,
  event,
  className
}: TrackedProps<T>) => {
  const trackOnCapture = () => {
    trackEvent(event);
  };

  return (
    <S.Tracked className={className} onClickCapture={trackOnCapture}>
      {children}
    </S.Tracked>
  );
};

export default Tracked;

import Image from 'next/image';

import { Inline, Typography } from '@dribe-io/ui-kit/components';

import { TrackedLink } from '@components/Tracked';
import RouteNames from '@constants/RouteNames';
import Device from '@utils/Device';

import * as S from './Footer.styled';

type DownloadProps = {
  downloadText: string;
};

const Download = ({ downloadText }: DownloadProps) => {
  return (
    <Device>
      {({ isMobile, isIOS, isAndroid }) => {
        return (
          <S.DownloadContainer flexWrap="wrap">
            <Inline pr={16}>
              {isMobile ? (
                <TrackedLink
                  href={`${RouteNames.LANDING}`}
                  prefetch={false}
                  title="Dribe logo"
                >
                  <Image
                    src="/logo_grey.svg"
                    alt="Dribe logo"
                    width={141.3}
                    height={36}
                  />
                </TrackedLink>
              ) : (
                <Typography variant="h5" color="snow">
                  {downloadText}
                </Typography>
              )}
            </Inline>
            <S.DownloadContainer>
              {!isAndroid && (
                <TrackedLink
                  title="App Store"
                  href="https://apps.apple.com/us/app/dribe-bilabonnement/id1294854647"
                  prefetch={false}
                >
                  <Image
                    src="/appstore.svg"
                    alt="App Store"
                    width={120}
                    height={40}
                    priority
                  />
                </TrackedLink>
              )}
              {!isIOS && (
                <TrackedLink
                  title="Play Store"
                  href="https://play.google.com/store/apps/details?id=com.mutualmobile.dribe"
                  prefetch={false}
                >
                  <Image
                    src="/playstore.svg"
                    alt="Play Store"
                    width={140}
                    height={40}
                    priority
                  />
                </TrackedLink>
              )}
            </S.DownloadContainer>
          </S.DownloadContainer>
        );
      }}
    </Device>
  );
};

export default Download;

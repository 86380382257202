import { iconMapper } from '@dribe-io/ui-kit/components/Icon/iconMapper';

import * as S from './FlagIcon.styled';

const FlagNames: {
  [key: string]: keyof typeof iconMapper;
} = {
  el: 'greece',
  da: 'denmark',
  en: 'unitedKingdom'
};

type FlagIconProps = {
  size: number;
  name: keyof typeof FlagNames;
};

export const FlagIcon = ({ name, size }: FlagIconProps) => {
  const flagName = FlagNames[name];
  return <S.FlagIcon name={flagName} size={size} $size={size} />;
};

import Link from 'next/link';

import { Button, Typography } from '@dribe-io/ui-kit/components';

import RouteNames from '@constants/RouteNames';

import * as S from './PreviewAlert.styled';

const PreviewAlert = () => {
  return (
    <S.FlexContainer>
      <div>
        <Typography color="snow">This is page is a preview. </Typography>
        <Link prefetch={false} href={`${RouteNames.API_EXIT_PREVIEW}`}>
          <Button variant="plain">Click here</Button>
        </Link>
        &nbsp;
        <Typography color="snow">to exit preview mode.</Typography>
      </div>
    </S.FlexContainer>
  );
};

export default PreviewAlert;

import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  opacity: 0.9;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex[10]};
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.petrol};
`;

import * as C from '@dribe-io/ui-kit/components';

import styled, { css } from 'styled-components';

import { contentPaddingHorizontal, contentWidthBoundary } from '@theme/layout';

export const FooterContainer = styled.section<{ $navDisabled?: boolean }>`
  ${contentPaddingHorizontal}
  background-color: ${({ theme }) => theme.palette.petrol};
  padding-top: ${({ theme }) => theme.spacing[48]};
  padding-bottom: ${({ theme }) => theme.spacing[40]};
  ${({ $navDisabled }) =>
    $navDisabled &&
    css`
      filter: blur(4px);
    `}
  & > * {
    pointer-events: ${({ $navDisabled }) => ($navDisabled ? 'none' : 'auto')};
    user-select: ${({ $navDisabled }) => ($navDisabled ? 'none' : 'auto')};
  }
`;

export const FooterContentWrapper = styled.div`
  ${contentWidthBoundary}
  padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[16]}`};
`;

export const TopContainer = styled(C.Inline)`
  flex-direction: row;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing[32]};

  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      `
        flex-direction: column;
        align-items: flex-start;
      `
    )};
`;

export const DownloadContainer = styled(C.Inline)`
  gap: ${({ theme }) => theme.spacing[16]};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        gap: ${theme.spacing[24]};
      `
    )};
`;

export const RatingContainer = styled(C.Inline)`
  gap: ${({ theme }) => theme.spacing[80]};
  margin-bottom: ${({ theme }) => theme.spacing[4]};

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        flex-wrap: wrap;
        gap: ${theme.spacing[40]};
        margin-bottom: ${theme.spacing[0]};
      `
    )};
`;

export const LinksContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing[48]};
  gap: ${({ theme }) => theme.spacing[48]};

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;

  width: 100%;
  height: 100%;

  ${({ theme }) =>
    theme.breakpoints.up(
      'lg',
      css`
        grid-template-columns: repeat(5, 1fr);
      `
    )};

  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      css`
        grid-template-columns: repeat(4, 1fr);
      `
    )};

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      css`
        grid-template-columns: repeat(3, 1fr);
      `
    )};

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        grid-template-columns: repeat(1, 1fr);
      `
    )};
`;

export const SocialContainer = styled(C.Inline)`
  gap: ${({ theme }) => theme.spacing[24]};
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  justify-content: flex-start;
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        margin-top: ${theme.spacing[40]};
        justify-content: center;
      `
    )};
`;

export const BottomContainer = styled(C.Stack)`
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        margin-top: ${theme.spacing[32]};
      `
    )};
`;

export const Divider = styled.hr`
  height: 1px;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing[40]};
  margin-bottom: ${({ theme }) => theme.spacing[40]};
  background-color: ${({ theme }) => theme.palette.snow};
  opacity: 0.2;
  border: none;
  display: block;
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      css`
        display: none;
      `
    )};
`;

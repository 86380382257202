import Image from 'next/image';

import * as C from '@dribe-io/ui-kit/components';

import styled from 'styled-components';

export const RichTextWrapper = styled.div`
  display: inherit;
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-word;
`;

export const ParagraphBlock = styled(C.Typography)`
  margin-top: ${({ theme }) => theme.spacing[24]};
  &:first-child {
    margin-top: 0;
  }
  .rich-text-bold {
    font: ${({ theme }) => theme.typography.body1};
    font-family: 'GTFlexa-Bd';
  }
`;

export const HeadingBlock = styled(C.Typography)`
  margin-top: ${({ theme }) => theme.spacing[40]};
`;

export const TypographyMark = styled(C.Typography)`
  font: inherit;
`;

export const ListItem = styled.li`
  margin-top: ${({ theme }) => theme.spacing[24]};
`;

export const BulletList = styled.ul`
  & li {
    display: list-item;
  }
  margin-left: ${({ theme }) => theme.spacing[24]};
`;

export const NumberList = styled.ol`
  font: ${({ theme }) => theme.typography.body1};
  font-family: 'GTFlexa-Bd';
  & li {
    display: list-item;
  }
  margin-left: ${({ theme }) => theme.spacing[24]};
`;

export const RichTextTableOverflowWrapper = styled.div`
  overflow: hidden;
  overflow-x: auto;
`;

export const RichTextTable = styled.table`
  padding: ${({ theme }) => theme.spacing[16]};
  border: ${({ theme }) => theme.borders.primaryS};
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  min-width: 60rem;
  word-break: break-word;

  & tbody {
    vertical-align: top;

    & a span {
      color: ${({ theme }) => theme.palette.orange};
      &:hover {
        color: ${({ theme }) => theme.palette.tertiaryOrange};
      }
    }

    & th,
    & tr:first-child {
      border: ${({ theme }) => theme.borders.outlinedS};
      background-color: ${({ theme }) => theme.palette.orange};
      text-align: start;
      padding: 1em 1.5rem; // Value used from old WP table
      & * {
        color: ${({ theme }) => theme.palette.snow};
      }
    }

    & tr:nth-child(even) {
      background-color: ${({ theme }) => theme.palette.lightGrey};
    }

    & td {
      padding: 1em 1.5rem; // Value used from old WP table
      border: ${({ theme }) => theme.borders.primaryS};
    }
  }
`;

export const TypographyLink = styled(C.Typography)`
  font: inherit;
  &:hover {
    color: ${({ theme }) => theme.palette.tertiaryOrange};
  }
`;

export const TypographyBlockQuote = styled(C.Typography)`
  font: inherit;
  display: block;
  margin: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[40]}`};
  border-left: ${({ theme }) => theme.borders.primaryXL};
  padding: ${({ theme }) => theme.spacing[16]};
  background: ${({ theme }) => theme.palette.xxLightGrey};
  & span {
    color: inherit;
  }
`;

export const RichTextImage = styled(Image)`
  margin-top: ${({ theme }) => theme.spacing[32]};
  height: auto;
  max-width: 100%;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
`;

export const RichTextVideo = styled.video`
  margin-top: ${({ theme }) => theme.spacing[32]};
  height: auto;
  max-width: 100%;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
`;

import { FunctionComponent } from 'react';

import { NextPage } from 'next';

import {
  Maybe,
  ComingSoonPageFragment,
  getLayout,
  getComingSoonPage
} from '@api/contentful';
import { getDribeConfig } from '@api/dribe';
import ComingSoonLayout from '@layouts/ComingSoonLayout';
import Layout, { LayoutProps } from '@layouts/Layout';
import MaintenanceLayout from '@layouts/MaintenanceLayout';

export enum Mode {
  Live = 'Live',
  ComingSoon = 'Coming soon',
  Maintenance = 'Maintenance'
}

interface WithLayoutProps {
  layout: Maybe<LayoutProps>;
  mode: Maybe<string>;
  comingSoonLayout: Maybe<ComingSoonPageFragment>;
}

const withLayout = <T extends WithLayoutProps>(
  WrappedComponent: FunctionComponent<T>
) => {
  const ComponentWithLayout: NextPage<T> = (props: T) => {
    const getLayout = () => {
      switch (props?.mode) {
        case Mode.Live:
          return <WrappedComponent {...props} />;
        case Mode.ComingSoon:
          return <ComingSoonLayout {...props.comingSoonLayout} />;
        case Mode.Maintenance:
          return <MaintenanceLayout />;
        default:
          return <WrappedComponent {...props} />;
      }
    };

    return (
      <Layout navDisabled={props?.mode !== Mode.Live} {...props?.layout}>
        {getLayout()}
      </Layout>
    );
  };

  return ComponentWithLayout;
};

export const getStaticLayoutProps = async (
  locale?: string,
  preview = false,
  isB2B = false
): Promise<WithLayoutProps> => {
  try {
    const contentfulResult = await getLayout(
      {
        variables: {
          tagId: process.env.CONTENTFUL_TAG_ID,
          locale,
          preview
        }
      },
      [preview]
    );
    const { data: { dribeConfig } = {} } = await getDribeConfig({});

    const comingSoonEnabled =
      contentfulResult.data.siteSwitcherCollection?.items[0]?.mode ===
      Mode.ComingSoon;

    let comingSoonResult;

    if (comingSoonEnabled) {
      comingSoonResult = await getComingSoonPage(
        {
          variables: {
            tagId: process.env.CONTENTFUL_TAG_ID,
            locale,
            preview
          }
        },
        [preview]
      );
    }

    return {
      mode: contentfulResult.data.siteSwitcherCollection?.items[0]?.mode,
      comingSoonLayout: {
        headerImage: comingSoonEnabled
          ? comingSoonResult?.data.comingSoonPageCollection?.items[0]
              ?.headerImage
          : null,
        header: comingSoonEnabled
          ? comingSoonResult?.data.comingSoonPageCollection?.items[0]?.header
          : '',
        description: comingSoonEnabled
          ? comingSoonResult?.data.comingSoonPageCollection?.items[0]
              ?.description
          : null,
        previewLocationsCollection: comingSoonEnabled
          ? comingSoonResult?.data.comingSoonPageCollection?.items[0]
              ?.previewLocationsCollection
          : null
      },
      layout: {
        footerFragment: contentfulResult.data.footerCollection?.items[0],
        menuFragment: contentfulResult.data.menuCollection?.items[0],
        dribeConfig,
        preview,
        isB2B,
        languageCode:
          contentfulResult.data.menuCollection?.items[0]?.languageCode
      }
    };
  } catch (error) {
    return {
      mode: Mode.Maintenance,
      comingSoonLayout: undefined,
      layout: undefined
    };
  }
};

export default withLayout;

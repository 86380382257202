import { ReactNode } from 'react';

import { Maybe } from 'graphql/jsutils/Maybe';

import { FooterFragment, MenuFragment } from '@api/contentful';
import { DribeConfig } from '@api/dribe';
import Footer from '@components/Footer';
import Meta from '@components/Meta';
import Navbar from '@components/Navbar';
import PreviewAlert from '@components/PreviewAlert';
import { useShowNavigation } from '@hooks/useShowNavigation';
import { IsB2B } from 'types/response';

import * as S from './Layout.styled';

export type LayoutProps = {
  preview?: boolean;
  children?: ReactNode;
  footerFragment?: Maybe<FooterFragment>;
  menuFragment?: Maybe<MenuFragment>;
  dribeConfig?: Maybe<DribeConfig>;
  navDisabled?: boolean;
  languageCode?: Maybe<string>;
} & IsB2B;

const Layout = ({
  preview,
  children,
  footerFragment,
  menuFragment,
  navDisabled,
  isB2B,
  dribeConfig
}: LayoutProps) => {
  const { showNavigation } = useShowNavigation();

  return (
    <>
      <Meta />
      {preview && <PreviewAlert />}
      <main>
        {showNavigation && (
          <Navbar
            b2bUrl={dribeConfig?.B2BUrl}
            isB2B={isB2B}
            menuFragment={menuFragment}
            navDisabled={navDisabled}
          />
        )}
        <S.ContentSection $withNavbarPadding={showNavigation}>
          {children}
        </S.ContentSection>
        {showNavigation && (
          <Footer
            isB2B={isB2B}
            {...(footerFragment || [])}
            navDisabled={navDisabled}
          />
        )}
      </main>
    </>
  );
};

export default Layout;

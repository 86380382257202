import { ReactNode, useState } from 'react';

import { Button, Icon, Inline, Typography } from '@dribe-io/ui-kit/components';
import { IconProps } from '@dribe-io/ui-kit/components/Icon/Icon';

import { useTranslation } from 'next-i18next';

import { Maybe } from 'graphql/jsutils/Maybe';

import Tracked, { TrackedLink } from '@components/Tracked';
import RouteNames from '@constants/RouteNames';
import ButtonActions from '@lib/rudderstack/ButtonActions';
import { createButtonClickedSpec } from '@lib/rudderstack/EventSpecs';
import { IsB2B } from 'types/response';

import * as S from './CategoryCard.styled';

type CategoryCardProps = {
  title?: Maybe<string>;
  iconName?: Maybe<IconProps['name']>;
  entries?: Maybe<{
    title?: Maybe<string>;
    slug?: Maybe<string>;
  }>[];
  showNoEntriesText?: boolean;
  children?: ReactNode;
  className?: string;
} & IsB2B;

const CategoryCard = ({
  title,
  iconName,
  entries,
  showNoEntriesText,
  isB2B,
  children,
  className
}: CategoryCardProps) => {
  const [showMore, setShowMore] = useState(false);

  const { t } = useTranslation();

  const filteredEntries = entries?.filter(e => e?.slug && e?.title) || [];
  const entriesToShow = filteredEntries?.slice(
    0,
    showMore ? filteredEntries?.length : 3
  );

  return (
    <S.CategoryCardContainer className={className} flexDirection="column">
      {(iconName || title) && (
        <Inline justifyContent="center" alignItems="center">
          {iconName && (
            <S.IconContainer mr={18}>
              <Icon name={iconName} size={25} />
            </S.IconContainer>
          )}
          {title && <S.EntryTitle element="p">{title}</S.EntryTitle>}
        </Inline>
      )}

      {entriesToShow?.map((entry, i) => (
        <Inline
          pt={32}
          key={entry?.slug}
          className={showMore && i > 2 ? '' : 'hide'}
        >
          <TrackedLink
            title={entry?.title || ''}
            href={
              isB2B
                ? `${RouteNames.B2B_LANDING}${entry?.slug}`
                : `${entry?.slug}`
            }
          >
            <S.LinkText variant="body2" element="p">
              {entry?.title}
            </S.LinkText>
          </TrackedLink>
        </Inline>
      ))}
      {filteredEntries.length > 3 && (
        <Inline pt={24}>
          <Tracked
            event={createButtonClickedSpec(
              ButtonActions.CATEGORY_SHOW_MORE_CLICKED
            )}
          >
            <Button variant="plain" onClick={() => setShowMore(!showMore)}>
              <S.LinkText textTransform="none" variant="cta" color="petrol">
                {showMore
                  ? t('categoryCard.showLess')
                  : t('categoryCard.showMore')}
              </S.LinkText>
            </Button>
          </Tracked>
        </Inline>
      )}
      {!filteredEntries.length && showNoEntriesText && (
        <Typography variant="body2" element="p">
          {t('categoryCard.noEntries')}
        </Typography>
      )}
      {children}
    </S.CategoryCardContainer>
  );
};

export default CategoryCard;

import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { DropdownOption } from '@dribe-io/ui-kit/components/DropdownV2';

import { useTranslation } from 'next-i18next';

import { FlagIcon } from './FlagIcon/FlagIcon';
import * as S from './LanguageSelector.styled';

export const LanguageSelector = () => {
  const { i18n, t } = useTranslation();
  const { language: currentLanguage } = i18n;
  const router = useRouter();
  const setLocale = useCallback(
    async (locale: string) => {
      const { pathname, asPath, query } = router;
      await router.push({ pathname, query }, asPath, {
        locale,
        shallow: false
      });
      router.reload();
    },
    [router]
  );

  const setCookie = (locale: string) => {
    document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`;
  };

  const languageChanged = useCallback(
    async (option: DropdownOption) => {
      const locale = option.value;
      if (locale !== router.locale) {
        setCookie(locale);
        await setLocale(locale);
      }
    },
    [router.locale, setLocale]
  );

  const locales = router.locales ?? [currentLanguage];

  const currentLanguageName =
    t(`languageSelector.${currentLanguage}`) ?? currentLanguage.toUpperCase();

  const options: DropdownOption[] = locales.sort().map(locale => ({
    label: t(`languageSelector.${locale}`) ?? locale.toUpperCase(),
    value: locale,
    IconComponent: <FlagIcon size={16} name={locale} />
  }));

  return (
    <S.LanguageDropdown
      IconComponent={<FlagIcon size={16} name={currentLanguage} />}
      options={options}
      onChange={languageChanged}
      placeholder={currentLanguageName}
      defaultValue={options.find(locale => locale.value === currentLanguage)}
      selectedVariant="cta"
      optionsStyle={{
        top: 40,
        padding: 8,
        shadow: '4px 4px 20px 0px rgba(118, 117, 117, 0.25)'
      }}
      noBorder={true}
      transparent={true}
    />
  );
};

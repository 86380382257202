/* THIS FILE IS GENERATED WITH `pnpm watch` DO NOT EDIT MANUALLY */

import * as O from './operations';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export const AssetFragmentDoc = gql`
  fragment AssetFragment on Asset {
    sys {
      id
    }
    title
    description
    width
    height
    url
    contentType
    contentfulMetadata {
      tags {
        id
        name
      }
    }
  }
`;
export const DynamicFormFragmentDoc = gql`
  fragment DynamicFormFragment on DynamicForm {
    formIdentifierTag
    formTextContent {
      json
    }
    formFieldsCollection {
      items {
        fieldName
        formFieldFreeText {
          json
        }
        fieldLabel
        fieldType
        fieldRequired
        fieldErrorMessage
        fieldFullWidth
        fieldRegex
      }
    }
    submitButtonLabel
    successfulSubmitMessage {
      json
    }
  }
`;
export const SeoFragmentDoc = gql`
  fragment SeoFragment on SeoMetadata {
    sys {
      id
    }
    internalName
    title
    description
    keywords
    canonical
    noindex
    nofollow
    openGraphTitle
    openGraphDescription
    openGraphImage {
      ...AssetFragment
    }
    openGraphUrl
    openGraphType
    twitterCard
    twitterSite
  }
  ${AssetFragmentDoc}
`;
export const BlogEntryFragmentDoc = gql`
  fragment BlogEntryFragment on BlogEntry {
    title
    sys {
      firstPublishedAt
    }
    previewText
    titleImage {
      ...AssetFragment
    }
    titlePrefix
    slug
    site
    path
    category {
      contentfulMetadata {
        tags {
          id
        }
      }
      sys {
        id
        environmentId
        spaceId
      }
      title
    }
    content {
      json
      links {
        assets {
          block {
            ...AssetFragment
          }
        }
        entries {
          block {
            sys {
              id
            }
            __typename
            ...DynamicFormFragment
          }
        }
      }
    }
    newsletterSection {
      header
      content {
        json
      }
      newsletterSubscriptionForm {
        ...DynamicFormFragment
      }
    }
    seoMetadata {
      ...SeoFragment
    }
  }
  ${AssetFragmentDoc}
  ${DynamicFormFragmentDoc}
  ${SeoFragmentDoc}
`;
export const BlogPageFragmentDoc = gql`
  fragment BlogPageFragment on BlogPage {
    title
    highlightedBlogPost {
      ...BlogEntryFragment
    }
    newsletterSection {
      header
      content {
        json
      }
      newsletterSubscriptionForm {
        __typename
        ...DynamicFormFragment
      }
    }
    slug
    seoMetadata {
      ...SeoFragment
    }
  }
  ${BlogEntryFragmentDoc}
  ${DynamicFormFragmentDoc}
  ${SeoFragmentDoc}
`;
export const CampaignEntryFragmentDoc = gql`
  fragment CampaignEntryFragment on CampaignEntry {
    sys {
      id
    }
    title
    content {
      json
      links {
        assets {
          block {
            ...AssetFragment
          }
        }
        entries {
          block {
            sys {
              id
            }
            __typename
            ...DynamicFormFragment
          }
        }
      }
    }
    path
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${AssetFragmentDoc}
  ${DynamicFormFragmentDoc}
  ${SeoFragmentDoc}
`;
export const ComingSoonPageFragmentDoc = gql`
  fragment ComingSoonPageFragment on ComingSoonPage {
    headerImage {
      ...AssetFragment
    }
    header
    description {
      json
      links {
        assets {
          block {
            ...AssetFragment
          }
        }
        entries {
          block {
            sys {
              id
            }
            __typename
            ...DynamicFormFragment
          }
        }
      }
    }
    previewLocationsCollection {
      items {
        city
        address1
        address2
      }
    }
    slug
  }
  ${AssetFragmentDoc}
  ${DynamicFormFragmentDoc}
`;
export const ContactCardFragmentDoc = gql`
  fragment ContactCardFragment on ContactCard {
    contactDescription {
      json
    }
    contactInfo {
      contactInfo
      contactInfoType
    }
    header
    icon {
      iconName
    }
    underlinedSubLink {
      title
      url
    }
  }
`;
export const ContactPageB2BFragmentDoc = gql`
  fragment ContactPageB2BFragment on ContactPageB2B {
    title
    featureDescription
    featureList
    contactInfoCollection {
      items {
        ...ContactCardFragment
      }
    }
    submitTitle
    submitDescription {
      json
    }
    companyIdInputLabel
    companyIdRequired
    seoMetadata {
      ...SeoFragment
    }
    contactForm {
      __typename
      ...DynamicFormFragment
    }
    slug
  }
  ${ContactCardFragmentDoc}
  ${SeoFragmentDoc}
  ${DynamicFormFragmentDoc}
`;
export const ContactPageFragmentDoc = gql`
  fragment ContactPageFragment on ContactPage {
    title
    contactHeader
    contactSubDescription {
      json
    }
    contactCardsCollection {
      items {
        ...ContactCardFragment
      }
    }
    accentBox {
      json
    }
    assistanceAndDamagesImage {
      ...AssetFragment
    }
    assistanceAndDamagesHeader
    assistanceAndDamagesSubDesc {
      json
    }
    assistanceAndDamagesArrowLink {
      title
      url
    }
    assistanceAndDamagesContactCardsCollection {
      items {
        ...ContactCardFragment
      }
    }
    findGaragesHeader
    availableGaragesCardsCollection {
      items {
        header
        garagesList {
          json
        }
      }
    }
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${ContactCardFragmentDoc}
  ${AssetFragmentDoc}
  ${SeoFragmentDoc}
`;
export const DamagesContactPageFragmentDoc = gql`
  fragment DamagesContactPageFragment on DamagesContactPage {
    title
    header
    description {
      json
    }
    damagesContactCardsCollection {
      items {
        ...ContactCardFragment
      }
    }
    accentBox {
      json
    }
    startProblemsHeader
    startProblemsDesc1 {
      json
    }
    brandContactsCollection {
      items {
        brandName
        brandContactInfo {
          contactInfo
          contactInfoType
        }
      }
    }
    startProblemsDesc2 {
      json
    }
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${ContactCardFragmentDoc}
  ${SeoFragmentDoc}
`;
export const DamagesPageFragmentDoc = gql`
  fragment DamagesPageFragment on DamagesPage {
    title
    header
    damagesCardsCollection {
      items {
        image {
          ...AssetFragment
        }
        header
        description {
          json
        }
        arrowLink {
          title
          url
        }
      }
    }
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${AssetFragmentDoc}
  ${SeoFragmentDoc}
`;
export const DamagesPolicyPageFragmentDoc = gql`
  fragment DamagesPolicyPageFragment on DamagesPolicyPage {
    title
    policyContentCollection {
      items {
        header
        content {
          json
        }
        arrowLink {
          title
          url
        }
      }
    }
    collapseContainerHeader
    collapsableContentCollection {
      items {
        header
        content {
          json
        }
      }
    }
    insuranceCoverHeader
    insuranceCoverDescription {
      json
    }
    insuranceAccentBox {
      json
    }
    withAssistedInspection
    assistedInspectionScreenshot {
      ...AssetFragment
    }
    assistedInspectionHeader
    assistedInspectionDescription {
      json
    }
    howItWorksHeader
    assistedInspectionStepsCollection {
      items {
        stepHeader
        stepDescription {
          json
        }
      }
    }
    faqHeader
    faqDescription {
      json
    }
    faqButtonLink {
      title
      url
    }
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${AssetFragmentDoc}
  ${SeoFragmentDoc}
`;
export const DemoPageFragmentDoc = gql`
  fragment DemoPageFragment on DemoPage {
    title
    subTitle
    initialTitle
    initialProfileImage {
      ...AssetFragment
    }
    initialDescription {
      json
    }
    startImage {
      ...AssetFragment
    }
    slidesCollection {
      items {
        title
        description
        image {
          ...AssetFragment
        }
      }
    }
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${AssetFragmentDoc}
  ${SeoFragmentDoc}
`;
export const DownloadPageFragmentDoc = gql`
  fragment DownloadPageFragment on DownloadPage {
    title
    appPicture {
      ...AssetFragment
    }
    headerText {
      json
    }
    stepperFindCar
    stepperScanQr
    stepperCreateAccount
    stepperBookCar
    downloadButton
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${AssetFragmentDoc}
  ${SeoFragmentDoc}
`;
export const FaqEntryFragmentDoc = gql`
  fragment FaqEntryFragment on FaqEntry {
    sys {
      id
    }
    title
    text {
      json
      links {
        assets {
          block {
            ...AssetFragment
          }
        }
        entries {
          block {
            sys {
              id
            }
            __typename
            ...DynamicFormFragment
          }
        }
      }
    }
    path
    seoMetadata {
      ...SeoFragment
    }
    slug
    site
  }
  ${AssetFragmentDoc}
  ${DynamicFormFragmentDoc}
  ${SeoFragmentDoc}
`;
export const FaqPageFragmentDoc = gql`
  fragment FaqPageFragment on FaqPage {
    sys {
      id
    }
    title
    headerTitle
    headerBodyText {
      json
    }
    headerImage {
      ...AssetFragment
    }
    faqCategoriesCollection(locale: $locale, preview: $preview) {
      items {
        sys {
          id
        }
        title
        icon {
          iconName
        }
        faqEntriesCollection(locale: $locale, preview: $preview) {
          items {
            sys {
              id
            }
            title
            slug
            site
          }
        }
      }
    }
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${AssetFragmentDoc}
  ${SeoFragmentDoc}
`;
export const FooterFragmentDoc = gql`
  fragment FooterFragment on Footer {
    instagramLink
    facebookLink
    linkedInLink
    trustpilotLink
    tikTokLink
    youTubeLink
    googleLink
    trustpilotRating
    facebookRating
    googleRating
    fixedDribeFooterContent
    customerCareContactInfo {
      json
    }
    linkGroupsCollection(locale: $locale, preview: $preview) {
      items {
        sys {
          id
        }
        title
        linksCollection(locale: $locale, preview: $preview) {
          items {
            sys {
              id
            }
            title
            url
          }
        }
        b2BLinksCollection {
          items {
            sys {
              id
            }
            title
            url
          }
        }
      }
    }
  }
`;
export const GaragePageFragmentDoc = gql`
  fragment GaragePageFragment on GaragePage {
    announcement {
      content {
        json
      }
    }
    title
    slug
    headerTitle
    headerBodyText {
      json
    }
    headerImage {
      ...AssetFragment
    }
    whatIsIncluded
    priceText
    seoMetadata {
      ...SeoFragment
    }
  }
  ${AssetFragmentDoc}
  ${SeoFragmentDoc}
`;
export const LandingPageB2BFragmentDoc = gql`
  fragment LandingPageB2BFragment on LandingPageB2B {
    announcement {
      content {
        json
      }
    }
    title
    subText
    heroImage {
      ...AssetFragment
    }
    trustedPartnersTitle
    partnerLogosCollection {
      items {
        ...AssetFragment
      }
    }
    showCaseTitle
    showCaseSubTitle
    showCaseSectionCollection {
      items {
        title
        features
        image {
          ...AssetFragment
        }
        urlText
        urlSlug
      }
    }
    featuresTitle
    featuresSubTitle
    featuresFlipCardsCollection(locale: $locale, limit: 3, preview: $preview) {
      items {
        sys {
          id
        }
        title
        defaultIcon {
          iconName
        }
        defaultDescription {
          json
        }
        flipIcon {
          iconName
        }
        flipTitle
        flipDescription {
          json
        }
        visibleLinkTitle
        visibleLink
        flipVisibleLinkTitle
        flipVisibleLink
      }
    }
    blogSectionTitle
    hightlightedBlogEntriesCollection {
      items {
        ...BlogEntryFragment
      }
    }
    faqSectionTitle
    faqSectionItemsCollection(locale: $locale, preview: $preview) {
      items {
        sys {
          id
        }
        title
        text {
          json
        }
      }
    }
    contactSectionTitle
    contactSectionSubtitle
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${AssetFragmentDoc}
  ${BlogEntryFragmentDoc}
  ${SeoFragmentDoc}
`;
export const LandingPageFragmentDoc = gql`
  fragment LandingPageFragment on LandingPage {
    sys {
      id
    }
    announcement {
      content {
        json
      }
    }
    title
    slug
    heroTitleText
    bookingFormTitle
    bookingFormSearchText
    locationDropdownLabel
    locationDropdownPlaceholder
    dateInputLabel
    dateInputPlaceholder
    garageLabel
    heroBackgroundImage {
      ...AssetFragment
    }
    heroBackgroundImageMobile {
      ...AssetFragment
    }
    featuresFlipCardsCollection(locale: $locale, limit: 3, preview: $preview) {
      items {
        sys {
          id
        }
        title
        defaultIcon {
          iconName
        }
        defaultDescription {
          json
        }
        flipIcon {
          iconName
        }
        flipTitle
        flipDescription {
          json
        }
      }
    }
    ageRequirementsAccentBox
    appImagesCollection {
      total
      items {
        ...AssetFragment
      }
    }
    downloadAppTitle
    appFeatures
    appDescription
    cardPricesFromText
    budgetCategoryMaxPrice
    electricPropellantValue
    faqSectionTitle
    faqSectionItemsCollection(locale: $locale, preview: $preview) {
      items {
        sys {
          id
        }
        title
        text {
          json
        }
      }
    }
    seoMetadata {
      ...SeoFragment
    }
  }
  ${AssetFragmentDoc}
  ${SeoFragmentDoc}
`;
export const LegalEntryFragmentDoc = gql`
  fragment LegalEntryFragment on LegalEntry {
    sys {
      id
    }
    title
    tag
    site
    text {
      json
      links {
        assets {
          block {
            ...AssetFragment
          }
        }
        entries {
          block {
            sys {
              id
            }
            __typename
            ...DynamicFormFragment
          }
        }
      }
    }
    path
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${AssetFragmentDoc}
  ${DynamicFormFragmentDoc}
  ${SeoFragmentDoc}
`;
export const LegalPageFragmentDoc = gql`
  fragment LegalPageFragment on LegalPage {
    sys {
      id
    }
    title
    headerTitle
    legalEntriesCollection(locale: $locale, preview: $preview) {
      items {
        sys {
          id
        }
        title
        description
        site
        slug
      }
    }
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${SeoFragmentDoc}
`;
export const MenuFragmentDoc = gql`
  fragment MenuFragment on Menu {
    linksCollection {
      items {
        sys {
          id
        }
        title
        url
      }
    }
    b2BLinksCollection {
      items {
        sys {
          id
        }
        title
        url
      }
    }
    languageCode
    defaultPage
    redirectTimeout
    slug
  }
`;
export const OutOfStockPageFragmentDoc = gql`
  fragment OutOfStockPageFragment on OutOfStockPage {
    title
    subTitle
    watchlistTitle
    watchlistDescription
    mobileUiImageExample {
      ...AssetFragment
    }
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${AssetFragmentDoc}
  ${SeoFragmentDoc}
`;
export const PricingPageFragmentDoc = gql`
  fragment PricingPageFragment on PricingPage {
    title
    header
    exampleHeader
    exampleDescription {
      json
    }
    exampleRideCard {
      rideNameHeader
      rideVariantSubHeader
      rideImage {
        ...AssetFragment
      }
    }
    priceTable {
      json
    }
    accentBox {
      json
    }
    checklistHeader
    leftChecklist {
      json
    }
    rightChecklist {
      json
    }
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${AssetFragmentDoc}
  ${SeoFragmentDoc}
`;
export const ProductPageFragmentDoc = gql`
  fragment ProductPageFragment on ProductPage {
    sys {
      id
    }
    announcement {
      content {
        json
      }
    }
    title
    hideMsrpTaxContainer
    economyTableEntries
    economyTableBottomText
    includeLinkToPricingPage
    addOnsInfoText
    priceIncludesText
    seoMetadata {
      ...SeoFragment
    }
    slug
  }
  ${SeoFragmentDoc}
`;
export const SiteSwitcherFragmentDoc = gql`
  fragment SiteSwitcherFragment on SiteSwitcher {
    slug
    mode
  }
`;
export const TableRowFragmentDoc = gql`
  fragment TableRowFragment on TableRow {
    sys {
      id
    }
    icon {
      iconName
    }
    title
    value
    lokaliseKey
  }
`;
export const GetBlogEntryDocument = gql`
  query GetBlogEntry(
    $tagId: String
    $locale: String
    $preview: Boolean!
    $order: [BlogEntryOrder]
    $skip: Int
    $category: String
    $limit: Int
    $site: String
  ) {
    blogTopicsCollection(locale: $locale, preview: $preview) {
      items {
        title
      }
    }
    blogEntryCollection(
      limit: $limit
      locale: $locale
      preview: $preview
      order: $order
      skip: $skip
      where: {
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
        category_exists: true
        category: { title_contains: $category }
        site_contains_some: [$site]
      }
    ) {
      total
      items {
        ...BlogEntryFragment
      }
    }
  }
  ${BlogEntryFragmentDoc}
`;
export type GetBlogEntryQueryResult = Apollo.QueryResult<
  O.GetBlogEntryQuery,
  O.GetBlogEntryQueryVariables
>;
export const GetBlogEntryByPathDocument = gql`
  query GetBlogEntryByPath(
    $path: String!
    $tagId: String
    $locale: String
    $preview: Boolean!
    $slug: String
  ) {
    blogEntryCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        path: $path
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
        AND: { slug: $slug }
      }
    ) {
      items {
        ...BlogEntryFragment
      }
    }
  }
  ${BlogEntryFragmentDoc}
`;
export type GetBlogEntryByPathQueryResult = Apollo.QueryResult<
  O.GetBlogEntryByPathQuery,
  O.GetBlogEntryByPathQueryVariables
>;
export const GetBlogEntryBySlugDocument = gql`
  query GetBlogEntryBySlug(
    $tagId: String
    $locale: String
    $preview: Boolean!
    $slug: String
  ) {
    blogEntryCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        slug: $slug
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
      }
    ) {
      items {
        ...BlogEntryFragment
      }
    }
  }
  ${BlogEntryFragmentDoc}
`;
export type GetBlogEntryBySlugQueryResult = Apollo.QueryResult<
  O.GetBlogEntryBySlugQuery,
  O.GetBlogEntryBySlugQueryVariables
>;
export const GetBlogEntryPathsDocument = gql`
  query GetBlogEntryPaths($tagId: String) {
    blogEntryCollection(
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        path
        site
      }
    }
  }
`;
export type GetBlogEntryPathsQueryResult = Apollo.QueryResult<
  O.GetBlogEntryPathsQuery,
  O.GetBlogEntryPathsQueryVariables
>;
export const GetBlogPageBySlugDocument = gql`
  query GetBlogPageBySlug(
    $tagId: String
    $locale: String
    $preview: Boolean!
    $slug: String
  ) {
    blogPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
        AND: { slug: $slug }
      }
    ) {
      items {
        ...BlogPageFragment
      }
    }
  }
  ${BlogPageFragmentDoc}
`;
export type GetBlogPageBySlugQueryResult = Apollo.QueryResult<
  O.GetBlogPageBySlugQuery,
  O.GetBlogPageBySlugQueryVariables
>;
export const GetCampaignEntryByPathDocument = gql`
  query GetCampaignEntryByPath(
    $path: String!
    $tagId: String
    $locale: String
    $preview: Boolean!
  ) {
    campaignEntryCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        path: $path
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
      }
    ) {
      items {
        ...CampaignEntryFragment
      }
    }
  }
  ${CampaignEntryFragmentDoc}
`;
export type GetCampaignEntryByPathQueryResult = Apollo.QueryResult<
  O.GetCampaignEntryByPathQuery,
  O.GetCampaignEntryByPathQueryVariables
>;
export const GetCampaignEntryBySlugDocument = gql`
  query GetCampaignEntryBySlug(
    $slug: String!
    $tagId: String
    $locale: String
    $preview: Boolean!
  ) {
    campaignEntryCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        slug: $slug
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
      }
    ) {
      items {
        ...CampaignEntryFragment
      }
    }
  }
  ${CampaignEntryFragmentDoc}
`;
export type GetCampaignEntryBySlugQueryResult = Apollo.QueryResult<
  O.GetCampaignEntryBySlugQuery,
  O.GetCampaignEntryBySlugQueryVariables
>;
export const GetCampaignEntryPathsDocument = gql`
  query GetCampaignEntryPaths($tagId: String) {
    campaignEntryCollection(
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        path
      }
    }
  }
`;
export type GetCampaignEntryPathsQueryResult = Apollo.QueryResult<
  O.GetCampaignEntryPathsQuery,
  O.GetCampaignEntryPathsQueryVariables
>;
export const GetComingSoonPageDocument = gql`
  query GetComingSoonPage($tagId: String, $locale: String, $preview: Boolean!) {
    comingSoonPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        ...ComingSoonPageFragment
      }
    }
  }
  ${ComingSoonPageFragmentDoc}
`;
export type GetComingSoonPageQueryResult = Apollo.QueryResult<
  O.GetComingSoonPageQuery,
  O.GetComingSoonPageQueryVariables
>;
export const GetContactPageDocument = gql`
  query GetContactPage($tagId: String, $locale: String, $preview: Boolean!) {
    contactPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        ...ContactPageFragment
      }
    }
  }
  ${ContactPageFragmentDoc}
`;
export type GetContactPageQueryResult = Apollo.QueryResult<
  O.GetContactPageQuery,
  O.GetContactPageQueryVariables
>;
export const GetContactPageB2BDocument = gql`
  query GetContactPageB2B($tagId: String, $locale: String, $preview: Boolean!) {
    contactPageB2BCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        ...ContactPageB2BFragment
      }
    }
  }
  ${ContactPageB2BFragmentDoc}
`;
export type GetContactPageB2BQueryResult = Apollo.QueryResult<
  O.GetContactPageB2BQuery,
  O.GetContactPageB2BQueryVariables
>;
export const GetDamagesContactPageBySlugDocument = gql`
  query GetDamagesContactPageBySlug(
    $tagId: String
    $locale: String
    $preview: Boolean!
    $slug: String
  ) {
    damagesContactPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
        AND: { slug: $slug }
      }
    ) {
      items {
        ...DamagesContactPageFragment
      }
    }
  }
  ${DamagesContactPageFragmentDoc}
`;
export type GetDamagesContactPageBySlugQueryResult = Apollo.QueryResult<
  O.GetDamagesContactPageBySlugQuery,
  O.GetDamagesContactPageBySlugQueryVariables
>;
export const GetDamagesPageBySlugDocument = gql`
  query GetDamagesPageBySlug(
    $tagId: String
    $locale: String
    $preview: Boolean!
    $slug: String
  ) {
    damagesPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
        AND: { slug: $slug }
      }
    ) {
      items {
        ...DamagesPageFragment
      }
    }
  }
  ${DamagesPageFragmentDoc}
`;
export type GetDamagesPageBySlugQueryResult = Apollo.QueryResult<
  O.GetDamagesPageBySlugQuery,
  O.GetDamagesPageBySlugQueryVariables
>;
export const GetDamagesPolicyPageBySlugDocument = gql`
  query GetDamagesPolicyPageBySlug(
    $tagId: String
    $locale: String
    $preview: Boolean!
    $slug: String
  ) {
    damagesPolicyPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
        AND: { slug: $slug }
      }
    ) {
      items {
        ...DamagesPolicyPageFragment
      }
    }
  }
  ${DamagesPolicyPageFragmentDoc}
`;
export type GetDamagesPolicyPageBySlugQueryResult = Apollo.QueryResult<
  O.GetDamagesPolicyPageBySlugQuery,
  O.GetDamagesPolicyPageBySlugQueryVariables
>;
export const GetDemoPageDocument = gql`
  query GetDemoPage($tagId: String, $locale: String, $preview: Boolean!) {
    demoPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        ...DemoPageFragment
      }
    }
  }
  ${DemoPageFragmentDoc}
`;
export type GetDemoPageQueryResult = Apollo.QueryResult<
  O.GetDemoPageQuery,
  O.GetDemoPageQueryVariables
>;
export const GetDownloadPageBySlugDocument = gql`
  query GetDownloadPageBySlug(
    $tagId: String
    $locale: String
    $preview: Boolean!
    $slug: String!
  ) {
    downloadPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        slug: $slug
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
      }
    ) {
      items {
        ...DownloadPageFragment
      }
    }
  }
  ${DownloadPageFragmentDoc}
`;
export type GetDownloadPageBySlugQueryResult = Apollo.QueryResult<
  O.GetDownloadPageBySlugQuery,
  O.GetDownloadPageBySlugQueryVariables
>;
export const GetFaqEntryByPathDocument = gql`
  query GetFaqEntryByPath(
    $path: String!
    $tagId: String
    $locale: String
    $allowedLocales: [String]
    $preview: Boolean!
    $slug: String
  ) {
    faqEntryCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        path: $path
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
        AND: { slug: $slug }
      }
    ) {
      items {
        ...FaqEntryFragment
        linkedFrom(allowedLocales: $allowedLocales) {
          faqCategoryCollection(limit: 1, locale: $locale, preview: $preview) {
            items {
              sys {
                id
              }
              title
            }
          }
        }
      }
    }
  }
  ${FaqEntryFragmentDoc}
`;
export type GetFaqEntryByPathQueryResult = Apollo.QueryResult<
  O.GetFaqEntryByPathQuery,
  O.GetFaqEntryByPathQueryVariables
>;
export const GetFaqEntryBySlugDocument = gql`
  query GetFaqEntryBySlug(
    $slug: String!
    $tagId: String
    $locale: String
    $preview: Boolean!
  ) {
    faqEntryCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        slug: $slug
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
      }
    ) {
      items {
        ...FaqEntryFragment
        linkedFrom {
          faqCategoryCollection(limit: 1, locale: $locale, preview: $preview) {
            items {
              sys {
                id
              }
              title
            }
          }
        }
      }
    }
  }
  ${FaqEntryFragmentDoc}
`;
export type GetFaqEntryBySlugQueryResult = Apollo.QueryResult<
  O.GetFaqEntryBySlugQuery,
  O.GetFaqEntryBySlugQueryVariables
>;
export const GetFaqEntryPathsDocument = gql`
  query GetFaqEntryPaths($tagId: String) {
    faqEntryCollection(
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        path
        site
      }
    }
  }
`;
export type GetFaqEntryPathsQueryResult = Apollo.QueryResult<
  O.GetFaqEntryPathsQuery,
  O.GetFaqEntryPathsQueryVariables
>;
export const GetFaqPageBySlugDocument = gql`
  query GetFaqPageBySlug(
    $tagId: String
    $locale: String
    $preview: Boolean!
    $slug: String
  ) {
    faqPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
        AND: { slug: $slug }
      }
    ) {
      items {
        ...FaqPageFragment
      }
    }
  }
  ${FaqPageFragmentDoc}
`;
export type GetFaqPageBySlugQueryResult = Apollo.QueryResult<
  O.GetFaqPageBySlugQuery,
  O.GetFaqPageBySlugQueryVariables
>;
export const GetGaragePageBySlugDocument = gql`
  query GetGaragePageBySlug(
    $tagId: String
    $locale: String
    $preview: Boolean!
    $slug: String
  ) {
    garagePageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
        AND: { slug: $slug }
      }
    ) {
      items {
        ...GaragePageFragment
      }
    }
  }
  ${GaragePageFragmentDoc}
`;
export type GetGaragePageBySlugQueryResult = Apollo.QueryResult<
  O.GetGaragePageBySlugQuery,
  O.GetGaragePageBySlugQueryVariables
>;
export const GetLandingPageDocument = gql`
  query GetLandingPage($tagId: String, $locale: String, $preview: Boolean!) {
    landingPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        ...LandingPageFragment
      }
    }
  }
  ${LandingPageFragmentDoc}
`;
export type GetLandingPageQueryResult = Apollo.QueryResult<
  O.GetLandingPageQuery,
  O.GetLandingPageQueryVariables
>;
export const GetLandingPageB2BDocument = gql`
  query GetLandingPageB2B($tagId: String, $locale: String, $preview: Boolean!) {
    landingPageB2BCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        ...LandingPageB2BFragment
      }
    }
  }
  ${LandingPageB2BFragmentDoc}
`;
export type GetLandingPageB2BQueryResult = Apollo.QueryResult<
  O.GetLandingPageB2BQuery,
  O.GetLandingPageB2BQueryVariables
>;
export const GetLayoutDocument = gql`
  query GetLayout($tagId: String, $locale: String, $preview: Boolean!) {
    siteSwitcherCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        ...SiteSwitcherFragment
      }
    }
    footerCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        ...FooterFragment
      }
    }
    menuCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        ...MenuFragment
      }
    }
  }
  ${SiteSwitcherFragmentDoc}
  ${FooterFragmentDoc}
  ${MenuFragmentDoc}
`;
export type GetLayoutQueryResult = Apollo.QueryResult<
  O.GetLayoutQuery,
  O.GetLayoutQueryVariables
>;
export const GetLegalEntryByPathDocument = gql`
  query GetLegalEntryByPath(
    $path: String!
    $tagId: String
    $locale: String
    $preview: Boolean!
  ) {
    legalEntryCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        path: $path
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
      }
    ) {
      items {
        ...LegalEntryFragment
      }
    }
  }
  ${LegalEntryFragmentDoc}
`;
export type GetLegalEntryByPathQueryResult = Apollo.QueryResult<
  O.GetLegalEntryByPathQuery,
  O.GetLegalEntryByPathQueryVariables
>;
export const GetLegalEntryBySlugDocument = gql`
  query GetLegalEntryBySlug(
    $slug: String!
    $tagId: String
    $locale: String
    $preview: Boolean!
  ) {
    legalEntryCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        slug: $slug
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
      }
    ) {
      items {
        ...LegalEntryFragment
      }
    }
  }
  ${LegalEntryFragmentDoc}
`;
export type GetLegalEntryBySlugQueryResult = Apollo.QueryResult<
  O.GetLegalEntryBySlugQuery,
  O.GetLegalEntryBySlugQueryVariables
>;
export const GetLegalEntryPathsDocument = gql`
  query GetLegalEntryPaths($tagId: String) {
    legalEntryCollection(
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        path
        site
      }
    }
  }
`;
export type GetLegalEntryPathsQueryResult = Apollo.QueryResult<
  O.GetLegalEntryPathsQuery,
  O.GetLegalEntryPathsQueryVariables
>;
export const GetLegalPageBySlugDocument = gql`
  query GetLegalPageBySlug(
    $tagId: String
    $locale: String
    $preview: Boolean!
    $slug: String
  ) {
    legalPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
        AND: { slug: $slug }
      }
    ) {
      items {
        ...LegalPageFragment
      }
    }
  }
  ${LegalPageFragmentDoc}
`;
export type GetLegalPageBySlugQueryResult = Apollo.QueryResult<
  O.GetLegalPageBySlugQuery,
  O.GetLegalPageBySlugQueryVariables
>;
export const GetOutOfStockPageDocument = gql`
  query GetOutOfStockPage($tagId: String, $locale: String, $preview: Boolean!) {
    outOfStockPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        ...OutOfStockPageFragment
      }
    }
  }
  ${OutOfStockPageFragmentDoc}
`;
export type GetOutOfStockPageQueryResult = Apollo.QueryResult<
  O.GetOutOfStockPageQuery,
  O.GetOutOfStockPageQueryVariables
>;
export const GetPricingPageDocument = gql`
  query GetPricingPage($tagId: String, $locale: String, $preview: Boolean!) {
    pricingPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_all: [$tagId] } } }
    ) {
      items {
        ...PricingPageFragment
      }
    }
  }
  ${PricingPageFragmentDoc}
`;
export type GetPricingPageQueryResult = Apollo.QueryResult<
  O.GetPricingPageQuery,
  O.GetPricingPageQueryVariables
>;
export const GetProductPageBySlugDocument = gql`
  query GetProductPageBySlug(
    $slug: String!
    $tagId: String
    $locale: String
    $preview: Boolean!
  ) {
    productPageCollection(
      limit: 1
      locale: $locale
      preview: $preview
      where: {
        slug: $slug
        contentfulMetadata: { tags: { id_contains_all: [$tagId] } }
      }
    ) {
      items {
        ...ProductPageFragment
      }
    }
  }
  ${ProductPageFragmentDoc}
`;
export type GetProductPageBySlugQueryResult = Apollo.QueryResult<
  O.GetProductPageBySlugQuery,
  O.GetProductPageBySlugQueryVariables
>;

import { TFunction } from 'i18next';

export enum DateFormatEnum {
  DD_MM_YYYY = 'DD.MM.YYYY',
  MM_DD_YYYY = 'MM.DD.YYYY',
  YYYY_MM_DD = 'YYYY.MM.DD'
}

export const convertStringToURLFriendly = (string: string) => {
  return string
    .toLowerCase()
    .replace(/[^\w\s.,-]/gi, '')
    .replace(/\s+/g, '-')
    .replace(/[.,]/g, '-');
};

export const trim = (str: string, maxLength: number): string => {
  return str.length > maxLength ? `${str.substring(0, maxLength - 1)}...` : str;
};

export const trimSlug = (slug: string) => {
  const trimmedSlug = slug.startsWith('/') ? slug : `/${slug}`;
  return trimmedSlug.endsWith('/') && trimmedSlug.length > 1 // In cases where we want to revalidate the root "/"
    ? trimmedSlug.slice(0, -1)
    : trimmedSlug;
};

export const formatDateStringAsMomentDate = (
  dateString?: string | Date,
  format: DateFormatEnum = DateFormatEnum.DD_MM_YYYY
) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  switch (format) {
    case DateFormatEnum.DD_MM_YYYY:
      return `${day}.${month}.${year}`;
    case DateFormatEnum.MM_DD_YYYY:
      return `${month}.${day}.${year}`;
    case DateFormatEnum.YYYY_MM_DD:
      return `${year}.${month}.${day}`;
  }
};

export const getDisplayMonths = (t?: TFunction<'translation', undefined>) => {
  if (!t) return;
  return [
    t('months.january'),
    t('months.february'),
    t('months.march'),
    t('months.april'),
    t('months.may'),
    t('months.june'),
    t('months.july'),
    t('months.august'),
    t('months.september'),
    t('months.october'),
    t('months.november'),
    t('months.december')
  ];
};

const isNotDefaultLocale = (locale?: string): boolean => {
  return locale !== process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
};

export const checkForwardSlashOnRelativeURL = (
  url?: string,
  locale?: string
): string => {
  if (
    !url ||
    (url === '/' && isNotDefaultLocale(locale)) // We don't want slash on relative root page using default locale!
  )
    return '';
  return url.startsWith('/') ? url : `/${url}`; // If relative URL doesn't start with a forward slash, prepend it
};

// Used for cleaning up fallback canonical on e.g. /garage and /garage/[id] which uses router.asPath
export const removeQueryParamsFromURL = (url?: string): string => {
  return url?.split('?')?.[0] ?? '';
};

const generateInternalURL = (
  url: string,
  locale?: string,
  allowDefaultLocale?: boolean
): string => {
  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL || '';
  const localePrefix =
    locale && (isNotDefaultLocale(locale) || allowDefaultLocale) // Used for creating hreflang meta tag for defaultLocale aswell
      ? `/${locale}`
      : '';
  const relativeUrl = checkForwardSlashOnRelativeURL(url, locale);
  return `${siteUrl}${localePrefix}${relativeUrl}`;
};

// Turns relative links from Contentful and code into absolute links in the DOM
export const generateAbsoluteURL = (
  url: string,
  locale?: string,
  allowDefaultLocale?: boolean
): string => {
  const isAbsoluteURL = url.startsWith('http') || url.startsWith('www.'); // "https" will automatically be covered by "http" check
  const isActionURL = url.startsWith('tel:') || url.startsWith('mailto:');

  if (isAbsoluteURL) {
    const cleanUrl = url.replace(/www\./, ''); // Remove "www." from the URL
    return cleanUrl.startsWith('http') ? cleanUrl : `https://${cleanUrl}`; // If it doesn't start with "http" or "https", we assume it is meant to be a "https://" URL
  } else if (isActionURL) {
    return url; // Do not alter tel:/mailto: URLs
  } else {
    // If it is relative, append the site URL from environment variables to generate internal absolute href - also handles locale insertion
    return generateInternalURL(url, locale, allowDefaultLocale);
  }
};

export const generateWindowLocationSearchQueryString = (
  allowedQueryKeys: string[]
) => {
  const params: string[] = [];
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.forEach((value, key) => {
    if (allowedQueryKeys.includes(key)) {
      params.push(`${key}=${value}`);
    }
  });

  return params.length ? `?${params.join('&')}` : '';
};

export const getSlug = ({
  brand = '',
  model = '',
  prefix = ''
}: {
  brand?: string;
  model?: string;
  prefix?: string;
}) => {
  const vehicleSlug = `${convertStringToURLFriendly(
    brand
  )}-${convertStringToURLFriendly(model)}`;

  if (prefix) {
    return `${prefix}/${vehicleSlug}`;
  }

  return vehicleSlug;
};

export const getSlugWithId = ({
  id = '',
  ...params
}: {
  brand?: string;
  model?: string;
  prefix?: string;
  id?: string;
}) => {
  return `${getSlug(params)}/${id}`;
};

/* THIS FILE IS GENERATED WITH `pnpm watch` DO NOT EDIT MANUALLY */

export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  Dimension: { input: any; output: any };
  HexColor: { input: any; output: any };
  JSON: { input: any; output: any };
  Quality: { input: any; output: any };
};

/** Model for Announcement Banner [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/announcement) */
export type Announcement = Entry &
  _Node & {
    __typename?: 'Announcement';
    _id: Scalars['ID']['output'];
    content?: Maybe<AnnouncementContent>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<AnnouncementLinkingCollections>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Model for Announcement Banner [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/announcement) */
export type AnnouncementContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Model for Announcement Banner [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/announcement) */
export type AnnouncementLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Model for Announcement Banner [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/announcement) */
export type AnnouncementTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type AnnouncementCollection = {
  __typename?: 'AnnouncementCollection';
  items: Array<Maybe<Announcement>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AnnouncementContent = {
  __typename?: 'AnnouncementContent';
  json: Scalars['JSON']['output'];
  links: AnnouncementContentLinks;
};

export type AnnouncementContentAssets = {
  __typename?: 'AnnouncementContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AnnouncementContentEntries = {
  __typename?: 'AnnouncementContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AnnouncementContentLinks = {
  __typename?: 'AnnouncementContentLinks';
  assets: AnnouncementContentAssets;
  entries: AnnouncementContentEntries;
  resources: AnnouncementContentResources;
};

export type AnnouncementContentResources = {
  __typename?: 'AnnouncementContentResources';
  block: Array<AnnouncementContentResourcesBlock>;
  hyperlink: Array<AnnouncementContentResourcesHyperlink>;
  inline: Array<AnnouncementContentResourcesInline>;
};

export type AnnouncementContentResourcesBlock = ResourceLink & {
  __typename?: 'AnnouncementContentResourcesBlock';
  sys: ResourceSys;
};

export type AnnouncementContentResourcesHyperlink = ResourceLink & {
  __typename?: 'AnnouncementContentResourcesHyperlink';
  sys: ResourceSys;
};

export type AnnouncementContentResourcesInline = ResourceLink & {
  __typename?: 'AnnouncementContentResourcesInline';
  sys: ResourceSys;
};

export type AnnouncementFilter = {
  AND?: InputMaybe<Array<InputMaybe<AnnouncementFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AnnouncementFilter>>>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AnnouncementLinkingCollections = {
  __typename?: 'AnnouncementLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  garagePageCollection?: Maybe<GaragePageCollection>;
  landingPageB2BCollection?: Maybe<LandingPageB2BCollection>;
  landingPageCollection?: Maybe<LandingPageCollection>;
  productPageCollection?: Maybe<ProductPageCollection>;
};

export type AnnouncementLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AnnouncementLinkingCollectionsGaragePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<AnnouncementLinkingCollectionsGaragePageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AnnouncementLinkingCollectionsLandingPageB2BCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<AnnouncementLinkingCollectionsLandingPageB2BCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AnnouncementLinkingCollectionsLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<AnnouncementLinkingCollectionsLandingPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AnnouncementLinkingCollectionsProductPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<AnnouncementLinkingCollectionsProductPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum AnnouncementLinkingCollectionsGaragePageCollectionOrder {
  headerTitle_ASC = 'headerTitle_ASC',
  headerTitle_DESC = 'headerTitle_DESC',
  priceText_ASC = 'priceText_ASC',
  priceText_DESC = 'priceText_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum AnnouncementLinkingCollectionsLandingPageB2BCollectionOrder {
  blogSectionTitle_ASC = 'blogSectionTitle_ASC',
  blogSectionTitle_DESC = 'blogSectionTitle_DESC',
  contactSectionSubtitle_ASC = 'contactSectionSubtitle_ASC',
  contactSectionSubtitle_DESC = 'contactSectionSubtitle_DESC',
  contactSectionTitle_ASC = 'contactSectionTitle_ASC',
  contactSectionTitle_DESC = 'contactSectionTitle_DESC',
  faqSectionTitle_ASC = 'faqSectionTitle_ASC',
  faqSectionTitle_DESC = 'faqSectionTitle_DESC',
  featuresSubTitle_ASC = 'featuresSubTitle_ASC',
  featuresSubTitle_DESC = 'featuresSubTitle_DESC',
  featuresTitle_ASC = 'featuresTitle_ASC',
  featuresTitle_DESC = 'featuresTitle_DESC',
  showCaseSubTitle_ASC = 'showCaseSubTitle_ASC',
  showCaseSubTitle_DESC = 'showCaseSubTitle_DESC',
  showCaseTitle_ASC = 'showCaseTitle_ASC',
  showCaseTitle_DESC = 'showCaseTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  trustedPartnersTitle_ASC = 'trustedPartnersTitle_ASC',
  trustedPartnersTitle_DESC = 'trustedPartnersTitle_DESC'
}

export enum AnnouncementLinkingCollectionsLandingPageCollectionOrder {
  ageRequirementsAccentBox_ASC = 'ageRequirementsAccentBox_ASC',
  ageRequirementsAccentBox_DESC = 'ageRequirementsAccentBox_DESC',
  bookingFormSearchText_ASC = 'bookingFormSearchText_ASC',
  bookingFormSearchText_DESC = 'bookingFormSearchText_DESC',
  bookingFormTitle_ASC = 'bookingFormTitle_ASC',
  bookingFormTitle_DESC = 'bookingFormTitle_DESC',
  budgetCategoryMaxPrice_ASC = 'budgetCategoryMaxPrice_ASC',
  budgetCategoryMaxPrice_DESC = 'budgetCategoryMaxPrice_DESC',
  cardPricesFromText_ASC = 'cardPricesFromText_ASC',
  cardPricesFromText_DESC = 'cardPricesFromText_DESC',
  dateInputLabel_ASC = 'dateInputLabel_ASC',
  dateInputLabel_DESC = 'dateInputLabel_DESC',
  dateInputPlaceholder_ASC = 'dateInputPlaceholder_ASC',
  dateInputPlaceholder_DESC = 'dateInputPlaceholder_DESC',
  downloadAppTitle_ASC = 'downloadAppTitle_ASC',
  downloadAppTitle_DESC = 'downloadAppTitle_DESC',
  electricPropellantValue_ASC = 'electricPropellantValue_ASC',
  electricPropellantValue_DESC = 'electricPropellantValue_DESC',
  faqSectionTitle_ASC = 'faqSectionTitle_ASC',
  faqSectionTitle_DESC = 'faqSectionTitle_DESC',
  garageLabel_ASC = 'garageLabel_ASC',
  garageLabel_DESC = 'garageLabel_DESC',
  heroTitleText_ASC = 'heroTitleText_ASC',
  heroTitleText_DESC = 'heroTitleText_DESC',
  locationDropdownLabel_ASC = 'locationDropdownLabel_ASC',
  locationDropdownLabel_DESC = 'locationDropdownLabel_DESC',
  locationDropdownPlaceholder_ASC = 'locationDropdownPlaceholder_ASC',
  locationDropdownPlaceholder_DESC = 'locationDropdownPlaceholder_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum AnnouncementLinkingCollectionsProductPageCollectionOrder {
  addOnsInfoText_ASC = 'addOnsInfoText_ASC',
  addOnsInfoText_DESC = 'addOnsInfoText_DESC',
  economyTableBottomText_ASC = 'economyTableBottomText_ASC',
  economyTableBottomText_DESC = 'economyTableBottomText_DESC',
  hideMsrpTaxContainer_ASC = 'hideMsrpTaxContainer_ASC',
  hideMsrpTaxContainer_DESC = 'hideMsrpTaxContainer_DESC',
  includeLinkToPricingPage_ASC = 'includeLinkToPricingPage_ASC',
  includeLinkToPricingPage_DESC = 'includeLinkToPricingPage_DESC',
  priceIncludesText_ASC = 'priceIncludesText_ASC',
  priceIncludesText_DESC = 'priceIncludesText_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum AnnouncementOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset';
  contentType?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
  size?: Maybe<Scalars['Int']['output']>;
  sys: Sys;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  transform?: InputMaybe<ImageTransformOptions>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  items: Array<Maybe<Asset>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentType_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentType_not?: InputMaybe<Scalars['String']['input']>;
  contentType_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileName_contains?: InputMaybe<Scalars['String']['input']>;
  fileName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fileName_not?: InputMaybe<Scalars['String']['input']>;
  fileName_not_contains?: InputMaybe<Scalars['String']['input']>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_exists?: InputMaybe<Scalars['Boolean']['input']>;
  height_gt?: InputMaybe<Scalars['Int']['input']>;
  height_gte?: InputMaybe<Scalars['Int']['input']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  height_lt?: InputMaybe<Scalars['Int']['input']>;
  height_lte?: InputMaybe<Scalars['Int']['input']>;
  height_not?: InputMaybe<Scalars['Int']['input']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  size_exists?: InputMaybe<Scalars['Boolean']['input']>;
  size_gt?: InputMaybe<Scalars['Int']['input']>;
  size_gte?: InputMaybe<Scalars['Int']['input']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size_lt?: InputMaybe<Scalars['Int']['input']>;
  size_lte?: InputMaybe<Scalars['Int']['input']>;
  size_not?: InputMaybe<Scalars['Int']['input']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  width?: InputMaybe<Scalars['Int']['input']>;
  width_exists?: InputMaybe<Scalars['Boolean']['input']>;
  width_gt?: InputMaybe<Scalars['Int']['input']>;
  width_gte?: InputMaybe<Scalars['Int']['input']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  width_lt?: InputMaybe<Scalars['Int']['input']>;
  width_lte?: InputMaybe<Scalars['Int']['input']>;
  width_not?: InputMaybe<Scalars['Int']['input']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  b2BShowcaseModelCollection?: Maybe<B2BShowcaseModelCollection>;
  blogEntryCollection?: Maybe<BlogEntryCollection>;
  comingSoonPageCollection?: Maybe<ComingSoonPageCollection>;
  contactPageCollection?: Maybe<ContactPageCollection>;
  damagesCardCollection?: Maybe<DamagesCardCollection>;
  damagesPolicyPageCollection?: Maybe<DamagesPolicyPageCollection>;
  demoPageCollection?: Maybe<DemoPageCollection>;
  demoSlideCollection?: Maybe<DemoSlideCollection>;
  downloadPageCollection?: Maybe<DownloadPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
  faqPageCollection?: Maybe<FaqPageCollection>;
  garagePageCollection?: Maybe<GaragePageCollection>;
  landingPageB2BCollection?: Maybe<LandingPageB2BCollection>;
  landingPageCollection?: Maybe<LandingPageCollection>;
  outOfStockPageCollection?: Maybe<OutOfStockPageCollection>;
  partnersCollection?: Maybe<PartnersCollection>;
  pricingRideCardCollection?: Maybe<PricingRideCardCollection>;
  seoMetadataCollection?: Maybe<SeoMetadataCollection>;
};

export type AssetLinkingCollectionsB2BShowcaseModelCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsBlogEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsComingSoonPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsContactPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsDamagesCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsDamagesPolicyPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsDemoPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsDemoSlideCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsDownloadPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsFaqPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsGaragePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsLandingPageB2BCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsOutOfStockPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsPartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsPricingRideCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsSeoMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum AssetOrder {
  contentType_ASC = 'contentType_ASC',
  contentType_DESC = 'contentType_DESC',
  fileName_ASC = 'fileName_ASC',
  fileName_DESC = 'fileName_DESC',
  height_ASC = 'height_ASC',
  height_DESC = 'height_DESC',
  size_ASC = 'size_ASC',
  size_DESC = 'size_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  url_ASC = 'url_ASC',
  url_DESC = 'url_DESC',
  width_ASC = 'width_ASC',
  width_DESC = 'width_DESC'
}

/** Grey box at the bottom of Contact page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/availableGaragesCard) */
export type AvailableGaragesCard = Entry &
  _Node & {
    __typename?: 'AvailableGaragesCard';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    garagesList?: Maybe<AvailableGaragesCardGaragesList>;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<AvailableGaragesCardLinkingCollections>;
    sys: Sys;
  };

/** Grey box at the bottom of Contact page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/availableGaragesCard) */
export type AvailableGaragesCardGaragesListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Grey box at the bottom of Contact page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/availableGaragesCard) */
export type AvailableGaragesCardHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Grey box at the bottom of Contact page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/availableGaragesCard) */
export type AvailableGaragesCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AvailableGaragesCardCollection = {
  __typename?: 'AvailableGaragesCardCollection';
  items: Array<Maybe<AvailableGaragesCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AvailableGaragesCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<AvailableGaragesCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AvailableGaragesCardFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  garagesList_contains?: InputMaybe<Scalars['String']['input']>;
  garagesList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  garagesList_not_contains?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type AvailableGaragesCardGaragesList = {
  __typename?: 'AvailableGaragesCardGaragesList';
  json: Scalars['JSON']['output'];
  links: AvailableGaragesCardGaragesListLinks;
};

export type AvailableGaragesCardGaragesListAssets = {
  __typename?: 'AvailableGaragesCardGaragesListAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AvailableGaragesCardGaragesListEntries = {
  __typename?: 'AvailableGaragesCardGaragesListEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AvailableGaragesCardGaragesListLinks = {
  __typename?: 'AvailableGaragesCardGaragesListLinks';
  assets: AvailableGaragesCardGaragesListAssets;
  entries: AvailableGaragesCardGaragesListEntries;
  resources: AvailableGaragesCardGaragesListResources;
};

export type AvailableGaragesCardGaragesListResources = {
  __typename?: 'AvailableGaragesCardGaragesListResources';
  block: Array<AvailableGaragesCardGaragesListResourcesBlock>;
  hyperlink: Array<AvailableGaragesCardGaragesListResourcesHyperlink>;
  inline: Array<AvailableGaragesCardGaragesListResourcesInline>;
};

export type AvailableGaragesCardGaragesListResourcesBlock = ResourceLink & {
  __typename?: 'AvailableGaragesCardGaragesListResourcesBlock';
  sys: ResourceSys;
};

export type AvailableGaragesCardGaragesListResourcesHyperlink = ResourceLink & {
  __typename?: 'AvailableGaragesCardGaragesListResourcesHyperlink';
  sys: ResourceSys;
};

export type AvailableGaragesCardGaragesListResourcesInline = ResourceLink & {
  __typename?: 'AvailableGaragesCardGaragesListResourcesInline';
  sys: ResourceSys;
};

export type AvailableGaragesCardLinkingCollections = {
  __typename?: 'AvailableGaragesCardLinkingCollections';
  contactPageCollection?: Maybe<ContactPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type AvailableGaragesCardLinkingCollectionsContactPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<AvailableGaragesCardLinkingCollectionsContactPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AvailableGaragesCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum AvailableGaragesCardLinkingCollectionsContactPageCollectionOrder {
  assistanceAndDamagesHeader_ASC = 'assistanceAndDamagesHeader_ASC',
  assistanceAndDamagesHeader_DESC = 'assistanceAndDamagesHeader_DESC',
  contactHeader_ASC = 'contactHeader_ASC',
  contactHeader_DESC = 'contactHeader_DESC',
  findGaragesHeader_ASC = 'findGaragesHeader_ASC',
  findGaragesHeader_DESC = 'findGaragesHeader_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum AvailableGaragesCardOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** Model for showcasing [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/b2bShowcaseModel) */
export type B2BShowcaseModel = Entry &
  _Node & {
    __typename?: 'B2BShowcaseModel';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    features?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<B2BShowcaseModelLinkingCollections>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    urlSlug?: Maybe<Scalars['String']['output']>;
    urlText?: Maybe<Scalars['String']['output']>;
  };

/** Model for showcasing [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/b2bShowcaseModel) */
export type B2BShowcaseModelFeaturesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Model for showcasing [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/b2bShowcaseModel) */
export type B2BShowcaseModelImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Model for showcasing [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/b2bShowcaseModel) */
export type B2BShowcaseModelLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Model for showcasing [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/b2bShowcaseModel) */
export type B2BShowcaseModelTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Model for showcasing [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/b2bShowcaseModel) */
export type B2BShowcaseModelUrlSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Model for showcasing [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/b2bShowcaseModel) */
export type B2BShowcaseModelUrlTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type B2BShowcaseModelCollection = {
  __typename?: 'B2BShowcaseModelCollection';
  items: Array<Maybe<B2BShowcaseModel>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type B2BShowcaseModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<B2BShowcaseModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<B2BShowcaseModelFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  features_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  features_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  features_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  features_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlSlug?: InputMaybe<Scalars['String']['input']>;
  urlSlug_contains?: InputMaybe<Scalars['String']['input']>;
  urlSlug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  urlSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlSlug_not?: InputMaybe<Scalars['String']['input']>;
  urlSlug_not_contains?: InputMaybe<Scalars['String']['input']>;
  urlSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlText?: InputMaybe<Scalars['String']['input']>;
  urlText_contains?: InputMaybe<Scalars['String']['input']>;
  urlText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  urlText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlText_not?: InputMaybe<Scalars['String']['input']>;
  urlText_not_contains?: InputMaybe<Scalars['String']['input']>;
  urlText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type B2BShowcaseModelLinkingCollections = {
  __typename?: 'B2BShowcaseModelLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  landingPageB2BCollection?: Maybe<LandingPageB2BCollection>;
};

export type B2BShowcaseModelLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type B2BShowcaseModelLinkingCollectionsLandingPageB2BCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<B2BShowcaseModelLinkingCollectionsLandingPageB2BCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum B2BShowcaseModelLinkingCollectionsLandingPageB2BCollectionOrder {
  blogSectionTitle_ASC = 'blogSectionTitle_ASC',
  blogSectionTitle_DESC = 'blogSectionTitle_DESC',
  contactSectionSubtitle_ASC = 'contactSectionSubtitle_ASC',
  contactSectionSubtitle_DESC = 'contactSectionSubtitle_DESC',
  contactSectionTitle_ASC = 'contactSectionTitle_ASC',
  contactSectionTitle_DESC = 'contactSectionTitle_DESC',
  faqSectionTitle_ASC = 'faqSectionTitle_ASC',
  faqSectionTitle_DESC = 'faqSectionTitle_DESC',
  featuresSubTitle_ASC = 'featuresSubTitle_ASC',
  featuresSubTitle_DESC = 'featuresSubTitle_DESC',
  featuresTitle_ASC = 'featuresTitle_ASC',
  featuresTitle_DESC = 'featuresTitle_DESC',
  showCaseSubTitle_ASC = 'showCaseSubTitle_ASC',
  showCaseSubTitle_DESC = 'showCaseSubTitle_DESC',
  showCaseTitle_ASC = 'showCaseTitle_ASC',
  showCaseTitle_DESC = 'showCaseTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  trustedPartnersTitle_ASC = 'trustedPartnersTitle_ASC',
  trustedPartnersTitle_DESC = 'trustedPartnersTitle_DESC'
}

export enum B2BShowcaseModelOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  urlSlug_ASC = 'urlSlug_ASC',
  urlSlug_DESC = 'urlSlug_DESC',
  urlText_ASC = 'urlText_ASC',
  urlText_DESC = 'urlText_DESC'
}

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntry = Entry &
  _Node & {
    __typename?: 'BlogEntry';
    _id: Scalars['ID']['output'];
    category?: Maybe<BlogTopics>;
    content?: Maybe<BlogEntryContent>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<BlogEntryLinkingCollections>;
    newsletterSection?: Maybe<NewsletterContent>;
    path?: Maybe<Scalars['String']['output']>;
    previewText?: Maybe<Scalars['String']['output']>;
    seoMetadata?: Maybe<SeoMetadata>;
    site?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    titleImage?: Maybe<Asset>;
    titlePrefix?: Maybe<Scalars['String']['output']>;
  };

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntryCategoryArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BlogTopicsFilter>;
};

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntryContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntryNewsletterSectionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<NewsletterContentFilter>;
};

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntryPathArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntryPreviewTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntrySeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntrySiteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntrySlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntryTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntryTitleImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An entry for the blog [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogEntry) */
export type BlogEntryTitlePrefixArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type BlogEntryCollection = {
  __typename?: 'BlogEntryCollection';
  items: Array<Maybe<BlogEntry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BlogEntryContent = {
  __typename?: 'BlogEntryContent';
  json: Scalars['JSON']['output'];
  links: BlogEntryContentLinks;
};

export type BlogEntryContentAssets = {
  __typename?: 'BlogEntryContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BlogEntryContentEntries = {
  __typename?: 'BlogEntryContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BlogEntryContentLinks = {
  __typename?: 'BlogEntryContentLinks';
  assets: BlogEntryContentAssets;
  entries: BlogEntryContentEntries;
  resources: BlogEntryContentResources;
};

export type BlogEntryContentResources = {
  __typename?: 'BlogEntryContentResources';
  block: Array<BlogEntryContentResourcesBlock>;
  hyperlink: Array<BlogEntryContentResourcesHyperlink>;
  inline: Array<BlogEntryContentResourcesInline>;
};

export type BlogEntryContentResourcesBlock = ResourceLink & {
  __typename?: 'BlogEntryContentResourcesBlock';
  sys: ResourceSys;
};

export type BlogEntryContentResourcesHyperlink = ResourceLink & {
  __typename?: 'BlogEntryContentResourcesHyperlink';
  sys: ResourceSys;
};

export type BlogEntryContentResourcesInline = ResourceLink & {
  __typename?: 'BlogEntryContentResourcesInline';
  sys: ResourceSys;
};

export type BlogEntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlogEntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BlogEntryFilter>>>;
  category?: InputMaybe<CfBlogTopicsNestedFilter>;
  category_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  newsletterSection?: InputMaybe<CfNewsletterContentNestedFilter>;
  newsletterSection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  path_contains?: InputMaybe<Scalars['String']['input']>;
  path_exists?: InputMaybe<Scalars['Boolean']['input']>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  path_not?: InputMaybe<Scalars['String']['input']>;
  path_not_contains?: InputMaybe<Scalars['String']['input']>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  previewText?: InputMaybe<Scalars['String']['input']>;
  previewText_contains?: InputMaybe<Scalars['String']['input']>;
  previewText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  previewText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  previewText_not?: InputMaybe<Scalars['String']['input']>;
  previewText_not_contains?: InputMaybe<Scalars['String']['input']>;
  previewText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  site_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  site_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  site_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  site_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titlePrefix?: InputMaybe<Scalars['String']['input']>;
  titlePrefix_contains?: InputMaybe<Scalars['String']['input']>;
  titlePrefix_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titlePrefix_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titlePrefix_not?: InputMaybe<Scalars['String']['input']>;
  titlePrefix_not_contains?: InputMaybe<Scalars['String']['input']>;
  titlePrefix_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type BlogEntryLinkingCollections = {
  __typename?: 'BlogEntryLinkingCollections';
  blogPageCollection?: Maybe<BlogPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
  landingPageB2BCollection?: Maybe<LandingPageB2BCollection>;
};

export type BlogEntryLinkingCollectionsBlogPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<BlogEntryLinkingCollectionsBlogPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type BlogEntryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type BlogEntryLinkingCollectionsLandingPageB2BCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<BlogEntryLinkingCollectionsLandingPageB2BCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum BlogEntryLinkingCollectionsBlogPageCollectionOrder {
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum BlogEntryLinkingCollectionsLandingPageB2BCollectionOrder {
  blogSectionTitle_ASC = 'blogSectionTitle_ASC',
  blogSectionTitle_DESC = 'blogSectionTitle_DESC',
  contactSectionSubtitle_ASC = 'contactSectionSubtitle_ASC',
  contactSectionSubtitle_DESC = 'contactSectionSubtitle_DESC',
  contactSectionTitle_ASC = 'contactSectionTitle_ASC',
  contactSectionTitle_DESC = 'contactSectionTitle_DESC',
  faqSectionTitle_ASC = 'faqSectionTitle_ASC',
  faqSectionTitle_DESC = 'faqSectionTitle_DESC',
  featuresSubTitle_ASC = 'featuresSubTitle_ASC',
  featuresSubTitle_DESC = 'featuresSubTitle_DESC',
  featuresTitle_ASC = 'featuresTitle_ASC',
  featuresTitle_DESC = 'featuresTitle_DESC',
  showCaseSubTitle_ASC = 'showCaseSubTitle_ASC',
  showCaseSubTitle_DESC = 'showCaseSubTitle_DESC',
  showCaseTitle_ASC = 'showCaseTitle_ASC',
  showCaseTitle_DESC = 'showCaseTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  trustedPartnersTitle_ASC = 'trustedPartnersTitle_ASC',
  trustedPartnersTitle_DESC = 'trustedPartnersTitle_DESC'
}

export enum BlogEntryOrder {
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  previewText_ASC = 'previewText_ASC',
  previewText_DESC = 'previewText_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  titlePrefix_ASC = 'titlePrefix_ASC',
  titlePrefix_DESC = 'titlePrefix_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** Page for where all the articles live. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogPage) */
export type BlogPage = Entry &
  _Node & {
    __typename?: 'BlogPage';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    highlightedBlogPost?: Maybe<BlogEntry>;
    linkedFrom?: Maybe<BlogPageLinkingCollections>;
    newsletterSection?: Maybe<NewsletterContent>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Page for where all the articles live. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogPage) */
export type BlogPageHighlightedBlogPostArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BlogEntryFilter>;
};

/** Page for where all the articles live. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogPage) */
export type BlogPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Page for where all the articles live. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogPage) */
export type BlogPageNewsletterSectionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<NewsletterContentFilter>;
};

/** Page for where all the articles live. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogPage) */
export type BlogPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** Page for where all the articles live. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogPage) */
export type BlogPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Page for where all the articles live. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogPage) */
export type BlogPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type BlogPageCollection = {
  __typename?: 'BlogPageCollection';
  items: Array<Maybe<BlogPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BlogPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlogPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BlogPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  highlightedBlogPost?: InputMaybe<CfBlogEntryNestedFilter>;
  highlightedBlogPost_exists?: InputMaybe<Scalars['Boolean']['input']>;
  newsletterSection?: InputMaybe<CfNewsletterContentNestedFilter>;
  newsletterSection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type BlogPageLinkingCollections = {
  __typename?: 'BlogPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type BlogPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum BlogPageOrder {
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogTopics) */
export type BlogTopics = Entry &
  _Node & {
    __typename?: 'BlogTopics';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<BlogTopicsLinkingCollections>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogTopics) */
export type BlogTopicsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/blogTopics) */
export type BlogTopicsTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type BlogTopicsCollection = {
  __typename?: 'BlogTopicsCollection';
  items: Array<Maybe<BlogTopics>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BlogTopicsFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlogTopicsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BlogTopicsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type BlogTopicsLinkingCollections = {
  __typename?: 'BlogTopicsLinkingCollections';
  blogEntryCollection?: Maybe<BlogEntryCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type BlogTopicsLinkingCollectionsBlogEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<BlogTopicsLinkingCollectionsBlogEntryCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type BlogTopicsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum BlogTopicsLinkingCollectionsBlogEntryCollectionOrder {
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  previewText_ASC = 'previewText_ASC',
  previewText_DESC = 'previewText_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  titlePrefix_ASC = 'titlePrefix_ASC',
  titlePrefix_DESC = 'titlePrefix_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum BlogTopicsOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/brandContact) */
export type BrandContact = Entry &
  _Node & {
    __typename?: 'BrandContact';
    _id: Scalars['ID']['output'];
    brandContactInfo?: Maybe<ContactInfoLink>;
    brandName?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<BrandContactLinkingCollections>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/brandContact) */
export type BrandContactBrandContactInfoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ContactInfoLinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/brandContact) */
export type BrandContactBrandNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/brandContact) */
export type BrandContactLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type BrandContactCollection = {
  __typename?: 'BrandContactCollection';
  items: Array<Maybe<BrandContact>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BrandContactFilter = {
  AND?: InputMaybe<Array<InputMaybe<BrandContactFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BrandContactFilter>>>;
  brandContactInfo?: InputMaybe<CfContactInfoLinkNestedFilter>;
  brandContactInfo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  brandName?: InputMaybe<Scalars['String']['input']>;
  brandName_contains?: InputMaybe<Scalars['String']['input']>;
  brandName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  brandName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandName_not?: InputMaybe<Scalars['String']['input']>;
  brandName_not_contains?: InputMaybe<Scalars['String']['input']>;
  brandName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type BrandContactLinkingCollections = {
  __typename?: 'BrandContactLinkingCollections';
  damagesContactPageCollection?: Maybe<DamagesContactPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type BrandContactLinkingCollectionsDamagesContactPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<BrandContactLinkingCollectionsDamagesContactPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type BrandContactLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum BrandContactLinkingCollectionsDamagesContactPageCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  startProblemsHeader_ASC = 'startProblemsHeader_ASC',
  startProblemsHeader_DESC = 'startProblemsHeader_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum BrandContactOrder {
  brandName_ASC = 'brandName_ASC',
  brandName_DESC = 'brandName_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/campaignEntry) */
export type CampaignEntry = Entry &
  _Node & {
    __typename?: 'CampaignEntry';
    _id: Scalars['ID']['output'];
    content?: Maybe<CampaignEntryContent>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<CampaignEntryLinkingCollections>;
    path?: Maybe<Scalars['String']['output']>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/campaignEntry) */
export type CampaignEntryContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/campaignEntry) */
export type CampaignEntryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/campaignEntry) */
export type CampaignEntryPathArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/campaignEntry) */
export type CampaignEntrySeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/campaignEntry) */
export type CampaignEntrySlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/campaignEntry) */
export type CampaignEntryTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CampaignEntryCollection = {
  __typename?: 'CampaignEntryCollection';
  items: Array<Maybe<CampaignEntry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CampaignEntryContent = {
  __typename?: 'CampaignEntryContent';
  json: Scalars['JSON']['output'];
  links: CampaignEntryContentLinks;
};

export type CampaignEntryContentAssets = {
  __typename?: 'CampaignEntryContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CampaignEntryContentEntries = {
  __typename?: 'CampaignEntryContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CampaignEntryContentLinks = {
  __typename?: 'CampaignEntryContentLinks';
  assets: CampaignEntryContentAssets;
  entries: CampaignEntryContentEntries;
  resources: CampaignEntryContentResources;
};

export type CampaignEntryContentResources = {
  __typename?: 'CampaignEntryContentResources';
  block: Array<CampaignEntryContentResourcesBlock>;
  hyperlink: Array<CampaignEntryContentResourcesHyperlink>;
  inline: Array<CampaignEntryContentResourcesInline>;
};

export type CampaignEntryContentResourcesBlock = ResourceLink & {
  __typename?: 'CampaignEntryContentResourcesBlock';
  sys: ResourceSys;
};

export type CampaignEntryContentResourcesHyperlink = ResourceLink & {
  __typename?: 'CampaignEntryContentResourcesHyperlink';
  sys: ResourceSys;
};

export type CampaignEntryContentResourcesInline = ResourceLink & {
  __typename?: 'CampaignEntryContentResourcesInline';
  sys: ResourceSys;
};

export type CampaignEntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<CampaignEntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CampaignEntryFilter>>>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  path?: InputMaybe<Scalars['String']['input']>;
  path_contains?: InputMaybe<Scalars['String']['input']>;
  path_exists?: InputMaybe<Scalars['Boolean']['input']>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  path_not?: InputMaybe<Scalars['String']['input']>;
  path_not_contains?: InputMaybe<Scalars['String']['input']>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CampaignEntryLinkingCollections = {
  __typename?: 'CampaignEntryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CampaignEntryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CampaignEntryOrder {
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/collapsableContainer) */
export type CollapsableContainer = Entry &
  _Node & {
    __typename?: 'CollapsableContainer';
    _id: Scalars['ID']['output'];
    content?: Maybe<CollapsableContainerContent>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<CollapsableContainerLinkingCollections>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/collapsableContainer) */
export type CollapsableContainerContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/collapsableContainer) */
export type CollapsableContainerHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/collapsableContainer) */
export type CollapsableContainerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CollapsableContainerCollection = {
  __typename?: 'CollapsableContainerCollection';
  items: Array<Maybe<CollapsableContainer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CollapsableContainerContent = {
  __typename?: 'CollapsableContainerContent';
  json: Scalars['JSON']['output'];
  links: CollapsableContainerContentLinks;
};

export type CollapsableContainerContentAssets = {
  __typename?: 'CollapsableContainerContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CollapsableContainerContentEntries = {
  __typename?: 'CollapsableContainerContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CollapsableContainerContentLinks = {
  __typename?: 'CollapsableContainerContentLinks';
  assets: CollapsableContainerContentAssets;
  entries: CollapsableContainerContentEntries;
  resources: CollapsableContainerContentResources;
};

export type CollapsableContainerContentResources = {
  __typename?: 'CollapsableContainerContentResources';
  block: Array<CollapsableContainerContentResourcesBlock>;
  hyperlink: Array<CollapsableContainerContentResourcesHyperlink>;
  inline: Array<CollapsableContainerContentResourcesInline>;
};

export type CollapsableContainerContentResourcesBlock = ResourceLink & {
  __typename?: 'CollapsableContainerContentResourcesBlock';
  sys: ResourceSys;
};

export type CollapsableContainerContentResourcesHyperlink = ResourceLink & {
  __typename?: 'CollapsableContainerContentResourcesHyperlink';
  sys: ResourceSys;
};

export type CollapsableContainerContentResourcesInline = ResourceLink & {
  __typename?: 'CollapsableContainerContentResourcesInline';
  sys: ResourceSys;
};

export type CollapsableContainerFilter = {
  AND?: InputMaybe<Array<InputMaybe<CollapsableContainerFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CollapsableContainerFilter>>>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CollapsableContainerLinkingCollections = {
  __typename?: 'CollapsableContainerLinkingCollections';
  damagesPolicyPageCollection?: Maybe<DamagesPolicyPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type CollapsableContainerLinkingCollectionsDamagesPolicyPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale?: InputMaybe<Scalars['String']['input']>;
    order?: InputMaybe<
      Array<
        InputMaybe<CollapsableContainerLinkingCollectionsDamagesPolicyPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type CollapsableContainerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CollapsableContainerLinkingCollectionsDamagesPolicyPageCollectionOrder {
  assistedInspectionHeader_ASC = 'assistedInspectionHeader_ASC',
  assistedInspectionHeader_DESC = 'assistedInspectionHeader_DESC',
  collapseContainerHeader_ASC = 'collapseContainerHeader_ASC',
  collapseContainerHeader_DESC = 'collapseContainerHeader_DESC',
  faqHeader_ASC = 'faqHeader_ASC',
  faqHeader_DESC = 'faqHeader_DESC',
  howItWorksHeader_ASC = 'howItWorksHeader_ASC',
  howItWorksHeader_DESC = 'howItWorksHeader_DESC',
  insuranceCoverHeader_ASC = 'insuranceCoverHeader_ASC',
  insuranceCoverHeader_DESC = 'insuranceCoverHeader_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  withAssistedInspection_ASC = 'withAssistedInspection_ASC',
  withAssistedInspection_DESC = 'withAssistedInspection_DESC'
}

export enum CollapsableContainerOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/comingSoonPage) */
export type ComingSoonPage = Entry &
  _Node & {
    __typename?: 'ComingSoonPage';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<ComingSoonPageDescription>;
    header?: Maybe<Scalars['String']['output']>;
    headerImage?: Maybe<Asset>;
    linkedFrom?: Maybe<ComingSoonPageLinkingCollections>;
    previewLocationsCollection?: Maybe<ComingSoonPagePreviewLocationsCollection>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    wd?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/comingSoonPage) */
export type ComingSoonPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/comingSoonPage) */
export type ComingSoonPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/comingSoonPage) */
export type ComingSoonPageHeaderImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/comingSoonPage) */
export type ComingSoonPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/comingSoonPage) */
export type ComingSoonPagePreviewLocationsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<ComingSoonPagePreviewLocationsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PreviewLocationsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/comingSoonPage) */
export type ComingSoonPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/comingSoonPage) */
export type ComingSoonPageWdArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ComingSoonPageCollection = {
  __typename?: 'ComingSoonPageCollection';
  items: Array<Maybe<ComingSoonPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ComingSoonPageDescription = {
  __typename?: 'ComingSoonPageDescription';
  json: Scalars['JSON']['output'];
  links: ComingSoonPageDescriptionLinks;
};

export type ComingSoonPageDescriptionAssets = {
  __typename?: 'ComingSoonPageDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ComingSoonPageDescriptionEntries = {
  __typename?: 'ComingSoonPageDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ComingSoonPageDescriptionLinks = {
  __typename?: 'ComingSoonPageDescriptionLinks';
  assets: ComingSoonPageDescriptionAssets;
  entries: ComingSoonPageDescriptionEntries;
  resources: ComingSoonPageDescriptionResources;
};

export type ComingSoonPageDescriptionResources = {
  __typename?: 'ComingSoonPageDescriptionResources';
  block: Array<ComingSoonPageDescriptionResourcesBlock>;
  hyperlink: Array<ComingSoonPageDescriptionResourcesHyperlink>;
  inline: Array<ComingSoonPageDescriptionResourcesInline>;
};

export type ComingSoonPageDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'ComingSoonPageDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type ComingSoonPageDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'ComingSoonPageDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type ComingSoonPageDescriptionResourcesInline = ResourceLink & {
  __typename?: 'ComingSoonPageDescriptionResourcesInline';
  sys: ResourceSys;
};

export type ComingSoonPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComingSoonPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ComingSoonPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  headerImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  previewLocations?: InputMaybe<CfPreviewLocationsNestedFilter>;
  previewLocationsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  wd?: InputMaybe<Scalars['String']['input']>;
  wd_contains?: InputMaybe<Scalars['String']['input']>;
  wd_exists?: InputMaybe<Scalars['Boolean']['input']>;
  wd_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  wd_not?: InputMaybe<Scalars['String']['input']>;
  wd_not_contains?: InputMaybe<Scalars['String']['input']>;
  wd_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComingSoonPageLinkingCollections = {
  __typename?: 'ComingSoonPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ComingSoonPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ComingSoonPageOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  wd_ASC = 'wd_ASC',
  wd_DESC = 'wd_DESC'
}

export type ComingSoonPagePreviewLocationsCollection = {
  __typename?: 'ComingSoonPagePreviewLocationsCollection';
  items: Array<Maybe<PreviewLocations>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ComingSoonPagePreviewLocationsCollectionOrder {
  address1_ASC = 'address1_ASC',
  address1_DESC = 'address1_DESC',
  address2_ASC = 'address2_ASC',
  address2_DESC = 'address2_DESC',
  city_ASC = 'city_ASC',
  city_DESC = 'city_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** Grey box with contact information for the Contact Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactCard) */
export type ContactCard = Entry &
  _Node & {
    __typename?: 'ContactCard';
    _id: Scalars['ID']['output'];
    contactDescription?: Maybe<ContactCardContactDescription>;
    contactInfo?: Maybe<ContactInfoLink>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<DribeIcon>;
    linkedFrom?: Maybe<ContactCardLinkingCollections>;
    sys: Sys;
    underlinedSubLink?: Maybe<Link>;
  };

/** Grey box with contact information for the Contact Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactCard) */
export type ContactCardContactDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Grey box with contact information for the Contact Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactCard) */
export type ContactCardContactInfoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ContactInfoLinkFilter>;
};

/** Grey box with contact information for the Contact Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactCard) */
export type ContactCardHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Grey box with contact information for the Contact Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactCard) */
export type ContactCardIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DribeIconFilter>;
};

/** Grey box with contact information for the Contact Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactCard) */
export type ContactCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Grey box with contact information for the Contact Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactCard) */
export type ContactCardUnderlinedSubLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

export type ContactCardCollection = {
  __typename?: 'ContactCardCollection';
  items: Array<Maybe<ContactCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContactCardContactDescription = {
  __typename?: 'ContactCardContactDescription';
  json: Scalars['JSON']['output'];
  links: ContactCardContactDescriptionLinks;
};

export type ContactCardContactDescriptionAssets = {
  __typename?: 'ContactCardContactDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ContactCardContactDescriptionEntries = {
  __typename?: 'ContactCardContactDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ContactCardContactDescriptionLinks = {
  __typename?: 'ContactCardContactDescriptionLinks';
  assets: ContactCardContactDescriptionAssets;
  entries: ContactCardContactDescriptionEntries;
  resources: ContactCardContactDescriptionResources;
};

export type ContactCardContactDescriptionResources = {
  __typename?: 'ContactCardContactDescriptionResources';
  block: Array<ContactCardContactDescriptionResourcesBlock>;
  hyperlink: Array<ContactCardContactDescriptionResourcesHyperlink>;
  inline: Array<ContactCardContactDescriptionResourcesInline>;
};

export type ContactCardContactDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'ContactCardContactDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type ContactCardContactDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'ContactCardContactDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type ContactCardContactDescriptionResourcesInline = ResourceLink & {
  __typename?: 'ContactCardContactDescriptionResourcesInline';
  sys: ResourceSys;
};

export type ContactCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContactCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContactCardFilter>>>;
  contactDescription_contains?: InputMaybe<Scalars['String']['input']>;
  contactDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfo?: InputMaybe<CfContactInfoLinkNestedFilter>;
  contactInfo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  icon?: InputMaybe<CfDribeIconNestedFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  underlinedSubLink?: InputMaybe<CfLinkNestedFilter>;
  underlinedSubLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactCardLinkingCollections = {
  __typename?: 'ContactCardLinkingCollections';
  contactPageB2BCollection?: Maybe<ContactPageB2BCollection>;
  contactPageCollection?: Maybe<ContactPageCollection>;
  damagesContactPageCollection?: Maybe<DamagesContactPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type ContactCardLinkingCollectionsContactPageB2BCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContactCardLinkingCollectionsContactPageB2BCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactCardLinkingCollectionsContactPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<ContactCardLinkingCollectionsContactPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactCardLinkingCollectionsDamagesContactPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContactCardLinkingCollectionsDamagesContactPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContactCardLinkingCollectionsContactPageB2BCollectionOrder {
  companyIdInputLabel_ASC = 'companyIdInputLabel_ASC',
  companyIdInputLabel_DESC = 'companyIdInputLabel_DESC',
  companyIdRequired_ASC = 'companyIdRequired_ASC',
  companyIdRequired_DESC = 'companyIdRequired_DESC',
  featureDescription_ASC = 'featureDescription_ASC',
  featureDescription_DESC = 'featureDescription_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  submitTitle_ASC = 'submitTitle_ASC',
  submitTitle_DESC = 'submitTitle_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum ContactCardLinkingCollectionsContactPageCollectionOrder {
  assistanceAndDamagesHeader_ASC = 'assistanceAndDamagesHeader_ASC',
  assistanceAndDamagesHeader_DESC = 'assistanceAndDamagesHeader_DESC',
  contactHeader_ASC = 'contactHeader_ASC',
  contactHeader_DESC = 'contactHeader_DESC',
  findGaragesHeader_ASC = 'findGaragesHeader_ASC',
  findGaragesHeader_DESC = 'findGaragesHeader_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum ContactCardLinkingCollectionsDamagesContactPageCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  startProblemsHeader_ASC = 'startProblemsHeader_ASC',
  startProblemsHeader_DESC = 'startProblemsHeader_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum ContactCardOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactInfoLink) */
export type ContactInfoLink = Entry &
  _Node & {
    __typename?: 'ContactInfoLink';
    _id: Scalars['ID']['output'];
    contactInfo?: Maybe<Scalars['String']['output']>;
    contactInfoType?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<ContactInfoLinkLinkingCollections>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactInfoLink) */
export type ContactInfoLinkContactInfoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactInfoLink) */
export type ContactInfoLinkContactInfoTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactInfoLink) */
export type ContactInfoLinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContactInfoLinkCollection = {
  __typename?: 'ContactInfoLinkCollection';
  items: Array<Maybe<ContactInfoLink>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContactInfoLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContactInfoLinkFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContactInfoLinkFilter>>>;
  contactInfo?: InputMaybe<Scalars['String']['input']>;
  contactInfoType?: InputMaybe<Scalars['String']['input']>;
  contactInfoType_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfoType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactInfoType_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contactInfoType_not?: InputMaybe<Scalars['String']['input']>;
  contactInfoType_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfoType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contactInfo_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactInfo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactInfo_not?: InputMaybe<Scalars['String']['input']>;
  contactInfo_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type ContactInfoLinkLinkingCollections = {
  __typename?: 'ContactInfoLinkLinkingCollections';
  brandContactCollection?: Maybe<BrandContactCollection>;
  contactCardCollection?: Maybe<ContactCardCollection>;
  downloadPageCollection?: Maybe<DownloadPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type ContactInfoLinkLinkingCollectionsBrandContactCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContactInfoLinkLinkingCollectionsBrandContactCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactInfoLinkLinkingCollectionsContactCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContactInfoLinkLinkingCollectionsContactCardCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactInfoLinkLinkingCollectionsDownloadPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContactInfoLinkLinkingCollectionsDownloadPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactInfoLinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContactInfoLinkLinkingCollectionsBrandContactCollectionOrder {
  brandName_ASC = 'brandName_ASC',
  brandName_DESC = 'brandName_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export enum ContactInfoLinkLinkingCollectionsContactCardCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export enum ContactInfoLinkLinkingCollectionsDownloadPageCollectionOrder {
  downloadButton_ASC = 'downloadButton_ASC',
  downloadButton_DESC = 'downloadButton_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  stepperBookCar_ASC = 'stepperBookCar_ASC',
  stepperBookCar_DESC = 'stepperBookCar_DESC',
  stepperCreateAccount_ASC = 'stepperCreateAccount_ASC',
  stepperCreateAccount_DESC = 'stepperCreateAccount_DESC',
  stepperFindCar_ASC = 'stepperFindCar_ASC',
  stepperFindCar_DESC = 'stepperFindCar_DESC',
  stepperScanQR_ASC = 'stepperScanQR_ASC',
  stepperScanQR_DESC = 'stepperScanQR_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum ContactInfoLinkOrder {
  contactInfoType_ASC = 'contactInfoType_ASC',
  contactInfoType_DESC = 'contactInfoType_DESC',
  contactInfo_ASC = 'contactInfo_ASC',
  contactInfo_DESC = 'contactInfo_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPage = Entry &
  _Node & {
    __typename?: 'ContactPage';
    _id: Scalars['ID']['output'];
    accentBox?: Maybe<ContactPageAccentBox>;
    assistanceAndDamagesArrowLink?: Maybe<Link>;
    assistanceAndDamagesContactCardsCollection?: Maybe<ContactPageAssistanceAndDamagesContactCardsCollection>;
    assistanceAndDamagesHeader?: Maybe<Scalars['String']['output']>;
    assistanceAndDamagesImage?: Maybe<Asset>;
    assistanceAndDamagesSubDesc?: Maybe<ContactPageAssistanceAndDamagesSubDesc>;
    availableGaragesCardsCollection?: Maybe<ContactPageAvailableGaragesCardsCollection>;
    contactCardsCollection?: Maybe<ContactPageContactCardsCollection>;
    contactHeader?: Maybe<Scalars['String']['output']>;
    contactSubDescription?: Maybe<ContactPageContactSubDescription>;
    contentfulMetadata: ContentfulMetadata;
    findGaragesHeader?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<ContactPageLinkingCollections>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageAccentBoxArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageAssistanceAndDamagesArrowLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageAssistanceAndDamagesContactCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<ContactPageAssistanceAndDamagesContactCardsCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactCardFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageAssistanceAndDamagesHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageAssistanceAndDamagesImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageAssistanceAndDamagesSubDescArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageAvailableGaragesCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<ContactPageAvailableGaragesCardsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AvailableGaragesCardFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageContactCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ContactPageContactCardsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactCardFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageContactHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageContactSubDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageFindGaragesHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPage) */
export type ContactPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPageAccentBox = {
  __typename?: 'ContactPageAccentBox';
  json: Scalars['JSON']['output'];
  links: ContactPageAccentBoxLinks;
};

export type ContactPageAccentBoxAssets = {
  __typename?: 'ContactPageAccentBoxAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ContactPageAccentBoxEntries = {
  __typename?: 'ContactPageAccentBoxEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ContactPageAccentBoxLinks = {
  __typename?: 'ContactPageAccentBoxLinks';
  assets: ContactPageAccentBoxAssets;
  entries: ContactPageAccentBoxEntries;
  resources: ContactPageAccentBoxResources;
};

export type ContactPageAccentBoxResources = {
  __typename?: 'ContactPageAccentBoxResources';
  block: Array<ContactPageAccentBoxResourcesBlock>;
  hyperlink: Array<ContactPageAccentBoxResourcesHyperlink>;
  inline: Array<ContactPageAccentBoxResourcesInline>;
};

export type ContactPageAccentBoxResourcesBlock = ResourceLink & {
  __typename?: 'ContactPageAccentBoxResourcesBlock';
  sys: ResourceSys;
};

export type ContactPageAccentBoxResourcesHyperlink = ResourceLink & {
  __typename?: 'ContactPageAccentBoxResourcesHyperlink';
  sys: ResourceSys;
};

export type ContactPageAccentBoxResourcesInline = ResourceLink & {
  __typename?: 'ContactPageAccentBoxResourcesInline';
  sys: ResourceSys;
};

export type ContactPageAssistanceAndDamagesContactCardsCollection = {
  __typename?: 'ContactPageAssistanceAndDamagesContactCardsCollection';
  items: Array<Maybe<ContactCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContactPageAssistanceAndDamagesContactCardsCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type ContactPageAssistanceAndDamagesSubDesc = {
  __typename?: 'ContactPageAssistanceAndDamagesSubDesc';
  json: Scalars['JSON']['output'];
  links: ContactPageAssistanceAndDamagesSubDescLinks;
};

export type ContactPageAssistanceAndDamagesSubDescAssets = {
  __typename?: 'ContactPageAssistanceAndDamagesSubDescAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ContactPageAssistanceAndDamagesSubDescEntries = {
  __typename?: 'ContactPageAssistanceAndDamagesSubDescEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ContactPageAssistanceAndDamagesSubDescLinks = {
  __typename?: 'ContactPageAssistanceAndDamagesSubDescLinks';
  assets: ContactPageAssistanceAndDamagesSubDescAssets;
  entries: ContactPageAssistanceAndDamagesSubDescEntries;
  resources: ContactPageAssistanceAndDamagesSubDescResources;
};

export type ContactPageAssistanceAndDamagesSubDescResources = {
  __typename?: 'ContactPageAssistanceAndDamagesSubDescResources';
  block: Array<ContactPageAssistanceAndDamagesSubDescResourcesBlock>;
  hyperlink: Array<ContactPageAssistanceAndDamagesSubDescResourcesHyperlink>;
  inline: Array<ContactPageAssistanceAndDamagesSubDescResourcesInline>;
};

export type ContactPageAssistanceAndDamagesSubDescResourcesBlock =
  ResourceLink & {
    __typename?: 'ContactPageAssistanceAndDamagesSubDescResourcesBlock';
    sys: ResourceSys;
  };

export type ContactPageAssistanceAndDamagesSubDescResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ContactPageAssistanceAndDamagesSubDescResourcesHyperlink';
    sys: ResourceSys;
  };

export type ContactPageAssistanceAndDamagesSubDescResourcesInline =
  ResourceLink & {
    __typename?: 'ContactPageAssistanceAndDamagesSubDescResourcesInline';
    sys: ResourceSys;
  };

export type ContactPageAvailableGaragesCardsCollection = {
  __typename?: 'ContactPageAvailableGaragesCardsCollection';
  items: Array<Maybe<AvailableGaragesCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContactPageAvailableGaragesCardsCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2B = Entry &
  _Node & {
    __typename?: 'ContactPageB2B';
    _id: Scalars['ID']['output'];
    companyIdInputLabel?: Maybe<Scalars['String']['output']>;
    companyIdRequired?: Maybe<Scalars['String']['output']>;
    contactForm?: Maybe<DynamicForm>;
    contactInfoCollection?: Maybe<ContactPageB2BContactInfoCollection>;
    contentfulMetadata: ContentfulMetadata;
    featureDescription?: Maybe<Scalars['String']['output']>;
    featureList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    linkedFrom?: Maybe<ContactPageB2BLinkingCollections>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    submitDescription?: Maybe<ContactPageB2BSubmitDescription>;
    submitTitle?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2BCompanyIdInputLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2BCompanyIdRequiredArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2BContactFormArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DynamicFormFilter>;
};

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2BContactInfoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<ContactPageB2BContactInfoCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactCardFilter>;
};

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2BFeatureDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2BFeatureListArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2BLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2BSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2BSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2BSubmitDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2BSubmitTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Contact page for B2B site [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/contactPageB2b) */
export type ContactPageB2BTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPageB2BCollection = {
  __typename?: 'ContactPageB2BCollection';
  items: Array<Maybe<ContactPageB2B>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContactPageB2BContactInfoCollection = {
  __typename?: 'ContactPageB2BContactInfoCollection';
  items: Array<Maybe<ContactCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContactPageB2BContactInfoCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type ContactPageB2BFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContactPageB2BFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContactPageB2BFilter>>>;
  companyIdInputLabel?: InputMaybe<Scalars['String']['input']>;
  companyIdInputLabel_contains?: InputMaybe<Scalars['String']['input']>;
  companyIdInputLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  companyIdInputLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  companyIdInputLabel_not?: InputMaybe<Scalars['String']['input']>;
  companyIdInputLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  companyIdInputLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  companyIdRequired?: InputMaybe<Scalars['String']['input']>;
  companyIdRequired_contains?: InputMaybe<Scalars['String']['input']>;
  companyIdRequired_exists?: InputMaybe<Scalars['Boolean']['input']>;
  companyIdRequired_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  companyIdRequired_not?: InputMaybe<Scalars['String']['input']>;
  companyIdRequired_not_contains?: InputMaybe<Scalars['String']['input']>;
  companyIdRequired_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contactForm?: InputMaybe<CfDynamicFormNestedFilter>;
  contactForm_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactInfo?: InputMaybe<CfContactCardNestedFilter>;
  contactInfoCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  featureDescription?: InputMaybe<Scalars['String']['input']>;
  featureDescription_contains?: InputMaybe<Scalars['String']['input']>;
  featureDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  featureDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featureDescription_not?: InputMaybe<Scalars['String']['input']>;
  featureDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  featureDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featureList_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featureList_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featureList_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featureList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  submitDescription_contains?: InputMaybe<Scalars['String']['input']>;
  submitDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  submitDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  submitTitle?: InputMaybe<Scalars['String']['input']>;
  submitTitle_contains?: InputMaybe<Scalars['String']['input']>;
  submitTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  submitTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  submitTitle_not?: InputMaybe<Scalars['String']['input']>;
  submitTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  submitTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContactPageB2BLinkingCollections = {
  __typename?: 'ContactPageB2BLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ContactPageB2BLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContactPageB2BOrder {
  companyIdInputLabel_ASC = 'companyIdInputLabel_ASC',
  companyIdInputLabel_DESC = 'companyIdInputLabel_DESC',
  companyIdRequired_ASC = 'companyIdRequired_ASC',
  companyIdRequired_DESC = 'companyIdRequired_DESC',
  featureDescription_ASC = 'featureDescription_ASC',
  featureDescription_DESC = 'featureDescription_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  submitTitle_ASC = 'submitTitle_ASC',
  submitTitle_DESC = 'submitTitle_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type ContactPageB2BSubmitDescription = {
  __typename?: 'ContactPageB2BSubmitDescription';
  json: Scalars['JSON']['output'];
  links: ContactPageB2BSubmitDescriptionLinks;
};

export type ContactPageB2BSubmitDescriptionAssets = {
  __typename?: 'ContactPageB2BSubmitDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ContactPageB2BSubmitDescriptionEntries = {
  __typename?: 'ContactPageB2BSubmitDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ContactPageB2BSubmitDescriptionLinks = {
  __typename?: 'ContactPageB2BSubmitDescriptionLinks';
  assets: ContactPageB2BSubmitDescriptionAssets;
  entries: ContactPageB2BSubmitDescriptionEntries;
  resources: ContactPageB2BSubmitDescriptionResources;
};

export type ContactPageB2BSubmitDescriptionResources = {
  __typename?: 'ContactPageB2BSubmitDescriptionResources';
  block: Array<ContactPageB2BSubmitDescriptionResourcesBlock>;
  hyperlink: Array<ContactPageB2BSubmitDescriptionResourcesHyperlink>;
  inline: Array<ContactPageB2BSubmitDescriptionResourcesInline>;
};

export type ContactPageB2BSubmitDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'ContactPageB2BSubmitDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type ContactPageB2BSubmitDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'ContactPageB2BSubmitDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type ContactPageB2BSubmitDescriptionResourcesInline = ResourceLink & {
  __typename?: 'ContactPageB2BSubmitDescriptionResourcesInline';
  sys: ResourceSys;
};

export type ContactPageCollection = {
  __typename?: 'ContactPageCollection';
  items: Array<Maybe<ContactPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContactPageContactCardsCollection = {
  __typename?: 'ContactPageContactCardsCollection';
  items: Array<Maybe<ContactCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ContactPageContactCardsCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type ContactPageContactSubDescription = {
  __typename?: 'ContactPageContactSubDescription';
  json: Scalars['JSON']['output'];
  links: ContactPageContactSubDescriptionLinks;
};

export type ContactPageContactSubDescriptionAssets = {
  __typename?: 'ContactPageContactSubDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ContactPageContactSubDescriptionEntries = {
  __typename?: 'ContactPageContactSubDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ContactPageContactSubDescriptionLinks = {
  __typename?: 'ContactPageContactSubDescriptionLinks';
  assets: ContactPageContactSubDescriptionAssets;
  entries: ContactPageContactSubDescriptionEntries;
  resources: ContactPageContactSubDescriptionResources;
};

export type ContactPageContactSubDescriptionResources = {
  __typename?: 'ContactPageContactSubDescriptionResources';
  block: Array<ContactPageContactSubDescriptionResourcesBlock>;
  hyperlink: Array<ContactPageContactSubDescriptionResourcesHyperlink>;
  inline: Array<ContactPageContactSubDescriptionResourcesInline>;
};

export type ContactPageContactSubDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'ContactPageContactSubDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type ContactPageContactSubDescriptionResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ContactPageContactSubDescriptionResourcesHyperlink';
    sys: ResourceSys;
  };

export type ContactPageContactSubDescriptionResourcesInline = ResourceLink & {
  __typename?: 'ContactPageContactSubDescriptionResourcesInline';
  sys: ResourceSys;
};

export type ContactPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContactPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContactPageFilter>>>;
  accentBox_contains?: InputMaybe<Scalars['String']['input']>;
  accentBox_exists?: InputMaybe<Scalars['Boolean']['input']>;
  accentBox_not_contains?: InputMaybe<Scalars['String']['input']>;
  assistanceAndDamagesArrowLink?: InputMaybe<CfLinkNestedFilter>;
  assistanceAndDamagesArrowLink_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  assistanceAndDamagesContactCards?: InputMaybe<CfContactCardNestedFilter>;
  assistanceAndDamagesContactCardsCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  assistanceAndDamagesHeader?: InputMaybe<Scalars['String']['input']>;
  assistanceAndDamagesHeader_contains?: InputMaybe<Scalars['String']['input']>;
  assistanceAndDamagesHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  assistanceAndDamagesHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  assistanceAndDamagesHeader_not?: InputMaybe<Scalars['String']['input']>;
  assistanceAndDamagesHeader_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  assistanceAndDamagesHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  assistanceAndDamagesImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  assistanceAndDamagesSubDesc_contains?: InputMaybe<Scalars['String']['input']>;
  assistanceAndDamagesSubDesc_exists?: InputMaybe<Scalars['Boolean']['input']>;
  assistanceAndDamagesSubDesc_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  availableGaragesCards?: InputMaybe<CfAvailableGaragesCardNestedFilter>;
  availableGaragesCardsCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  contactCards?: InputMaybe<CfContactCardNestedFilter>;
  contactCardsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactHeader?: InputMaybe<Scalars['String']['input']>;
  contactHeader_contains?: InputMaybe<Scalars['String']['input']>;
  contactHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactHeader_not?: InputMaybe<Scalars['String']['input']>;
  contactHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contactSubDescription_contains?: InputMaybe<Scalars['String']['input']>;
  contactSubDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactSubDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  findGaragesHeader?: InputMaybe<Scalars['String']['input']>;
  findGaragesHeader_contains?: InputMaybe<Scalars['String']['input']>;
  findGaragesHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  findGaragesHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  findGaragesHeader_not?: InputMaybe<Scalars['String']['input']>;
  findGaragesHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  findGaragesHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ContactPageLinkingCollections = {
  __typename?: 'ContactPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ContactPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ContactPageOrder {
  assistanceAndDamagesHeader_ASC = 'assistanceAndDamagesHeader_ASC',
  assistanceAndDamagesHeader_DESC = 'assistanceAndDamagesHeader_DESC',
  contactHeader_ASC = 'contactHeader_ASC',
  contactHeader_DESC = 'contactHeader_DESC',
  findGaragesHeader_ASC = 'findGaragesHeader_ASC',
  findGaragesHeader_DESC = 'findGaragesHeader_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
  tags?: InputMaybe<ContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesCard) */
export type DamagesCard = Entry &
  _Node & {
    __typename?: 'DamagesCard';
    _id: Scalars['ID']['output'];
    arrowLink?: Maybe<Link>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<DamagesCardDescription>;
    header?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<DamagesCardLinkingCollections>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesCard) */
export type DamagesCardArrowLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesCard) */
export type DamagesCardDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesCard) */
export type DamagesCardHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesCard) */
export type DamagesCardImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesCard) */
export type DamagesCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DamagesCardCollection = {
  __typename?: 'DamagesCardCollection';
  items: Array<Maybe<DamagesCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DamagesCardDescription = {
  __typename?: 'DamagesCardDescription';
  json: Scalars['JSON']['output'];
  links: DamagesCardDescriptionLinks;
};

export type DamagesCardDescriptionAssets = {
  __typename?: 'DamagesCardDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DamagesCardDescriptionEntries = {
  __typename?: 'DamagesCardDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DamagesCardDescriptionLinks = {
  __typename?: 'DamagesCardDescriptionLinks';
  assets: DamagesCardDescriptionAssets;
  entries: DamagesCardDescriptionEntries;
  resources: DamagesCardDescriptionResources;
};

export type DamagesCardDescriptionResources = {
  __typename?: 'DamagesCardDescriptionResources';
  block: Array<DamagesCardDescriptionResourcesBlock>;
  hyperlink: Array<DamagesCardDescriptionResourcesHyperlink>;
  inline: Array<DamagesCardDescriptionResourcesInline>;
};

export type DamagesCardDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'DamagesCardDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type DamagesCardDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'DamagesCardDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type DamagesCardDescriptionResourcesInline = ResourceLink & {
  __typename?: 'DamagesCardDescriptionResourcesInline';
  sys: ResourceSys;
};

export type DamagesCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<DamagesCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DamagesCardFilter>>>;
  arrowLink?: InputMaybe<CfLinkNestedFilter>;
  arrowLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type DamagesCardLinkingCollections = {
  __typename?: 'DamagesCardLinkingCollections';
  damagesPageCollection?: Maybe<DamagesPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type DamagesCardLinkingCollectionsDamagesPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<DamagesCardLinkingCollectionsDamagesPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type DamagesCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum DamagesCardLinkingCollectionsDamagesPageCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum DamagesCardOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPage = Entry &
  _Node & {
    __typename?: 'DamagesContactPage';
    _id: Scalars['ID']['output'];
    accentBox?: Maybe<DamagesContactPageAccentBox>;
    brandContactsCollection?: Maybe<DamagesContactPageBrandContactsCollection>;
    contentfulMetadata: ContentfulMetadata;
    damagesContactCardsCollection?: Maybe<DamagesContactPageDamagesContactCardsCollection>;
    description?: Maybe<DamagesContactPageDescription>;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<DamagesContactPageLinkingCollections>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    startProblemsDesc1?: Maybe<DamagesContactPageStartProblemsDesc1>;
    startProblemsDesc2?: Maybe<DamagesContactPageStartProblemsDesc2>;
    startProblemsHeader?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPageAccentBoxArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPageBrandContactsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<DamagesContactPageBrandContactsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BrandContactFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPageDamagesContactCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<DamagesContactPageDamagesContactCardsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactCardFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPageDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPageStartProblemsDesc1Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPageStartProblemsDesc2Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPageStartProblemsHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesContactPage) */
export type DamagesContactPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type DamagesContactPageAccentBox = {
  __typename?: 'DamagesContactPageAccentBox';
  json: Scalars['JSON']['output'];
  links: DamagesContactPageAccentBoxLinks;
};

export type DamagesContactPageAccentBoxAssets = {
  __typename?: 'DamagesContactPageAccentBoxAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DamagesContactPageAccentBoxEntries = {
  __typename?: 'DamagesContactPageAccentBoxEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DamagesContactPageAccentBoxLinks = {
  __typename?: 'DamagesContactPageAccentBoxLinks';
  assets: DamagesContactPageAccentBoxAssets;
  entries: DamagesContactPageAccentBoxEntries;
  resources: DamagesContactPageAccentBoxResources;
};

export type DamagesContactPageAccentBoxResources = {
  __typename?: 'DamagesContactPageAccentBoxResources';
  block: Array<DamagesContactPageAccentBoxResourcesBlock>;
  hyperlink: Array<DamagesContactPageAccentBoxResourcesHyperlink>;
  inline: Array<DamagesContactPageAccentBoxResourcesInline>;
};

export type DamagesContactPageAccentBoxResourcesBlock = ResourceLink & {
  __typename?: 'DamagesContactPageAccentBoxResourcesBlock';
  sys: ResourceSys;
};

export type DamagesContactPageAccentBoxResourcesHyperlink = ResourceLink & {
  __typename?: 'DamagesContactPageAccentBoxResourcesHyperlink';
  sys: ResourceSys;
};

export type DamagesContactPageAccentBoxResourcesInline = ResourceLink & {
  __typename?: 'DamagesContactPageAccentBoxResourcesInline';
  sys: ResourceSys;
};

export type DamagesContactPageBrandContactsCollection = {
  __typename?: 'DamagesContactPageBrandContactsCollection';
  items: Array<Maybe<BrandContact>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum DamagesContactPageBrandContactsCollectionOrder {
  brandName_ASC = 'brandName_ASC',
  brandName_DESC = 'brandName_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type DamagesContactPageCollection = {
  __typename?: 'DamagesContactPageCollection';
  items: Array<Maybe<DamagesContactPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DamagesContactPageDamagesContactCardsCollection = {
  __typename?: 'DamagesContactPageDamagesContactCardsCollection';
  items: Array<Maybe<ContactCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum DamagesContactPageDamagesContactCardsCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type DamagesContactPageDescription = {
  __typename?: 'DamagesContactPageDescription';
  json: Scalars['JSON']['output'];
  links: DamagesContactPageDescriptionLinks;
};

export type DamagesContactPageDescriptionAssets = {
  __typename?: 'DamagesContactPageDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DamagesContactPageDescriptionEntries = {
  __typename?: 'DamagesContactPageDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DamagesContactPageDescriptionLinks = {
  __typename?: 'DamagesContactPageDescriptionLinks';
  assets: DamagesContactPageDescriptionAssets;
  entries: DamagesContactPageDescriptionEntries;
  resources: DamagesContactPageDescriptionResources;
};

export type DamagesContactPageDescriptionResources = {
  __typename?: 'DamagesContactPageDescriptionResources';
  block: Array<DamagesContactPageDescriptionResourcesBlock>;
  hyperlink: Array<DamagesContactPageDescriptionResourcesHyperlink>;
  inline: Array<DamagesContactPageDescriptionResourcesInline>;
};

export type DamagesContactPageDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'DamagesContactPageDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type DamagesContactPageDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'DamagesContactPageDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type DamagesContactPageDescriptionResourcesInline = ResourceLink & {
  __typename?: 'DamagesContactPageDescriptionResourcesInline';
  sys: ResourceSys;
};

export type DamagesContactPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<DamagesContactPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DamagesContactPageFilter>>>;
  accentBox_contains?: InputMaybe<Scalars['String']['input']>;
  accentBox_exists?: InputMaybe<Scalars['Boolean']['input']>;
  accentBox_not_contains?: InputMaybe<Scalars['String']['input']>;
  brandContacts?: InputMaybe<CfBrandContactNestedFilter>;
  brandContactsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  damagesContactCards?: InputMaybe<CfContactCardNestedFilter>;
  damagesContactCardsCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startProblemsDesc1_contains?: InputMaybe<Scalars['String']['input']>;
  startProblemsDesc1_exists?: InputMaybe<Scalars['Boolean']['input']>;
  startProblemsDesc1_not_contains?: InputMaybe<Scalars['String']['input']>;
  startProblemsDesc2_contains?: InputMaybe<Scalars['String']['input']>;
  startProblemsDesc2_exists?: InputMaybe<Scalars['Boolean']['input']>;
  startProblemsDesc2_not_contains?: InputMaybe<Scalars['String']['input']>;
  startProblemsHeader?: InputMaybe<Scalars['String']['input']>;
  startProblemsHeader_contains?: InputMaybe<Scalars['String']['input']>;
  startProblemsHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  startProblemsHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  startProblemsHeader_not?: InputMaybe<Scalars['String']['input']>;
  startProblemsHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  startProblemsHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DamagesContactPageLinkingCollections = {
  __typename?: 'DamagesContactPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type DamagesContactPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum DamagesContactPageOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  startProblemsHeader_ASC = 'startProblemsHeader_ASC',
  startProblemsHeader_DESC = 'startProblemsHeader_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type DamagesContactPageStartProblemsDesc1 = {
  __typename?: 'DamagesContactPageStartProblemsDesc1';
  json: Scalars['JSON']['output'];
  links: DamagesContactPageStartProblemsDesc1Links;
};

export type DamagesContactPageStartProblemsDesc1Assets = {
  __typename?: 'DamagesContactPageStartProblemsDesc1Assets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DamagesContactPageStartProblemsDesc1Entries = {
  __typename?: 'DamagesContactPageStartProblemsDesc1Entries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DamagesContactPageStartProblemsDesc1Links = {
  __typename?: 'DamagesContactPageStartProblemsDesc1Links';
  assets: DamagesContactPageStartProblemsDesc1Assets;
  entries: DamagesContactPageStartProblemsDesc1Entries;
  resources: DamagesContactPageStartProblemsDesc1Resources;
};

export type DamagesContactPageStartProblemsDesc1Resources = {
  __typename?: 'DamagesContactPageStartProblemsDesc1Resources';
  block: Array<DamagesContactPageStartProblemsDesc1ResourcesBlock>;
  hyperlink: Array<DamagesContactPageStartProblemsDesc1ResourcesHyperlink>;
  inline: Array<DamagesContactPageStartProblemsDesc1ResourcesInline>;
};

export type DamagesContactPageStartProblemsDesc1ResourcesBlock =
  ResourceLink & {
    __typename?: 'DamagesContactPageStartProblemsDesc1ResourcesBlock';
    sys: ResourceSys;
  };

export type DamagesContactPageStartProblemsDesc1ResourcesHyperlink =
  ResourceLink & {
    __typename?: 'DamagesContactPageStartProblemsDesc1ResourcesHyperlink';
    sys: ResourceSys;
  };

export type DamagesContactPageStartProblemsDesc1ResourcesInline =
  ResourceLink & {
    __typename?: 'DamagesContactPageStartProblemsDesc1ResourcesInline';
    sys: ResourceSys;
  };

export type DamagesContactPageStartProblemsDesc2 = {
  __typename?: 'DamagesContactPageStartProblemsDesc2';
  json: Scalars['JSON']['output'];
  links: DamagesContactPageStartProblemsDesc2Links;
};

export type DamagesContactPageStartProblemsDesc2Assets = {
  __typename?: 'DamagesContactPageStartProblemsDesc2Assets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DamagesContactPageStartProblemsDesc2Entries = {
  __typename?: 'DamagesContactPageStartProblemsDesc2Entries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DamagesContactPageStartProblemsDesc2Links = {
  __typename?: 'DamagesContactPageStartProblemsDesc2Links';
  assets: DamagesContactPageStartProblemsDesc2Assets;
  entries: DamagesContactPageStartProblemsDesc2Entries;
  resources: DamagesContactPageStartProblemsDesc2Resources;
};

export type DamagesContactPageStartProblemsDesc2Resources = {
  __typename?: 'DamagesContactPageStartProblemsDesc2Resources';
  block: Array<DamagesContactPageStartProblemsDesc2ResourcesBlock>;
  hyperlink: Array<DamagesContactPageStartProblemsDesc2ResourcesHyperlink>;
  inline: Array<DamagesContactPageStartProblemsDesc2ResourcesInline>;
};

export type DamagesContactPageStartProblemsDesc2ResourcesBlock =
  ResourceLink & {
    __typename?: 'DamagesContactPageStartProblemsDesc2ResourcesBlock';
    sys: ResourceSys;
  };

export type DamagesContactPageStartProblemsDesc2ResourcesHyperlink =
  ResourceLink & {
    __typename?: 'DamagesContactPageStartProblemsDesc2ResourcesHyperlink';
    sys: ResourceSys;
  };

export type DamagesContactPageStartProblemsDesc2ResourcesInline =
  ResourceLink & {
    __typename?: 'DamagesContactPageStartProblemsDesc2ResourcesInline';
    sys: ResourceSys;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPage) */
export type DamagesPage = Entry &
  _Node & {
    __typename?: 'DamagesPage';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    damagesCardsCollection?: Maybe<DamagesPageDamagesCardsCollection>;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<DamagesPageLinkingCollections>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPage) */
export type DamagesPageDamagesCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DamagesPageDamagesCardsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DamagesCardFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPage) */
export type DamagesPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPage) */
export type DamagesPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPage) */
export type DamagesPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPage) */
export type DamagesPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPage) */
export type DamagesPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type DamagesPageCollection = {
  __typename?: 'DamagesPageCollection';
  items: Array<Maybe<DamagesPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DamagesPageDamagesCardsCollection = {
  __typename?: 'DamagesPageDamagesCardsCollection';
  items: Array<Maybe<DamagesCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum DamagesPageDamagesCardsCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type DamagesPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<DamagesPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DamagesPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  damagesCards?: InputMaybe<CfDamagesCardNestedFilter>;
  damagesCardsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DamagesPageLinkingCollections = {
  __typename?: 'DamagesPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type DamagesPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum DamagesPageOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPage = Entry &
  _Node & {
    __typename?: 'DamagesPolicyPage';
    _id: Scalars['ID']['output'];
    assistedInspectionDescription?: Maybe<DamagesPolicyPageAssistedInspectionDescription>;
    assistedInspectionHeader?: Maybe<Scalars['String']['output']>;
    assistedInspectionScreenshot?: Maybe<Asset>;
    assistedInspectionStepsCollection?: Maybe<DamagesPolicyPageAssistedInspectionStepsCollection>;
    collapsableContentCollection?: Maybe<DamagesPolicyPageCollapsableContentCollection>;
    collapseContainerHeader?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    faqButtonLink?: Maybe<Link>;
    faqDescription?: Maybe<DamagesPolicyPageFaqDescription>;
    faqHeader?: Maybe<Scalars['String']['output']>;
    howItWorksHeader?: Maybe<Scalars['String']['output']>;
    insuranceAccentBox?: Maybe<DamagesPolicyPageInsuranceAccentBox>;
    insuranceCoverDescription?: Maybe<DamagesPolicyPageInsuranceCoverDescription>;
    insuranceCoverHeader?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<DamagesPolicyPageLinkingCollections>;
    policyContentCollection?: Maybe<DamagesPolicyPagePolicyContentCollection>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    withAssistedInspection?: Maybe<Scalars['Boolean']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageAssistedInspectionDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageAssistedInspectionHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageAssistedInspectionScreenshotArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageAssistedInspectionStepsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<DamagesPolicyPageAssistedInspectionStepsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InspectionStepFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageCollapsableContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<DamagesPolicyPageCollapsableContentCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CollapsableContainerFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageCollapseContainerHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageFaqButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageFaqDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageFaqHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageHowItWorksHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageInsuranceAccentBoxArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageInsuranceCoverDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageInsuranceCoverHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPagePolicyContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<DamagesPolicyPagePolicyContentCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DamagesPolicyPageContentBoxFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPage) */
export type DamagesPolicyPageWithAssistedInspectionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type DamagesPolicyPageAssistedInspectionDescription = {
  __typename?: 'DamagesPolicyPageAssistedInspectionDescription';
  json: Scalars['JSON']['output'];
  links: DamagesPolicyPageAssistedInspectionDescriptionLinks;
};

export type DamagesPolicyPageAssistedInspectionDescriptionAssets = {
  __typename?: 'DamagesPolicyPageAssistedInspectionDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DamagesPolicyPageAssistedInspectionDescriptionEntries = {
  __typename?: 'DamagesPolicyPageAssistedInspectionDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DamagesPolicyPageAssistedInspectionDescriptionLinks = {
  __typename?: 'DamagesPolicyPageAssistedInspectionDescriptionLinks';
  assets: DamagesPolicyPageAssistedInspectionDescriptionAssets;
  entries: DamagesPolicyPageAssistedInspectionDescriptionEntries;
  resources: DamagesPolicyPageAssistedInspectionDescriptionResources;
};

export type DamagesPolicyPageAssistedInspectionDescriptionResources = {
  __typename?: 'DamagesPolicyPageAssistedInspectionDescriptionResources';
  block: Array<DamagesPolicyPageAssistedInspectionDescriptionResourcesBlock>;
  hyperlink: Array<DamagesPolicyPageAssistedInspectionDescriptionResourcesHyperlink>;
  inline: Array<DamagesPolicyPageAssistedInspectionDescriptionResourcesInline>;
};

export type DamagesPolicyPageAssistedInspectionDescriptionResourcesBlock =
  ResourceLink & {
    __typename?: 'DamagesPolicyPageAssistedInspectionDescriptionResourcesBlock';
    sys: ResourceSys;
  };

export type DamagesPolicyPageAssistedInspectionDescriptionResourcesHyperlink =
  ResourceLink & {
    __typename?: 'DamagesPolicyPageAssistedInspectionDescriptionResourcesHyperlink';
    sys: ResourceSys;
  };

export type DamagesPolicyPageAssistedInspectionDescriptionResourcesInline =
  ResourceLink & {
    __typename?: 'DamagesPolicyPageAssistedInspectionDescriptionResourcesInline';
    sys: ResourceSys;
  };

export type DamagesPolicyPageAssistedInspectionStepsCollection = {
  __typename?: 'DamagesPolicyPageAssistedInspectionStepsCollection';
  items: Array<Maybe<InspectionStep>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum DamagesPolicyPageAssistedInspectionStepsCollectionOrder {
  stepHeader_ASC = 'stepHeader_ASC',
  stepHeader_DESC = 'stepHeader_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type DamagesPolicyPageCollapsableContentCollection = {
  __typename?: 'DamagesPolicyPageCollapsableContentCollection';
  items: Array<Maybe<CollapsableContainer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum DamagesPolicyPageCollapsableContentCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type DamagesPolicyPageCollection = {
  __typename?: 'DamagesPolicyPageCollection';
  items: Array<Maybe<DamagesPolicyPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPageContentBox) */
export type DamagesPolicyPageContentBox = Entry &
  _Node & {
    __typename?: 'DamagesPolicyPageContentBox';
    _id: Scalars['ID']['output'];
    arrowLink?: Maybe<Link>;
    content?: Maybe<DamagesPolicyPageContentBoxContent>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<DamagesPolicyPageContentBoxLinkingCollections>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPageContentBox) */
export type DamagesPolicyPageContentBoxArrowLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPageContentBox) */
export type DamagesPolicyPageContentBoxContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPageContentBox) */
export type DamagesPolicyPageContentBoxHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/damagesPolicyPageContentBox) */
export type DamagesPolicyPageContentBoxLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DamagesPolicyPageContentBoxCollection = {
  __typename?: 'DamagesPolicyPageContentBoxCollection';
  items: Array<Maybe<DamagesPolicyPageContentBox>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DamagesPolicyPageContentBoxContent = {
  __typename?: 'DamagesPolicyPageContentBoxContent';
  json: Scalars['JSON']['output'];
  links: DamagesPolicyPageContentBoxContentLinks;
};

export type DamagesPolicyPageContentBoxContentAssets = {
  __typename?: 'DamagesPolicyPageContentBoxContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DamagesPolicyPageContentBoxContentEntries = {
  __typename?: 'DamagesPolicyPageContentBoxContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DamagesPolicyPageContentBoxContentLinks = {
  __typename?: 'DamagesPolicyPageContentBoxContentLinks';
  assets: DamagesPolicyPageContentBoxContentAssets;
  entries: DamagesPolicyPageContentBoxContentEntries;
  resources: DamagesPolicyPageContentBoxContentResources;
};

export type DamagesPolicyPageContentBoxContentResources = {
  __typename?: 'DamagesPolicyPageContentBoxContentResources';
  block: Array<DamagesPolicyPageContentBoxContentResourcesBlock>;
  hyperlink: Array<DamagesPolicyPageContentBoxContentResourcesHyperlink>;
  inline: Array<DamagesPolicyPageContentBoxContentResourcesInline>;
};

export type DamagesPolicyPageContentBoxContentResourcesBlock = ResourceLink & {
  __typename?: 'DamagesPolicyPageContentBoxContentResourcesBlock';
  sys: ResourceSys;
};

export type DamagesPolicyPageContentBoxContentResourcesHyperlink =
  ResourceLink & {
    __typename?: 'DamagesPolicyPageContentBoxContentResourcesHyperlink';
    sys: ResourceSys;
  };

export type DamagesPolicyPageContentBoxContentResourcesInline = ResourceLink & {
  __typename?: 'DamagesPolicyPageContentBoxContentResourcesInline';
  sys: ResourceSys;
};

export type DamagesPolicyPageContentBoxFilter = {
  AND?: InputMaybe<Array<InputMaybe<DamagesPolicyPageContentBoxFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DamagesPolicyPageContentBoxFilter>>>;
  arrowLink?: InputMaybe<CfLinkNestedFilter>;
  arrowLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type DamagesPolicyPageContentBoxLinkingCollections = {
  __typename?: 'DamagesPolicyPageContentBoxLinkingCollections';
  damagesPolicyPageCollection?: Maybe<DamagesPolicyPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type DamagesPolicyPageContentBoxLinkingCollectionsDamagesPolicyPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']['input']>;
    locale?: InputMaybe<Scalars['String']['input']>;
    order?: InputMaybe<
      Array<
        InputMaybe<DamagesPolicyPageContentBoxLinkingCollectionsDamagesPolicyPageCollectionOrder>
      >
    >;
    preview?: InputMaybe<Scalars['Boolean']['input']>;
    skip?: InputMaybe<Scalars['Int']['input']>;
  };

export type DamagesPolicyPageContentBoxLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum DamagesPolicyPageContentBoxLinkingCollectionsDamagesPolicyPageCollectionOrder {
  assistedInspectionHeader_ASC = 'assistedInspectionHeader_ASC',
  assistedInspectionHeader_DESC = 'assistedInspectionHeader_DESC',
  collapseContainerHeader_ASC = 'collapseContainerHeader_ASC',
  collapseContainerHeader_DESC = 'collapseContainerHeader_DESC',
  faqHeader_ASC = 'faqHeader_ASC',
  faqHeader_DESC = 'faqHeader_DESC',
  howItWorksHeader_ASC = 'howItWorksHeader_ASC',
  howItWorksHeader_DESC = 'howItWorksHeader_DESC',
  insuranceCoverHeader_ASC = 'insuranceCoverHeader_ASC',
  insuranceCoverHeader_DESC = 'insuranceCoverHeader_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  withAssistedInspection_ASC = 'withAssistedInspection_ASC',
  withAssistedInspection_DESC = 'withAssistedInspection_DESC'
}

export enum DamagesPolicyPageContentBoxOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type DamagesPolicyPageFaqDescription = {
  __typename?: 'DamagesPolicyPageFaqDescription';
  json: Scalars['JSON']['output'];
  links: DamagesPolicyPageFaqDescriptionLinks;
};

export type DamagesPolicyPageFaqDescriptionAssets = {
  __typename?: 'DamagesPolicyPageFaqDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DamagesPolicyPageFaqDescriptionEntries = {
  __typename?: 'DamagesPolicyPageFaqDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DamagesPolicyPageFaqDescriptionLinks = {
  __typename?: 'DamagesPolicyPageFaqDescriptionLinks';
  assets: DamagesPolicyPageFaqDescriptionAssets;
  entries: DamagesPolicyPageFaqDescriptionEntries;
  resources: DamagesPolicyPageFaqDescriptionResources;
};

export type DamagesPolicyPageFaqDescriptionResources = {
  __typename?: 'DamagesPolicyPageFaqDescriptionResources';
  block: Array<DamagesPolicyPageFaqDescriptionResourcesBlock>;
  hyperlink: Array<DamagesPolicyPageFaqDescriptionResourcesHyperlink>;
  inline: Array<DamagesPolicyPageFaqDescriptionResourcesInline>;
};

export type DamagesPolicyPageFaqDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'DamagesPolicyPageFaqDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type DamagesPolicyPageFaqDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'DamagesPolicyPageFaqDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type DamagesPolicyPageFaqDescriptionResourcesInline = ResourceLink & {
  __typename?: 'DamagesPolicyPageFaqDescriptionResourcesInline';
  sys: ResourceSys;
};

export type DamagesPolicyPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<DamagesPolicyPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DamagesPolicyPageFilter>>>;
  assistedInspectionDescription_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  assistedInspectionDescription_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  assistedInspectionDescription_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  assistedInspectionHeader?: InputMaybe<Scalars['String']['input']>;
  assistedInspectionHeader_contains?: InputMaybe<Scalars['String']['input']>;
  assistedInspectionHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  assistedInspectionHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  assistedInspectionHeader_not?: InputMaybe<Scalars['String']['input']>;
  assistedInspectionHeader_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  assistedInspectionHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  assistedInspectionScreenshot_exists?: InputMaybe<Scalars['Boolean']['input']>;
  assistedInspectionSteps?: InputMaybe<CfInspectionStepNestedFilter>;
  assistedInspectionStepsCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  collapsableContent?: InputMaybe<CfCollapsableContainerNestedFilter>;
  collapsableContentCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  collapseContainerHeader?: InputMaybe<Scalars['String']['input']>;
  collapseContainerHeader_contains?: InputMaybe<Scalars['String']['input']>;
  collapseContainerHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  collapseContainerHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  collapseContainerHeader_not?: InputMaybe<Scalars['String']['input']>;
  collapseContainerHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  collapseContainerHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  faqButtonLink?: InputMaybe<CfLinkNestedFilter>;
  faqButtonLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  faqDescription_contains?: InputMaybe<Scalars['String']['input']>;
  faqDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  faqDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  faqHeader?: InputMaybe<Scalars['String']['input']>;
  faqHeader_contains?: InputMaybe<Scalars['String']['input']>;
  faqHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  faqHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  faqHeader_not?: InputMaybe<Scalars['String']['input']>;
  faqHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  faqHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  howItWorksHeader?: InputMaybe<Scalars['String']['input']>;
  howItWorksHeader_contains?: InputMaybe<Scalars['String']['input']>;
  howItWorksHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  howItWorksHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  howItWorksHeader_not?: InputMaybe<Scalars['String']['input']>;
  howItWorksHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  howItWorksHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  insuranceAccentBox_contains?: InputMaybe<Scalars['String']['input']>;
  insuranceAccentBox_exists?: InputMaybe<Scalars['Boolean']['input']>;
  insuranceAccentBox_not_contains?: InputMaybe<Scalars['String']['input']>;
  insuranceCoverDescription_contains?: InputMaybe<Scalars['String']['input']>;
  insuranceCoverDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  insuranceCoverDescription_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  insuranceCoverHeader?: InputMaybe<Scalars['String']['input']>;
  insuranceCoverHeader_contains?: InputMaybe<Scalars['String']['input']>;
  insuranceCoverHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  insuranceCoverHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  insuranceCoverHeader_not?: InputMaybe<Scalars['String']['input']>;
  insuranceCoverHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  insuranceCoverHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  policyContent?: InputMaybe<CfDamagesPolicyPageContentBoxNestedFilter>;
  policyContentCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withAssistedInspection?: InputMaybe<Scalars['Boolean']['input']>;
  withAssistedInspection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  withAssistedInspection_not?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DamagesPolicyPageInsuranceAccentBox = {
  __typename?: 'DamagesPolicyPageInsuranceAccentBox';
  json: Scalars['JSON']['output'];
  links: DamagesPolicyPageInsuranceAccentBoxLinks;
};

export type DamagesPolicyPageInsuranceAccentBoxAssets = {
  __typename?: 'DamagesPolicyPageInsuranceAccentBoxAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DamagesPolicyPageInsuranceAccentBoxEntries = {
  __typename?: 'DamagesPolicyPageInsuranceAccentBoxEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DamagesPolicyPageInsuranceAccentBoxLinks = {
  __typename?: 'DamagesPolicyPageInsuranceAccentBoxLinks';
  assets: DamagesPolicyPageInsuranceAccentBoxAssets;
  entries: DamagesPolicyPageInsuranceAccentBoxEntries;
  resources: DamagesPolicyPageInsuranceAccentBoxResources;
};

export type DamagesPolicyPageInsuranceAccentBoxResources = {
  __typename?: 'DamagesPolicyPageInsuranceAccentBoxResources';
  block: Array<DamagesPolicyPageInsuranceAccentBoxResourcesBlock>;
  hyperlink: Array<DamagesPolicyPageInsuranceAccentBoxResourcesHyperlink>;
  inline: Array<DamagesPolicyPageInsuranceAccentBoxResourcesInline>;
};

export type DamagesPolicyPageInsuranceAccentBoxResourcesBlock = ResourceLink & {
  __typename?: 'DamagesPolicyPageInsuranceAccentBoxResourcesBlock';
  sys: ResourceSys;
};

export type DamagesPolicyPageInsuranceAccentBoxResourcesHyperlink =
  ResourceLink & {
    __typename?: 'DamagesPolicyPageInsuranceAccentBoxResourcesHyperlink';
    sys: ResourceSys;
  };

export type DamagesPolicyPageInsuranceAccentBoxResourcesInline =
  ResourceLink & {
    __typename?: 'DamagesPolicyPageInsuranceAccentBoxResourcesInline';
    sys: ResourceSys;
  };

export type DamagesPolicyPageInsuranceCoverDescription = {
  __typename?: 'DamagesPolicyPageInsuranceCoverDescription';
  json: Scalars['JSON']['output'];
  links: DamagesPolicyPageInsuranceCoverDescriptionLinks;
};

export type DamagesPolicyPageInsuranceCoverDescriptionAssets = {
  __typename?: 'DamagesPolicyPageInsuranceCoverDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DamagesPolicyPageInsuranceCoverDescriptionEntries = {
  __typename?: 'DamagesPolicyPageInsuranceCoverDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DamagesPolicyPageInsuranceCoverDescriptionLinks = {
  __typename?: 'DamagesPolicyPageInsuranceCoverDescriptionLinks';
  assets: DamagesPolicyPageInsuranceCoverDescriptionAssets;
  entries: DamagesPolicyPageInsuranceCoverDescriptionEntries;
  resources: DamagesPolicyPageInsuranceCoverDescriptionResources;
};

export type DamagesPolicyPageInsuranceCoverDescriptionResources = {
  __typename?: 'DamagesPolicyPageInsuranceCoverDescriptionResources';
  block: Array<DamagesPolicyPageInsuranceCoverDescriptionResourcesBlock>;
  hyperlink: Array<DamagesPolicyPageInsuranceCoverDescriptionResourcesHyperlink>;
  inline: Array<DamagesPolicyPageInsuranceCoverDescriptionResourcesInline>;
};

export type DamagesPolicyPageInsuranceCoverDescriptionResourcesBlock =
  ResourceLink & {
    __typename?: 'DamagesPolicyPageInsuranceCoverDescriptionResourcesBlock';
    sys: ResourceSys;
  };

export type DamagesPolicyPageInsuranceCoverDescriptionResourcesHyperlink =
  ResourceLink & {
    __typename?: 'DamagesPolicyPageInsuranceCoverDescriptionResourcesHyperlink';
    sys: ResourceSys;
  };

export type DamagesPolicyPageInsuranceCoverDescriptionResourcesInline =
  ResourceLink & {
    __typename?: 'DamagesPolicyPageInsuranceCoverDescriptionResourcesInline';
    sys: ResourceSys;
  };

export type DamagesPolicyPageLinkingCollections = {
  __typename?: 'DamagesPolicyPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type DamagesPolicyPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum DamagesPolicyPageOrder {
  assistedInspectionHeader_ASC = 'assistedInspectionHeader_ASC',
  assistedInspectionHeader_DESC = 'assistedInspectionHeader_DESC',
  collapseContainerHeader_ASC = 'collapseContainerHeader_ASC',
  collapseContainerHeader_DESC = 'collapseContainerHeader_DESC',
  faqHeader_ASC = 'faqHeader_ASC',
  faqHeader_DESC = 'faqHeader_DESC',
  howItWorksHeader_ASC = 'howItWorksHeader_ASC',
  howItWorksHeader_DESC = 'howItWorksHeader_DESC',
  insuranceCoverHeader_ASC = 'insuranceCoverHeader_ASC',
  insuranceCoverHeader_DESC = 'insuranceCoverHeader_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  withAssistedInspection_ASC = 'withAssistedInspection_ASC',
  withAssistedInspection_DESC = 'withAssistedInspection_DESC'
}

export type DamagesPolicyPagePolicyContentCollection = {
  __typename?: 'DamagesPolicyPagePolicyContentCollection';
  items: Array<Maybe<DamagesPolicyPageContentBox>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum DamagesPolicyPagePolicyContentCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoPage) */
export type DemoPage = Entry &
  _Node & {
    __typename?: 'DemoPage';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    initialDescription?: Maybe<DemoPageInitialDescription>;
    initialProfileImage?: Maybe<Asset>;
    initialTitle?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<DemoPageLinkingCollections>;
    seoMetadata?: Maybe<SeoMetadata>;
    slidesCollection?: Maybe<DemoPageSlidesCollection>;
    slug?: Maybe<Scalars['String']['output']>;
    startImage?: Maybe<Asset>;
    subTitle?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoPage) */
export type DemoPageInitialDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoPage) */
export type DemoPageInitialProfileImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoPage) */
export type DemoPageInitialTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoPage) */
export type DemoPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoPage) */
export type DemoPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoPage) */
export type DemoPageSlidesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DemoPageSlidesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DemoSlideFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoPage) */
export type DemoPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoPage) */
export type DemoPageStartImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoPage) */
export type DemoPageSubTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoPage) */
export type DemoPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type DemoPageCollection = {
  __typename?: 'DemoPageCollection';
  items: Array<Maybe<DemoPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DemoPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<DemoPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DemoPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  initialDescription_contains?: InputMaybe<Scalars['String']['input']>;
  initialDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  initialDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  initialProfileImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  initialTitle?: InputMaybe<Scalars['String']['input']>;
  initialTitle_contains?: InputMaybe<Scalars['String']['input']>;
  initialTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  initialTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  initialTitle_not?: InputMaybe<Scalars['String']['input']>;
  initialTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  initialTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slides?: InputMaybe<CfDemoSlideNestedFilter>;
  slidesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  subTitle_contains?: InputMaybe<Scalars['String']['input']>;
  subTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subTitle_not?: InputMaybe<Scalars['String']['input']>;
  subTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  subTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DemoPageInitialDescription = {
  __typename?: 'DemoPageInitialDescription';
  json: Scalars['JSON']['output'];
  links: DemoPageInitialDescriptionLinks;
};

export type DemoPageInitialDescriptionAssets = {
  __typename?: 'DemoPageInitialDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DemoPageInitialDescriptionEntries = {
  __typename?: 'DemoPageInitialDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DemoPageInitialDescriptionLinks = {
  __typename?: 'DemoPageInitialDescriptionLinks';
  assets: DemoPageInitialDescriptionAssets;
  entries: DemoPageInitialDescriptionEntries;
  resources: DemoPageInitialDescriptionResources;
};

export type DemoPageInitialDescriptionResources = {
  __typename?: 'DemoPageInitialDescriptionResources';
  block: Array<DemoPageInitialDescriptionResourcesBlock>;
  hyperlink: Array<DemoPageInitialDescriptionResourcesHyperlink>;
  inline: Array<DemoPageInitialDescriptionResourcesInline>;
};

export type DemoPageInitialDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'DemoPageInitialDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type DemoPageInitialDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'DemoPageInitialDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type DemoPageInitialDescriptionResourcesInline = ResourceLink & {
  __typename?: 'DemoPageInitialDescriptionResourcesInline';
  sys: ResourceSys;
};

export type DemoPageLinkingCollections = {
  __typename?: 'DemoPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type DemoPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum DemoPageOrder {
  initialTitle_ASC = 'initialTitle_ASC',
  initialTitle_DESC = 'initialTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  subTitle_ASC = 'subTitle_ASC',
  subTitle_DESC = 'subTitle_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type DemoPageSlidesCollection = {
  __typename?: 'DemoPageSlidesCollection';
  items: Array<Maybe<DemoSlide>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum DemoPageSlidesCollectionOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** Slide for the demo page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoSlide) */
export type DemoSlide = Entry &
  _Node & {
    __typename?: 'DemoSlide';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    linkedFrom?: Maybe<DemoSlideLinkingCollections>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Slide for the demo page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoSlide) */
export type DemoSlideDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Slide for the demo page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoSlide) */
export type DemoSlideImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Slide for the demo page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoSlide) */
export type DemoSlideLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Slide for the demo page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/demoSlide) */
export type DemoSlideTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type DemoSlideCollection = {
  __typename?: 'DemoSlideCollection';
  items: Array<Maybe<DemoSlide>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DemoSlideFilter = {
  AND?: InputMaybe<Array<InputMaybe<DemoSlideFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DemoSlideFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DemoSlideLinkingCollections = {
  __typename?: 'DemoSlideLinkingCollections';
  demoPageCollection?: Maybe<DemoPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type DemoSlideLinkingCollectionsDemoPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<DemoSlideLinkingCollectionsDemoPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type DemoSlideLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum DemoSlideLinkingCollectionsDemoPageCollectionOrder {
  initialTitle_ASC = 'initialTitle_ASC',
  initialTitle_DESC = 'initialTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  subTitle_ASC = 'subTitle_ASC',
  subTitle_DESC = 'subTitle_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum DemoSlideOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPage = Entry &
  _Node & {
    __typename?: 'DownloadPage';
    _id: Scalars['ID']['output'];
    appPicture?: Maybe<Asset>;
    contentfulMetadata: ContentfulMetadata;
    downloadButton?: Maybe<Scalars['String']['output']>;
    headerText?: Maybe<DownloadPageHeaderText>;
    headerTextWebVersion?: Maybe<DownloadPageHeaderTextWebVersion>;
    linkedFrom?: Maybe<DownloadPageLinkingCollections>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    stepperBookCar?: Maybe<Scalars['String']['output']>;
    stepperCreateAccount?: Maybe<Scalars['String']['output']>;
    stepperFindCar?: Maybe<Scalars['String']['output']>;
    stepperScanQr?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    webSupportDetailsLink?: Maybe<ContactInfoLink>;
  };

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageAppPictureArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageDownloadButtonArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageHeaderTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageHeaderTextWebVersionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageStepperBookCarArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageStepperCreateAccountArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageStepperFindCarArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageStepperScanQrArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Download app page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/downloadPage) */
export type DownloadPageWebSupportDetailsLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ContactInfoLinkFilter>;
};

export type DownloadPageCollection = {
  __typename?: 'DownloadPageCollection';
  items: Array<Maybe<DownloadPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DownloadPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<DownloadPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DownloadPageFilter>>>;
  appPicture_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  downloadButton?: InputMaybe<Scalars['String']['input']>;
  downloadButton_contains?: InputMaybe<Scalars['String']['input']>;
  downloadButton_exists?: InputMaybe<Scalars['Boolean']['input']>;
  downloadButton_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  downloadButton_not?: InputMaybe<Scalars['String']['input']>;
  downloadButton_not_contains?: InputMaybe<Scalars['String']['input']>;
  downloadButton_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  headerTextWebVersion_contains?: InputMaybe<Scalars['String']['input']>;
  headerTextWebVersion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerTextWebVersion_not_contains?: InputMaybe<Scalars['String']['input']>;
  headerText_contains?: InputMaybe<Scalars['String']['input']>;
  headerText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerText_not_contains?: InputMaybe<Scalars['String']['input']>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stepperBookCar?: InputMaybe<Scalars['String']['input']>;
  stepperBookCar_contains?: InputMaybe<Scalars['String']['input']>;
  stepperBookCar_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stepperBookCar_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stepperBookCar_not?: InputMaybe<Scalars['String']['input']>;
  stepperBookCar_not_contains?: InputMaybe<Scalars['String']['input']>;
  stepperBookCar_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  stepperCreateAccount?: InputMaybe<Scalars['String']['input']>;
  stepperCreateAccount_contains?: InputMaybe<Scalars['String']['input']>;
  stepperCreateAccount_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stepperCreateAccount_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  stepperCreateAccount_not?: InputMaybe<Scalars['String']['input']>;
  stepperCreateAccount_not_contains?: InputMaybe<Scalars['String']['input']>;
  stepperCreateAccount_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  stepperFindCar?: InputMaybe<Scalars['String']['input']>;
  stepperFindCar_contains?: InputMaybe<Scalars['String']['input']>;
  stepperFindCar_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stepperFindCar_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stepperFindCar_not?: InputMaybe<Scalars['String']['input']>;
  stepperFindCar_not_contains?: InputMaybe<Scalars['String']['input']>;
  stepperFindCar_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  stepperScanQR?: InputMaybe<Scalars['String']['input']>;
  stepperScanQR_contains?: InputMaybe<Scalars['String']['input']>;
  stepperScanQR_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stepperScanQR_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stepperScanQR_not?: InputMaybe<Scalars['String']['input']>;
  stepperScanQR_not_contains?: InputMaybe<Scalars['String']['input']>;
  stepperScanQR_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  webSupportDetailsLink?: InputMaybe<CfContactInfoLinkNestedFilter>;
  webSupportDetailsLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DownloadPageHeaderText = {
  __typename?: 'DownloadPageHeaderText';
  json: Scalars['JSON']['output'];
  links: DownloadPageHeaderTextLinks;
};

export type DownloadPageHeaderTextAssets = {
  __typename?: 'DownloadPageHeaderTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DownloadPageHeaderTextEntries = {
  __typename?: 'DownloadPageHeaderTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DownloadPageHeaderTextLinks = {
  __typename?: 'DownloadPageHeaderTextLinks';
  assets: DownloadPageHeaderTextAssets;
  entries: DownloadPageHeaderTextEntries;
  resources: DownloadPageHeaderTextResources;
};

export type DownloadPageHeaderTextResources = {
  __typename?: 'DownloadPageHeaderTextResources';
  block: Array<DownloadPageHeaderTextResourcesBlock>;
  hyperlink: Array<DownloadPageHeaderTextResourcesHyperlink>;
  inline: Array<DownloadPageHeaderTextResourcesInline>;
};

export type DownloadPageHeaderTextResourcesBlock = ResourceLink & {
  __typename?: 'DownloadPageHeaderTextResourcesBlock';
  sys: ResourceSys;
};

export type DownloadPageHeaderTextResourcesHyperlink = ResourceLink & {
  __typename?: 'DownloadPageHeaderTextResourcesHyperlink';
  sys: ResourceSys;
};

export type DownloadPageHeaderTextResourcesInline = ResourceLink & {
  __typename?: 'DownloadPageHeaderTextResourcesInline';
  sys: ResourceSys;
};

export type DownloadPageHeaderTextWebVersion = {
  __typename?: 'DownloadPageHeaderTextWebVersion';
  json: Scalars['JSON']['output'];
  links: DownloadPageHeaderTextWebVersionLinks;
};

export type DownloadPageHeaderTextWebVersionAssets = {
  __typename?: 'DownloadPageHeaderTextWebVersionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DownloadPageHeaderTextWebVersionEntries = {
  __typename?: 'DownloadPageHeaderTextWebVersionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DownloadPageHeaderTextWebVersionLinks = {
  __typename?: 'DownloadPageHeaderTextWebVersionLinks';
  assets: DownloadPageHeaderTextWebVersionAssets;
  entries: DownloadPageHeaderTextWebVersionEntries;
  resources: DownloadPageHeaderTextWebVersionResources;
};

export type DownloadPageHeaderTextWebVersionResources = {
  __typename?: 'DownloadPageHeaderTextWebVersionResources';
  block: Array<DownloadPageHeaderTextWebVersionResourcesBlock>;
  hyperlink: Array<DownloadPageHeaderTextWebVersionResourcesHyperlink>;
  inline: Array<DownloadPageHeaderTextWebVersionResourcesInline>;
};

export type DownloadPageHeaderTextWebVersionResourcesBlock = ResourceLink & {
  __typename?: 'DownloadPageHeaderTextWebVersionResourcesBlock';
  sys: ResourceSys;
};

export type DownloadPageHeaderTextWebVersionResourcesHyperlink =
  ResourceLink & {
    __typename?: 'DownloadPageHeaderTextWebVersionResourcesHyperlink';
    sys: ResourceSys;
  };

export type DownloadPageHeaderTextWebVersionResourcesInline = ResourceLink & {
  __typename?: 'DownloadPageHeaderTextWebVersionResourcesInline';
  sys: ResourceSys;
};

export type DownloadPageLinkingCollections = {
  __typename?: 'DownloadPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type DownloadPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum DownloadPageOrder {
  downloadButton_ASC = 'downloadButton_ASC',
  downloadButton_DESC = 'downloadButton_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  stepperBookCar_ASC = 'stepperBookCar_ASC',
  stepperBookCar_DESC = 'stepperBookCar_DESC',
  stepperCreateAccount_ASC = 'stepperCreateAccount_ASC',
  stepperCreateAccount_DESC = 'stepperCreateAccount_DESC',
  stepperFindCar_ASC = 'stepperFindCar_ASC',
  stepperFindCar_DESC = 'stepperFindCar_DESC',
  stepperScanQR_ASC = 'stepperScanQR_ASC',
  stepperScanQR_DESC = 'stepperScanQR_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/dribeIcon) */
export type DribeIcon = Entry &
  _Node & {
    __typename?: 'DribeIcon';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    iconName?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<DribeIconLinkingCollections>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/dribeIcon) */
export type DribeIconIconNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/dribeIcon) */
export type DribeIconLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DribeIconCollection = {
  __typename?: 'DribeIconCollection';
  items: Array<Maybe<DribeIcon>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DribeIconFilter = {
  AND?: InputMaybe<Array<InputMaybe<DribeIconFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DribeIconFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  iconName_contains?: InputMaybe<Scalars['String']['input']>;
  iconName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  iconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  iconName_not?: InputMaybe<Scalars['String']['input']>;
  iconName_not_contains?: InputMaybe<Scalars['String']['input']>;
  iconName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type DribeIconLinkingCollections = {
  __typename?: 'DribeIconLinkingCollections';
  contactCardCollection?: Maybe<ContactCardCollection>;
  entryCollection?: Maybe<EntryCollection>;
  faqCategoryCollection?: Maybe<FaqCategoryCollection>;
  featureCollection?: Maybe<FeatureCollection>;
  tableRowCollection?: Maybe<TableRowCollection>;
};

export type DribeIconLinkingCollectionsContactCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<DribeIconLinkingCollectionsContactCardCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type DribeIconLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type DribeIconLinkingCollectionsFaqCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<DribeIconLinkingCollectionsFaqCategoryCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type DribeIconLinkingCollectionsFeatureCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<DribeIconLinkingCollectionsFeatureCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type DribeIconLinkingCollectionsTableRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<DribeIconLinkingCollectionsTableRowCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum DribeIconLinkingCollectionsContactCardCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export enum DribeIconLinkingCollectionsFaqCategoryCollectionOrder {
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum DribeIconLinkingCollectionsFeatureCollectionOrder {
  flipTitle_ASC = 'flipTitle_ASC',
  flipTitle_DESC = 'flipTitle_DESC',
  flipVisibleLinkTitle_ASC = 'flipVisibleLinkTitle_ASC',
  flipVisibleLinkTitle_DESC = 'flipVisibleLinkTitle_DESC',
  flipVisibleLink_ASC = 'flipVisibleLink_ASC',
  flipVisibleLink_DESC = 'flipVisibleLink_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  visibleLinkTitle_ASC = 'visibleLinkTitle_ASC',
  visibleLinkTitle_DESC = 'visibleLinkTitle_DESC',
  visibleLink_ASC = 'visibleLink_ASC',
  visibleLink_DESC = 'visibleLink_DESC'
}

export enum DribeIconLinkingCollectionsTableRowCollectionOrder {
  lokaliseKey_ASC = 'lokaliseKey_ASC',
  lokaliseKey_DESC = 'lokaliseKey_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export enum DribeIconOrder {
  iconName_ASC = 'iconName_ASC',
  iconName_DESC = 'iconName_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** A customisable form for use on the website. The user can define the fields in the form themselves. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/dynamicForm) */
export type DynamicForm = Entry &
  _Node & {
    __typename?: 'DynamicForm';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    formEntryTitle?: Maybe<Scalars['String']['output']>;
    formFieldsCollection?: Maybe<DynamicFormFormFieldsCollection>;
    formIdentifierTag?: Maybe<Scalars['String']['output']>;
    formTextContent?: Maybe<DynamicFormFormTextContent>;
    linkedFrom?: Maybe<DynamicFormLinkingCollections>;
    submitButtonLabel?: Maybe<Scalars['String']['output']>;
    successfulSubmitMessage?: Maybe<DynamicFormSuccessfulSubmitMessage>;
    sys: Sys;
  };

/** A customisable form for use on the website. The user can define the fields in the form themselves. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/dynamicForm) */
export type DynamicFormFormEntryTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** A customisable form for use on the website. The user can define the fields in the form themselves. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/dynamicForm) */
export type DynamicFormFormFieldsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DynamicFormFormFieldsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FormFieldFilter>;
};

/** A customisable form for use on the website. The user can define the fields in the form themselves. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/dynamicForm) */
export type DynamicFormFormIdentifierTagArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** A customisable form for use on the website. The user can define the fields in the form themselves. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/dynamicForm) */
export type DynamicFormFormTextContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** A customisable form for use on the website. The user can define the fields in the form themselves. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/dynamicForm) */
export type DynamicFormLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** A customisable form for use on the website. The user can define the fields in the form themselves. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/dynamicForm) */
export type DynamicFormSubmitButtonLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** A customisable form for use on the website. The user can define the fields in the form themselves. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/dynamicForm) */
export type DynamicFormSuccessfulSubmitMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type DynamicFormCollection = {
  __typename?: 'DynamicFormCollection';
  items: Array<Maybe<DynamicForm>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type DynamicFormFilter = {
  AND?: InputMaybe<Array<InputMaybe<DynamicFormFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<DynamicFormFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  formEntryTitle?: InputMaybe<Scalars['String']['input']>;
  formEntryTitle_contains?: InputMaybe<Scalars['String']['input']>;
  formEntryTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formEntryTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formEntryTitle_not?: InputMaybe<Scalars['String']['input']>;
  formEntryTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  formEntryTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  formFields?: InputMaybe<CfFormFieldNestedFilter>;
  formFieldsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formIdentifierTag?: InputMaybe<Scalars['String']['input']>;
  formIdentifierTag_contains?: InputMaybe<Scalars['String']['input']>;
  formIdentifierTag_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formIdentifierTag_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  formIdentifierTag_not?: InputMaybe<Scalars['String']['input']>;
  formIdentifierTag_not_contains?: InputMaybe<Scalars['String']['input']>;
  formIdentifierTag_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  formTextContent_contains?: InputMaybe<Scalars['String']['input']>;
  formTextContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formTextContent_not_contains?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  submitButtonLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  submitButtonLabel_not?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  successfulSubmitMessage_contains?: InputMaybe<Scalars['String']['input']>;
  successfulSubmitMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  successfulSubmitMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type DynamicFormFormFieldsCollection = {
  __typename?: 'DynamicFormFormFieldsCollection';
  items: Array<Maybe<FormField>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum DynamicFormFormFieldsCollectionOrder {
  contentfulTitle_ASC = 'contentfulTitle_ASC',
  contentfulTitle_DESC = 'contentfulTitle_DESC',
  fieldErrorMessage_ASC = 'fieldErrorMessage_ASC',
  fieldErrorMessage_DESC = 'fieldErrorMessage_DESC',
  fieldFullWidth_ASC = 'fieldFullWidth_ASC',
  fieldFullWidth_DESC = 'fieldFullWidth_DESC',
  fieldLabel_ASC = 'fieldLabel_ASC',
  fieldLabel_DESC = 'fieldLabel_DESC',
  fieldName_ASC = 'fieldName_ASC',
  fieldName_DESC = 'fieldName_DESC',
  fieldRegex_ASC = 'fieldRegex_ASC',
  fieldRegex_DESC = 'fieldRegex_DESC',
  fieldRequired_ASC = 'fieldRequired_ASC',
  fieldRequired_DESC = 'fieldRequired_DESC',
  fieldType_ASC = 'fieldType_ASC',
  fieldType_DESC = 'fieldType_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type DynamicFormFormTextContent = {
  __typename?: 'DynamicFormFormTextContent';
  json: Scalars['JSON']['output'];
  links: DynamicFormFormTextContentLinks;
};

export type DynamicFormFormTextContentAssets = {
  __typename?: 'DynamicFormFormTextContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DynamicFormFormTextContentEntries = {
  __typename?: 'DynamicFormFormTextContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DynamicFormFormTextContentLinks = {
  __typename?: 'DynamicFormFormTextContentLinks';
  assets: DynamicFormFormTextContentAssets;
  entries: DynamicFormFormTextContentEntries;
  resources: DynamicFormFormTextContentResources;
};

export type DynamicFormFormTextContentResources = {
  __typename?: 'DynamicFormFormTextContentResources';
  block: Array<DynamicFormFormTextContentResourcesBlock>;
  hyperlink: Array<DynamicFormFormTextContentResourcesHyperlink>;
  inline: Array<DynamicFormFormTextContentResourcesInline>;
};

export type DynamicFormFormTextContentResourcesBlock = ResourceLink & {
  __typename?: 'DynamicFormFormTextContentResourcesBlock';
  sys: ResourceSys;
};

export type DynamicFormFormTextContentResourcesHyperlink = ResourceLink & {
  __typename?: 'DynamicFormFormTextContentResourcesHyperlink';
  sys: ResourceSys;
};

export type DynamicFormFormTextContentResourcesInline = ResourceLink & {
  __typename?: 'DynamicFormFormTextContentResourcesInline';
  sys: ResourceSys;
};

export type DynamicFormLinkingCollections = {
  __typename?: 'DynamicFormLinkingCollections';
  contactPageB2BCollection?: Maybe<ContactPageB2BCollection>;
  entryCollection?: Maybe<EntryCollection>;
  newsletterContentCollection?: Maybe<NewsletterContentCollection>;
};

export type DynamicFormLinkingCollectionsContactPageB2BCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<DynamicFormLinkingCollectionsContactPageB2BCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type DynamicFormLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type DynamicFormLinkingCollectionsNewsletterContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<DynamicFormLinkingCollectionsNewsletterContentCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum DynamicFormLinkingCollectionsContactPageB2BCollectionOrder {
  companyIdInputLabel_ASC = 'companyIdInputLabel_ASC',
  companyIdInputLabel_DESC = 'companyIdInputLabel_DESC',
  companyIdRequired_ASC = 'companyIdRequired_ASC',
  companyIdRequired_DESC = 'companyIdRequired_DESC',
  featureDescription_ASC = 'featureDescription_ASC',
  featureDescription_DESC = 'featureDescription_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  submitTitle_ASC = 'submitTitle_ASC',
  submitTitle_DESC = 'submitTitle_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum DynamicFormLinkingCollectionsNewsletterContentCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum DynamicFormOrder {
  formEntryTitle_ASC = 'formEntryTitle_ASC',
  formEntryTitle_DESC = 'formEntryTitle_DESC',
  formIdentifierTag_ASC = 'formIdentifierTag_ASC',
  formIdentifierTag_DESC = 'formIdentifierTag_DESC',
  submitButtonLabel_ASC = 'submitButtonLabel_ASC',
  submitButtonLabel_DESC = 'submitButtonLabel_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type DynamicFormSuccessfulSubmitMessage = {
  __typename?: 'DynamicFormSuccessfulSubmitMessage';
  json: Scalars['JSON']['output'];
  links: DynamicFormSuccessfulSubmitMessageLinks;
};

export type DynamicFormSuccessfulSubmitMessageAssets = {
  __typename?: 'DynamicFormSuccessfulSubmitMessageAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type DynamicFormSuccessfulSubmitMessageEntries = {
  __typename?: 'DynamicFormSuccessfulSubmitMessageEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type DynamicFormSuccessfulSubmitMessageLinks = {
  __typename?: 'DynamicFormSuccessfulSubmitMessageLinks';
  assets: DynamicFormSuccessfulSubmitMessageAssets;
  entries: DynamicFormSuccessfulSubmitMessageEntries;
  resources: DynamicFormSuccessfulSubmitMessageResources;
};

export type DynamicFormSuccessfulSubmitMessageResources = {
  __typename?: 'DynamicFormSuccessfulSubmitMessageResources';
  block: Array<DynamicFormSuccessfulSubmitMessageResourcesBlock>;
  hyperlink: Array<DynamicFormSuccessfulSubmitMessageResourcesHyperlink>;
  inline: Array<DynamicFormSuccessfulSubmitMessageResourcesInline>;
};

export type DynamicFormSuccessfulSubmitMessageResourcesBlock = ResourceLink & {
  __typename?: 'DynamicFormSuccessfulSubmitMessageResourcesBlock';
  sys: ResourceSys;
};

export type DynamicFormSuccessfulSubmitMessageResourcesHyperlink =
  ResourceLink & {
    __typename?: 'DynamicFormSuccessfulSubmitMessageResourcesHyperlink';
    sys: ResourceSys;
  };

export type DynamicFormSuccessfulSubmitMessageResourcesInline = ResourceLink & {
  __typename?: 'DynamicFormSuccessfulSubmitMessageResourcesInline';
  sys: ResourceSys;
};

export type Entry = {
  contentfulMetadata: ContentfulMetadata;
  sys: Sys;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export enum EntryOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqCategory) */
export type FaqCategory = Entry &
  _Node & {
    __typename?: 'FaqCategory';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    faqEntriesCollection?: Maybe<FaqCategoryFaqEntriesCollection>;
    icon?: Maybe<DribeIcon>;
    linkedFrom?: Maybe<FaqCategoryLinkingCollections>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqCategory) */
export type FaqCategoryFaqEntriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqCategoryFaqEntriesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqEntryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqCategory) */
export type FaqCategoryIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DribeIconFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqCategory) */
export type FaqCategoryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqCategory) */
export type FaqCategorySlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqCategory) */
export type FaqCategoryTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FaqCategoryCollection = {
  __typename?: 'FaqCategoryCollection';
  items: Array<Maybe<FaqCategory>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqCategoryFaqEntriesCollection = {
  __typename?: 'FaqCategoryFaqEntriesCollection';
  items: Array<Maybe<FaqEntry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FaqCategoryFaqEntriesCollectionOrder {
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type FaqCategoryFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqCategoryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqCategoryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  faqEntries?: InputMaybe<CfFaqEntryNestedFilter>;
  faqEntriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<CfDribeIconNestedFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FaqCategoryLinkingCollections = {
  __typename?: 'FaqCategoryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqPageCollection?: Maybe<FaqPageCollection>;
};

export type FaqCategoryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FaqCategoryLinkingCollectionsFaqPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqCategoryLinkingCollectionsFaqPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FaqCategoryLinkingCollectionsFaqPageCollectionOrder {
  headerText_ASC = 'headerText_ASC',
  headerText_DESC = 'headerText_DESC',
  headerTitle_ASC = 'headerTitle_ASC',
  headerTitle_DESC = 'headerTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum FaqCategoryOrder {
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqEntry) */
export type FaqEntry = Entry &
  _Node & {
    __typename?: 'FaqEntry';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<FaqEntryLinkingCollections>;
    path?: Maybe<Scalars['String']['output']>;
    seoMetadata?: Maybe<SeoMetadata>;
    site?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    text?: Maybe<FaqEntryText>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqEntry) */
export type FaqEntryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqEntry) */
export type FaqEntryPathArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqEntry) */
export type FaqEntrySeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqEntry) */
export type FaqEntrySiteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqEntry) */
export type FaqEntrySlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqEntry) */
export type FaqEntryTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqEntry) */
export type FaqEntryTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FaqEntryCollection = {
  __typename?: 'FaqEntryCollection';
  items: Array<Maybe<FaqEntry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqEntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqEntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqEntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  path?: InputMaybe<Scalars['String']['input']>;
  path_contains?: InputMaybe<Scalars['String']['input']>;
  path_exists?: InputMaybe<Scalars['Boolean']['input']>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  path_not?: InputMaybe<Scalars['String']['input']>;
  path_not_contains?: InputMaybe<Scalars['String']['input']>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  site_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  site_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  site_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  site_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FaqEntryLinkingCollections = {
  __typename?: 'FaqEntryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqCategoryCollection?: Maybe<FaqCategoryCollection>;
  landingPageB2BCollection?: Maybe<LandingPageB2BCollection>;
  landingPageCollection?: Maybe<LandingPageCollection>;
};

export type FaqEntryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FaqEntryLinkingCollectionsFaqCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqEntryLinkingCollectionsFaqCategoryCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FaqEntryLinkingCollectionsLandingPageB2BCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqEntryLinkingCollectionsLandingPageB2BCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FaqEntryLinkingCollectionsLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FaqEntryLinkingCollectionsLandingPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FaqEntryLinkingCollectionsFaqCategoryCollectionOrder {
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum FaqEntryLinkingCollectionsLandingPageB2BCollectionOrder {
  blogSectionTitle_ASC = 'blogSectionTitle_ASC',
  blogSectionTitle_DESC = 'blogSectionTitle_DESC',
  contactSectionSubtitle_ASC = 'contactSectionSubtitle_ASC',
  contactSectionSubtitle_DESC = 'contactSectionSubtitle_DESC',
  contactSectionTitle_ASC = 'contactSectionTitle_ASC',
  contactSectionTitle_DESC = 'contactSectionTitle_DESC',
  faqSectionTitle_ASC = 'faqSectionTitle_ASC',
  faqSectionTitle_DESC = 'faqSectionTitle_DESC',
  featuresSubTitle_ASC = 'featuresSubTitle_ASC',
  featuresSubTitle_DESC = 'featuresSubTitle_DESC',
  featuresTitle_ASC = 'featuresTitle_ASC',
  featuresTitle_DESC = 'featuresTitle_DESC',
  showCaseSubTitle_ASC = 'showCaseSubTitle_ASC',
  showCaseSubTitle_DESC = 'showCaseSubTitle_DESC',
  showCaseTitle_ASC = 'showCaseTitle_ASC',
  showCaseTitle_DESC = 'showCaseTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  trustedPartnersTitle_ASC = 'trustedPartnersTitle_ASC',
  trustedPartnersTitle_DESC = 'trustedPartnersTitle_DESC'
}

export enum FaqEntryLinkingCollectionsLandingPageCollectionOrder {
  ageRequirementsAccentBox_ASC = 'ageRequirementsAccentBox_ASC',
  ageRequirementsAccentBox_DESC = 'ageRequirementsAccentBox_DESC',
  bookingFormSearchText_ASC = 'bookingFormSearchText_ASC',
  bookingFormSearchText_DESC = 'bookingFormSearchText_DESC',
  bookingFormTitle_ASC = 'bookingFormTitle_ASC',
  bookingFormTitle_DESC = 'bookingFormTitle_DESC',
  budgetCategoryMaxPrice_ASC = 'budgetCategoryMaxPrice_ASC',
  budgetCategoryMaxPrice_DESC = 'budgetCategoryMaxPrice_DESC',
  cardPricesFromText_ASC = 'cardPricesFromText_ASC',
  cardPricesFromText_DESC = 'cardPricesFromText_DESC',
  dateInputLabel_ASC = 'dateInputLabel_ASC',
  dateInputLabel_DESC = 'dateInputLabel_DESC',
  dateInputPlaceholder_ASC = 'dateInputPlaceholder_ASC',
  dateInputPlaceholder_DESC = 'dateInputPlaceholder_DESC',
  downloadAppTitle_ASC = 'downloadAppTitle_ASC',
  downloadAppTitle_DESC = 'downloadAppTitle_DESC',
  electricPropellantValue_ASC = 'electricPropellantValue_ASC',
  electricPropellantValue_DESC = 'electricPropellantValue_DESC',
  faqSectionTitle_ASC = 'faqSectionTitle_ASC',
  faqSectionTitle_DESC = 'faqSectionTitle_DESC',
  garageLabel_ASC = 'garageLabel_ASC',
  garageLabel_DESC = 'garageLabel_DESC',
  heroTitleText_ASC = 'heroTitleText_ASC',
  heroTitleText_DESC = 'heroTitleText_DESC',
  locationDropdownLabel_ASC = 'locationDropdownLabel_ASC',
  locationDropdownLabel_DESC = 'locationDropdownLabel_DESC',
  locationDropdownPlaceholder_ASC = 'locationDropdownPlaceholder_ASC',
  locationDropdownPlaceholder_DESC = 'locationDropdownPlaceholder_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum FaqEntryOrder {
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type FaqEntryText = {
  __typename?: 'FaqEntryText';
  json: Scalars['JSON']['output'];
  links: FaqEntryTextLinks;
};

export type FaqEntryTextAssets = {
  __typename?: 'FaqEntryTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FaqEntryTextEntries = {
  __typename?: 'FaqEntryTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FaqEntryTextLinks = {
  __typename?: 'FaqEntryTextLinks';
  assets: FaqEntryTextAssets;
  entries: FaqEntryTextEntries;
  resources: FaqEntryTextResources;
};

export type FaqEntryTextResources = {
  __typename?: 'FaqEntryTextResources';
  block: Array<FaqEntryTextResourcesBlock>;
  hyperlink: Array<FaqEntryTextResourcesHyperlink>;
  inline: Array<FaqEntryTextResourcesInline>;
};

export type FaqEntryTextResourcesBlock = ResourceLink & {
  __typename?: 'FaqEntryTextResourcesBlock';
  sys: ResourceSys;
};

export type FaqEntryTextResourcesHyperlink = ResourceLink & {
  __typename?: 'FaqEntryTextResourcesHyperlink';
  sys: ResourceSys;
};

export type FaqEntryTextResourcesInline = ResourceLink & {
  __typename?: 'FaqEntryTextResourcesInline';
  sys: ResourceSys;
};

/** FAQ Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqPage) */
export type FaqPage = Entry &
  _Node & {
    __typename?: 'FaqPage';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    faqCategoriesCollection?: Maybe<FaqPageFaqCategoriesCollection>;
    headerBodyText?: Maybe<FaqPageHeaderBodyText>;
    headerImage?: Maybe<Asset>;
    headerText?: Maybe<Scalars['String']['output']>;
    headerTitle?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<FaqPageLinkingCollections>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** FAQ Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqPage) */
export type FaqPageFaqCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqPageFaqCategoriesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqCategoryFilter>;
};

/** FAQ Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqPage) */
export type FaqPageHeaderBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** FAQ Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqPage) */
export type FaqPageHeaderImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** FAQ Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqPage) */
export type FaqPageHeaderTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** FAQ Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqPage) */
export type FaqPageHeaderTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** FAQ Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqPage) */
export type FaqPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** FAQ Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqPage) */
export type FaqPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** FAQ Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqPage) */
export type FaqPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** FAQ Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/faqPage) */
export type FaqPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FaqPageCollection = {
  __typename?: 'FaqPageCollection';
  items: Array<Maybe<FaqPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FaqPageFaqCategoriesCollection = {
  __typename?: 'FaqPageFaqCategoriesCollection';
  items: Array<Maybe<FaqCategory>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FaqPageFaqCategoriesCollectionOrder {
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type FaqPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  faqCategories?: InputMaybe<CfFaqCategoryNestedFilter>;
  faqCategoriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerBodyText_contains?: InputMaybe<Scalars['String']['input']>;
  headerBodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerBodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  headerImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerText_contains?: InputMaybe<Scalars['String']['input']>;
  headerText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headerText_not?: InputMaybe<Scalars['String']['input']>;
  headerText_not_contains?: InputMaybe<Scalars['String']['input']>;
  headerText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headerTitle?: InputMaybe<Scalars['String']['input']>;
  headerTitle_contains?: InputMaybe<Scalars['String']['input']>;
  headerTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headerTitle_not?: InputMaybe<Scalars['String']['input']>;
  headerTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  headerTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FaqPageHeaderBodyText = {
  __typename?: 'FaqPageHeaderBodyText';
  json: Scalars['JSON']['output'];
  links: FaqPageHeaderBodyTextLinks;
};

export type FaqPageHeaderBodyTextAssets = {
  __typename?: 'FaqPageHeaderBodyTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FaqPageHeaderBodyTextEntries = {
  __typename?: 'FaqPageHeaderBodyTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FaqPageHeaderBodyTextLinks = {
  __typename?: 'FaqPageHeaderBodyTextLinks';
  assets: FaqPageHeaderBodyTextAssets;
  entries: FaqPageHeaderBodyTextEntries;
  resources: FaqPageHeaderBodyTextResources;
};

export type FaqPageHeaderBodyTextResources = {
  __typename?: 'FaqPageHeaderBodyTextResources';
  block: Array<FaqPageHeaderBodyTextResourcesBlock>;
  hyperlink: Array<FaqPageHeaderBodyTextResourcesHyperlink>;
  inline: Array<FaqPageHeaderBodyTextResourcesInline>;
};

export type FaqPageHeaderBodyTextResourcesBlock = ResourceLink & {
  __typename?: 'FaqPageHeaderBodyTextResourcesBlock';
  sys: ResourceSys;
};

export type FaqPageHeaderBodyTextResourcesHyperlink = ResourceLink & {
  __typename?: 'FaqPageHeaderBodyTextResourcesHyperlink';
  sys: ResourceSys;
};

export type FaqPageHeaderBodyTextResourcesInline = ResourceLink & {
  __typename?: 'FaqPageHeaderBodyTextResourcesInline';
  sys: ResourceSys;
};

export type FaqPageLinkingCollections = {
  __typename?: 'FaqPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FaqPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FaqPageOrder {
  headerText_ASC = 'headerText_ASC',
  headerText_DESC = 'headerText_DESC',
  headerTitle_ASC = 'headerTitle_ASC',
  headerTitle_DESC = 'headerTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type Feature = Entry &
  _Node & {
    __typename?: 'Feature';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    defaultDescription?: Maybe<FeatureDefaultDescription>;
    defaultIcon?: Maybe<DribeIcon>;
    flipDescription?: Maybe<FeatureFlipDescription>;
    flipIcon?: Maybe<DribeIcon>;
    flipTitle?: Maybe<Scalars['String']['output']>;
    flipVisibleLink?: Maybe<Scalars['String']['output']>;
    flipVisibleLinkTitle?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<FeatureLinkingCollections>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    visibleLink?: Maybe<Scalars['String']['output']>;
    visibleLinkTitle?: Maybe<Scalars['String']['output']>;
  };

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type FeatureDefaultDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type FeatureDefaultIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DribeIconFilter>;
};

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type FeatureFlipDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type FeatureFlipIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DribeIconFilter>;
};

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type FeatureFlipTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type FeatureFlipVisibleLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type FeatureFlipVisibleLinkTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type FeatureLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type FeatureSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type FeatureTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type FeatureVisibleLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Features of the Dribe platform describing how they benefit the user. [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/feature) */
export type FeatureVisibleLinkTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FeatureCollection = {
  __typename?: 'FeatureCollection';
  items: Array<Maybe<Feature>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FeatureDefaultDescription = {
  __typename?: 'FeatureDefaultDescription';
  json: Scalars['JSON']['output'];
  links: FeatureDefaultDescriptionLinks;
};

export type FeatureDefaultDescriptionAssets = {
  __typename?: 'FeatureDefaultDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FeatureDefaultDescriptionEntries = {
  __typename?: 'FeatureDefaultDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FeatureDefaultDescriptionLinks = {
  __typename?: 'FeatureDefaultDescriptionLinks';
  assets: FeatureDefaultDescriptionAssets;
  entries: FeatureDefaultDescriptionEntries;
  resources: FeatureDefaultDescriptionResources;
};

export type FeatureDefaultDescriptionResources = {
  __typename?: 'FeatureDefaultDescriptionResources';
  block: Array<FeatureDefaultDescriptionResourcesBlock>;
  hyperlink: Array<FeatureDefaultDescriptionResourcesHyperlink>;
  inline: Array<FeatureDefaultDescriptionResourcesInline>;
};

export type FeatureDefaultDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'FeatureDefaultDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type FeatureDefaultDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'FeatureDefaultDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type FeatureDefaultDescriptionResourcesInline = ResourceLink & {
  __typename?: 'FeatureDefaultDescriptionResourcesInline';
  sys: ResourceSys;
};

export type FeatureFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeatureFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FeatureFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  defaultDescription_contains?: InputMaybe<Scalars['String']['input']>;
  defaultDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  defaultDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  defaultIcon?: InputMaybe<CfDribeIconNestedFilter>;
  defaultIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flipDescription_contains?: InputMaybe<Scalars['String']['input']>;
  flipDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flipDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  flipIcon?: InputMaybe<CfDribeIconNestedFilter>;
  flipIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flipTitle?: InputMaybe<Scalars['String']['input']>;
  flipTitle_contains?: InputMaybe<Scalars['String']['input']>;
  flipTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flipTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  flipTitle_not?: InputMaybe<Scalars['String']['input']>;
  flipTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  flipTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  flipVisibleLink?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLinkTitle?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLinkTitle_contains?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLinkTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flipVisibleLinkTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  flipVisibleLinkTitle_not?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLinkTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLinkTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  flipVisibleLink_contains?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flipVisibleLink_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  flipVisibleLink_not?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  visibleLink?: InputMaybe<Scalars['String']['input']>;
  visibleLinkTitle?: InputMaybe<Scalars['String']['input']>;
  visibleLinkTitle_contains?: InputMaybe<Scalars['String']['input']>;
  visibleLinkTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  visibleLinkTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visibleLinkTitle_not?: InputMaybe<Scalars['String']['input']>;
  visibleLinkTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  visibleLinkTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visibleLink_contains?: InputMaybe<Scalars['String']['input']>;
  visibleLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  visibleLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  visibleLink_not?: InputMaybe<Scalars['String']['input']>;
  visibleLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  visibleLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type FeatureFlipDescription = {
  __typename?: 'FeatureFlipDescription';
  json: Scalars['JSON']['output'];
  links: FeatureFlipDescriptionLinks;
};

export type FeatureFlipDescriptionAssets = {
  __typename?: 'FeatureFlipDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FeatureFlipDescriptionEntries = {
  __typename?: 'FeatureFlipDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FeatureFlipDescriptionLinks = {
  __typename?: 'FeatureFlipDescriptionLinks';
  assets: FeatureFlipDescriptionAssets;
  entries: FeatureFlipDescriptionEntries;
  resources: FeatureFlipDescriptionResources;
};

export type FeatureFlipDescriptionResources = {
  __typename?: 'FeatureFlipDescriptionResources';
  block: Array<FeatureFlipDescriptionResourcesBlock>;
  hyperlink: Array<FeatureFlipDescriptionResourcesHyperlink>;
  inline: Array<FeatureFlipDescriptionResourcesInline>;
};

export type FeatureFlipDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'FeatureFlipDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type FeatureFlipDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'FeatureFlipDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type FeatureFlipDescriptionResourcesInline = ResourceLink & {
  __typename?: 'FeatureFlipDescriptionResourcesInline';
  sys: ResourceSys;
};

export type FeatureLinkingCollections = {
  __typename?: 'FeatureLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  landingPageB2BCollection?: Maybe<LandingPageB2BCollection>;
  landingPageCollection?: Maybe<LandingPageCollection>;
};

export type FeatureLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FeatureLinkingCollectionsLandingPageB2BCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FeatureLinkingCollectionsLandingPageB2BCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FeatureLinkingCollectionsLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FeatureLinkingCollectionsLandingPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FeatureLinkingCollectionsLandingPageB2BCollectionOrder {
  blogSectionTitle_ASC = 'blogSectionTitle_ASC',
  blogSectionTitle_DESC = 'blogSectionTitle_DESC',
  contactSectionSubtitle_ASC = 'contactSectionSubtitle_ASC',
  contactSectionSubtitle_DESC = 'contactSectionSubtitle_DESC',
  contactSectionTitle_ASC = 'contactSectionTitle_ASC',
  contactSectionTitle_DESC = 'contactSectionTitle_DESC',
  faqSectionTitle_ASC = 'faqSectionTitle_ASC',
  faqSectionTitle_DESC = 'faqSectionTitle_DESC',
  featuresSubTitle_ASC = 'featuresSubTitle_ASC',
  featuresSubTitle_DESC = 'featuresSubTitle_DESC',
  featuresTitle_ASC = 'featuresTitle_ASC',
  featuresTitle_DESC = 'featuresTitle_DESC',
  showCaseSubTitle_ASC = 'showCaseSubTitle_ASC',
  showCaseSubTitle_DESC = 'showCaseSubTitle_DESC',
  showCaseTitle_ASC = 'showCaseTitle_ASC',
  showCaseTitle_DESC = 'showCaseTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  trustedPartnersTitle_ASC = 'trustedPartnersTitle_ASC',
  trustedPartnersTitle_DESC = 'trustedPartnersTitle_DESC'
}

export enum FeatureLinkingCollectionsLandingPageCollectionOrder {
  ageRequirementsAccentBox_ASC = 'ageRequirementsAccentBox_ASC',
  ageRequirementsAccentBox_DESC = 'ageRequirementsAccentBox_DESC',
  bookingFormSearchText_ASC = 'bookingFormSearchText_ASC',
  bookingFormSearchText_DESC = 'bookingFormSearchText_DESC',
  bookingFormTitle_ASC = 'bookingFormTitle_ASC',
  bookingFormTitle_DESC = 'bookingFormTitle_DESC',
  budgetCategoryMaxPrice_ASC = 'budgetCategoryMaxPrice_ASC',
  budgetCategoryMaxPrice_DESC = 'budgetCategoryMaxPrice_DESC',
  cardPricesFromText_ASC = 'cardPricesFromText_ASC',
  cardPricesFromText_DESC = 'cardPricesFromText_DESC',
  dateInputLabel_ASC = 'dateInputLabel_ASC',
  dateInputLabel_DESC = 'dateInputLabel_DESC',
  dateInputPlaceholder_ASC = 'dateInputPlaceholder_ASC',
  dateInputPlaceholder_DESC = 'dateInputPlaceholder_DESC',
  downloadAppTitle_ASC = 'downloadAppTitle_ASC',
  downloadAppTitle_DESC = 'downloadAppTitle_DESC',
  electricPropellantValue_ASC = 'electricPropellantValue_ASC',
  electricPropellantValue_DESC = 'electricPropellantValue_DESC',
  faqSectionTitle_ASC = 'faqSectionTitle_ASC',
  faqSectionTitle_DESC = 'faqSectionTitle_DESC',
  garageLabel_ASC = 'garageLabel_ASC',
  garageLabel_DESC = 'garageLabel_DESC',
  heroTitleText_ASC = 'heroTitleText_ASC',
  heroTitleText_DESC = 'heroTitleText_DESC',
  locationDropdownLabel_ASC = 'locationDropdownLabel_ASC',
  locationDropdownLabel_DESC = 'locationDropdownLabel_DESC',
  locationDropdownPlaceholder_ASC = 'locationDropdownPlaceholder_ASC',
  locationDropdownPlaceholder_DESC = 'locationDropdownPlaceholder_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum FeatureOrder {
  flipTitle_ASC = 'flipTitle_ASC',
  flipTitle_DESC = 'flipTitle_DESC',
  flipVisibleLinkTitle_ASC = 'flipVisibleLinkTitle_ASC',
  flipVisibleLinkTitle_DESC = 'flipVisibleLinkTitle_DESC',
  flipVisibleLink_ASC = 'flipVisibleLink_ASC',
  flipVisibleLink_DESC = 'flipVisibleLink_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  visibleLinkTitle_ASC = 'visibleLinkTitle_ASC',
  visibleLinkTitle_DESC = 'visibleLinkTitle_DESC',
  visibleLink_ASC = 'visibleLink_ASC',
  visibleLink_DESC = 'visibleLink_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type Footer = Entry &
  _Node & {
    __typename?: 'Footer';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    customerCareContactInfo?: Maybe<FooterCustomerCareContactInfo>;
    facebookLink?: Maybe<Scalars['String']['output']>;
    facebookRating?: Maybe<Scalars['Float']['output']>;
    fixedDribeFooterContent?: Maybe<Scalars['String']['output']>;
    googleLink?: Maybe<Scalars['String']['output']>;
    googleRating?: Maybe<Scalars['Float']['output']>;
    instagramLink?: Maybe<Scalars['String']['output']>;
    linkGroupsCollection?: Maybe<FooterLinkGroupsCollection>;
    linkedFrom?: Maybe<FooterLinkingCollections>;
    linkedInLink?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    tikTokLink?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trustpilotLink?: Maybe<Scalars['String']['output']>;
    trustpilotRating?: Maybe<Scalars['Float']['output']>;
    youTubeLink?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterCustomerCareContactInfoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterFacebookLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterFacebookRatingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterFixedDribeFooterContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterGoogleLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterGoogleRatingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterInstagramLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterLinkGroupsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FooterLinkGroupsCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkGroupFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterLinkedInLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterTikTokLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterTrustpilotLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterTrustpilotRatingArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/footer) */
export type FooterYouTubeLinkArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type FooterCollection = {
  __typename?: 'FooterCollection';
  items: Array<Maybe<Footer>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FooterCustomerCareContactInfo = {
  __typename?: 'FooterCustomerCareContactInfo';
  json: Scalars['JSON']['output'];
  links: FooterCustomerCareContactInfoLinks;
};

export type FooterCustomerCareContactInfoAssets = {
  __typename?: 'FooterCustomerCareContactInfoAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FooterCustomerCareContactInfoEntries = {
  __typename?: 'FooterCustomerCareContactInfoEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FooterCustomerCareContactInfoLinks = {
  __typename?: 'FooterCustomerCareContactInfoLinks';
  assets: FooterCustomerCareContactInfoAssets;
  entries: FooterCustomerCareContactInfoEntries;
  resources: FooterCustomerCareContactInfoResources;
};

export type FooterCustomerCareContactInfoResources = {
  __typename?: 'FooterCustomerCareContactInfoResources';
  block: Array<FooterCustomerCareContactInfoResourcesBlock>;
  hyperlink: Array<FooterCustomerCareContactInfoResourcesHyperlink>;
  inline: Array<FooterCustomerCareContactInfoResourcesInline>;
};

export type FooterCustomerCareContactInfoResourcesBlock = ResourceLink & {
  __typename?: 'FooterCustomerCareContactInfoResourcesBlock';
  sys: ResourceSys;
};

export type FooterCustomerCareContactInfoResourcesHyperlink = ResourceLink & {
  __typename?: 'FooterCustomerCareContactInfoResourcesHyperlink';
  sys: ResourceSys;
};

export type FooterCustomerCareContactInfoResourcesInline = ResourceLink & {
  __typename?: 'FooterCustomerCareContactInfoResourcesInline';
  sys: ResourceSys;
};

export type FooterFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  customerCareContactInfo_contains?: InputMaybe<Scalars['String']['input']>;
  customerCareContactInfo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  customerCareContactInfo_not_contains?: InputMaybe<Scalars['String']['input']>;
  facebookLink?: InputMaybe<Scalars['String']['input']>;
  facebookLink_contains?: InputMaybe<Scalars['String']['input']>;
  facebookLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  facebookLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  facebookLink_not?: InputMaybe<Scalars['String']['input']>;
  facebookLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  facebookLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  facebookRating?: InputMaybe<Scalars['Float']['input']>;
  facebookRating_exists?: InputMaybe<Scalars['Boolean']['input']>;
  facebookRating_gt?: InputMaybe<Scalars['Float']['input']>;
  facebookRating_gte?: InputMaybe<Scalars['Float']['input']>;
  facebookRating_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  facebookRating_lt?: InputMaybe<Scalars['Float']['input']>;
  facebookRating_lte?: InputMaybe<Scalars['Float']['input']>;
  facebookRating_not?: InputMaybe<Scalars['Float']['input']>;
  facebookRating_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  fixedDribeFooterContent?: InputMaybe<Scalars['String']['input']>;
  fixedDribeFooterContent_contains?: InputMaybe<Scalars['String']['input']>;
  fixedDribeFooterContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fixedDribeFooterContent_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fixedDribeFooterContent_not?: InputMaybe<Scalars['String']['input']>;
  fixedDribeFooterContent_not_contains?: InputMaybe<Scalars['String']['input']>;
  fixedDribeFooterContent_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  googleLink?: InputMaybe<Scalars['String']['input']>;
  googleLink_contains?: InputMaybe<Scalars['String']['input']>;
  googleLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  googleLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  googleLink_not?: InputMaybe<Scalars['String']['input']>;
  googleLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  googleLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  googleRating?: InputMaybe<Scalars['Float']['input']>;
  googleRating_exists?: InputMaybe<Scalars['Boolean']['input']>;
  googleRating_gt?: InputMaybe<Scalars['Float']['input']>;
  googleRating_gte?: InputMaybe<Scalars['Float']['input']>;
  googleRating_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  googleRating_lt?: InputMaybe<Scalars['Float']['input']>;
  googleRating_lte?: InputMaybe<Scalars['Float']['input']>;
  googleRating_not?: InputMaybe<Scalars['Float']['input']>;
  googleRating_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  instagramLink?: InputMaybe<Scalars['String']['input']>;
  instagramLink_contains?: InputMaybe<Scalars['String']['input']>;
  instagramLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  instagramLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  instagramLink_not?: InputMaybe<Scalars['String']['input']>;
  instagramLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  instagramLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  linkGroups?: InputMaybe<CfLinkGroupNestedFilter>;
  linkGroupsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  linkedInLink?: InputMaybe<Scalars['String']['input']>;
  linkedInLink_contains?: InputMaybe<Scalars['String']['input']>;
  linkedInLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  linkedInLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  linkedInLink_not?: InputMaybe<Scalars['String']['input']>;
  linkedInLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  linkedInLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  tikTokLink?: InputMaybe<Scalars['String']['input']>;
  tikTokLink_contains?: InputMaybe<Scalars['String']['input']>;
  tikTokLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tikTokLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tikTokLink_not?: InputMaybe<Scalars['String']['input']>;
  tikTokLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  tikTokLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trustpilotLink?: InputMaybe<Scalars['String']['input']>;
  trustpilotLink_contains?: InputMaybe<Scalars['String']['input']>;
  trustpilotLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  trustpilotLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trustpilotLink_not?: InputMaybe<Scalars['String']['input']>;
  trustpilotLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  trustpilotLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  trustpilotRating?: InputMaybe<Scalars['Float']['input']>;
  trustpilotRating_exists?: InputMaybe<Scalars['Boolean']['input']>;
  trustpilotRating_gt?: InputMaybe<Scalars['Float']['input']>;
  trustpilotRating_gte?: InputMaybe<Scalars['Float']['input']>;
  trustpilotRating_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  trustpilotRating_lt?: InputMaybe<Scalars['Float']['input']>;
  trustpilotRating_lte?: InputMaybe<Scalars['Float']['input']>;
  trustpilotRating_not?: InputMaybe<Scalars['Float']['input']>;
  trustpilotRating_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  youTubeLink?: InputMaybe<Scalars['String']['input']>;
  youTubeLink_contains?: InputMaybe<Scalars['String']['input']>;
  youTubeLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  youTubeLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  youTubeLink_not?: InputMaybe<Scalars['String']['input']>;
  youTubeLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  youTubeLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type FooterLinkGroupsCollection = {
  __typename?: 'FooterLinkGroupsCollection';
  items: Array<Maybe<LinkGroup>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum FooterLinkGroupsCollectionOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type FooterLinkingCollections = {
  __typename?: 'FooterLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type FooterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FooterOrder {
  facebookLink_ASC = 'facebookLink_ASC',
  facebookLink_DESC = 'facebookLink_DESC',
  facebookRating_ASC = 'facebookRating_ASC',
  facebookRating_DESC = 'facebookRating_DESC',
  fixedDribeFooterContent_ASC = 'fixedDribeFooterContent_ASC',
  fixedDribeFooterContent_DESC = 'fixedDribeFooterContent_DESC',
  googleLink_ASC = 'googleLink_ASC',
  googleLink_DESC = 'googleLink_DESC',
  googleRating_ASC = 'googleRating_ASC',
  googleRating_DESC = 'googleRating_DESC',
  instagramLink_ASC = 'instagramLink_ASC',
  instagramLink_DESC = 'instagramLink_DESC',
  linkedInLink_ASC = 'linkedInLink_ASC',
  linkedInLink_DESC = 'linkedInLink_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  tikTokLink_ASC = 'tikTokLink_ASC',
  tikTokLink_DESC = 'tikTokLink_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  trustpilotLink_ASC = 'trustpilotLink_ASC',
  trustpilotLink_DESC = 'trustpilotLink_DESC',
  trustpilotRating_ASC = 'trustpilotRating_ASC',
  trustpilotRating_DESC = 'trustpilotRating_DESC',
  youTubeLink_ASC = 'youTubeLink_ASC',
  youTubeLink_DESC = 'youTubeLink_DESC'
}

/** One of the fields in the dynamic form [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/formField) */
export type FormField = Entry &
  _Node & {
    __typename?: 'FormField';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    contentfulTitle?: Maybe<Scalars['String']['output']>;
    fieldErrorMessage?: Maybe<Scalars['String']['output']>;
    fieldFullWidth?: Maybe<Scalars['Boolean']['output']>;
    fieldLabel?: Maybe<Scalars['String']['output']>;
    fieldName?: Maybe<Scalars['String']['output']>;
    fieldRegex?: Maybe<Scalars['String']['output']>;
    fieldRequired?: Maybe<Scalars['Boolean']['output']>;
    fieldType?: Maybe<Scalars['String']['output']>;
    formFieldFreeText?: Maybe<FormFieldFormFieldFreeText>;
    linkedFrom?: Maybe<FormFieldLinkingCollections>;
    sys: Sys;
  };

/** One of the fields in the dynamic form [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/formField) */
export type FormFieldContentfulTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** One of the fields in the dynamic form [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/formField) */
export type FormFieldFieldErrorMessageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** One of the fields in the dynamic form [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/formField) */
export type FormFieldFieldFullWidthArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** One of the fields in the dynamic form [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/formField) */
export type FormFieldFieldLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** One of the fields in the dynamic form [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/formField) */
export type FormFieldFieldNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** One of the fields in the dynamic form [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/formField) */
export type FormFieldFieldRegexArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** One of the fields in the dynamic form [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/formField) */
export type FormFieldFieldRequiredArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** One of the fields in the dynamic form [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/formField) */
export type FormFieldFieldTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** One of the fields in the dynamic form [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/formField) */
export type FormFieldFormFieldFreeTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** One of the fields in the dynamic form [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/formField) */
export type FormFieldLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FormFieldCollection = {
  __typename?: 'FormFieldCollection';
  items: Array<Maybe<FormField>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type FormFieldFilter = {
  AND?: InputMaybe<Array<InputMaybe<FormFieldFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FormFieldFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  contentfulTitle?: InputMaybe<Scalars['String']['input']>;
  contentfulTitle_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulTitle_not?: InputMaybe<Scalars['String']['input']>;
  contentfulTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fieldErrorMessage?: InputMaybe<Scalars['String']['input']>;
  fieldErrorMessage_contains?: InputMaybe<Scalars['String']['input']>;
  fieldErrorMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fieldErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  fieldErrorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  fieldErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fieldFullWidth?: InputMaybe<Scalars['Boolean']['input']>;
  fieldFullWidth_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldFullWidth_not?: InputMaybe<Scalars['Boolean']['input']>;
  fieldLabel?: InputMaybe<Scalars['String']['input']>;
  fieldLabel_contains?: InputMaybe<Scalars['String']['input']>;
  fieldLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldLabel_not?: InputMaybe<Scalars['String']['input']>;
  fieldLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  fieldLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  fieldName_contains?: InputMaybe<Scalars['String']['input']>;
  fieldName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldName_not?: InputMaybe<Scalars['String']['input']>;
  fieldName_not_contains?: InputMaybe<Scalars['String']['input']>;
  fieldName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldRegex?: InputMaybe<Scalars['String']['input']>;
  fieldRegex_contains?: InputMaybe<Scalars['String']['input']>;
  fieldRegex_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldRegex_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldRegex_not?: InputMaybe<Scalars['String']['input']>;
  fieldRegex_not_contains?: InputMaybe<Scalars['String']['input']>;
  fieldRegex_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldRequired?: InputMaybe<Scalars['Boolean']['input']>;
  fieldRequired_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldRequired_not?: InputMaybe<Scalars['Boolean']['input']>;
  fieldType?: InputMaybe<Scalars['String']['input']>;
  fieldType_contains?: InputMaybe<Scalars['String']['input']>;
  fieldType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldType_not?: InputMaybe<Scalars['String']['input']>;
  fieldType_not_contains?: InputMaybe<Scalars['String']['input']>;
  fieldType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formFieldFreeText_contains?: InputMaybe<Scalars['String']['input']>;
  formFieldFreeText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formFieldFreeText_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type FormFieldFormFieldFreeText = {
  __typename?: 'FormFieldFormFieldFreeText';
  json: Scalars['JSON']['output'];
  links: FormFieldFormFieldFreeTextLinks;
};

export type FormFieldFormFieldFreeTextAssets = {
  __typename?: 'FormFieldFormFieldFreeTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FormFieldFormFieldFreeTextEntries = {
  __typename?: 'FormFieldFormFieldFreeTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FormFieldFormFieldFreeTextLinks = {
  __typename?: 'FormFieldFormFieldFreeTextLinks';
  assets: FormFieldFormFieldFreeTextAssets;
  entries: FormFieldFormFieldFreeTextEntries;
  resources: FormFieldFormFieldFreeTextResources;
};

export type FormFieldFormFieldFreeTextResources = {
  __typename?: 'FormFieldFormFieldFreeTextResources';
  block: Array<FormFieldFormFieldFreeTextResourcesBlock>;
  hyperlink: Array<FormFieldFormFieldFreeTextResourcesHyperlink>;
  inline: Array<FormFieldFormFieldFreeTextResourcesInline>;
};

export type FormFieldFormFieldFreeTextResourcesBlock = ResourceLink & {
  __typename?: 'FormFieldFormFieldFreeTextResourcesBlock';
  sys: ResourceSys;
};

export type FormFieldFormFieldFreeTextResourcesHyperlink = ResourceLink & {
  __typename?: 'FormFieldFormFieldFreeTextResourcesHyperlink';
  sys: ResourceSys;
};

export type FormFieldFormFieldFreeTextResourcesInline = ResourceLink & {
  __typename?: 'FormFieldFormFieldFreeTextResourcesInline';
  sys: ResourceSys;
};

export type FormFieldLinkingCollections = {
  __typename?: 'FormFieldLinkingCollections';
  dynamicFormCollection?: Maybe<DynamicFormCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type FormFieldLinkingCollectionsDynamicFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<FormFieldLinkingCollectionsDynamicFormCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FormFieldLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum FormFieldLinkingCollectionsDynamicFormCollectionOrder {
  formEntryTitle_ASC = 'formEntryTitle_ASC',
  formEntryTitle_DESC = 'formEntryTitle_DESC',
  formIdentifierTag_ASC = 'formIdentifierTag_ASC',
  formIdentifierTag_DESC = 'formIdentifierTag_DESC',
  submitButtonLabel_ASC = 'submitButtonLabel_ASC',
  submitButtonLabel_DESC = 'submitButtonLabel_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export enum FormFieldOrder {
  contentfulTitle_ASC = 'contentfulTitle_ASC',
  contentfulTitle_DESC = 'contentfulTitle_DESC',
  fieldErrorMessage_ASC = 'fieldErrorMessage_ASC',
  fieldErrorMessage_DESC = 'fieldErrorMessage_DESC',
  fieldFullWidth_ASC = 'fieldFullWidth_ASC',
  fieldFullWidth_DESC = 'fieldFullWidth_DESC',
  fieldLabel_ASC = 'fieldLabel_ASC',
  fieldLabel_DESC = 'fieldLabel_DESC',
  fieldName_ASC = 'fieldName_ASC',
  fieldName_DESC = 'fieldName_DESC',
  fieldRegex_ASC = 'fieldRegex_ASC',
  fieldRegex_DESC = 'fieldRegex_DESC',
  fieldRequired_ASC = 'fieldRequired_ASC',
  fieldRequired_DESC = 'fieldRequired_DESC',
  fieldType_ASC = 'fieldType_ASC',
  fieldType_DESC = 'fieldType_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/garagePage) */
export type GaragePage = Entry &
  _Node & {
    __typename?: 'GaragePage';
    _id: Scalars['ID']['output'];
    announcement?: Maybe<Announcement>;
    contentfulMetadata: ContentfulMetadata;
    headerBodyText?: Maybe<GaragePageHeaderBodyText>;
    headerImage?: Maybe<Asset>;
    headerTitle?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<GaragePageLinkingCollections>;
    priceText?: Maybe<Scalars['String']['output']>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    whatIsIncluded?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/garagePage) */
export type GaragePageAnnouncementArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AnnouncementFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/garagePage) */
export type GaragePageHeaderBodyTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/garagePage) */
export type GaragePageHeaderImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/garagePage) */
export type GaragePageHeaderTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/garagePage) */
export type GaragePageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/garagePage) */
export type GaragePagePriceTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/garagePage) */
export type GaragePageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/garagePage) */
export type GaragePageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/garagePage) */
export type GaragePageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/garagePage) */
export type GaragePageWhatIsIncludedArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type GaragePageCollection = {
  __typename?: 'GaragePageCollection';
  items: Array<Maybe<GaragePage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type GaragePageFilter = {
  AND?: InputMaybe<Array<InputMaybe<GaragePageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GaragePageFilter>>>;
  announcement?: InputMaybe<CfAnnouncementNestedFilter>;
  announcement_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  headerBodyText_contains?: InputMaybe<Scalars['String']['input']>;
  headerBodyText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerBodyText_not_contains?: InputMaybe<Scalars['String']['input']>;
  headerImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerTitle?: InputMaybe<Scalars['String']['input']>;
  headerTitle_contains?: InputMaybe<Scalars['String']['input']>;
  headerTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headerTitle_not?: InputMaybe<Scalars['String']['input']>;
  headerTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  headerTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  priceText?: InputMaybe<Scalars['String']['input']>;
  priceText_contains?: InputMaybe<Scalars['String']['input']>;
  priceText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  priceText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  priceText_not?: InputMaybe<Scalars['String']['input']>;
  priceText_not_contains?: InputMaybe<Scalars['String']['input']>;
  priceText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  whatIsIncluded_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  whatIsIncluded_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  whatIsIncluded_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  whatIsIncluded_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GaragePageHeaderBodyText = {
  __typename?: 'GaragePageHeaderBodyText';
  json: Scalars['JSON']['output'];
  links: GaragePageHeaderBodyTextLinks;
};

export type GaragePageHeaderBodyTextAssets = {
  __typename?: 'GaragePageHeaderBodyTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type GaragePageHeaderBodyTextEntries = {
  __typename?: 'GaragePageHeaderBodyTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type GaragePageHeaderBodyTextLinks = {
  __typename?: 'GaragePageHeaderBodyTextLinks';
  assets: GaragePageHeaderBodyTextAssets;
  entries: GaragePageHeaderBodyTextEntries;
  resources: GaragePageHeaderBodyTextResources;
};

export type GaragePageHeaderBodyTextResources = {
  __typename?: 'GaragePageHeaderBodyTextResources';
  block: Array<GaragePageHeaderBodyTextResourcesBlock>;
  hyperlink: Array<GaragePageHeaderBodyTextResourcesHyperlink>;
  inline: Array<GaragePageHeaderBodyTextResourcesInline>;
};

export type GaragePageHeaderBodyTextResourcesBlock = ResourceLink & {
  __typename?: 'GaragePageHeaderBodyTextResourcesBlock';
  sys: ResourceSys;
};

export type GaragePageHeaderBodyTextResourcesHyperlink = ResourceLink & {
  __typename?: 'GaragePageHeaderBodyTextResourcesHyperlink';
  sys: ResourceSys;
};

export type GaragePageHeaderBodyTextResourcesInline = ResourceLink & {
  __typename?: 'GaragePageHeaderBodyTextResourcesInline';
  sys: ResourceSys;
};

export type GaragePageLinkingCollections = {
  __typename?: 'GaragePageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type GaragePageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum GaragePageOrder {
  headerTitle_ASC = 'headerTitle_ASC',
  headerTitle_DESC = 'headerTitle_DESC',
  priceText_ASC = 'priceText_ASC',
  priceText_DESC = 'priceText_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum ImageFormat {
  AVIF = 'AVIF',
  /** JPG image format. */
  JPG = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JPG_PROGRESSIVE = 'JPG_PROGRESSIVE',
  /** PNG image format */
  PNG = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  PNG8 = 'PNG8',
  /** WebP image format. */
  WEBP = 'WEBP'
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  BOTTOM = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  CENTER = 'CENTER',
  /** Focus the resizing on the largest face. */
  FACE = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  FACES = 'FACES',
  /** Focus the resizing on the left. */
  LEFT = 'LEFT',
  /** Focus the resizing on the right. */
  RIGHT = 'RIGHT',
  /** Focus the resizing on the top. */
  TOP = 'TOP',
  /** Focus the resizing on the top left. */
  TOP_LEFT = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TOP_RIGHT = 'TOP_RIGHT'
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  CROP = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  FILL = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  FIT = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  PAD = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  SCALE = 'SCALE',
  /** Creates a thumbnail from the image. */
  THUMB = 'THUMB'
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']['input']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']['input']>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']['input']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']['input']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/inspectionStep) */
export type InspectionStep = Entry &
  _Node & {
    __typename?: 'InspectionStep';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<InspectionStepLinkingCollections>;
    stepDescription?: Maybe<InspectionStepStepDescription>;
    stepHeader?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/inspectionStep) */
export type InspectionStepLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/inspectionStep) */
export type InspectionStepStepDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/inspectionStep) */
export type InspectionStepStepHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type InspectionStepCollection = {
  __typename?: 'InspectionStepCollection';
  items: Array<Maybe<InspectionStep>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type InspectionStepFilter = {
  AND?: InputMaybe<Array<InputMaybe<InspectionStepFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<InspectionStepFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  stepDescription_contains?: InputMaybe<Scalars['String']['input']>;
  stepDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stepDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  stepHeader?: InputMaybe<Scalars['String']['input']>;
  stepHeader_contains?: InputMaybe<Scalars['String']['input']>;
  stepHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stepHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stepHeader_not?: InputMaybe<Scalars['String']['input']>;
  stepHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  stepHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type InspectionStepLinkingCollections = {
  __typename?: 'InspectionStepLinkingCollections';
  damagesPolicyPageCollection?: Maybe<DamagesPolicyPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type InspectionStepLinkingCollectionsDamagesPolicyPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<InspectionStepLinkingCollectionsDamagesPolicyPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type InspectionStepLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum InspectionStepLinkingCollectionsDamagesPolicyPageCollectionOrder {
  assistedInspectionHeader_ASC = 'assistedInspectionHeader_ASC',
  assistedInspectionHeader_DESC = 'assistedInspectionHeader_DESC',
  collapseContainerHeader_ASC = 'collapseContainerHeader_ASC',
  collapseContainerHeader_DESC = 'collapseContainerHeader_DESC',
  faqHeader_ASC = 'faqHeader_ASC',
  faqHeader_DESC = 'faqHeader_DESC',
  howItWorksHeader_ASC = 'howItWorksHeader_ASC',
  howItWorksHeader_DESC = 'howItWorksHeader_DESC',
  insuranceCoverHeader_ASC = 'insuranceCoverHeader_ASC',
  insuranceCoverHeader_DESC = 'insuranceCoverHeader_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  withAssistedInspection_ASC = 'withAssistedInspection_ASC',
  withAssistedInspection_DESC = 'withAssistedInspection_DESC'
}

export enum InspectionStepOrder {
  stepHeader_ASC = 'stepHeader_ASC',
  stepHeader_DESC = 'stepHeader_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type InspectionStepStepDescription = {
  __typename?: 'InspectionStepStepDescription';
  json: Scalars['JSON']['output'];
  links: InspectionStepStepDescriptionLinks;
};

export type InspectionStepStepDescriptionAssets = {
  __typename?: 'InspectionStepStepDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type InspectionStepStepDescriptionEntries = {
  __typename?: 'InspectionStepStepDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type InspectionStepStepDescriptionLinks = {
  __typename?: 'InspectionStepStepDescriptionLinks';
  assets: InspectionStepStepDescriptionAssets;
  entries: InspectionStepStepDescriptionEntries;
  resources: InspectionStepStepDescriptionResources;
};

export type InspectionStepStepDescriptionResources = {
  __typename?: 'InspectionStepStepDescriptionResources';
  block: Array<InspectionStepStepDescriptionResourcesBlock>;
  hyperlink: Array<InspectionStepStepDescriptionResourcesHyperlink>;
  inline: Array<InspectionStepStepDescriptionResourcesInline>;
};

export type InspectionStepStepDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'InspectionStepStepDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type InspectionStepStepDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'InspectionStepStepDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type InspectionStepStepDescriptionResourcesInline = ResourceLink & {
  __typename?: 'InspectionStepStepDescriptionResourcesInline';
  sys: ResourceSys;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPage = Entry &
  _Node & {
    __typename?: 'LandingPage';
    _id: Scalars['ID']['output'];
    ageRequirementsAccentBox?: Maybe<Scalars['String']['output']>;
    announcement?: Maybe<Announcement>;
    appDescription?: Maybe<Scalars['String']['output']>;
    appFeatures?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    appImagesCollection?: Maybe<AssetCollection>;
    bookingFormSearchText?: Maybe<Scalars['String']['output']>;
    bookingFormTitle?: Maybe<Scalars['String']['output']>;
    budgetCategoryMaxPrice?: Maybe<Scalars['Int']['output']>;
    cardPricesFromText?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    dateInputLabel?: Maybe<Scalars['String']['output']>;
    dateInputPlaceholder?: Maybe<Scalars['String']['output']>;
    downloadAppTitle?: Maybe<Scalars['String']['output']>;
    electricPropellantValue?: Maybe<Scalars['String']['output']>;
    faqSectionItemsCollection?: Maybe<LandingPageFaqSectionItemsCollection>;
    faqSectionTitle?: Maybe<Scalars['String']['output']>;
    featuresFlipCardsCollection?: Maybe<LandingPageFeaturesFlipCardsCollection>;
    garageLabel?: Maybe<Scalars['String']['output']>;
    heroBackgroundImage?: Maybe<Asset>;
    heroBackgroundImageMobile?: Maybe<Asset>;
    heroTitleText?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<LandingPageLinkingCollections>;
    locationDropdownLabel?: Maybe<Scalars['String']['output']>;
    locationDropdownPlaceholder?: Maybe<Scalars['String']['output']>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageAgeRequirementsAccentBoxArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageAnnouncementArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AnnouncementFilter>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageAppDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageAppFeaturesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageAppImagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageBookingFormSearchTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageBookingFormTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageBudgetCategoryMaxPriceArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageCardPricesFromTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageDateInputLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageDateInputPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageDownloadAppTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageElectricPropellantValueArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageFaqSectionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LandingPageFaqSectionItemsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqEntryFilter>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageFaqSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageFeaturesFlipCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LandingPageFeaturesFlipCardsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeatureFilter>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageGarageLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageHeroBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageHeroBackgroundImageMobileArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageHeroTitleTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageLocationDropdownLabelArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageLocationDropdownPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Landing Page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPage) */
export type LandingPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2B = Entry &
  _Node & {
    __typename?: 'LandingPageB2B';
    _id: Scalars['ID']['output'];
    announcement?: Maybe<Announcement>;
    blogSectionTitle?: Maybe<Scalars['String']['output']>;
    contactSectionSubtitle?: Maybe<Scalars['String']['output']>;
    contactSectionTitle?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    faqSectionItemsCollection?: Maybe<LandingPageB2BFaqSectionItemsCollection>;
    faqSectionTitle?: Maybe<Scalars['String']['output']>;
    featuresFlipCardsCollection?: Maybe<LandingPageB2BFeaturesFlipCardsCollection>;
    featuresSubTitle?: Maybe<Scalars['String']['output']>;
    featuresTitle?: Maybe<Scalars['String']['output']>;
    heroImage?: Maybe<Asset>;
    hightlightedBlogEntriesCollection?: Maybe<LandingPageB2BHightlightedBlogEntriesCollection>;
    linkedFrom?: Maybe<LandingPageB2BLinkingCollections>;
    partnerLogosCollection?: Maybe<AssetCollection>;
    seoMetadata?: Maybe<SeoMetadata>;
    showCaseSectionCollection?: Maybe<LandingPageB2BShowCaseSectionCollection>;
    showCaseSubTitle?: Maybe<Scalars['String']['output']>;
    showCaseTitle?: Maybe<Scalars['String']['output']>;
    slug?: Maybe<Scalars['String']['output']>;
    subText?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    trustedPartnersTitle?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BAnnouncementArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AnnouncementFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BBlogSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BContactSectionSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BContactSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BFaqSectionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LandingPageB2BFaqSectionItemsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqEntryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BFaqSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BFeaturesFlipCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LandingPageB2BFeaturesFlipCardsCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeatureFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BFeaturesSubTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BFeaturesTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BHeroImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BHightlightedBlogEntriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LandingPageB2BHightlightedBlogEntriesCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlogEntryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BPartnerLogosCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BShowCaseSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LandingPageB2BShowCaseSectionCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<B2BShowcaseModelFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BShowCaseSubTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BShowCaseTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BSubTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/landingPageB2b) */
export type LandingPageB2BTrustedPartnersTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type LandingPageB2BCollection = {
  __typename?: 'LandingPageB2BCollection';
  items: Array<Maybe<LandingPageB2B>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LandingPageB2BFaqSectionItemsCollection = {
  __typename?: 'LandingPageB2BFaqSectionItemsCollection';
  items: Array<Maybe<FaqEntry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LandingPageB2BFaqSectionItemsCollectionOrder {
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type LandingPageB2BFeaturesFlipCardsCollection = {
  __typename?: 'LandingPageB2BFeaturesFlipCardsCollection';
  items: Array<Maybe<Feature>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LandingPageB2BFeaturesFlipCardsCollectionOrder {
  flipTitle_ASC = 'flipTitle_ASC',
  flipTitle_DESC = 'flipTitle_DESC',
  flipVisibleLinkTitle_ASC = 'flipVisibleLinkTitle_ASC',
  flipVisibleLinkTitle_DESC = 'flipVisibleLinkTitle_DESC',
  flipVisibleLink_ASC = 'flipVisibleLink_ASC',
  flipVisibleLink_DESC = 'flipVisibleLink_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  visibleLinkTitle_ASC = 'visibleLinkTitle_ASC',
  visibleLinkTitle_DESC = 'visibleLinkTitle_DESC',
  visibleLink_ASC = 'visibleLink_ASC',
  visibleLink_DESC = 'visibleLink_DESC'
}

export type LandingPageB2BFilter = {
  AND?: InputMaybe<Array<InputMaybe<LandingPageB2BFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LandingPageB2BFilter>>>;
  announcement?: InputMaybe<CfAnnouncementNestedFilter>;
  announcement_exists?: InputMaybe<Scalars['Boolean']['input']>;
  blogSectionTitle?: InputMaybe<Scalars['String']['input']>;
  blogSectionTitle_contains?: InputMaybe<Scalars['String']['input']>;
  blogSectionTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  blogSectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  blogSectionTitle_not?: InputMaybe<Scalars['String']['input']>;
  blogSectionTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  blogSectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contactSectionSubtitle?: InputMaybe<Scalars['String']['input']>;
  contactSectionSubtitle_contains?: InputMaybe<Scalars['String']['input']>;
  contactSectionSubtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactSectionSubtitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contactSectionSubtitle_not?: InputMaybe<Scalars['String']['input']>;
  contactSectionSubtitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactSectionSubtitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contactSectionTitle?: InputMaybe<Scalars['String']['input']>;
  contactSectionTitle_contains?: InputMaybe<Scalars['String']['input']>;
  contactSectionTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactSectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contactSectionTitle_not?: InputMaybe<Scalars['String']['input']>;
  contactSectionTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactSectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  faqSectionItems?: InputMaybe<CfFaqEntryNestedFilter>;
  faqSectionItemsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  faqSectionTitle?: InputMaybe<Scalars['String']['input']>;
  faqSectionTitle_contains?: InputMaybe<Scalars['String']['input']>;
  faqSectionTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  faqSectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  faqSectionTitle_not?: InputMaybe<Scalars['String']['input']>;
  faqSectionTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  faqSectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featuresFlipCards?: InputMaybe<CfFeatureNestedFilter>;
  featuresFlipCardsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  featuresSubTitle?: InputMaybe<Scalars['String']['input']>;
  featuresSubTitle_contains?: InputMaybe<Scalars['String']['input']>;
  featuresSubTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  featuresSubTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featuresSubTitle_not?: InputMaybe<Scalars['String']['input']>;
  featuresSubTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  featuresSubTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featuresTitle?: InputMaybe<Scalars['String']['input']>;
  featuresTitle_contains?: InputMaybe<Scalars['String']['input']>;
  featuresTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  featuresTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  featuresTitle_not?: InputMaybe<Scalars['String']['input']>;
  featuresTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  featuresTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  heroImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hightlightedBlogEntries?: InputMaybe<CfBlogEntryNestedFilter>;
  hightlightedBlogEntriesCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  partnerLogosCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showCaseSection?: InputMaybe<CfB2BShowcaseModelNestedFilter>;
  showCaseSectionCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showCaseSubTitle?: InputMaybe<Scalars['String']['input']>;
  showCaseSubTitle_contains?: InputMaybe<Scalars['String']['input']>;
  showCaseSubTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showCaseSubTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showCaseSubTitle_not?: InputMaybe<Scalars['String']['input']>;
  showCaseSubTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  showCaseSubTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  showCaseTitle?: InputMaybe<Scalars['String']['input']>;
  showCaseTitle_contains?: InputMaybe<Scalars['String']['input']>;
  showCaseTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  showCaseTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  showCaseTitle_not?: InputMaybe<Scalars['String']['input']>;
  showCaseTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  showCaseTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subText?: InputMaybe<Scalars['String']['input']>;
  subText_contains?: InputMaybe<Scalars['String']['input']>;
  subText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subText_not?: InputMaybe<Scalars['String']['input']>;
  subText_not_contains?: InputMaybe<Scalars['String']['input']>;
  subText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  trustedPartnersTitle?: InputMaybe<Scalars['String']['input']>;
  trustedPartnersTitle_contains?: InputMaybe<Scalars['String']['input']>;
  trustedPartnersTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  trustedPartnersTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  trustedPartnersTitle_not?: InputMaybe<Scalars['String']['input']>;
  trustedPartnersTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  trustedPartnersTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type LandingPageB2BHightlightedBlogEntriesCollection = {
  __typename?: 'LandingPageB2BHightlightedBlogEntriesCollection';
  items: Array<Maybe<BlogEntry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LandingPageB2BHightlightedBlogEntriesCollectionOrder {
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  previewText_ASC = 'previewText_ASC',
  previewText_DESC = 'previewText_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  titlePrefix_ASC = 'titlePrefix_ASC',
  titlePrefix_DESC = 'titlePrefix_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type LandingPageB2BLinkingCollections = {
  __typename?: 'LandingPageB2BLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type LandingPageB2BLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LandingPageB2BOrder {
  blogSectionTitle_ASC = 'blogSectionTitle_ASC',
  blogSectionTitle_DESC = 'blogSectionTitle_DESC',
  contactSectionSubtitle_ASC = 'contactSectionSubtitle_ASC',
  contactSectionSubtitle_DESC = 'contactSectionSubtitle_DESC',
  contactSectionTitle_ASC = 'contactSectionTitle_ASC',
  contactSectionTitle_DESC = 'contactSectionTitle_DESC',
  faqSectionTitle_ASC = 'faqSectionTitle_ASC',
  faqSectionTitle_DESC = 'faqSectionTitle_DESC',
  featuresSubTitle_ASC = 'featuresSubTitle_ASC',
  featuresSubTitle_DESC = 'featuresSubTitle_DESC',
  featuresTitle_ASC = 'featuresTitle_ASC',
  featuresTitle_DESC = 'featuresTitle_DESC',
  showCaseSubTitle_ASC = 'showCaseSubTitle_ASC',
  showCaseSubTitle_DESC = 'showCaseSubTitle_DESC',
  showCaseTitle_ASC = 'showCaseTitle_ASC',
  showCaseTitle_DESC = 'showCaseTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  trustedPartnersTitle_ASC = 'trustedPartnersTitle_ASC',
  trustedPartnersTitle_DESC = 'trustedPartnersTitle_DESC'
}

export type LandingPageB2BShowCaseSectionCollection = {
  __typename?: 'LandingPageB2BShowCaseSectionCollection';
  items: Array<Maybe<B2BShowcaseModel>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LandingPageB2BShowCaseSectionCollectionOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  urlSlug_ASC = 'urlSlug_ASC',
  urlSlug_DESC = 'urlSlug_DESC',
  urlText_ASC = 'urlText_ASC',
  urlText_DESC = 'urlText_DESC'
}

export type LandingPageCollection = {
  __typename?: 'LandingPageCollection';
  items: Array<Maybe<LandingPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LandingPageFaqSectionItemsCollection = {
  __typename?: 'LandingPageFaqSectionItemsCollection';
  items: Array<Maybe<FaqEntry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LandingPageFaqSectionItemsCollectionOrder {
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type LandingPageFeaturesFlipCardsCollection = {
  __typename?: 'LandingPageFeaturesFlipCardsCollection';
  items: Array<Maybe<Feature>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LandingPageFeaturesFlipCardsCollectionOrder {
  flipTitle_ASC = 'flipTitle_ASC',
  flipTitle_DESC = 'flipTitle_DESC',
  flipVisibleLinkTitle_ASC = 'flipVisibleLinkTitle_ASC',
  flipVisibleLinkTitle_DESC = 'flipVisibleLinkTitle_DESC',
  flipVisibleLink_ASC = 'flipVisibleLink_ASC',
  flipVisibleLink_DESC = 'flipVisibleLink_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  visibleLinkTitle_ASC = 'visibleLinkTitle_ASC',
  visibleLinkTitle_DESC = 'visibleLinkTitle_DESC',
  visibleLink_ASC = 'visibleLink_ASC',
  visibleLink_DESC = 'visibleLink_DESC'
}

export type LandingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<LandingPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LandingPageFilter>>>;
  ageRequirementsAccentBox?: InputMaybe<Scalars['String']['input']>;
  ageRequirementsAccentBox_contains?: InputMaybe<Scalars['String']['input']>;
  ageRequirementsAccentBox_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ageRequirementsAccentBox_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  ageRequirementsAccentBox_not?: InputMaybe<Scalars['String']['input']>;
  ageRequirementsAccentBox_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  ageRequirementsAccentBox_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  announcement?: InputMaybe<CfAnnouncementNestedFilter>;
  announcement_exists?: InputMaybe<Scalars['Boolean']['input']>;
  appDescription?: InputMaybe<Scalars['String']['input']>;
  appDescription_contains?: InputMaybe<Scalars['String']['input']>;
  appDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  appDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  appDescription_not?: InputMaybe<Scalars['String']['input']>;
  appDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  appDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  appFeatures_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  appFeatures_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  appFeatures_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  appFeatures_exists?: InputMaybe<Scalars['Boolean']['input']>;
  appImagesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bookingFormSearchText?: InputMaybe<Scalars['String']['input']>;
  bookingFormSearchText_contains?: InputMaybe<Scalars['String']['input']>;
  bookingFormSearchText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bookingFormSearchText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  bookingFormSearchText_not?: InputMaybe<Scalars['String']['input']>;
  bookingFormSearchText_not_contains?: InputMaybe<Scalars['String']['input']>;
  bookingFormSearchText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  bookingFormTitle?: InputMaybe<Scalars['String']['input']>;
  bookingFormTitle_contains?: InputMaybe<Scalars['String']['input']>;
  bookingFormTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bookingFormTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  bookingFormTitle_not?: InputMaybe<Scalars['String']['input']>;
  bookingFormTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  bookingFormTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  budgetCategoryMaxPrice?: InputMaybe<Scalars['Int']['input']>;
  budgetCategoryMaxPrice_exists?: InputMaybe<Scalars['Boolean']['input']>;
  budgetCategoryMaxPrice_gt?: InputMaybe<Scalars['Int']['input']>;
  budgetCategoryMaxPrice_gte?: InputMaybe<Scalars['Int']['input']>;
  budgetCategoryMaxPrice_in?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  budgetCategoryMaxPrice_lt?: InputMaybe<Scalars['Int']['input']>;
  budgetCategoryMaxPrice_lte?: InputMaybe<Scalars['Int']['input']>;
  budgetCategoryMaxPrice_not?: InputMaybe<Scalars['Int']['input']>;
  budgetCategoryMaxPrice_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  cardPricesFromText?: InputMaybe<Scalars['String']['input']>;
  cardPricesFromText_contains?: InputMaybe<Scalars['String']['input']>;
  cardPricesFromText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cardPricesFromText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  cardPricesFromText_not?: InputMaybe<Scalars['String']['input']>;
  cardPricesFromText_not_contains?: InputMaybe<Scalars['String']['input']>;
  cardPricesFromText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  dateInputLabel?: InputMaybe<Scalars['String']['input']>;
  dateInputLabel_contains?: InputMaybe<Scalars['String']['input']>;
  dateInputLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  dateInputLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateInputLabel_not?: InputMaybe<Scalars['String']['input']>;
  dateInputLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  dateInputLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  dateInputPlaceholder?: InputMaybe<Scalars['String']['input']>;
  dateInputPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  dateInputPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  dateInputPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  dateInputPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  dateInputPlaceholder_not_contains?: InputMaybe<Scalars['String']['input']>;
  dateInputPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  downloadAppTitle?: InputMaybe<Scalars['String']['input']>;
  downloadAppTitle_contains?: InputMaybe<Scalars['String']['input']>;
  downloadAppTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  downloadAppTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  downloadAppTitle_not?: InputMaybe<Scalars['String']['input']>;
  downloadAppTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  downloadAppTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  electricPropellantValue?: InputMaybe<Scalars['String']['input']>;
  electricPropellantValue_contains?: InputMaybe<Scalars['String']['input']>;
  electricPropellantValue_exists?: InputMaybe<Scalars['Boolean']['input']>;
  electricPropellantValue_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  electricPropellantValue_not?: InputMaybe<Scalars['String']['input']>;
  electricPropellantValue_not_contains?: InputMaybe<Scalars['String']['input']>;
  electricPropellantValue_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  faqSectionItems?: InputMaybe<CfFaqEntryNestedFilter>;
  faqSectionItemsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  faqSectionTitle?: InputMaybe<Scalars['String']['input']>;
  faqSectionTitle_contains?: InputMaybe<Scalars['String']['input']>;
  faqSectionTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  faqSectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  faqSectionTitle_not?: InputMaybe<Scalars['String']['input']>;
  faqSectionTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  faqSectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  featuresFlipCards?: InputMaybe<CfFeatureNestedFilter>;
  featuresFlipCardsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  garageLabel?: InputMaybe<Scalars['String']['input']>;
  garageLabel_contains?: InputMaybe<Scalars['String']['input']>;
  garageLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  garageLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  garageLabel_not?: InputMaybe<Scalars['String']['input']>;
  garageLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  garageLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  heroBackgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heroBackgroundImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heroTitleText?: InputMaybe<Scalars['String']['input']>;
  heroTitleText_contains?: InputMaybe<Scalars['String']['input']>;
  heroTitleText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  heroTitleText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  heroTitleText_not?: InputMaybe<Scalars['String']['input']>;
  heroTitleText_not_contains?: InputMaybe<Scalars['String']['input']>;
  heroTitleText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locationDropdownLabel?: InputMaybe<Scalars['String']['input']>;
  locationDropdownLabel_contains?: InputMaybe<Scalars['String']['input']>;
  locationDropdownLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locationDropdownLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locationDropdownLabel_not?: InputMaybe<Scalars['String']['input']>;
  locationDropdownLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  locationDropdownLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locationDropdownPlaceholder?: InputMaybe<Scalars['String']['input']>;
  locationDropdownPlaceholder_contains?: InputMaybe<Scalars['String']['input']>;
  locationDropdownPlaceholder_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locationDropdownPlaceholder_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  locationDropdownPlaceholder_not?: InputMaybe<Scalars['String']['input']>;
  locationDropdownPlaceholder_not_contains?: InputMaybe<
    Scalars['String']['input']
  >;
  locationDropdownPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LandingPageLinkingCollections = {
  __typename?: 'LandingPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type LandingPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LandingPageOrder {
  ageRequirementsAccentBox_ASC = 'ageRequirementsAccentBox_ASC',
  ageRequirementsAccentBox_DESC = 'ageRequirementsAccentBox_DESC',
  bookingFormSearchText_ASC = 'bookingFormSearchText_ASC',
  bookingFormSearchText_DESC = 'bookingFormSearchText_DESC',
  bookingFormTitle_ASC = 'bookingFormTitle_ASC',
  bookingFormTitle_DESC = 'bookingFormTitle_DESC',
  budgetCategoryMaxPrice_ASC = 'budgetCategoryMaxPrice_ASC',
  budgetCategoryMaxPrice_DESC = 'budgetCategoryMaxPrice_DESC',
  cardPricesFromText_ASC = 'cardPricesFromText_ASC',
  cardPricesFromText_DESC = 'cardPricesFromText_DESC',
  dateInputLabel_ASC = 'dateInputLabel_ASC',
  dateInputLabel_DESC = 'dateInputLabel_DESC',
  dateInputPlaceholder_ASC = 'dateInputPlaceholder_ASC',
  dateInputPlaceholder_DESC = 'dateInputPlaceholder_DESC',
  downloadAppTitle_ASC = 'downloadAppTitle_ASC',
  downloadAppTitle_DESC = 'downloadAppTitle_DESC',
  electricPropellantValue_ASC = 'electricPropellantValue_ASC',
  electricPropellantValue_DESC = 'electricPropellantValue_DESC',
  faqSectionTitle_ASC = 'faqSectionTitle_ASC',
  faqSectionTitle_DESC = 'faqSectionTitle_DESC',
  garageLabel_ASC = 'garageLabel_ASC',
  garageLabel_DESC = 'garageLabel_DESC',
  heroTitleText_ASC = 'heroTitleText_ASC',
  heroTitleText_DESC = 'heroTitleText_DESC',
  locationDropdownLabel_ASC = 'locationDropdownLabel_ASC',
  locationDropdownLabel_DESC = 'locationDropdownLabel_DESC',
  locationDropdownPlaceholder_ASC = 'locationDropdownPlaceholder_ASC',
  locationDropdownPlaceholder_DESC = 'locationDropdownPlaceholder_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalEntry) */
export type LegalEntry = Entry &
  _Node & {
    __typename?: 'LegalEntry';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<LegalEntryLinkingCollections>;
    path?: Maybe<Scalars['String']['output']>;
    seoMetadata?: Maybe<SeoMetadata>;
    site?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    tag?: Maybe<Scalars['String']['output']>;
    text?: Maybe<LegalEntryText>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalEntry) */
export type LegalEntryDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalEntry) */
export type LegalEntryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalEntry) */
export type LegalEntryPathArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalEntry) */
export type LegalEntrySeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalEntry) */
export type LegalEntrySiteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalEntry) */
export type LegalEntrySlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalEntry) */
export type LegalEntryTagArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalEntry) */
export type LegalEntryTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalEntry) */
export type LegalEntryTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type LegalEntryCollection = {
  __typename?: 'LegalEntryCollection';
  items: Array<Maybe<LegalEntry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LegalEntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<LegalEntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LegalEntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  path?: InputMaybe<Scalars['String']['input']>;
  path_contains?: InputMaybe<Scalars['String']['input']>;
  path_exists?: InputMaybe<Scalars['Boolean']['input']>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  path_not?: InputMaybe<Scalars['String']['input']>;
  path_not_contains?: InputMaybe<Scalars['String']['input']>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  site_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  site_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  site_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  site_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  tag?: InputMaybe<Scalars['String']['input']>;
  tag_contains?: InputMaybe<Scalars['String']['input']>;
  tag_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tag_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tag_not?: InputMaybe<Scalars['String']['input']>;
  tag_not_contains?: InputMaybe<Scalars['String']['input']>;
  tag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LegalEntryLinkingCollections = {
  __typename?: 'LegalEntryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  legalPageCollection?: Maybe<LegalPageCollection>;
};

export type LegalEntryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LegalEntryLinkingCollectionsLegalPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LegalEntryLinkingCollectionsLegalPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LegalEntryLinkingCollectionsLegalPageCollectionOrder {
  headerTitle_ASC = 'headerTitle_ASC',
  headerTitle_DESC = 'headerTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum LegalEntryOrder {
  description_ASC = 'description_ASC',
  description_DESC = 'description_DESC',
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  tag_ASC = 'tag_ASC',
  tag_DESC = 'tag_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type LegalEntryText = {
  __typename?: 'LegalEntryText';
  json: Scalars['JSON']['output'];
  links: LegalEntryTextLinks;
};

export type LegalEntryTextAssets = {
  __typename?: 'LegalEntryTextAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type LegalEntryTextEntries = {
  __typename?: 'LegalEntryTextEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type LegalEntryTextLinks = {
  __typename?: 'LegalEntryTextLinks';
  assets: LegalEntryTextAssets;
  entries: LegalEntryTextEntries;
  resources: LegalEntryTextResources;
};

export type LegalEntryTextResources = {
  __typename?: 'LegalEntryTextResources';
  block: Array<LegalEntryTextResourcesBlock>;
  hyperlink: Array<LegalEntryTextResourcesHyperlink>;
  inline: Array<LegalEntryTextResourcesInline>;
};

export type LegalEntryTextResourcesBlock = ResourceLink & {
  __typename?: 'LegalEntryTextResourcesBlock';
  sys: ResourceSys;
};

export type LegalEntryTextResourcesHyperlink = ResourceLink & {
  __typename?: 'LegalEntryTextResourcesHyperlink';
  sys: ResourceSys;
};

export type LegalEntryTextResourcesInline = ResourceLink & {
  __typename?: 'LegalEntryTextResourcesInline';
  sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalPage) */
export type LegalPage = Entry &
  _Node & {
    __typename?: 'LegalPage';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    headerTitle?: Maybe<Scalars['String']['output']>;
    legalEntriesCollection?: Maybe<LegalPageLegalEntriesCollection>;
    linkedFrom?: Maybe<LegalPageLinkingCollections>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalPage) */
export type LegalPageHeaderTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalPage) */
export type LegalPageLegalEntriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LegalPageLegalEntriesCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LegalEntryFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalPage) */
export type LegalPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalPage) */
export type LegalPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalPage) */
export type LegalPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/legalPage) */
export type LegalPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type LegalPageCollection = {
  __typename?: 'LegalPageCollection';
  items: Array<Maybe<LegalPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LegalPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<LegalPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LegalPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  headerTitle?: InputMaybe<Scalars['String']['input']>;
  headerTitle_contains?: InputMaybe<Scalars['String']['input']>;
  headerTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headerTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headerTitle_not?: InputMaybe<Scalars['String']['input']>;
  headerTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  headerTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  legalEntries?: InputMaybe<CfLegalEntryNestedFilter>;
  legalEntriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LegalPageLegalEntriesCollection = {
  __typename?: 'LegalPageLegalEntriesCollection';
  items: Array<Maybe<LegalEntry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LegalPageLegalEntriesCollectionOrder {
  description_ASC = 'description_ASC',
  description_DESC = 'description_DESC',
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  tag_ASC = 'tag_ASC',
  tag_DESC = 'tag_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type LegalPageLinkingCollections = {
  __typename?: 'LegalPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type LegalPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LegalPageOrder {
  headerTitle_ASC = 'headerTitle_ASC',
  headerTitle_DESC = 'headerTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/link) */
export type Link = Entry &
  _Node & {
    __typename?: 'Link';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<LinkLinkingCollections>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    url?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/link) */
export type LinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/link) */
export type LinkTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/link) */
export type LinkUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type LinkCollection = {
  __typename?: 'LinkCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<LinkFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LinkFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/linkGroup) */
export type LinkGroup = Entry &
  _Node & {
    __typename?: 'LinkGroup';
    _id: Scalars['ID']['output'];
    b2BLinksCollection?: Maybe<LinkGroupB2bLinksCollection>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<LinkGroupLinkingCollections>;
    linksCollection?: Maybe<LinkGroupLinksCollection>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/linkGroup) */
export type LinkGroupB2BLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LinkGroupB2bLinksCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/linkGroup) */
export type LinkGroupLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/linkGroup) */
export type LinkGroupLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LinkGroupLinksCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/linkGroup) */
export type LinkGroupTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type LinkGroupB2bLinksCollection = {
  __typename?: 'LinkGroupB2bLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LinkGroupB2bLinksCollectionOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  url_ASC = 'url_ASC',
  url_DESC = 'url_DESC'
}

export type LinkGroupCollection = {
  __typename?: 'LinkGroupCollection';
  items: Array<Maybe<LinkGroup>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type LinkGroupFilter = {
  AND?: InputMaybe<Array<InputMaybe<LinkGroupFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LinkGroupFilter>>>;
  b2bLinks?: InputMaybe<CfLinkNestedFilter>;
  b2bLinksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  links?: InputMaybe<CfLinkNestedFilter>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LinkGroupLinkingCollections = {
  __typename?: 'LinkGroupLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  footerCollection?: Maybe<FooterCollection>;
};

export type LinkGroupLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkGroupLinkingCollectionsFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkGroupLinkingCollectionsFooterCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LinkGroupLinkingCollectionsFooterCollectionOrder {
  facebookLink_ASC = 'facebookLink_ASC',
  facebookLink_DESC = 'facebookLink_DESC',
  facebookRating_ASC = 'facebookRating_ASC',
  facebookRating_DESC = 'facebookRating_DESC',
  fixedDribeFooterContent_ASC = 'fixedDribeFooterContent_ASC',
  fixedDribeFooterContent_DESC = 'fixedDribeFooterContent_DESC',
  googleLink_ASC = 'googleLink_ASC',
  googleLink_DESC = 'googleLink_DESC',
  googleRating_ASC = 'googleRating_ASC',
  googleRating_DESC = 'googleRating_DESC',
  instagramLink_ASC = 'instagramLink_ASC',
  instagramLink_DESC = 'instagramLink_DESC',
  linkedInLink_ASC = 'linkedInLink_ASC',
  linkedInLink_DESC = 'linkedInLink_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  tikTokLink_ASC = 'tikTokLink_ASC',
  tikTokLink_DESC = 'tikTokLink_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  trustpilotLink_ASC = 'trustpilotLink_ASC',
  trustpilotLink_DESC = 'trustpilotLink_DESC',
  trustpilotRating_ASC = 'trustpilotRating_ASC',
  trustpilotRating_DESC = 'trustpilotRating_DESC',
  youTubeLink_ASC = 'youTubeLink_ASC',
  youTubeLink_DESC = 'youTubeLink_DESC'
}

export type LinkGroupLinksCollection = {
  __typename?: 'LinkGroupLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum LinkGroupLinksCollectionOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  url_ASC = 'url_ASC',
  url_DESC = 'url_DESC'
}

export enum LinkGroupOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type LinkLinkingCollections = {
  __typename?: 'LinkLinkingCollections';
  contactCardCollection?: Maybe<ContactCardCollection>;
  contactPageCollection?: Maybe<ContactPageCollection>;
  damagesCardCollection?: Maybe<DamagesCardCollection>;
  damagesPolicyPageCollection?: Maybe<DamagesPolicyPageCollection>;
  damagesPolicyPageContentBoxCollection?: Maybe<DamagesPolicyPageContentBoxCollection>;
  entryCollection?: Maybe<EntryCollection>;
  linkGroupCollection?: Maybe<LinkGroupCollection>;
  menuCollection?: Maybe<MenuCollection>;
};

export type LinkLinkingCollectionsContactCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsContactCardCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsContactPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsContactPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsDamagesCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsDamagesCardCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsDamagesPolicyPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsDamagesPolicyPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsDamagesPolicyPageContentBoxCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<LinkLinkingCollectionsDamagesPolicyPageContentBoxCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsLinkGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsLinkGroupCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkLinkingCollectionsMenuCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsMenuCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LinkLinkingCollectionsContactCardCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export enum LinkLinkingCollectionsContactPageCollectionOrder {
  assistanceAndDamagesHeader_ASC = 'assistanceAndDamagesHeader_ASC',
  assistanceAndDamagesHeader_DESC = 'assistanceAndDamagesHeader_DESC',
  contactHeader_ASC = 'contactHeader_ASC',
  contactHeader_DESC = 'contactHeader_DESC',
  findGaragesHeader_ASC = 'findGaragesHeader_ASC',
  findGaragesHeader_DESC = 'findGaragesHeader_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum LinkLinkingCollectionsDamagesCardCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export enum LinkLinkingCollectionsDamagesPolicyPageCollectionOrder {
  assistedInspectionHeader_ASC = 'assistedInspectionHeader_ASC',
  assistedInspectionHeader_DESC = 'assistedInspectionHeader_DESC',
  collapseContainerHeader_ASC = 'collapseContainerHeader_ASC',
  collapseContainerHeader_DESC = 'collapseContainerHeader_DESC',
  faqHeader_ASC = 'faqHeader_ASC',
  faqHeader_DESC = 'faqHeader_DESC',
  howItWorksHeader_ASC = 'howItWorksHeader_ASC',
  howItWorksHeader_DESC = 'howItWorksHeader_DESC',
  insuranceCoverHeader_ASC = 'insuranceCoverHeader_ASC',
  insuranceCoverHeader_DESC = 'insuranceCoverHeader_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  withAssistedInspection_ASC = 'withAssistedInspection_ASC',
  withAssistedInspection_DESC = 'withAssistedInspection_DESC'
}

export enum LinkLinkingCollectionsDamagesPolicyPageContentBoxCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export enum LinkLinkingCollectionsLinkGroupCollectionOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum LinkLinkingCollectionsMenuCollectionOrder {
  defaultPage_ASC = 'defaultPage_ASC',
  defaultPage_DESC = 'defaultPage_DESC',
  languageCode_ASC = 'languageCode_ASC',
  languageCode_DESC = 'languageCode_DESC',
  redirectTimeout_ASC = 'redirectTimeout_ASC',
  redirectTimeout_DESC = 'redirectTimeout_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum LinkOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  url_ASC = 'url_ASC',
  url_DESC = 'url_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/menu) */
export type Menu = Entry &
  _Node & {
    __typename?: 'Menu';
    _id: Scalars['ID']['output'];
    b2BLinksCollection?: Maybe<MenuB2bLinksCollection>;
    contentfulMetadata: ContentfulMetadata;
    defaultPage?: Maybe<Scalars['String']['output']>;
    languageCode?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<MenuLinkingCollections>;
    linksCollection?: Maybe<MenuLinksCollection>;
    redirectTimeout?: Maybe<Scalars['Int']['output']>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/menu) */
export type MenuB2BLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MenuB2bLinksCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/menu) */
export type MenuDefaultPageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/menu) */
export type MenuLanguageCodeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/menu) */
export type MenuLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/menu) */
export type MenuLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MenuLinksCollectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/menu) */
export type MenuRedirectTimeoutArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/menu) */
export type MenuSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/menu) */
export type MenuTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type MenuB2bLinksCollection = {
  __typename?: 'MenuB2bLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum MenuB2bLinksCollectionOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  url_ASC = 'url_ASC',
  url_DESC = 'url_DESC'
}

export type MenuCollection = {
  __typename?: 'MenuCollection';
  items: Array<Maybe<Menu>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MenuFilter = {
  AND?: InputMaybe<Array<InputMaybe<MenuFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MenuFilter>>>;
  b2bLinks?: InputMaybe<CfLinkNestedFilter>;
  b2bLinksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  defaultPage?: InputMaybe<Scalars['String']['input']>;
  defaultPage_contains?: InputMaybe<Scalars['String']['input']>;
  defaultPage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  defaultPage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  defaultPage_not?: InputMaybe<Scalars['String']['input']>;
  defaultPage_not_contains?: InputMaybe<Scalars['String']['input']>;
  defaultPage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  languageCode_contains?: InputMaybe<Scalars['String']['input']>;
  languageCode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  languageCode_not?: InputMaybe<Scalars['String']['input']>;
  languageCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  languageCode_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  links?: InputMaybe<CfLinkNestedFilter>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectTimeout?: InputMaybe<Scalars['Int']['input']>;
  redirectTimeout_exists?: InputMaybe<Scalars['Boolean']['input']>;
  redirectTimeout_gt?: InputMaybe<Scalars['Int']['input']>;
  redirectTimeout_gte?: InputMaybe<Scalars['Int']['input']>;
  redirectTimeout_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  redirectTimeout_lt?: InputMaybe<Scalars['Int']['input']>;
  redirectTimeout_lte?: InputMaybe<Scalars['Int']['input']>;
  redirectTimeout_not?: InputMaybe<Scalars['Int']['input']>;
  redirectTimeout_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MenuLinkingCollections = {
  __typename?: 'MenuLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type MenuLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type MenuLinksCollection = {
  __typename?: 'MenuLinksCollection';
  items: Array<Maybe<Link>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum MenuLinksCollectionOrder {
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  url_ASC = 'url_ASC',
  url_DESC = 'url_DESC'
}

export enum MenuOrder {
  defaultPage_ASC = 'defaultPage_ASC',
  defaultPage_DESC = 'defaultPage_DESC',
  languageCode_ASC = 'languageCode_ASC',
  languageCode_DESC = 'languageCode_DESC',
  redirectTimeout_ASC = 'redirectTimeout_ASC',
  redirectTimeout_DESC = 'redirectTimeout_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** The content for the newsletter part of blog pages [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/newsletterContent) */
export type NewsletterContent = Entry &
  _Node & {
    __typename?: 'NewsletterContent';
    _id: Scalars['ID']['output'];
    content?: Maybe<NewsletterContentContent>;
    contentfulMetadata: ContentfulMetadata;
    header?: Maybe<Scalars['String']['output']>;
    linkedFrom?: Maybe<NewsletterContentLinkingCollections>;
    newsletterSubscriptionForm?: Maybe<DynamicForm>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** The content for the newsletter part of blog pages [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/newsletterContent) */
export type NewsletterContentContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The content for the newsletter part of blog pages [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/newsletterContent) */
export type NewsletterContentHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** The content for the newsletter part of blog pages [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/newsletterContent) */
export type NewsletterContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The content for the newsletter part of blog pages [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/newsletterContent) */
export type NewsletterContentNewsletterSubscriptionFormArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DynamicFormFilter>;
};

/** The content for the newsletter part of blog pages [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/newsletterContent) */
export type NewsletterContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type NewsletterContentCollection = {
  __typename?: 'NewsletterContentCollection';
  items: Array<Maybe<NewsletterContent>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type NewsletterContentContent = {
  __typename?: 'NewsletterContentContent';
  json: Scalars['JSON']['output'];
  links: NewsletterContentContentLinks;
};

export type NewsletterContentContentAssets = {
  __typename?: 'NewsletterContentContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type NewsletterContentContentEntries = {
  __typename?: 'NewsletterContentContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type NewsletterContentContentLinks = {
  __typename?: 'NewsletterContentContentLinks';
  assets: NewsletterContentContentAssets;
  entries: NewsletterContentContentEntries;
  resources: NewsletterContentContentResources;
};

export type NewsletterContentContentResources = {
  __typename?: 'NewsletterContentContentResources';
  block: Array<NewsletterContentContentResourcesBlock>;
  hyperlink: Array<NewsletterContentContentResourcesHyperlink>;
  inline: Array<NewsletterContentContentResourcesInline>;
};

export type NewsletterContentContentResourcesBlock = ResourceLink & {
  __typename?: 'NewsletterContentContentResourcesBlock';
  sys: ResourceSys;
};

export type NewsletterContentContentResourcesHyperlink = ResourceLink & {
  __typename?: 'NewsletterContentContentResourcesHyperlink';
  sys: ResourceSys;
};

export type NewsletterContentContentResourcesInline = ResourceLink & {
  __typename?: 'NewsletterContentContentResourcesInline';
  sys: ResourceSys;
};

export type NewsletterContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<NewsletterContentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NewsletterContentFilter>>>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  newsletterSubscriptionForm?: InputMaybe<CfDynamicFormNestedFilter>;
  newsletterSubscriptionForm_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NewsletterContentLinkingCollections = {
  __typename?: 'NewsletterContentLinkingCollections';
  blogEntryCollection?: Maybe<BlogEntryCollection>;
  blogPageCollection?: Maybe<BlogPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type NewsletterContentLinkingCollectionsBlogEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<NewsletterContentLinkingCollectionsBlogEntryCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type NewsletterContentLinkingCollectionsBlogPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<NewsletterContentLinkingCollectionsBlogPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type NewsletterContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum NewsletterContentLinkingCollectionsBlogEntryCollectionOrder {
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  previewText_ASC = 'previewText_ASC',
  previewText_DESC = 'previewText_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  titlePrefix_ASC = 'titlePrefix_ASC',
  titlePrefix_DESC = 'titlePrefix_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum NewsletterContentLinkingCollectionsBlogPageCollectionOrder {
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum NewsletterContentOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/outOfStockPage) */
export type OutOfStockPage = Entry &
  _Node & {
    __typename?: 'OutOfStockPage';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<OutOfStockPageLinkingCollections>;
    mobileUiImageExample?: Maybe<Asset>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    subTitle?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    watchlistDescription?: Maybe<Scalars['String']['output']>;
    watchlistTitle?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/outOfStockPage) */
export type OutOfStockPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/outOfStockPage) */
export type OutOfStockPageMobileUiImageExampleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/outOfStockPage) */
export type OutOfStockPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/outOfStockPage) */
export type OutOfStockPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/outOfStockPage) */
export type OutOfStockPageSubTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/outOfStockPage) */
export type OutOfStockPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/outOfStockPage) */
export type OutOfStockPageWatchlistDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/outOfStockPage) */
export type OutOfStockPageWatchlistTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type OutOfStockPageCollection = {
  __typename?: 'OutOfStockPageCollection';
  items: Array<Maybe<OutOfStockPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type OutOfStockPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<OutOfStockPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<OutOfStockPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  mobileUiImageExample_exists?: InputMaybe<Scalars['Boolean']['input']>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  subTitle_contains?: InputMaybe<Scalars['String']['input']>;
  subTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subTitle_not?: InputMaybe<Scalars['String']['input']>;
  subTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  subTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  watchlistDescription?: InputMaybe<Scalars['String']['input']>;
  watchlistDescription_contains?: InputMaybe<Scalars['String']['input']>;
  watchlistDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  watchlistDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  watchlistDescription_not?: InputMaybe<Scalars['String']['input']>;
  watchlistDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  watchlistDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  watchlistTitle?: InputMaybe<Scalars['String']['input']>;
  watchlistTitle_contains?: InputMaybe<Scalars['String']['input']>;
  watchlistTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  watchlistTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  watchlistTitle_not?: InputMaybe<Scalars['String']['input']>;
  watchlistTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  watchlistTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type OutOfStockPageLinkingCollections = {
  __typename?: 'OutOfStockPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type OutOfStockPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum OutOfStockPageOrder {
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  subTitle_ASC = 'subTitle_ASC',
  subTitle_DESC = 'subTitle_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  watchlistDescription_ASC = 'watchlistDescription_ASC',
  watchlistDescription_DESC = 'watchlistDescription_DESC',
  watchlistTitle_ASC = 'watchlistTitle_ASC',
  watchlistTitle_DESC = 'watchlistTitle_DESC'
}

/** Entry for partner logos [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/partners) */
export type Partners = Entry &
  _Node & {
    __typename?: 'Partners';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PartnersLinkingCollections>;
    partnerLogo?: Maybe<Asset>;
    partnerName?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** Entry for partner logos [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/partners) */
export type PartnersLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Entry for partner logos [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/partners) */
export type PartnersPartnerLogoArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Entry for partner logos [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/partners) */
export type PartnersPartnerNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PartnersCollection = {
  __typename?: 'PartnersCollection';
  items: Array<Maybe<Partners>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PartnersFilter = {
  AND?: InputMaybe<Array<InputMaybe<PartnersFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PartnersFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  partnerLogo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  partnerName_contains?: InputMaybe<Scalars['String']['input']>;
  partnerName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  partnerName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  partnerName_not?: InputMaybe<Scalars['String']['input']>;
  partnerName_not_contains?: InputMaybe<Scalars['String']['input']>;
  partnerName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type PartnersLinkingCollections = {
  __typename?: 'PartnersLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type PartnersLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PartnersOrder {
  partnerName_ASC = 'partnerName_ASC',
  partnerName_DESC = 'partnerName_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** Locations of the franchisee, for the coming soon page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/previewLocations) */
export type PreviewLocations = Entry &
  _Node & {
    __typename?: 'PreviewLocations';
    _id: Scalars['ID']['output'];
    address1?: Maybe<Scalars['String']['output']>;
    address2?: Maybe<Scalars['String']['output']>;
    city?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PreviewLocationsLinkingCollections>;
    sys: Sys;
  };

/** Locations of the franchisee, for the coming soon page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/previewLocations) */
export type PreviewLocationsAddress1Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Locations of the franchisee, for the coming soon page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/previewLocations) */
export type PreviewLocationsAddress2Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Locations of the franchisee, for the coming soon page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/previewLocations) */
export type PreviewLocationsCityArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Locations of the franchisee, for the coming soon page [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/previewLocations) */
export type PreviewLocationsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PreviewLocationsCollection = {
  __typename?: 'PreviewLocationsCollection';
  items: Array<Maybe<PreviewLocations>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PreviewLocationsFilter = {
  AND?: InputMaybe<Array<InputMaybe<PreviewLocationsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PreviewLocationsFilter>>>;
  address1?: InputMaybe<Scalars['String']['input']>;
  address1_contains?: InputMaybe<Scalars['String']['input']>;
  address1_exists?: InputMaybe<Scalars['Boolean']['input']>;
  address1_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  address1_not?: InputMaybe<Scalars['String']['input']>;
  address1_not_contains?: InputMaybe<Scalars['String']['input']>;
  address1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address2_contains?: InputMaybe<Scalars['String']['input']>;
  address2_exists?: InputMaybe<Scalars['Boolean']['input']>;
  address2_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  address2_not?: InputMaybe<Scalars['String']['input']>;
  address2_not_contains?: InputMaybe<Scalars['String']['input']>;
  address2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_contains?: InputMaybe<Scalars['String']['input']>;
  city_exists?: InputMaybe<Scalars['Boolean']['input']>;
  city_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  city_not?: InputMaybe<Scalars['String']['input']>;
  city_not_contains?: InputMaybe<Scalars['String']['input']>;
  city_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type PreviewLocationsLinkingCollections = {
  __typename?: 'PreviewLocationsLinkingCollections';
  comingSoonPageCollection?: Maybe<ComingSoonPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};

export type PreviewLocationsLinkingCollectionsComingSoonPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PreviewLocationsLinkingCollectionsComingSoonPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PreviewLocationsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PreviewLocationsLinkingCollectionsComingSoonPageCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  wd_ASC = 'wd_ASC',
  wd_DESC = 'wd_DESC'
}

export enum PreviewLocationsOrder {
  address1_ASC = 'address1_ASC',
  address1_DESC = 'address1_DESC',
  address2_ASC = 'address2_ASC',
  address2_DESC = 'address2_DESC',
  city_ASC = 'city_ASC',
  city_DESC = 'city_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPage = Entry &
  _Node & {
    __typename?: 'PricingPage';
    _id: Scalars['ID']['output'];
    accentBox?: Maybe<PricingPageAccentBox>;
    checklistHeader?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    exampleDescription?: Maybe<PricingPageExampleDescription>;
    exampleHeader?: Maybe<Scalars['String']['output']>;
    exampleRideCard?: Maybe<PricingRideCard>;
    header?: Maybe<Scalars['String']['output']>;
    leftChecklist?: Maybe<PricingPageLeftChecklist>;
    linkedFrom?: Maybe<PricingPageLinkingCollections>;
    priceTable?: Maybe<PricingPagePriceTable>;
    rightChecklist?: Maybe<PricingPageRightChecklist>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPageAccentBoxArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPageChecklistHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPageExampleDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPageExampleHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPageExampleRideCardArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PricingRideCardFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPageLeftChecklistArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPagePriceTableArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPageRightChecklistArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingPage) */
export type PricingPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PricingPageAccentBox = {
  __typename?: 'PricingPageAccentBox';
  json: Scalars['JSON']['output'];
  links: PricingPageAccentBoxLinks;
};

export type PricingPageAccentBoxAssets = {
  __typename?: 'PricingPageAccentBoxAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PricingPageAccentBoxEntries = {
  __typename?: 'PricingPageAccentBoxEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PricingPageAccentBoxLinks = {
  __typename?: 'PricingPageAccentBoxLinks';
  assets: PricingPageAccentBoxAssets;
  entries: PricingPageAccentBoxEntries;
  resources: PricingPageAccentBoxResources;
};

export type PricingPageAccentBoxResources = {
  __typename?: 'PricingPageAccentBoxResources';
  block: Array<PricingPageAccentBoxResourcesBlock>;
  hyperlink: Array<PricingPageAccentBoxResourcesHyperlink>;
  inline: Array<PricingPageAccentBoxResourcesInline>;
};

export type PricingPageAccentBoxResourcesBlock = ResourceLink & {
  __typename?: 'PricingPageAccentBoxResourcesBlock';
  sys: ResourceSys;
};

export type PricingPageAccentBoxResourcesHyperlink = ResourceLink & {
  __typename?: 'PricingPageAccentBoxResourcesHyperlink';
  sys: ResourceSys;
};

export type PricingPageAccentBoxResourcesInline = ResourceLink & {
  __typename?: 'PricingPageAccentBoxResourcesInline';
  sys: ResourceSys;
};

export type PricingPageCollection = {
  __typename?: 'PricingPageCollection';
  items: Array<Maybe<PricingPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PricingPageExampleDescription = {
  __typename?: 'PricingPageExampleDescription';
  json: Scalars['JSON']['output'];
  links: PricingPageExampleDescriptionLinks;
};

export type PricingPageExampleDescriptionAssets = {
  __typename?: 'PricingPageExampleDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PricingPageExampleDescriptionEntries = {
  __typename?: 'PricingPageExampleDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PricingPageExampleDescriptionLinks = {
  __typename?: 'PricingPageExampleDescriptionLinks';
  assets: PricingPageExampleDescriptionAssets;
  entries: PricingPageExampleDescriptionEntries;
  resources: PricingPageExampleDescriptionResources;
};

export type PricingPageExampleDescriptionResources = {
  __typename?: 'PricingPageExampleDescriptionResources';
  block: Array<PricingPageExampleDescriptionResourcesBlock>;
  hyperlink: Array<PricingPageExampleDescriptionResourcesHyperlink>;
  inline: Array<PricingPageExampleDescriptionResourcesInline>;
};

export type PricingPageExampleDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'PricingPageExampleDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type PricingPageExampleDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'PricingPageExampleDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type PricingPageExampleDescriptionResourcesInline = ResourceLink & {
  __typename?: 'PricingPageExampleDescriptionResourcesInline';
  sys: ResourceSys;
};

export type PricingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<PricingPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PricingPageFilter>>>;
  accentBox_contains?: InputMaybe<Scalars['String']['input']>;
  accentBox_exists?: InputMaybe<Scalars['Boolean']['input']>;
  accentBox_not_contains?: InputMaybe<Scalars['String']['input']>;
  checklistHeader?: InputMaybe<Scalars['String']['input']>;
  checklistHeader_contains?: InputMaybe<Scalars['String']['input']>;
  checklistHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  checklistHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  checklistHeader_not?: InputMaybe<Scalars['String']['input']>;
  checklistHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  checklistHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  exampleDescription_contains?: InputMaybe<Scalars['String']['input']>;
  exampleDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  exampleDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  exampleHeader?: InputMaybe<Scalars['String']['input']>;
  exampleHeader_contains?: InputMaybe<Scalars['String']['input']>;
  exampleHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  exampleHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exampleHeader_not?: InputMaybe<Scalars['String']['input']>;
  exampleHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  exampleHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  exampleRideCard?: InputMaybe<CfPricingRideCardNestedFilter>;
  exampleRideCard_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leftChecklist_contains?: InputMaybe<Scalars['String']['input']>;
  leftChecklist_exists?: InputMaybe<Scalars['Boolean']['input']>;
  leftChecklist_not_contains?: InputMaybe<Scalars['String']['input']>;
  priceTable_contains?: InputMaybe<Scalars['String']['input']>;
  priceTable_exists?: InputMaybe<Scalars['Boolean']['input']>;
  priceTable_not_contains?: InputMaybe<Scalars['String']['input']>;
  rightChecklist_contains?: InputMaybe<Scalars['String']['input']>;
  rightChecklist_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rightChecklist_not_contains?: InputMaybe<Scalars['String']['input']>;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PricingPageLeftChecklist = {
  __typename?: 'PricingPageLeftChecklist';
  json: Scalars['JSON']['output'];
  links: PricingPageLeftChecklistLinks;
};

export type PricingPageLeftChecklistAssets = {
  __typename?: 'PricingPageLeftChecklistAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PricingPageLeftChecklistEntries = {
  __typename?: 'PricingPageLeftChecklistEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PricingPageLeftChecklistLinks = {
  __typename?: 'PricingPageLeftChecklistLinks';
  assets: PricingPageLeftChecklistAssets;
  entries: PricingPageLeftChecklistEntries;
  resources: PricingPageLeftChecklistResources;
};

export type PricingPageLeftChecklistResources = {
  __typename?: 'PricingPageLeftChecklistResources';
  block: Array<PricingPageLeftChecklistResourcesBlock>;
  hyperlink: Array<PricingPageLeftChecklistResourcesHyperlink>;
  inline: Array<PricingPageLeftChecklistResourcesInline>;
};

export type PricingPageLeftChecklistResourcesBlock = ResourceLink & {
  __typename?: 'PricingPageLeftChecklistResourcesBlock';
  sys: ResourceSys;
};

export type PricingPageLeftChecklistResourcesHyperlink = ResourceLink & {
  __typename?: 'PricingPageLeftChecklistResourcesHyperlink';
  sys: ResourceSys;
};

export type PricingPageLeftChecklistResourcesInline = ResourceLink & {
  __typename?: 'PricingPageLeftChecklistResourcesInline';
  sys: ResourceSys;
};

export type PricingPageLinkingCollections = {
  __typename?: 'PricingPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type PricingPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PricingPageOrder {
  checklistHeader_ASC = 'checklistHeader_ASC',
  checklistHeader_DESC = 'checklistHeader_DESC',
  exampleHeader_ASC = 'exampleHeader_ASC',
  exampleHeader_DESC = 'exampleHeader_DESC',
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type PricingPagePriceTable = {
  __typename?: 'PricingPagePriceTable';
  json: Scalars['JSON']['output'];
  links: PricingPagePriceTableLinks;
};

export type PricingPagePriceTableAssets = {
  __typename?: 'PricingPagePriceTableAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PricingPagePriceTableEntries = {
  __typename?: 'PricingPagePriceTableEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PricingPagePriceTableLinks = {
  __typename?: 'PricingPagePriceTableLinks';
  assets: PricingPagePriceTableAssets;
  entries: PricingPagePriceTableEntries;
  resources: PricingPagePriceTableResources;
};

export type PricingPagePriceTableResources = {
  __typename?: 'PricingPagePriceTableResources';
  block: Array<PricingPagePriceTableResourcesBlock>;
  hyperlink: Array<PricingPagePriceTableResourcesHyperlink>;
  inline: Array<PricingPagePriceTableResourcesInline>;
};

export type PricingPagePriceTableResourcesBlock = ResourceLink & {
  __typename?: 'PricingPagePriceTableResourcesBlock';
  sys: ResourceSys;
};

export type PricingPagePriceTableResourcesHyperlink = ResourceLink & {
  __typename?: 'PricingPagePriceTableResourcesHyperlink';
  sys: ResourceSys;
};

export type PricingPagePriceTableResourcesInline = ResourceLink & {
  __typename?: 'PricingPagePriceTableResourcesInline';
  sys: ResourceSys;
};

export type PricingPageRightChecklist = {
  __typename?: 'PricingPageRightChecklist';
  json: Scalars['JSON']['output'];
  links: PricingPageRightChecklistLinks;
};

export type PricingPageRightChecklistAssets = {
  __typename?: 'PricingPageRightChecklistAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PricingPageRightChecklistEntries = {
  __typename?: 'PricingPageRightChecklistEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PricingPageRightChecklistLinks = {
  __typename?: 'PricingPageRightChecklistLinks';
  assets: PricingPageRightChecklistAssets;
  entries: PricingPageRightChecklistEntries;
  resources: PricingPageRightChecklistResources;
};

export type PricingPageRightChecklistResources = {
  __typename?: 'PricingPageRightChecklistResources';
  block: Array<PricingPageRightChecklistResourcesBlock>;
  hyperlink: Array<PricingPageRightChecklistResourcesHyperlink>;
  inline: Array<PricingPageRightChecklistResourcesInline>;
};

export type PricingPageRightChecklistResourcesBlock = ResourceLink & {
  __typename?: 'PricingPageRightChecklistResourcesBlock';
  sys: ResourceSys;
};

export type PricingPageRightChecklistResourcesHyperlink = ResourceLink & {
  __typename?: 'PricingPageRightChecklistResourcesHyperlink';
  sys: ResourceSys;
};

export type PricingPageRightChecklistResourcesInline = ResourceLink & {
  __typename?: 'PricingPageRightChecklistResourcesInline';
  sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingRideCard) */
export type PricingRideCard = Entry &
  _Node & {
    __typename?: 'PricingRideCard';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<PricingRideCardLinkingCollections>;
    rideImage?: Maybe<Asset>;
    rideNameHeader?: Maybe<Scalars['String']['output']>;
    rideVariantSubHeader?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingRideCard) */
export type PricingRideCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingRideCard) */
export type PricingRideCardRideImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingRideCard) */
export type PricingRideCardRideNameHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/pricingRideCard) */
export type PricingRideCardRideVariantSubHeaderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type PricingRideCardCollection = {
  __typename?: 'PricingRideCardCollection';
  items: Array<Maybe<PricingRideCard>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PricingRideCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<PricingRideCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PricingRideCardFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  rideImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rideNameHeader?: InputMaybe<Scalars['String']['input']>;
  rideNameHeader_contains?: InputMaybe<Scalars['String']['input']>;
  rideNameHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rideNameHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rideNameHeader_not?: InputMaybe<Scalars['String']['input']>;
  rideNameHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  rideNameHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  rideVariantSubHeader?: InputMaybe<Scalars['String']['input']>;
  rideVariantSubHeader_contains?: InputMaybe<Scalars['String']['input']>;
  rideVariantSubHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rideVariantSubHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  rideVariantSubHeader_not?: InputMaybe<Scalars['String']['input']>;
  rideVariantSubHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  rideVariantSubHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type PricingRideCardLinkingCollections = {
  __typename?: 'PricingRideCardLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  pricingPageCollection?: Maybe<PricingPageCollection>;
};

export type PricingRideCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PricingRideCardLinkingCollectionsPricingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<PricingRideCardLinkingCollectionsPricingPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum PricingRideCardLinkingCollectionsPricingPageCollectionOrder {
  checklistHeader_ASC = 'checklistHeader_ASC',
  checklistHeader_DESC = 'checklistHeader_DESC',
  exampleHeader_ASC = 'exampleHeader_ASC',
  exampleHeader_DESC = 'exampleHeader_DESC',
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum PricingRideCardOrder {
  rideNameHeader_ASC = 'rideNameHeader_ASC',
  rideNameHeader_DESC = 'rideNameHeader_DESC',
  rideVariantSubHeader_ASC = 'rideVariantSubHeader_ASC',
  rideVariantSubHeader_DESC = 'rideVariantSubHeader_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPage = Entry &
  _Node & {
    __typename?: 'ProductPage';
    _id: Scalars['ID']['output'];
    addOnsInfoText?: Maybe<Scalars['String']['output']>;
    announcement?: Maybe<Announcement>;
    contentfulMetadata: ContentfulMetadata;
    economyTableBottomText?: Maybe<Scalars['String']['output']>;
    economyTableEntries?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    hideMsrpTaxContainer?: Maybe<Scalars['Boolean']['output']>;
    includeLinkToPricingPage?: Maybe<Scalars['Boolean']['output']>;
    linkedFrom?: Maybe<ProductPageLinkingCollections>;
    priceIncludesText?: Maybe<Scalars['String']['output']>;
    priceTooltipContentCollection?: Maybe<ProductPagePriceTooltipContentCollection>;
    seoMetadata?: Maybe<SeoMetadata>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPageAddOnsInfoTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPageAnnouncementArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AnnouncementFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPageEconomyTableBottomTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPageEconomyTableEntriesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPageHideMsrpTaxContainerArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPageIncludeLinkToPricingPageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPagePriceIncludesTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPagePriceTooltipContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<ProductPagePriceTooltipContentCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TableRowFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPageSeoMetadataArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/productPage) */
export type ProductPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type ProductPageCollection = {
  __typename?: 'ProductPageCollection';
  items: Array<Maybe<ProductPage>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ProductPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ProductPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ProductPageFilter>>>;
  addOnsInfoText?: InputMaybe<Scalars['String']['input']>;
  addOnsInfoText_contains?: InputMaybe<Scalars['String']['input']>;
  addOnsInfoText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  addOnsInfoText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  addOnsInfoText_not?: InputMaybe<Scalars['String']['input']>;
  addOnsInfoText_not_contains?: InputMaybe<Scalars['String']['input']>;
  addOnsInfoText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  announcement?: InputMaybe<CfAnnouncementNestedFilter>;
  announcement_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  economyTableBottomText?: InputMaybe<Scalars['String']['input']>;
  economyTableBottomText_contains?: InputMaybe<Scalars['String']['input']>;
  economyTableBottomText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  economyTableBottomText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  economyTableBottomText_not?: InputMaybe<Scalars['String']['input']>;
  economyTableBottomText_not_contains?: InputMaybe<Scalars['String']['input']>;
  economyTableBottomText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  economyTableEntries_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  economyTableEntries_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  economyTableEntries_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  economyTableEntries_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hideMsrpTaxContainer?: InputMaybe<Scalars['Boolean']['input']>;
  hideMsrpTaxContainer_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hideMsrpTaxContainer_not?: InputMaybe<Scalars['Boolean']['input']>;
  includeLinkToPricingPage?: InputMaybe<Scalars['Boolean']['input']>;
  includeLinkToPricingPage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  includeLinkToPricingPage_not?: InputMaybe<Scalars['Boolean']['input']>;
  priceIncludesText?: InputMaybe<Scalars['String']['input']>;
  priceIncludesText_contains?: InputMaybe<Scalars['String']['input']>;
  priceIncludesText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  priceIncludesText_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  priceIncludesText_not?: InputMaybe<Scalars['String']['input']>;
  priceIncludesText_not_contains?: InputMaybe<Scalars['String']['input']>;
  priceIncludesText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  priceTooltipContent?: InputMaybe<CfTableRowNestedFilter>;
  priceTooltipContentCollection_exists?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  seoMetadata?: InputMaybe<CfSeoMetadataNestedFilter>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProductPageLinkingCollections = {
  __typename?: 'ProductPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type ProductPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum ProductPageOrder {
  addOnsInfoText_ASC = 'addOnsInfoText_ASC',
  addOnsInfoText_DESC = 'addOnsInfoText_DESC',
  economyTableBottomText_ASC = 'economyTableBottomText_ASC',
  economyTableBottomText_DESC = 'economyTableBottomText_DESC',
  hideMsrpTaxContainer_ASC = 'hideMsrpTaxContainer_ASC',
  hideMsrpTaxContainer_DESC = 'hideMsrpTaxContainer_DESC',
  includeLinkToPricingPage_ASC = 'includeLinkToPricingPage_ASC',
  includeLinkToPricingPage_DESC = 'includeLinkToPricingPage_DESC',
  priceIncludesText_ASC = 'priceIncludesText_ASC',
  priceIncludesText_DESC = 'priceIncludesText_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export type ProductPagePriceTooltipContentCollection = {
  __typename?: 'ProductPagePriceTooltipContentCollection';
  items: Array<Maybe<TableRow>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ProductPagePriceTooltipContentCollectionOrder {
  lokaliseKey_ASC = 'lokaliseKey_ASC',
  lokaliseKey_DESC = 'lokaliseKey_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type Query = {
  __typename?: 'Query';
  _node?: Maybe<_Node>;
  announcement?: Maybe<Announcement>;
  announcementCollection?: Maybe<AnnouncementCollection>;
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  availableGaragesCard?: Maybe<AvailableGaragesCard>;
  availableGaragesCardCollection?: Maybe<AvailableGaragesCardCollection>;
  b2BShowcaseModel?: Maybe<B2BShowcaseModel>;
  b2BShowcaseModelCollection?: Maybe<B2BShowcaseModelCollection>;
  blogEntry?: Maybe<BlogEntry>;
  blogEntryCollection?: Maybe<BlogEntryCollection>;
  blogPage?: Maybe<BlogPage>;
  blogPageCollection?: Maybe<BlogPageCollection>;
  blogTopics?: Maybe<BlogTopics>;
  blogTopicsCollection?: Maybe<BlogTopicsCollection>;
  brandContact?: Maybe<BrandContact>;
  brandContactCollection?: Maybe<BrandContactCollection>;
  campaignEntry?: Maybe<CampaignEntry>;
  campaignEntryCollection?: Maybe<CampaignEntryCollection>;
  collapsableContainer?: Maybe<CollapsableContainer>;
  collapsableContainerCollection?: Maybe<CollapsableContainerCollection>;
  comingSoonPage?: Maybe<ComingSoonPage>;
  comingSoonPageCollection?: Maybe<ComingSoonPageCollection>;
  contactCard?: Maybe<ContactCard>;
  contactCardCollection?: Maybe<ContactCardCollection>;
  contactInfoLink?: Maybe<ContactInfoLink>;
  contactInfoLinkCollection?: Maybe<ContactInfoLinkCollection>;
  contactPage?: Maybe<ContactPage>;
  contactPageB2B?: Maybe<ContactPageB2B>;
  contactPageB2BCollection?: Maybe<ContactPageB2BCollection>;
  contactPageCollection?: Maybe<ContactPageCollection>;
  damagesCard?: Maybe<DamagesCard>;
  damagesCardCollection?: Maybe<DamagesCardCollection>;
  damagesContactPage?: Maybe<DamagesContactPage>;
  damagesContactPageCollection?: Maybe<DamagesContactPageCollection>;
  damagesPage?: Maybe<DamagesPage>;
  damagesPageCollection?: Maybe<DamagesPageCollection>;
  damagesPolicyPage?: Maybe<DamagesPolicyPage>;
  damagesPolicyPageCollection?: Maybe<DamagesPolicyPageCollection>;
  damagesPolicyPageContentBox?: Maybe<DamagesPolicyPageContentBox>;
  damagesPolicyPageContentBoxCollection?: Maybe<DamagesPolicyPageContentBoxCollection>;
  demoPage?: Maybe<DemoPage>;
  demoPageCollection?: Maybe<DemoPageCollection>;
  demoSlide?: Maybe<DemoSlide>;
  demoSlideCollection?: Maybe<DemoSlideCollection>;
  downloadPage?: Maybe<DownloadPage>;
  downloadPageCollection?: Maybe<DownloadPageCollection>;
  dribeIcon?: Maybe<DribeIcon>;
  dribeIconCollection?: Maybe<DribeIconCollection>;
  dynamicForm?: Maybe<DynamicForm>;
  dynamicFormCollection?: Maybe<DynamicFormCollection>;
  entryCollection?: Maybe<EntryCollection>;
  faqCategory?: Maybe<FaqCategory>;
  faqCategoryCollection?: Maybe<FaqCategoryCollection>;
  faqEntry?: Maybe<FaqEntry>;
  faqEntryCollection?: Maybe<FaqEntryCollection>;
  faqPage?: Maybe<FaqPage>;
  faqPageCollection?: Maybe<FaqPageCollection>;
  feature?: Maybe<Feature>;
  featureCollection?: Maybe<FeatureCollection>;
  footer?: Maybe<Footer>;
  footerCollection?: Maybe<FooterCollection>;
  formField?: Maybe<FormField>;
  formFieldCollection?: Maybe<FormFieldCollection>;
  garagePage?: Maybe<GaragePage>;
  garagePageCollection?: Maybe<GaragePageCollection>;
  inspectionStep?: Maybe<InspectionStep>;
  inspectionStepCollection?: Maybe<InspectionStepCollection>;
  landingPage?: Maybe<LandingPage>;
  landingPageB2B?: Maybe<LandingPageB2B>;
  landingPageB2BCollection?: Maybe<LandingPageB2BCollection>;
  landingPageCollection?: Maybe<LandingPageCollection>;
  legalEntry?: Maybe<LegalEntry>;
  legalEntryCollection?: Maybe<LegalEntryCollection>;
  legalPage?: Maybe<LegalPage>;
  legalPageCollection?: Maybe<LegalPageCollection>;
  link?: Maybe<Link>;
  linkCollection?: Maybe<LinkCollection>;
  linkGroup?: Maybe<LinkGroup>;
  linkGroupCollection?: Maybe<LinkGroupCollection>;
  menu?: Maybe<Menu>;
  menuCollection?: Maybe<MenuCollection>;
  newsletterContent?: Maybe<NewsletterContent>;
  newsletterContentCollection?: Maybe<NewsletterContentCollection>;
  outOfStockPage?: Maybe<OutOfStockPage>;
  outOfStockPageCollection?: Maybe<OutOfStockPageCollection>;
  partners?: Maybe<Partners>;
  partnersCollection?: Maybe<PartnersCollection>;
  previewLocations?: Maybe<PreviewLocations>;
  previewLocationsCollection?: Maybe<PreviewLocationsCollection>;
  pricingPage?: Maybe<PricingPage>;
  pricingPageCollection?: Maybe<PricingPageCollection>;
  pricingRideCard?: Maybe<PricingRideCard>;
  pricingRideCardCollection?: Maybe<PricingRideCardCollection>;
  productPage?: Maybe<ProductPage>;
  productPageCollection?: Maybe<ProductPageCollection>;
  seoMetadata?: Maybe<SeoMetadata>;
  seoMetadataCollection?: Maybe<SeoMetadataCollection>;
  siteSwitcher?: Maybe<SiteSwitcher>;
  siteSwitcherCollection?: Maybe<SiteSwitcherCollection>;
  tableRow?: Maybe<TableRow>;
  tableRowCollection?: Maybe<TableRowCollection>;
};

export type Query_NodeArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAnnouncementArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAnnouncementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<AnnouncementOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AnnouncementFilter>;
};

export type QueryAssetArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<AssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AssetFilter>;
};

export type QueryAvailableGaragesCardArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAvailableGaragesCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<AvailableGaragesCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AvailableGaragesCardFilter>;
};

export type QueryB2BShowcaseModelArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryB2BShowcaseModelCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<B2BShowcaseModelOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<B2BShowcaseModelFilter>;
};

export type QueryBlogEntryArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBlogEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BlogEntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlogEntryFilter>;
};

export type QueryBlogPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBlogPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BlogPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlogPageFilter>;
};

export type QueryBlogTopicsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBlogTopicsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BlogTopicsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlogTopicsFilter>;
};

export type QueryBrandContactArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBrandContactCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BrandContactOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BrandContactFilter>;
};

export type QueryCampaignEntryArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCampaignEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CampaignEntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CampaignEntryFilter>;
};

export type QueryCollapsableContainerArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCollapsableContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CollapsableContainerOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CollapsableContainerFilter>;
};

export type QueryComingSoonPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryComingSoonPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ComingSoonPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ComingSoonPageFilter>;
};

export type QueryContactCardArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContactCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ContactCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactCardFilter>;
};

export type QueryContactInfoLinkArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContactInfoLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ContactInfoLinkOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactInfoLinkFilter>;
};

export type QueryContactPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContactPageB2BArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryContactPageB2BCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ContactPageB2BOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactPageB2BFilter>;
};

export type QueryContactPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ContactPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactPageFilter>;
};

export type QueryDamagesCardArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryDamagesCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DamagesCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DamagesCardFilter>;
};

export type QueryDamagesContactPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryDamagesContactPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DamagesContactPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DamagesContactPageFilter>;
};

export type QueryDamagesPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryDamagesPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DamagesPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DamagesPageFilter>;
};

export type QueryDamagesPolicyPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryDamagesPolicyPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DamagesPolicyPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DamagesPolicyPageFilter>;
};

export type QueryDamagesPolicyPageContentBoxArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryDamagesPolicyPageContentBoxCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DamagesPolicyPageContentBoxOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DamagesPolicyPageContentBoxFilter>;
};

export type QueryDemoPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryDemoPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DemoPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DemoPageFilter>;
};

export type QueryDemoSlideArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryDemoSlideCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DemoSlideOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DemoSlideFilter>;
};

export type QueryDownloadPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryDownloadPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DownloadPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DownloadPageFilter>;
};

export type QueryDribeIconArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryDribeIconCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DribeIconOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DribeIconFilter>;
};

export type QueryDynamicFormArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryDynamicFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<DynamicFormOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DynamicFormFilter>;
};

export type QueryEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<EntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EntryFilter>;
};

export type QueryFaqCategoryArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqCategoryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqCategoryFilter>;
};

export type QueryFaqEntryArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqEntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqEntryFilter>;
};

export type QueryFaqPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFaqPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FaqPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaqPageFilter>;
};

export type QueryFeatureArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFeatureCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FeatureOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeatureFilter>;
};

export type QueryFooterArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FooterOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FooterFilter>;
};

export type QueryFormFieldArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFormFieldCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<FormFieldOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FormFieldFilter>;
};

export type QueryGaragePageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGaragePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<GaragePageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GaragePageFilter>;
};

export type QueryInspectionStepArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryInspectionStepCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<InspectionStepOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InspectionStepFilter>;
};

export type QueryLandingPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLandingPageB2BArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLandingPageB2BCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LandingPageB2BOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LandingPageB2BFilter>;
};

export type QueryLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LandingPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LandingPageFilter>;
};

export type QueryLegalEntryArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLegalEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LegalEntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LegalEntryFilter>;
};

export type QueryLegalPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLegalPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LegalPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LegalPageFilter>;
};

export type QueryLinkArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LinkOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkFilter>;
};

export type QueryLinkGroupArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLinkGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<LinkGroupOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LinkGroupFilter>;
};

export type QueryMenuArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryMenuCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<MenuOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MenuFilter>;
};

export type QueryNewsletterContentArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryNewsletterContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<NewsletterContentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NewsletterContentFilter>;
};

export type QueryOutOfStockPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryOutOfStockPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<OutOfStockPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OutOfStockPageFilter>;
};

export type QueryPartnersArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPartnersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PartnersOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PartnersFilter>;
};

export type QueryPreviewLocationsArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPreviewLocationsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PreviewLocationsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PreviewLocationsFilter>;
};

export type QueryPricingPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPricingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PricingPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PricingPageFilter>;
};

export type QueryPricingRideCardArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPricingRideCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<PricingRideCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PricingRideCardFilter>;
};

export type QueryProductPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryProductPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductPageFilter>;
};

export type QuerySeoMetadataArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySeoMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SeoMetadataOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SeoMetadataFilter>;
};

export type QuerySiteSwitcherArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySiteSwitcherCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<SiteSwitcherOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SiteSwitcherFilter>;
};

export type QueryTableRowArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryTableRowCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<TableRowOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TableRowFilter>;
};

export type ResourceLink = {
  sys: ResourceSys;
};

export type ResourceSys = {
  __typename?: 'ResourceSys';
  linkType: Scalars['String']['output'];
  urn: Scalars['String']['output'];
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadata = Entry &
  _Node & {
    __typename?: 'SeoMetadata';
    _id: Scalars['ID']['output'];
    canonical?: Maybe<Scalars['String']['output']>;
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']['output']>;
    internalName?: Maybe<Scalars['String']['output']>;
    keywords?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    linkedFrom?: Maybe<SeoMetadataLinkingCollections>;
    nofollow?: Maybe<Scalars['Boolean']['output']>;
    noindex?: Maybe<Scalars['Boolean']['output']>;
    openGraphDescription?: Maybe<Scalars['String']['output']>;
    openGraphImage?: Maybe<Asset>;
    openGraphTitle?: Maybe<Scalars['String']['output']>;
    openGraphType?: Maybe<Scalars['String']['output']>;
    openGraphUrl?: Maybe<Scalars['String']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    twitterCard?: Maybe<Scalars['String']['output']>;
    twitterSite?: Maybe<Scalars['String']['output']>;
  };

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataCanonicalArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataKeywordsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataNofollowArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataNoindexArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataOpenGraphDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataOpenGraphImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataOpenGraphTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataOpenGraphTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataOpenGraphUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataTwitterCardArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** SEO Metadata for use with the Commercial website [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/seoMetadata) */
export type SeoMetadataTwitterSiteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SeoMetadataCollection = {
  __typename?: 'SeoMetadataCollection';
  items: Array<Maybe<SeoMetadata>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SeoMetadataFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeoMetadataFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SeoMetadataFilter>>>;
  canonical?: InputMaybe<Scalars['String']['input']>;
  canonical_contains?: InputMaybe<Scalars['String']['input']>;
  canonical_exists?: InputMaybe<Scalars['Boolean']['input']>;
  canonical_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  canonical_not?: InputMaybe<Scalars['String']['input']>;
  canonical_not_contains?: InputMaybe<Scalars['String']['input']>;
  canonical_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  internalName?: InputMaybe<Scalars['String']['input']>;
  internalName_contains?: InputMaybe<Scalars['String']['input']>;
  internalName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalName_not?: InputMaybe<Scalars['String']['input']>;
  internalName_not_contains?: InputMaybe<Scalars['String']['input']>;
  internalName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  keywords_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  keywords_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  keywords_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  keywords_exists?: InputMaybe<Scalars['Boolean']['input']>;
  nofollow?: InputMaybe<Scalars['Boolean']['input']>;
  nofollow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  nofollow_not?: InputMaybe<Scalars['Boolean']['input']>;
  noindex?: InputMaybe<Scalars['Boolean']['input']>;
  noindex_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noindex_not?: InputMaybe<Scalars['Boolean']['input']>;
  openGraphDescription?: InputMaybe<Scalars['String']['input']>;
  openGraphDescription_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openGraphDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  openGraphDescription_not?: InputMaybe<Scalars['String']['input']>;
  openGraphDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  openGraphImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openGraphTitle?: InputMaybe<Scalars['String']['input']>;
  openGraphTitle_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openGraphTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  openGraphTitle_not?: InputMaybe<Scalars['String']['input']>;
  openGraphTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  openGraphType?: InputMaybe<Scalars['String']['input']>;
  openGraphType_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openGraphType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  openGraphType_not?: InputMaybe<Scalars['String']['input']>;
  openGraphType_not_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  openGraphUrl?: InputMaybe<Scalars['String']['input']>;
  openGraphUrl_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openGraphUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  openGraphUrl_not?: InputMaybe<Scalars['String']['input']>;
  openGraphUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitterCard?: InputMaybe<Scalars['String']['input']>;
  twitterCard_contains?: InputMaybe<Scalars['String']['input']>;
  twitterCard_exists?: InputMaybe<Scalars['Boolean']['input']>;
  twitterCard_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitterCard_not?: InputMaybe<Scalars['String']['input']>;
  twitterCard_not_contains?: InputMaybe<Scalars['String']['input']>;
  twitterCard_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  twitterSite?: InputMaybe<Scalars['String']['input']>;
  twitterSite_contains?: InputMaybe<Scalars['String']['input']>;
  twitterSite_exists?: InputMaybe<Scalars['Boolean']['input']>;
  twitterSite_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitterSite_not?: InputMaybe<Scalars['String']['input']>;
  twitterSite_not_contains?: InputMaybe<Scalars['String']['input']>;
  twitterSite_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type SeoMetadataLinkingCollections = {
  __typename?: 'SeoMetadataLinkingCollections';
  blogEntryCollection?: Maybe<BlogEntryCollection>;
  blogPageCollection?: Maybe<BlogPageCollection>;
  campaignEntryCollection?: Maybe<CampaignEntryCollection>;
  contactPageB2BCollection?: Maybe<ContactPageB2BCollection>;
  contactPageCollection?: Maybe<ContactPageCollection>;
  damagesContactPageCollection?: Maybe<DamagesContactPageCollection>;
  damagesPageCollection?: Maybe<DamagesPageCollection>;
  damagesPolicyPageCollection?: Maybe<DamagesPolicyPageCollection>;
  demoPageCollection?: Maybe<DemoPageCollection>;
  downloadPageCollection?: Maybe<DownloadPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
  faqEntryCollection?: Maybe<FaqEntryCollection>;
  faqPageCollection?: Maybe<FaqPageCollection>;
  garagePageCollection?: Maybe<GaragePageCollection>;
  landingPageB2BCollection?: Maybe<LandingPageB2BCollection>;
  landingPageCollection?: Maybe<LandingPageCollection>;
  legalEntryCollection?: Maybe<LegalEntryCollection>;
  legalPageCollection?: Maybe<LegalPageCollection>;
  outOfStockPageCollection?: Maybe<OutOfStockPageCollection>;
  pricingPageCollection?: Maybe<PricingPageCollection>;
  productPageCollection?: Maybe<ProductPageCollection>;
};

export type SeoMetadataLinkingCollectionsBlogEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsBlogEntryCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsBlogPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsBlogPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsCampaignEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsCampaignEntryCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsContactPageB2BCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<SeoMetadataLinkingCollectionsContactPageB2BCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsContactPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsContactPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsDamagesContactPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<SeoMetadataLinkingCollectionsDamagesContactPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsDamagesPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsDamagesPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsDamagesPolicyPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<SeoMetadataLinkingCollectionsDamagesPolicyPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsDemoPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsDemoPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsDownloadPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsDownloadPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsFaqEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsFaqEntryCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsFaqPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsFaqPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsGaragePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsGaragePageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsLandingPageB2BCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<SeoMetadataLinkingCollectionsLandingPageB2BCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsLandingPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsLegalEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsLegalEntryCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsLegalPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsLegalPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsOutOfStockPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<
      InputMaybe<SeoMetadataLinkingCollectionsOutOfStockPageCollectionOrder>
    >
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsPricingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsPricingPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SeoMetadataLinkingCollectionsProductPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<SeoMetadataLinkingCollectionsProductPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SeoMetadataLinkingCollectionsBlogEntryCollectionOrder {
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  previewText_ASC = 'previewText_ASC',
  previewText_DESC = 'previewText_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  titlePrefix_ASC = 'titlePrefix_ASC',
  titlePrefix_DESC = 'titlePrefix_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsBlogPageCollectionOrder {
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsCampaignEntryCollectionOrder {
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsContactPageB2BCollectionOrder {
  companyIdInputLabel_ASC = 'companyIdInputLabel_ASC',
  companyIdInputLabel_DESC = 'companyIdInputLabel_DESC',
  companyIdRequired_ASC = 'companyIdRequired_ASC',
  companyIdRequired_DESC = 'companyIdRequired_DESC',
  featureDescription_ASC = 'featureDescription_ASC',
  featureDescription_DESC = 'featureDescription_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  submitTitle_ASC = 'submitTitle_ASC',
  submitTitle_DESC = 'submitTitle_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsContactPageCollectionOrder {
  assistanceAndDamagesHeader_ASC = 'assistanceAndDamagesHeader_ASC',
  assistanceAndDamagesHeader_DESC = 'assistanceAndDamagesHeader_DESC',
  contactHeader_ASC = 'contactHeader_ASC',
  contactHeader_DESC = 'contactHeader_DESC',
  findGaragesHeader_ASC = 'findGaragesHeader_ASC',
  findGaragesHeader_DESC = 'findGaragesHeader_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsDamagesContactPageCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  startProblemsHeader_ASC = 'startProblemsHeader_ASC',
  startProblemsHeader_DESC = 'startProblemsHeader_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsDamagesPageCollectionOrder {
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsDamagesPolicyPageCollectionOrder {
  assistedInspectionHeader_ASC = 'assistedInspectionHeader_ASC',
  assistedInspectionHeader_DESC = 'assistedInspectionHeader_DESC',
  collapseContainerHeader_ASC = 'collapseContainerHeader_ASC',
  collapseContainerHeader_DESC = 'collapseContainerHeader_DESC',
  faqHeader_ASC = 'faqHeader_ASC',
  faqHeader_DESC = 'faqHeader_DESC',
  howItWorksHeader_ASC = 'howItWorksHeader_ASC',
  howItWorksHeader_DESC = 'howItWorksHeader_DESC',
  insuranceCoverHeader_ASC = 'insuranceCoverHeader_ASC',
  insuranceCoverHeader_DESC = 'insuranceCoverHeader_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  withAssistedInspection_ASC = 'withAssistedInspection_ASC',
  withAssistedInspection_DESC = 'withAssistedInspection_DESC'
}

export enum SeoMetadataLinkingCollectionsDemoPageCollectionOrder {
  initialTitle_ASC = 'initialTitle_ASC',
  initialTitle_DESC = 'initialTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  subTitle_ASC = 'subTitle_ASC',
  subTitle_DESC = 'subTitle_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsDownloadPageCollectionOrder {
  downloadButton_ASC = 'downloadButton_ASC',
  downloadButton_DESC = 'downloadButton_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  stepperBookCar_ASC = 'stepperBookCar_ASC',
  stepperBookCar_DESC = 'stepperBookCar_DESC',
  stepperCreateAccount_ASC = 'stepperCreateAccount_ASC',
  stepperCreateAccount_DESC = 'stepperCreateAccount_DESC',
  stepperFindCar_ASC = 'stepperFindCar_ASC',
  stepperFindCar_DESC = 'stepperFindCar_DESC',
  stepperScanQR_ASC = 'stepperScanQR_ASC',
  stepperScanQR_DESC = 'stepperScanQR_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsFaqEntryCollectionOrder {
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsFaqPageCollectionOrder {
  headerText_ASC = 'headerText_ASC',
  headerText_DESC = 'headerText_DESC',
  headerTitle_ASC = 'headerTitle_ASC',
  headerTitle_DESC = 'headerTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsGaragePageCollectionOrder {
  headerTitle_ASC = 'headerTitle_ASC',
  headerTitle_DESC = 'headerTitle_DESC',
  priceText_ASC = 'priceText_ASC',
  priceText_DESC = 'priceText_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsLandingPageB2BCollectionOrder {
  blogSectionTitle_ASC = 'blogSectionTitle_ASC',
  blogSectionTitle_DESC = 'blogSectionTitle_DESC',
  contactSectionSubtitle_ASC = 'contactSectionSubtitle_ASC',
  contactSectionSubtitle_DESC = 'contactSectionSubtitle_DESC',
  contactSectionTitle_ASC = 'contactSectionTitle_ASC',
  contactSectionTitle_DESC = 'contactSectionTitle_DESC',
  faqSectionTitle_ASC = 'faqSectionTitle_ASC',
  faqSectionTitle_DESC = 'faqSectionTitle_DESC',
  featuresSubTitle_ASC = 'featuresSubTitle_ASC',
  featuresSubTitle_DESC = 'featuresSubTitle_DESC',
  featuresTitle_ASC = 'featuresTitle_ASC',
  featuresTitle_DESC = 'featuresTitle_DESC',
  showCaseSubTitle_ASC = 'showCaseSubTitle_ASC',
  showCaseSubTitle_DESC = 'showCaseSubTitle_DESC',
  showCaseTitle_ASC = 'showCaseTitle_ASC',
  showCaseTitle_DESC = 'showCaseTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  trustedPartnersTitle_ASC = 'trustedPartnersTitle_ASC',
  trustedPartnersTitle_DESC = 'trustedPartnersTitle_DESC'
}

export enum SeoMetadataLinkingCollectionsLandingPageCollectionOrder {
  ageRequirementsAccentBox_ASC = 'ageRequirementsAccentBox_ASC',
  ageRequirementsAccentBox_DESC = 'ageRequirementsAccentBox_DESC',
  bookingFormSearchText_ASC = 'bookingFormSearchText_ASC',
  bookingFormSearchText_DESC = 'bookingFormSearchText_DESC',
  bookingFormTitle_ASC = 'bookingFormTitle_ASC',
  bookingFormTitle_DESC = 'bookingFormTitle_DESC',
  budgetCategoryMaxPrice_ASC = 'budgetCategoryMaxPrice_ASC',
  budgetCategoryMaxPrice_DESC = 'budgetCategoryMaxPrice_DESC',
  cardPricesFromText_ASC = 'cardPricesFromText_ASC',
  cardPricesFromText_DESC = 'cardPricesFromText_DESC',
  dateInputLabel_ASC = 'dateInputLabel_ASC',
  dateInputLabel_DESC = 'dateInputLabel_DESC',
  dateInputPlaceholder_ASC = 'dateInputPlaceholder_ASC',
  dateInputPlaceholder_DESC = 'dateInputPlaceholder_DESC',
  downloadAppTitle_ASC = 'downloadAppTitle_ASC',
  downloadAppTitle_DESC = 'downloadAppTitle_DESC',
  electricPropellantValue_ASC = 'electricPropellantValue_ASC',
  electricPropellantValue_DESC = 'electricPropellantValue_DESC',
  faqSectionTitle_ASC = 'faqSectionTitle_ASC',
  faqSectionTitle_DESC = 'faqSectionTitle_DESC',
  garageLabel_ASC = 'garageLabel_ASC',
  garageLabel_DESC = 'garageLabel_DESC',
  heroTitleText_ASC = 'heroTitleText_ASC',
  heroTitleText_DESC = 'heroTitleText_DESC',
  locationDropdownLabel_ASC = 'locationDropdownLabel_ASC',
  locationDropdownLabel_DESC = 'locationDropdownLabel_DESC',
  locationDropdownPlaceholder_ASC = 'locationDropdownPlaceholder_ASC',
  locationDropdownPlaceholder_DESC = 'locationDropdownPlaceholder_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsLegalEntryCollectionOrder {
  description_ASC = 'description_ASC',
  description_DESC = 'description_DESC',
  path_ASC = 'path_ASC',
  path_DESC = 'path_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  tag_ASC = 'tag_ASC',
  tag_DESC = 'tag_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsLegalPageCollectionOrder {
  headerTitle_ASC = 'headerTitle_ASC',
  headerTitle_DESC = 'headerTitle_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsOutOfStockPageCollectionOrder {
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  subTitle_ASC = 'subTitle_ASC',
  subTitle_DESC = 'subTitle_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  watchlistDescription_ASC = 'watchlistDescription_ASC',
  watchlistDescription_DESC = 'watchlistDescription_DESC',
  watchlistTitle_ASC = 'watchlistTitle_ASC',
  watchlistTitle_DESC = 'watchlistTitle_DESC'
}

export enum SeoMetadataLinkingCollectionsPricingPageCollectionOrder {
  checklistHeader_ASC = 'checklistHeader_ASC',
  checklistHeader_DESC = 'checklistHeader_DESC',
  exampleHeader_ASC = 'exampleHeader_ASC',
  exampleHeader_DESC = 'exampleHeader_DESC',
  header_ASC = 'header_ASC',
  header_DESC = 'header_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataLinkingCollectionsProductPageCollectionOrder {
  addOnsInfoText_ASC = 'addOnsInfoText_ASC',
  addOnsInfoText_DESC = 'addOnsInfoText_DESC',
  economyTableBottomText_ASC = 'economyTableBottomText_ASC',
  economyTableBottomText_DESC = 'economyTableBottomText_DESC',
  hideMsrpTaxContainer_ASC = 'hideMsrpTaxContainer_ASC',
  hideMsrpTaxContainer_DESC = 'hideMsrpTaxContainer_DESC',
  includeLinkToPricingPage_ASC = 'includeLinkToPricingPage_ASC',
  includeLinkToPricingPage_DESC = 'includeLinkToPricingPage_DESC',
  priceIncludesText_ASC = 'priceIncludesText_ASC',
  priceIncludesText_DESC = 'priceIncludesText_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum SeoMetadataOrder {
  canonical_ASC = 'canonical_ASC',
  canonical_DESC = 'canonical_DESC',
  description_ASC = 'description_ASC',
  description_DESC = 'description_DESC',
  internalName_ASC = 'internalName_ASC',
  internalName_DESC = 'internalName_DESC',
  nofollow_ASC = 'nofollow_ASC',
  nofollow_DESC = 'nofollow_DESC',
  noindex_ASC = 'noindex_ASC',
  noindex_DESC = 'noindex_DESC',
  openGraphDescription_ASC = 'openGraphDescription_ASC',
  openGraphDescription_DESC = 'openGraphDescription_DESC',
  openGraphTitle_ASC = 'openGraphTitle_ASC',
  openGraphTitle_DESC = 'openGraphTitle_DESC',
  openGraphType_ASC = 'openGraphType_ASC',
  openGraphType_DESC = 'openGraphType_DESC',
  openGraphUrl_ASC = 'openGraphUrl_ASC',
  openGraphUrl_DESC = 'openGraphUrl_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  twitterCard_ASC = 'twitterCard_ASC',
  twitterCard_DESC = 'twitterCard_DESC',
  twitterSite_ASC = 'twitterSite_ASC',
  twitterSite_DESC = 'twitterSite_DESC'
}

/** Used for toggling between "Live", "Maintenance", "Coming Soon" etc... [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/siteSwitcher) */
export type SiteSwitcher = Entry &
  _Node & {
    __typename?: 'SiteSwitcher';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom?: Maybe<SiteSwitcherLinkingCollections>;
    mode?: Maybe<Scalars['String']['output']>;
    slug?: Maybe<Scalars['String']['output']>;
    sys: Sys;
  };

/** Used for toggling between "Live", "Maintenance", "Coming Soon" etc... [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/siteSwitcher) */
export type SiteSwitcherLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Used for toggling between "Live", "Maintenance", "Coming Soon" etc... [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/siteSwitcher) */
export type SiteSwitcherModeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Used for toggling between "Live", "Maintenance", "Coming Soon" etc... [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/siteSwitcher) */
export type SiteSwitcherSlugArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type SiteSwitcherCollection = {
  __typename?: 'SiteSwitcherCollection';
  items: Array<Maybe<SiteSwitcher>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SiteSwitcherFilter = {
  AND?: InputMaybe<Array<InputMaybe<SiteSwitcherFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SiteSwitcherFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  mode?: InputMaybe<Scalars['String']['input']>;
  mode_contains?: InputMaybe<Scalars['String']['input']>;
  mode_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mode_not?: InputMaybe<Scalars['String']['input']>;
  mode_not_contains?: InputMaybe<Scalars['String']['input']>;
  mode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type SiteSwitcherLinkingCollections = {
  __typename?: 'SiteSwitcherLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type SiteSwitcherLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum SiteSwitcherOrder {
  mode_ASC = 'mode_ASC',
  mode_DESC = 'mode_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC'
}

export type Sys = {
  __typename?: 'Sys';
  environmentId: Scalars['String']['output'];
  firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  /** The locale that was requested. */
  locale?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  publishedVersion?: Maybe<Scalars['Int']['output']>;
  spaceId: Scalars['String']['output'];
};

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']['input']>;
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  publishedVersion?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedVersion_gt?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_gte?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  publishedVersion_lt?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_lte?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_not?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/tableRow) */
export type TableRow = Entry &
  _Node & {
    __typename?: 'TableRow';
    _id: Scalars['ID']['output'];
    contentfulMetadata: ContentfulMetadata;
    icon?: Maybe<DribeIcon>;
    linkedFrom?: Maybe<TableRowLinkingCollections>;
    lokaliseKey?: Maybe<Scalars['Boolean']['output']>;
    sys: Sys;
    title?: Maybe<Scalars['String']['output']>;
    value?: Maybe<Scalars['String']['output']>;
  };

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/tableRow) */
export type TableRowIconArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DribeIconFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/tableRow) */
export type TableRowLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/tableRow) */
export type TableRowLokaliseKeyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/tableRow) */
export type TableRowTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/roz41t1c7khz/content_types/tableRow) */
export type TableRowValueArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type TableRowCollection = {
  __typename?: 'TableRowCollection';
  items: Array<Maybe<TableRow>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TableRowFilter = {
  AND?: InputMaybe<Array<InputMaybe<TableRowFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TableRowFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon?: InputMaybe<CfDribeIconNestedFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lokaliseKey?: InputMaybe<Scalars['Boolean']['input']>;
  lokaliseKey_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lokaliseKey_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TableRowLinkingCollections = {
  __typename?: 'TableRowLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  productPageCollection?: Maybe<ProductPageCollection>;
};

export type TableRowLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type TableRowLinkingCollectionsProductPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<
    Array<InputMaybe<TableRowLinkingCollectionsProductPageCollectionOrder>>
  >;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum TableRowLinkingCollectionsProductPageCollectionOrder {
  addOnsInfoText_ASC = 'addOnsInfoText_ASC',
  addOnsInfoText_DESC = 'addOnsInfoText_DESC',
  economyTableBottomText_ASC = 'economyTableBottomText_ASC',
  economyTableBottomText_DESC = 'economyTableBottomText_DESC',
  hideMsrpTaxContainer_ASC = 'hideMsrpTaxContainer_ASC',
  hideMsrpTaxContainer_DESC = 'hideMsrpTaxContainer_DESC',
  includeLinkToPricingPage_ASC = 'includeLinkToPricingPage_ASC',
  includeLinkToPricingPage_DESC = 'includeLinkToPricingPage_DESC',
  priceIncludesText_ASC = 'priceIncludesText_ASC',
  priceIncludesText_DESC = 'priceIncludesText_DESC',
  slug_ASC = 'slug_ASC',
  slug_DESC = 'slug_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC'
}

export enum TableRowOrder {
  lokaliseKey_ASC = 'lokaliseKey_ASC',
  lokaliseKey_DESC = 'lokaliseKey_DESC',
  sys_firstPublishedAt_ASC = 'sys_firstPublishedAt_ASC',
  sys_firstPublishedAt_DESC = 'sys_firstPublishedAt_DESC',
  sys_id_ASC = 'sys_id_ASC',
  sys_id_DESC = 'sys_id_DESC',
  sys_publishedAt_ASC = 'sys_publishedAt_ASC',
  sys_publishedAt_DESC = 'sys_publishedAt_DESC',
  sys_publishedVersion_ASC = 'sys_publishedVersion_ASC',
  sys_publishedVersion_DESC = 'sys_publishedVersion_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type _Node = {
  _id: Scalars['ID']['output'];
};

export type CfAnnouncementNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAnnouncementNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAnnouncementNestedFilter>>>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfAvailableGaragesCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAvailableGaragesCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAvailableGaragesCardNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  garagesList_contains?: InputMaybe<Scalars['String']['input']>;
  garagesList_exists?: InputMaybe<Scalars['Boolean']['input']>;
  garagesList_not_contains?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfB2BShowcaseModelNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfB2BShowcaseModelNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfB2BShowcaseModelNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  features_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  features_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  features_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  features_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlSlug?: InputMaybe<Scalars['String']['input']>;
  urlSlug_contains?: InputMaybe<Scalars['String']['input']>;
  urlSlug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  urlSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlSlug_not?: InputMaybe<Scalars['String']['input']>;
  urlSlug_not_contains?: InputMaybe<Scalars['String']['input']>;
  urlSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlText?: InputMaybe<Scalars['String']['input']>;
  urlText_contains?: InputMaybe<Scalars['String']['input']>;
  urlText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  urlText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  urlText_not?: InputMaybe<Scalars['String']['input']>;
  urlText_not_contains?: InputMaybe<Scalars['String']['input']>;
  urlText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfBlogEntryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfBlogEntryNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfBlogEntryNestedFilter>>>;
  category_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  newsletterSection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  path_contains?: InputMaybe<Scalars['String']['input']>;
  path_exists?: InputMaybe<Scalars['Boolean']['input']>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  path_not?: InputMaybe<Scalars['String']['input']>;
  path_not_contains?: InputMaybe<Scalars['String']['input']>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  previewText?: InputMaybe<Scalars['String']['input']>;
  previewText_contains?: InputMaybe<Scalars['String']['input']>;
  previewText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  previewText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  previewText_not?: InputMaybe<Scalars['String']['input']>;
  previewText_not_contains?: InputMaybe<Scalars['String']['input']>;
  previewText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  site_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  site_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  site_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  site_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titlePrefix?: InputMaybe<Scalars['String']['input']>;
  titlePrefix_contains?: InputMaybe<Scalars['String']['input']>;
  titlePrefix_exists?: InputMaybe<Scalars['Boolean']['input']>;
  titlePrefix_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  titlePrefix_not?: InputMaybe<Scalars['String']['input']>;
  titlePrefix_not_contains?: InputMaybe<Scalars['String']['input']>;
  titlePrefix_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfBlogTopicsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfBlogTopicsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfBlogTopicsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfBrandContactNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfBrandContactNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfBrandContactNestedFilter>>>;
  brandContactInfo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  brandName?: InputMaybe<Scalars['String']['input']>;
  brandName_contains?: InputMaybe<Scalars['String']['input']>;
  brandName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  brandName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brandName_not?: InputMaybe<Scalars['String']['input']>;
  brandName_not_contains?: InputMaybe<Scalars['String']['input']>;
  brandName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type CfCollapsableContainerNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCollapsableContainerNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfCollapsableContainerNestedFilter>>>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfContactCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfContactCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfContactCardNestedFilter>>>;
  contactDescription_contains?: InputMaybe<Scalars['String']['input']>;
  contactDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  underlinedSubLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CfContactInfoLinkNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfContactInfoLinkNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfContactInfoLinkNestedFilter>>>;
  contactInfo?: InputMaybe<Scalars['String']['input']>;
  contactInfoType?: InputMaybe<Scalars['String']['input']>;
  contactInfoType_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfoType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactInfoType_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contactInfoType_not?: InputMaybe<Scalars['String']['input']>;
  contactInfoType_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfoType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contactInfo_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contactInfo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contactInfo_not?: InputMaybe<Scalars['String']['input']>;
  contactInfo_not_contains?: InputMaybe<Scalars['String']['input']>;
  contactInfo_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type CfDamagesCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfDamagesCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfDamagesCardNestedFilter>>>;
  arrowLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfDamagesPolicyPageContentBoxNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<CfDamagesPolicyPageContentBoxNestedFilter>>
  >;
  OR?: InputMaybe<Array<InputMaybe<CfDamagesPolicyPageContentBoxNestedFilter>>>;
  arrowLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfDemoSlideNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfDemoSlideNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfDemoSlideNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfDribeIconNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfDribeIconNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfDribeIconNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  iconName_contains?: InputMaybe<Scalars['String']['input']>;
  iconName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  iconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  iconName_not?: InputMaybe<Scalars['String']['input']>;
  iconName_not_contains?: InputMaybe<Scalars['String']['input']>;
  iconName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfDynamicFormNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfDynamicFormNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfDynamicFormNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  formEntryTitle?: InputMaybe<Scalars['String']['input']>;
  formEntryTitle_contains?: InputMaybe<Scalars['String']['input']>;
  formEntryTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formEntryTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formEntryTitle_not?: InputMaybe<Scalars['String']['input']>;
  formEntryTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  formEntryTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  formFieldsCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formIdentifierTag?: InputMaybe<Scalars['String']['input']>;
  formIdentifierTag_contains?: InputMaybe<Scalars['String']['input']>;
  formIdentifierTag_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formIdentifierTag_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  formIdentifierTag_not?: InputMaybe<Scalars['String']['input']>;
  formIdentifierTag_not_contains?: InputMaybe<Scalars['String']['input']>;
  formIdentifierTag_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  formTextContent_contains?: InputMaybe<Scalars['String']['input']>;
  formTextContent_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formTextContent_not_contains?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_contains?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  submitButtonLabel_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  submitButtonLabel_not?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  submitButtonLabel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  successfulSubmitMessage_contains?: InputMaybe<Scalars['String']['input']>;
  successfulSubmitMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  successfulSubmitMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfFaqCategoryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqCategoryNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqCategoryNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  faqEntriesCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfFaqEntryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqEntryNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFaqEntryNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  path?: InputMaybe<Scalars['String']['input']>;
  path_contains?: InputMaybe<Scalars['String']['input']>;
  path_exists?: InputMaybe<Scalars['Boolean']['input']>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  path_not?: InputMaybe<Scalars['String']['input']>;
  path_not_contains?: InputMaybe<Scalars['String']['input']>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  site_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  site_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  site_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  site_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfFeatureNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFeatureNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFeatureNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  defaultDescription_contains?: InputMaybe<Scalars['String']['input']>;
  defaultDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  defaultDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  defaultIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flipDescription_contains?: InputMaybe<Scalars['String']['input']>;
  flipDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flipDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  flipIcon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flipTitle?: InputMaybe<Scalars['String']['input']>;
  flipTitle_contains?: InputMaybe<Scalars['String']['input']>;
  flipTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flipTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  flipTitle_not?: InputMaybe<Scalars['String']['input']>;
  flipTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  flipTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  flipVisibleLink?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLinkTitle?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLinkTitle_contains?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLinkTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flipVisibleLinkTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  flipVisibleLinkTitle_not?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLinkTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLinkTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  flipVisibleLink_contains?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  flipVisibleLink_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  flipVisibleLink_not?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  flipVisibleLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  visibleLink?: InputMaybe<Scalars['String']['input']>;
  visibleLinkTitle?: InputMaybe<Scalars['String']['input']>;
  visibleLinkTitle_contains?: InputMaybe<Scalars['String']['input']>;
  visibleLinkTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  visibleLinkTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visibleLinkTitle_not?: InputMaybe<Scalars['String']['input']>;
  visibleLinkTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  visibleLinkTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  visibleLink_contains?: InputMaybe<Scalars['String']['input']>;
  visibleLink_exists?: InputMaybe<Scalars['Boolean']['input']>;
  visibleLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  visibleLink_not?: InputMaybe<Scalars['String']['input']>;
  visibleLink_not_contains?: InputMaybe<Scalars['String']['input']>;
  visibleLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type CfFormFieldNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFormFieldNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFormFieldNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  contentfulTitle?: InputMaybe<Scalars['String']['input']>;
  contentfulTitle_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulTitle_not?: InputMaybe<Scalars['String']['input']>;
  contentfulTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fieldErrorMessage?: InputMaybe<Scalars['String']['input']>;
  fieldErrorMessage_contains?: InputMaybe<Scalars['String']['input']>;
  fieldErrorMessage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldErrorMessage_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fieldErrorMessage_not?: InputMaybe<Scalars['String']['input']>;
  fieldErrorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  fieldErrorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fieldFullWidth?: InputMaybe<Scalars['Boolean']['input']>;
  fieldFullWidth_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldFullWidth_not?: InputMaybe<Scalars['Boolean']['input']>;
  fieldLabel?: InputMaybe<Scalars['String']['input']>;
  fieldLabel_contains?: InputMaybe<Scalars['String']['input']>;
  fieldLabel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldLabel_not?: InputMaybe<Scalars['String']['input']>;
  fieldLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  fieldLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  fieldName_contains?: InputMaybe<Scalars['String']['input']>;
  fieldName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldName_not?: InputMaybe<Scalars['String']['input']>;
  fieldName_not_contains?: InputMaybe<Scalars['String']['input']>;
  fieldName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldRegex?: InputMaybe<Scalars['String']['input']>;
  fieldRegex_contains?: InputMaybe<Scalars['String']['input']>;
  fieldRegex_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldRegex_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldRegex_not?: InputMaybe<Scalars['String']['input']>;
  fieldRegex_not_contains?: InputMaybe<Scalars['String']['input']>;
  fieldRegex_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldRequired?: InputMaybe<Scalars['Boolean']['input']>;
  fieldRequired_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldRequired_not?: InputMaybe<Scalars['Boolean']['input']>;
  fieldType?: InputMaybe<Scalars['String']['input']>;
  fieldType_contains?: InputMaybe<Scalars['String']['input']>;
  fieldType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fieldType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldType_not?: InputMaybe<Scalars['String']['input']>;
  fieldType_not_contains?: InputMaybe<Scalars['String']['input']>;
  fieldType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formFieldFreeText_contains?: InputMaybe<Scalars['String']['input']>;
  formFieldFreeText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formFieldFreeText_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfInspectionStepNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfInspectionStepNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfInspectionStepNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  stepDescription_contains?: InputMaybe<Scalars['String']['input']>;
  stepDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stepDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  stepHeader?: InputMaybe<Scalars['String']['input']>;
  stepHeader_contains?: InputMaybe<Scalars['String']['input']>;
  stepHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  stepHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  stepHeader_not?: InputMaybe<Scalars['String']['input']>;
  stepHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  stepHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfLegalEntryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfLegalEntryNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfLegalEntryNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  path?: InputMaybe<Scalars['String']['input']>;
  path_contains?: InputMaybe<Scalars['String']['input']>;
  path_exists?: InputMaybe<Scalars['Boolean']['input']>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  path_not?: InputMaybe<Scalars['String']['input']>;
  path_not_contains?: InputMaybe<Scalars['String']['input']>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seoMetadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  site_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  site_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  site_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  site_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_not?: InputMaybe<Scalars['String']['input']>;
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  tag?: InputMaybe<Scalars['String']['input']>;
  tag_contains?: InputMaybe<Scalars['String']['input']>;
  tag_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tag_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tag_not?: InputMaybe<Scalars['String']['input']>;
  tag_not_contains?: InputMaybe<Scalars['String']['input']>;
  tag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_contains?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfLinkGroupNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfLinkGroupNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfLinkGroupNestedFilter>>>;
  b2bLinksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfLinkNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfLinkNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfLinkNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfNewsletterContentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfNewsletterContentNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfNewsletterContentNestedFilter>>>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']['input']>;
  header_contains?: InputMaybe<Scalars['String']['input']>;
  header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  header_not?: InputMaybe<Scalars['String']['input']>;
  header_not_contains?: InputMaybe<Scalars['String']['input']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  newsletterSubscriptionForm_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CfPreviewLocationsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPreviewLocationsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPreviewLocationsNestedFilter>>>;
  address1?: InputMaybe<Scalars['String']['input']>;
  address1_contains?: InputMaybe<Scalars['String']['input']>;
  address1_exists?: InputMaybe<Scalars['Boolean']['input']>;
  address1_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  address1_not?: InputMaybe<Scalars['String']['input']>;
  address1_not_contains?: InputMaybe<Scalars['String']['input']>;
  address1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address2_contains?: InputMaybe<Scalars['String']['input']>;
  address2_exists?: InputMaybe<Scalars['Boolean']['input']>;
  address2_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  address2_not?: InputMaybe<Scalars['String']['input']>;
  address2_not_contains?: InputMaybe<Scalars['String']['input']>;
  address2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_contains?: InputMaybe<Scalars['String']['input']>;
  city_exists?: InputMaybe<Scalars['Boolean']['input']>;
  city_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  city_not?: InputMaybe<Scalars['String']['input']>;
  city_not_contains?: InputMaybe<Scalars['String']['input']>;
  city_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type CfPricingRideCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPricingRideCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPricingRideCardNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  rideImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rideNameHeader?: InputMaybe<Scalars['String']['input']>;
  rideNameHeader_contains?: InputMaybe<Scalars['String']['input']>;
  rideNameHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rideNameHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rideNameHeader_not?: InputMaybe<Scalars['String']['input']>;
  rideNameHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  rideNameHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  rideVariantSubHeader?: InputMaybe<Scalars['String']['input']>;
  rideVariantSubHeader_contains?: InputMaybe<Scalars['String']['input']>;
  rideVariantSubHeader_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rideVariantSubHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  rideVariantSubHeader_not?: InputMaybe<Scalars['String']['input']>;
  rideVariantSubHeader_not_contains?: InputMaybe<Scalars['String']['input']>;
  rideVariantSubHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
};

export type CfSeoMetadataNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSeoMetadataNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSeoMetadataNestedFilter>>>;
  canonical?: InputMaybe<Scalars['String']['input']>;
  canonical_contains?: InputMaybe<Scalars['String']['input']>;
  canonical_exists?: InputMaybe<Scalars['Boolean']['input']>;
  canonical_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  canonical_not?: InputMaybe<Scalars['String']['input']>;
  canonical_not_contains?: InputMaybe<Scalars['String']['input']>;
  canonical_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  internalName?: InputMaybe<Scalars['String']['input']>;
  internalName_contains?: InputMaybe<Scalars['String']['input']>;
  internalName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalName_not?: InputMaybe<Scalars['String']['input']>;
  internalName_not_contains?: InputMaybe<Scalars['String']['input']>;
  internalName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  keywords_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  keywords_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  keywords_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  keywords_exists?: InputMaybe<Scalars['Boolean']['input']>;
  nofollow?: InputMaybe<Scalars['Boolean']['input']>;
  nofollow_exists?: InputMaybe<Scalars['Boolean']['input']>;
  nofollow_not?: InputMaybe<Scalars['Boolean']['input']>;
  noindex?: InputMaybe<Scalars['Boolean']['input']>;
  noindex_exists?: InputMaybe<Scalars['Boolean']['input']>;
  noindex_not?: InputMaybe<Scalars['Boolean']['input']>;
  openGraphDescription?: InputMaybe<Scalars['String']['input']>;
  openGraphDescription_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphDescription_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openGraphDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  openGraphDescription_not?: InputMaybe<Scalars['String']['input']>;
  openGraphDescription_not_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  openGraphImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openGraphTitle?: InputMaybe<Scalars['String']['input']>;
  openGraphTitle_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphTitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openGraphTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  openGraphTitle_not?: InputMaybe<Scalars['String']['input']>;
  openGraphTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  openGraphType?: InputMaybe<Scalars['String']['input']>;
  openGraphType_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openGraphType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  openGraphType_not?: InputMaybe<Scalars['String']['input']>;
  openGraphType_not_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  openGraphUrl?: InputMaybe<Scalars['String']['input']>;
  openGraphUrl_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  openGraphUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  openGraphUrl_not?: InputMaybe<Scalars['String']['input']>;
  openGraphUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  openGraphUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitterCard?: InputMaybe<Scalars['String']['input']>;
  twitterCard_contains?: InputMaybe<Scalars['String']['input']>;
  twitterCard_exists?: InputMaybe<Scalars['Boolean']['input']>;
  twitterCard_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitterCard_not?: InputMaybe<Scalars['String']['input']>;
  twitterCard_not_contains?: InputMaybe<Scalars['String']['input']>;
  twitterCard_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  twitterSite?: InputMaybe<Scalars['String']['input']>;
  twitterSite_contains?: InputMaybe<Scalars['String']['input']>;
  twitterSite_exists?: InputMaybe<Scalars['Boolean']['input']>;
  twitterSite_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitterSite_not?: InputMaybe<Scalars['String']['input']>;
  twitterSite_not_contains?: InputMaybe<Scalars['String']['input']>;
  twitterSite_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
};

export type CfTableRowNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTableRowNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfTableRowNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lokaliseKey?: InputMaybe<Scalars['Boolean']['input']>;
  lokaliseKey_exists?: InputMaybe<Scalars['Boolean']['input']>;
  lokaliseKey_not?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AssetFragment_Asset_sys_Sys = { __typename?: 'Sys'; id: string };

export type AssetFragment_Asset_contentfulMetadata_ContentfulMetadata_tags_ContentfulTag =
  {
    __typename?: 'ContentfulTag';
    id?: string | null | undefined;
    name?: string | null | undefined;
  };

export type AssetFragment_Asset_contentfulMetadata_ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  tags: Array<
    | AssetFragment_Asset_contentfulMetadata_ContentfulMetadata_tags_ContentfulTag
    | null
    | undefined
  >;
};

export type AssetFragment = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type BlogEntryFragment_BlogEntry_sys_Sys = {
  __typename?: 'Sys';
  firstPublishedAt?: any | null | undefined;
};

export type BlogEntryFragment_BlogEntry_titleImage_Asset = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type BlogEntryFragment_BlogEntry_category_BlogTopics_contentfulMetadata_ContentfulMetadata_tags_ContentfulTag =
  { __typename?: 'ContentfulTag'; id?: string | null | undefined };

export type BlogEntryFragment_BlogEntry_category_BlogTopics_contentfulMetadata_ContentfulMetadata =
  {
    __typename?: 'ContentfulMetadata';
    tags: Array<
      | BlogEntryFragment_BlogEntry_category_BlogTopics_contentfulMetadata_ContentfulMetadata_tags_ContentfulTag
      | null
      | undefined
    >;
  };

export type BlogEntryFragment_BlogEntry_category_BlogTopics_sys_Sys = {
  __typename?: 'Sys';
  id: string;
  environmentId: string;
  spaceId: string;
};

export type BlogEntryFragment_BlogEntry_category_BlogTopics = {
  __typename?: 'BlogTopics';
  title?: string | null | undefined;
  contentfulMetadata: BlogEntryFragment_BlogEntry_category_BlogTopics_contentfulMetadata_ContentfulMetadata;
  sys: BlogEntryFragment_BlogEntry_category_BlogTopics_sys_Sys;
};

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_assets_BlogEntryContentAssets_block_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_assets_BlogEntryContentAssets =
  {
    __typename?: 'BlogEntryContentAssets';
    block: Array<
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_assets_BlogEntryContentAssets_block_Asset
      | null
      | undefined
    >;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formTextContent_DynamicFormFormTextContent =
  { __typename?: 'DynamicFormFormTextContent'; json: any };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection_items_FormField_formFieldFreeText_FormFieldFormFieldFreeText =
  { __typename?: 'FormFieldFormFieldFreeText'; json: any };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection_items_FormField =
  {
    __typename?: 'FormField';
    fieldName?: string | null | undefined;
    fieldLabel?: string | null | undefined;
    fieldType?: string | null | undefined;
    fieldRequired?: boolean | null | undefined;
    fieldErrorMessage?: string | null | undefined;
    fieldFullWidth?: boolean | null | undefined;
    fieldRegex?: string | null | undefined;
    formFieldFreeText?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection_items_FormField_formFieldFreeText_FormFieldFormFieldFreeText
      | null
      | undefined;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection =
  {
    __typename?: 'DynamicFormFormFieldsCollection';
    items: Array<
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection_items_FormField
      | null
      | undefined
    >;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_successfulSubmitMessage_DynamicFormSuccessfulSubmitMessage =
  { __typename?: 'DynamicFormSuccessfulSubmitMessage'; json: any };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement =
  {
    __typename: 'Announcement';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_AvailableGaragesCard =
  {
    __typename: 'AvailableGaragesCard';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_B2BShowcaseModel =
  {
    __typename: 'B2BShowcaseModel';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_BlogEntry =
  {
    __typename: 'BlogEntry';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_BlogPage =
  {
    __typename: 'BlogPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_BlogTopics =
  {
    __typename: 'BlogTopics';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_BrandContact =
  {
    __typename: 'BrandContact';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_CampaignEntry =
  {
    __typename: 'CampaignEntry';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_CollapsableContainer =
  {
    __typename: 'CollapsableContainer';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_ComingSoonPage =
  {
    __typename: 'ComingSoonPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_ContactCard =
  {
    __typename: 'ContactCard';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_ContactInfoLink =
  {
    __typename: 'ContactInfoLink';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_ContactPage =
  {
    __typename: 'ContactPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_ContactPageB2B =
  {
    __typename: 'ContactPageB2B';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DamagesCard =
  {
    __typename: 'DamagesCard';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DamagesContactPage =
  {
    __typename: 'DamagesContactPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DamagesPage =
  {
    __typename: 'DamagesPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DamagesPolicyPage =
  {
    __typename: 'DamagesPolicyPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DamagesPolicyPageContentBox =
  {
    __typename: 'DamagesPolicyPageContentBox';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DemoPage =
  {
    __typename: 'DemoPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DemoSlide =
  {
    __typename: 'DemoSlide';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DownloadPage =
  {
    __typename: 'DownloadPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DribeIcon =
  {
    __typename: 'DribeIcon';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm =
  {
    __typename: 'DynamicForm';
    formIdentifierTag?: string | null | undefined;
    submitButtonLabel?: string | null | undefined;
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
    formTextContent?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formTextContent_DynamicFormFormTextContent
      | null
      | undefined;
    formFieldsCollection?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection
      | null
      | undefined;
    successfulSubmitMessage?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_successfulSubmitMessage_DynamicFormSuccessfulSubmitMessage
      | null
      | undefined;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_FaqCategory =
  {
    __typename: 'FaqCategory';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_FaqEntry =
  {
    __typename: 'FaqEntry';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_FaqPage =
  {
    __typename: 'FaqPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Feature =
  {
    __typename: 'Feature';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Footer =
  {
    __typename: 'Footer';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_FormField =
  {
    __typename: 'FormField';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_GaragePage =
  {
    __typename: 'GaragePage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_InspectionStep =
  {
    __typename: 'InspectionStep';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_LandingPage =
  {
    __typename: 'LandingPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_LandingPageB2B =
  {
    __typename: 'LandingPageB2B';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_LegalEntry =
  {
    __typename: 'LegalEntry';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_LegalPage =
  {
    __typename: 'LegalPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Link =
  {
    __typename: 'Link';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_LinkGroup =
  {
    __typename: 'LinkGroup';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Menu =
  {
    __typename: 'Menu';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_NewsletterContent =
  {
    __typename: 'NewsletterContent';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_OutOfStockPage =
  {
    __typename: 'OutOfStockPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Partners =
  {
    __typename: 'Partners';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_PreviewLocations =
  {
    __typename: 'PreviewLocations';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_PricingPage =
  {
    __typename: 'PricingPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_PricingRideCard =
  {
    __typename: 'PricingRideCard';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_ProductPage =
  {
    __typename: 'ProductPage';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_SeoMetadata =
  {
    __typename: 'SeoMetadata';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_SiteSwitcher =
  {
    __typename: 'SiteSwitcher';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_TableRow =
  {
    __typename: 'TableRow';
    sys: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement_sys_Sys;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block =

    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Announcement
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_AvailableGaragesCard
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_B2BShowcaseModel
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_BlogEntry
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_BlogPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_BlogTopics
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_BrandContact
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_CampaignEntry
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_CollapsableContainer
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_ComingSoonPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_ContactCard
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_ContactInfoLink
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_ContactPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_ContactPageB2B
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DamagesCard
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DamagesContactPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DamagesPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DamagesPolicyPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DamagesPolicyPageContentBox
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DemoPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DemoSlide
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DownloadPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DribeIcon
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_FaqCategory
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_FaqEntry
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_FaqPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Feature
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Footer
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_FormField
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_GaragePage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_InspectionStep
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_LandingPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_LandingPageB2B
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_LegalEntry
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_LegalPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Link
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_LinkGroup
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Menu
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_NewsletterContent
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_OutOfStockPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_Partners
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_PreviewLocations
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_PricingPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_PricingRideCard
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_ProductPage
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_SeoMetadata
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_SiteSwitcher
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_TableRow;

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries =
  {
    __typename?: 'BlogEntryContentEntries';
    block: Array<
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block
      | null
      | undefined
    >;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks =
  {
    __typename?: 'BlogEntryContentLinks';
    assets: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_assets_BlogEntryContentAssets;
    entries: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries;
  };

export type BlogEntryFragment_BlogEntry_content_BlogEntryContent = {
  __typename?: 'BlogEntryContent';
  json: any;
  links: BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks;
};

export type BlogEntryFragment_BlogEntry_newsletterSection_NewsletterContent_content_NewsletterContentContent =
  { __typename?: 'NewsletterContentContent'; json: any };

export type BlogEntryFragment_BlogEntry_newsletterSection_NewsletterContent_newsletterSubscriptionForm_DynamicForm =
  {
    __typename?: 'DynamicForm';
    formIdentifierTag?: string | null | undefined;
    submitButtonLabel?: string | null | undefined;
    formTextContent?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formTextContent_DynamicFormFormTextContent
      | null
      | undefined;
    formFieldsCollection?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection
      | null
      | undefined;
    successfulSubmitMessage?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_successfulSubmitMessage_DynamicFormSuccessfulSubmitMessage
      | null
      | undefined;
  };

export type BlogEntryFragment_BlogEntry_newsletterSection_NewsletterContent = {
  __typename?: 'NewsletterContent';
  header?: string | null | undefined;
  content?:
    | BlogEntryFragment_BlogEntry_newsletterSection_NewsletterContent_content_NewsletterContentContent
    | null
    | undefined;
  newsletterSubscriptionForm?:
    | BlogEntryFragment_BlogEntry_newsletterSection_NewsletterContent_newsletterSubscriptionForm_DynamicForm
    | null
    | undefined;
};

export type BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys = {
  __typename?: 'Sys';
  id: string;
};

export type BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type BlogEntryFragment = {
  __typename?: 'BlogEntry';
  title?: string | null | undefined;
  previewText?: string | null | undefined;
  titlePrefix?: string | null | undefined;
  slug?: string | null | undefined;
  site?: Array<string | null | undefined> | null | undefined;
  path?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_sys_Sys;
  titleImage?: BlogEntryFragment_BlogEntry_titleImage_Asset | null | undefined;
  category?: BlogEntryFragment_BlogEntry_category_BlogTopics | null | undefined;
  content?:
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent
    | null
    | undefined;
  newsletterSection?:
    | BlogEntryFragment_BlogEntry_newsletterSection_NewsletterContent
    | null
    | undefined;
  seoMetadata?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type BlogPageFragment_BlogPage_highlightedBlogPost_BlogEntry = {
  __typename?: 'BlogEntry';
  title?: string | null | undefined;
  previewText?: string | null | undefined;
  titlePrefix?: string | null | undefined;
  slug?: string | null | undefined;
  site?: Array<string | null | undefined> | null | undefined;
  path?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_sys_Sys;
  titleImage?: BlogEntryFragment_BlogEntry_titleImage_Asset | null | undefined;
  category?: BlogEntryFragment_BlogEntry_category_BlogTopics | null | undefined;
  content?:
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent
    | null
    | undefined;
  newsletterSection?:
    | BlogEntryFragment_BlogEntry_newsletterSection_NewsletterContent
    | null
    | undefined;
  seoMetadata?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type BlogPageFragment_BlogPage_newsletterSection_NewsletterContent_content_NewsletterContentContent =
  { __typename?: 'NewsletterContentContent'; json: any };

export type BlogPageFragment_BlogPage_newsletterSection_NewsletterContent_newsletterSubscriptionForm_DynamicForm =
  {
    __typename: 'DynamicForm';
    formIdentifierTag?: string | null | undefined;
    submitButtonLabel?: string | null | undefined;
    formTextContent?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formTextContent_DynamicFormFormTextContent
      | null
      | undefined;
    formFieldsCollection?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection
      | null
      | undefined;
    successfulSubmitMessage?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_successfulSubmitMessage_DynamicFormSuccessfulSubmitMessage
      | null
      | undefined;
  };

export type BlogPageFragment_BlogPage_newsletterSection_NewsletterContent = {
  __typename?: 'NewsletterContent';
  header?: string | null | undefined;
  content?:
    | BlogPageFragment_BlogPage_newsletterSection_NewsletterContent_content_NewsletterContentContent
    | null
    | undefined;
  newsletterSubscriptionForm?:
    | BlogPageFragment_BlogPage_newsletterSection_NewsletterContent_newsletterSubscriptionForm_DynamicForm
    | null
    | undefined;
};

export type BlogPageFragment_BlogPage_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type BlogPageFragment = {
  __typename?: 'BlogPage';
  title?: string | null | undefined;
  slug?: string | null | undefined;
  highlightedBlogPost?:
    | BlogPageFragment_BlogPage_highlightedBlogPost_BlogEntry
    | null
    | undefined;
  newsletterSection?:
    | BlogPageFragment_BlogPage_newsletterSection_NewsletterContent
    | null
    | undefined;
  seoMetadata?:
    | BlogPageFragment_BlogPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type CampaignEntryFragment_CampaignEntry_sys_Sys = {
  __typename?: 'Sys';
  id: string;
};

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_assets_CampaignEntryContentAssets_block_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_assets_CampaignEntryContentAssets =
  {
    __typename?: 'CampaignEntryContentAssets';
    block: Array<
      | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_assets_CampaignEntryContentAssets_block_Asset
      | null
      | undefined
    >;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement =
  {
    __typename: 'Announcement';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_AvailableGaragesCard =
  {
    __typename: 'AvailableGaragesCard';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_B2BShowcaseModel =
  {
    __typename: 'B2BShowcaseModel';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_BlogEntry =
  {
    __typename: 'BlogEntry';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_BlogPage =
  {
    __typename: 'BlogPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_BlogTopics =
  {
    __typename: 'BlogTopics';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_BrandContact =
  {
    __typename: 'BrandContact';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_CampaignEntry =
  {
    __typename: 'CampaignEntry';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_CollapsableContainer =
  {
    __typename: 'CollapsableContainer';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_ComingSoonPage =
  {
    __typename: 'ComingSoonPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_ContactCard =
  {
    __typename: 'ContactCard';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_ContactInfoLink =
  {
    __typename: 'ContactInfoLink';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_ContactPage =
  {
    __typename: 'ContactPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_ContactPageB2B =
  {
    __typename: 'ContactPageB2B';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DamagesCard =
  {
    __typename: 'DamagesCard';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DamagesContactPage =
  {
    __typename: 'DamagesContactPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DamagesPage =
  {
    __typename: 'DamagesPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DamagesPolicyPage =
  {
    __typename: 'DamagesPolicyPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DamagesPolicyPageContentBox =
  {
    __typename: 'DamagesPolicyPageContentBox';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DemoPage =
  {
    __typename: 'DemoPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DemoSlide =
  {
    __typename: 'DemoSlide';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DownloadPage =
  {
    __typename: 'DownloadPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DribeIcon =
  {
    __typename: 'DribeIcon';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DynamicForm =
  {
    __typename: 'DynamicForm';
    formIdentifierTag?: string | null | undefined;
    submitButtonLabel?: string | null | undefined;
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
    formTextContent?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formTextContent_DynamicFormFormTextContent
      | null
      | undefined;
    formFieldsCollection?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection
      | null
      | undefined;
    successfulSubmitMessage?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_successfulSubmitMessage_DynamicFormSuccessfulSubmitMessage
      | null
      | undefined;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_FaqCategory =
  {
    __typename: 'FaqCategory';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_FaqEntry =
  {
    __typename: 'FaqEntry';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_FaqPage =
  {
    __typename: 'FaqPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Feature =
  {
    __typename: 'Feature';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Footer =
  {
    __typename: 'Footer';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_FormField =
  {
    __typename: 'FormField';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_GaragePage =
  {
    __typename: 'GaragePage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_InspectionStep =
  {
    __typename: 'InspectionStep';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_LandingPage =
  {
    __typename: 'LandingPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_LandingPageB2B =
  {
    __typename: 'LandingPageB2B';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_LegalEntry =
  {
    __typename: 'LegalEntry';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_LegalPage =
  {
    __typename: 'LegalPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Link =
  {
    __typename: 'Link';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_LinkGroup =
  {
    __typename: 'LinkGroup';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Menu =
  {
    __typename: 'Menu';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_NewsletterContent =
  {
    __typename: 'NewsletterContent';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_OutOfStockPage =
  {
    __typename: 'OutOfStockPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Partners =
  {
    __typename: 'Partners';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_PreviewLocations =
  {
    __typename: 'PreviewLocations';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_PricingPage =
  {
    __typename: 'PricingPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_PricingRideCard =
  {
    __typename: 'PricingRideCard';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_ProductPage =
  {
    __typename: 'ProductPage';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_SeoMetadata =
  {
    __typename: 'SeoMetadata';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_SiteSwitcher =
  {
    __typename: 'SiteSwitcher';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_TableRow =
  {
    __typename: 'TableRow';
    sys: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement_sys_Sys;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block =

    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Announcement
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_AvailableGaragesCard
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_B2BShowcaseModel
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_BlogEntry
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_BlogPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_BlogTopics
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_BrandContact
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_CampaignEntry
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_CollapsableContainer
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_ComingSoonPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_ContactCard
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_ContactInfoLink
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_ContactPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_ContactPageB2B
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DamagesCard
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DamagesContactPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DamagesPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DamagesPolicyPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DamagesPolicyPageContentBox
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DemoPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DemoSlide
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DownloadPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DribeIcon
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_DynamicForm
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_FaqCategory
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_FaqEntry
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_FaqPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Feature
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Footer
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_FormField
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_GaragePage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_InspectionStep
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_LandingPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_LandingPageB2B
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_LegalEntry
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_LegalPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Link
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_LinkGroup
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Menu
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_NewsletterContent
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_OutOfStockPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_Partners
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_PreviewLocations
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_PricingPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_PricingRideCard
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_ProductPage
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_SeoMetadata
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_SiteSwitcher
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block_TableRow;

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries =
  {
    __typename?: 'CampaignEntryContentEntries';
    block: Array<
      | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries_block
      | null
      | undefined
    >;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks =
  {
    __typename?: 'CampaignEntryContentLinks';
    assets: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_assets_CampaignEntryContentAssets;
    entries: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks_entries_CampaignEntryContentEntries;
  };

export type CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent = {
  __typename?: 'CampaignEntryContent';
  json: any;
  links: CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent_links_CampaignEntryContentLinks;
};

export type CampaignEntryFragment_CampaignEntry_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type CampaignEntryFragment = {
  __typename?: 'CampaignEntry';
  title?: string | null | undefined;
  path?: string | null | undefined;
  slug?: string | null | undefined;
  sys: CampaignEntryFragment_CampaignEntry_sys_Sys;
  content?:
    | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent
    | null
    | undefined;
  seoMetadata?:
    | CampaignEntryFragment_CampaignEntry_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type ComingSoonPageFragment_ComingSoonPage_headerImage_Asset = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_assets_ComingSoonPageDescriptionAssets_block_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_assets_ComingSoonPageDescriptionAssets =
  {
    __typename?: 'ComingSoonPageDescriptionAssets';
    block: Array<
      | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_assets_ComingSoonPageDescriptionAssets_block_Asset
      | null
      | undefined
    >;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement =
  {
    __typename: 'Announcement';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_AvailableGaragesCard =
  {
    __typename: 'AvailableGaragesCard';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_B2BShowcaseModel =
  {
    __typename: 'B2BShowcaseModel';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_BlogEntry =
  {
    __typename: 'BlogEntry';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_BlogPage =
  {
    __typename: 'BlogPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_BlogTopics =
  {
    __typename: 'BlogTopics';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_BrandContact =
  {
    __typename: 'BrandContact';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_CampaignEntry =
  {
    __typename: 'CampaignEntry';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_CollapsableContainer =
  {
    __typename: 'CollapsableContainer';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_ComingSoonPage =
  {
    __typename: 'ComingSoonPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_ContactCard =
  {
    __typename: 'ContactCard';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_ContactInfoLink =
  {
    __typename: 'ContactInfoLink';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_ContactPage =
  {
    __typename: 'ContactPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_ContactPageB2B =
  {
    __typename: 'ContactPageB2B';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DamagesCard =
  {
    __typename: 'DamagesCard';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DamagesContactPage =
  {
    __typename: 'DamagesContactPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DamagesPage =
  {
    __typename: 'DamagesPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DamagesPolicyPage =
  {
    __typename: 'DamagesPolicyPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DamagesPolicyPageContentBox =
  {
    __typename: 'DamagesPolicyPageContentBox';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DemoPage =
  {
    __typename: 'DemoPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DemoSlide =
  {
    __typename: 'DemoSlide';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DownloadPage =
  {
    __typename: 'DownloadPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DribeIcon =
  {
    __typename: 'DribeIcon';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DynamicForm =
  {
    __typename: 'DynamicForm';
    formIdentifierTag?: string | null | undefined;
    submitButtonLabel?: string | null | undefined;
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
    formTextContent?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formTextContent_DynamicFormFormTextContent
      | null
      | undefined;
    formFieldsCollection?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection
      | null
      | undefined;
    successfulSubmitMessage?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_successfulSubmitMessage_DynamicFormSuccessfulSubmitMessage
      | null
      | undefined;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_FaqCategory =
  {
    __typename: 'FaqCategory';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_FaqEntry =
  {
    __typename: 'FaqEntry';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_FaqPage =
  {
    __typename: 'FaqPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Feature =
  {
    __typename: 'Feature';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Footer =
  {
    __typename: 'Footer';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_FormField =
  {
    __typename: 'FormField';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_GaragePage =
  {
    __typename: 'GaragePage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_InspectionStep =
  {
    __typename: 'InspectionStep';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_LandingPage =
  {
    __typename: 'LandingPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_LandingPageB2B =
  {
    __typename: 'LandingPageB2B';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_LegalEntry =
  {
    __typename: 'LegalEntry';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_LegalPage =
  {
    __typename: 'LegalPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Link =
  {
    __typename: 'Link';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_LinkGroup =
  {
    __typename: 'LinkGroup';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Menu =
  {
    __typename: 'Menu';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_NewsletterContent =
  {
    __typename: 'NewsletterContent';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_OutOfStockPage =
  {
    __typename: 'OutOfStockPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Partners =
  {
    __typename: 'Partners';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_PreviewLocations =
  {
    __typename: 'PreviewLocations';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_PricingPage =
  {
    __typename: 'PricingPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_PricingRideCard =
  {
    __typename: 'PricingRideCard';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_ProductPage =
  {
    __typename: 'ProductPage';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_SeoMetadata =
  {
    __typename: 'SeoMetadata';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_SiteSwitcher =
  {
    __typename: 'SiteSwitcher';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_TableRow =
  {
    __typename: 'TableRow';
    sys: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement_sys_Sys;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block =

    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Announcement
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_AvailableGaragesCard
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_B2BShowcaseModel
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_BlogEntry
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_BlogPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_BlogTopics
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_BrandContact
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_CampaignEntry
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_CollapsableContainer
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_ComingSoonPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_ContactCard
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_ContactInfoLink
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_ContactPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_ContactPageB2B
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DamagesCard
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DamagesContactPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DamagesPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DamagesPolicyPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DamagesPolicyPageContentBox
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DemoPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DemoSlide
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DownloadPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DribeIcon
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_DynamicForm
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_FaqCategory
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_FaqEntry
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_FaqPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Feature
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Footer
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_FormField
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_GaragePage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_InspectionStep
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_LandingPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_LandingPageB2B
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_LegalEntry
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_LegalPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Link
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_LinkGroup
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Menu
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_NewsletterContent
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_OutOfStockPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_Partners
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_PreviewLocations
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_PricingPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_PricingRideCard
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_ProductPage
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_SeoMetadata
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_SiteSwitcher
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block_TableRow;

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries =
  {
    __typename?: 'ComingSoonPageDescriptionEntries';
    block: Array<
      | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries_block
      | null
      | undefined
    >;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks =
  {
    __typename?: 'ComingSoonPageDescriptionLinks';
    assets: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_assets_ComingSoonPageDescriptionAssets;
    entries: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks_entries_ComingSoonPageDescriptionEntries;
  };

export type ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription =
  {
    __typename?: 'ComingSoonPageDescription';
    json: any;
    links: ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription_links_ComingSoonPageDescriptionLinks;
  };

export type ComingSoonPageFragment_ComingSoonPage_previewLocationsCollection_ComingSoonPagePreviewLocationsCollection_items_PreviewLocations =
  {
    __typename?: 'PreviewLocations';
    city?: string | null | undefined;
    address1?: string | null | undefined;
    address2?: string | null | undefined;
  };

export type ComingSoonPageFragment_ComingSoonPage_previewLocationsCollection_ComingSoonPagePreviewLocationsCollection =
  {
    __typename?: 'ComingSoonPagePreviewLocationsCollection';
    items: Array<
      | ComingSoonPageFragment_ComingSoonPage_previewLocationsCollection_ComingSoonPagePreviewLocationsCollection_items_PreviewLocations
      | null
      | undefined
    >;
  };

export type ComingSoonPageFragment = {
  __typename?: 'ComingSoonPage';
  header?: string | null | undefined;
  slug?: string | null | undefined;
  headerImage?:
    | ComingSoonPageFragment_ComingSoonPage_headerImage_Asset
    | null
    | undefined;
  description?:
    | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription
    | null
    | undefined;
  previewLocationsCollection?:
    | ComingSoonPageFragment_ComingSoonPage_previewLocationsCollection_ComingSoonPagePreviewLocationsCollection
    | null
    | undefined;
};

export type ContactCardFragment_ContactCard_contactDescription_ContactCardContactDescription =
  { __typename?: 'ContactCardContactDescription'; json: any };

export type ContactCardFragment_ContactCard_contactInfo_ContactInfoLink = {
  __typename?: 'ContactInfoLink';
  contactInfo?: string | null | undefined;
  contactInfoType?: string | null | undefined;
};

export type ContactCardFragment_ContactCard_icon_DribeIcon = {
  __typename?: 'DribeIcon';
  iconName?: string | null | undefined;
};

export type ContactCardFragment_ContactCard_underlinedSubLink_Link = {
  __typename?: 'Link';
  title?: string | null | undefined;
  url?: string | null | undefined;
};

export type ContactCardFragment = {
  __typename?: 'ContactCard';
  header?: string | null | undefined;
  contactDescription?:
    | ContactCardFragment_ContactCard_contactDescription_ContactCardContactDescription
    | null
    | undefined;
  contactInfo?:
    | ContactCardFragment_ContactCard_contactInfo_ContactInfoLink
    | null
    | undefined;
  icon?: ContactCardFragment_ContactCard_icon_DribeIcon | null | undefined;
  underlinedSubLink?:
    | ContactCardFragment_ContactCard_underlinedSubLink_Link
    | null
    | undefined;
};

export type ContactPageB2BFragment_ContactPageB2B_contactInfoCollection_ContactPageB2BContactInfoCollection_items_ContactCard =
  {
    __typename?: 'ContactCard';
    header?: string | null | undefined;
    contactDescription?:
      | ContactCardFragment_ContactCard_contactDescription_ContactCardContactDescription
      | null
      | undefined;
    contactInfo?:
      | ContactCardFragment_ContactCard_contactInfo_ContactInfoLink
      | null
      | undefined;
    icon?: ContactCardFragment_ContactCard_icon_DribeIcon | null | undefined;
    underlinedSubLink?:
      | ContactCardFragment_ContactCard_underlinedSubLink_Link
      | null
      | undefined;
  };

export type ContactPageB2BFragment_ContactPageB2B_contactInfoCollection_ContactPageB2BContactInfoCollection =
  {
    __typename?: 'ContactPageB2BContactInfoCollection';
    items: Array<
      | ContactPageB2BFragment_ContactPageB2B_contactInfoCollection_ContactPageB2BContactInfoCollection_items_ContactCard
      | null
      | undefined
    >;
  };

export type ContactPageB2BFragment_ContactPageB2B_submitDescription_ContactPageB2BSubmitDescription =
  { __typename?: 'ContactPageB2BSubmitDescription'; json: any };

export type ContactPageB2BFragment_ContactPageB2B_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type ContactPageB2BFragment_ContactPageB2B_contactForm_DynamicForm = {
  __typename: 'DynamicForm';
  formIdentifierTag?: string | null | undefined;
  submitButtonLabel?: string | null | undefined;
  formTextContent?:
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formTextContent_DynamicFormFormTextContent
    | null
    | undefined;
  formFieldsCollection?:
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection
    | null
    | undefined;
  successfulSubmitMessage?:
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_successfulSubmitMessage_DynamicFormSuccessfulSubmitMessage
    | null
    | undefined;
};

export type ContactPageB2BFragment = {
  __typename?: 'ContactPageB2B';
  title?: string | null | undefined;
  featureDescription?: string | null | undefined;
  featureList?: Array<string | null | undefined> | null | undefined;
  submitTitle?: string | null | undefined;
  companyIdInputLabel?: string | null | undefined;
  companyIdRequired?: string | null | undefined;
  slug?: string | null | undefined;
  contactInfoCollection?:
    | ContactPageB2BFragment_ContactPageB2B_contactInfoCollection_ContactPageB2BContactInfoCollection
    | null
    | undefined;
  submitDescription?:
    | ContactPageB2BFragment_ContactPageB2B_submitDescription_ContactPageB2BSubmitDescription
    | null
    | undefined;
  seoMetadata?:
    | ContactPageB2BFragment_ContactPageB2B_seoMetadata_SeoMetadata
    | null
    | undefined;
  contactForm?:
    | ContactPageB2BFragment_ContactPageB2B_contactForm_DynamicForm
    | null
    | undefined;
};

export type ContactPageFragment_ContactPage_contactSubDescription_ContactPageContactSubDescription =
  { __typename?: 'ContactPageContactSubDescription'; json: any };

export type ContactPageFragment_ContactPage_contactCardsCollection_ContactPageContactCardsCollection_items_ContactCard =
  {
    __typename?: 'ContactCard';
    header?: string | null | undefined;
    contactDescription?:
      | ContactCardFragment_ContactCard_contactDescription_ContactCardContactDescription
      | null
      | undefined;
    contactInfo?:
      | ContactCardFragment_ContactCard_contactInfo_ContactInfoLink
      | null
      | undefined;
    icon?: ContactCardFragment_ContactCard_icon_DribeIcon | null | undefined;
    underlinedSubLink?:
      | ContactCardFragment_ContactCard_underlinedSubLink_Link
      | null
      | undefined;
  };

export type ContactPageFragment_ContactPage_contactCardsCollection_ContactPageContactCardsCollection =
  {
    __typename?: 'ContactPageContactCardsCollection';
    items: Array<
      | ContactPageFragment_ContactPage_contactCardsCollection_ContactPageContactCardsCollection_items_ContactCard
      | null
      | undefined
    >;
  };

export type ContactPageFragment_ContactPage_accentBox_ContactPageAccentBox = {
  __typename?: 'ContactPageAccentBox';
  json: any;
};

export type ContactPageFragment_ContactPage_assistanceAndDamagesImage_Asset = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type ContactPageFragment_ContactPage_assistanceAndDamagesSubDesc_ContactPageAssistanceAndDamagesSubDesc =
  { __typename?: 'ContactPageAssistanceAndDamagesSubDesc'; json: any };

export type ContactPageFragment_ContactPage_assistanceAndDamagesArrowLink_Link =
  {
    __typename?: 'Link';
    title?: string | null | undefined;
    url?: string | null | undefined;
  };

export type ContactPageFragment_ContactPage_assistanceAndDamagesContactCardsCollection_ContactPageAssistanceAndDamagesContactCardsCollection_items_ContactCard =
  {
    __typename?: 'ContactCard';
    header?: string | null | undefined;
    contactDescription?:
      | ContactCardFragment_ContactCard_contactDescription_ContactCardContactDescription
      | null
      | undefined;
    contactInfo?:
      | ContactCardFragment_ContactCard_contactInfo_ContactInfoLink
      | null
      | undefined;
    icon?: ContactCardFragment_ContactCard_icon_DribeIcon | null | undefined;
    underlinedSubLink?:
      | ContactCardFragment_ContactCard_underlinedSubLink_Link
      | null
      | undefined;
  };

export type ContactPageFragment_ContactPage_assistanceAndDamagesContactCardsCollection_ContactPageAssistanceAndDamagesContactCardsCollection =
  {
    __typename?: 'ContactPageAssistanceAndDamagesContactCardsCollection';
    items: Array<
      | ContactPageFragment_ContactPage_assistanceAndDamagesContactCardsCollection_ContactPageAssistanceAndDamagesContactCardsCollection_items_ContactCard
      | null
      | undefined
    >;
  };

export type ContactPageFragment_ContactPage_availableGaragesCardsCollection_ContactPageAvailableGaragesCardsCollection_items_AvailableGaragesCard_garagesList_AvailableGaragesCardGaragesList =
  { __typename?: 'AvailableGaragesCardGaragesList'; json: any };

export type ContactPageFragment_ContactPage_availableGaragesCardsCollection_ContactPageAvailableGaragesCardsCollection_items_AvailableGaragesCard =
  {
    __typename?: 'AvailableGaragesCard';
    header?: string | null | undefined;
    garagesList?:
      | ContactPageFragment_ContactPage_availableGaragesCardsCollection_ContactPageAvailableGaragesCardsCollection_items_AvailableGaragesCard_garagesList_AvailableGaragesCardGaragesList
      | null
      | undefined;
  };

export type ContactPageFragment_ContactPage_availableGaragesCardsCollection_ContactPageAvailableGaragesCardsCollection =
  {
    __typename?: 'ContactPageAvailableGaragesCardsCollection';
    items: Array<
      | ContactPageFragment_ContactPage_availableGaragesCardsCollection_ContactPageAvailableGaragesCardsCollection_items_AvailableGaragesCard
      | null
      | undefined
    >;
  };

export type ContactPageFragment_ContactPage_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type ContactPageFragment = {
  __typename?: 'ContactPage';
  title?: string | null | undefined;
  contactHeader?: string | null | undefined;
  assistanceAndDamagesHeader?: string | null | undefined;
  findGaragesHeader?: string | null | undefined;
  slug?: string | null | undefined;
  contactSubDescription?:
    | ContactPageFragment_ContactPage_contactSubDescription_ContactPageContactSubDescription
    | null
    | undefined;
  contactCardsCollection?:
    | ContactPageFragment_ContactPage_contactCardsCollection_ContactPageContactCardsCollection
    | null
    | undefined;
  accentBox?:
    | ContactPageFragment_ContactPage_accentBox_ContactPageAccentBox
    | null
    | undefined;
  assistanceAndDamagesImage?:
    | ContactPageFragment_ContactPage_assistanceAndDamagesImage_Asset
    | null
    | undefined;
  assistanceAndDamagesSubDesc?:
    | ContactPageFragment_ContactPage_assistanceAndDamagesSubDesc_ContactPageAssistanceAndDamagesSubDesc
    | null
    | undefined;
  assistanceAndDamagesArrowLink?:
    | ContactPageFragment_ContactPage_assistanceAndDamagesArrowLink_Link
    | null
    | undefined;
  assistanceAndDamagesContactCardsCollection?:
    | ContactPageFragment_ContactPage_assistanceAndDamagesContactCardsCollection_ContactPageAssistanceAndDamagesContactCardsCollection
    | null
    | undefined;
  availableGaragesCardsCollection?:
    | ContactPageFragment_ContactPage_availableGaragesCardsCollection_ContactPageAvailableGaragesCardsCollection
    | null
    | undefined;
  seoMetadata?:
    | ContactPageFragment_ContactPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type DamagesContactPageFragment_DamagesContactPage_description_DamagesContactPageDescription =
  { __typename?: 'DamagesContactPageDescription'; json: any };

export type DamagesContactPageFragment_DamagesContactPage_damagesContactCardsCollection_DamagesContactPageDamagesContactCardsCollection_items_ContactCard =
  {
    __typename?: 'ContactCard';
    header?: string | null | undefined;
    contactDescription?:
      | ContactCardFragment_ContactCard_contactDescription_ContactCardContactDescription
      | null
      | undefined;
    contactInfo?:
      | ContactCardFragment_ContactCard_contactInfo_ContactInfoLink
      | null
      | undefined;
    icon?: ContactCardFragment_ContactCard_icon_DribeIcon | null | undefined;
    underlinedSubLink?:
      | ContactCardFragment_ContactCard_underlinedSubLink_Link
      | null
      | undefined;
  };

export type DamagesContactPageFragment_DamagesContactPage_damagesContactCardsCollection_DamagesContactPageDamagesContactCardsCollection =
  {
    __typename?: 'DamagesContactPageDamagesContactCardsCollection';
    items: Array<
      | DamagesContactPageFragment_DamagesContactPage_damagesContactCardsCollection_DamagesContactPageDamagesContactCardsCollection_items_ContactCard
      | null
      | undefined
    >;
  };

export type DamagesContactPageFragment_DamagesContactPage_accentBox_DamagesContactPageAccentBox =
  { __typename?: 'DamagesContactPageAccentBox'; json: any };

export type DamagesContactPageFragment_DamagesContactPage_startProblemsDesc1_DamagesContactPageStartProblemsDesc1 =
  { __typename?: 'DamagesContactPageStartProblemsDesc1'; json: any };

export type DamagesContactPageFragment_DamagesContactPage_brandContactsCollection_DamagesContactPageBrandContactsCollection_items_BrandContact_brandContactInfo_ContactInfoLink =
  {
    __typename?: 'ContactInfoLink';
    contactInfo?: string | null | undefined;
    contactInfoType?: string | null | undefined;
  };

export type DamagesContactPageFragment_DamagesContactPage_brandContactsCollection_DamagesContactPageBrandContactsCollection_items_BrandContact =
  {
    __typename?: 'BrandContact';
    brandName?: string | null | undefined;
    brandContactInfo?:
      | DamagesContactPageFragment_DamagesContactPage_brandContactsCollection_DamagesContactPageBrandContactsCollection_items_BrandContact_brandContactInfo_ContactInfoLink
      | null
      | undefined;
  };

export type DamagesContactPageFragment_DamagesContactPage_brandContactsCollection_DamagesContactPageBrandContactsCollection =
  {
    __typename?: 'DamagesContactPageBrandContactsCollection';
    items: Array<
      | DamagesContactPageFragment_DamagesContactPage_brandContactsCollection_DamagesContactPageBrandContactsCollection_items_BrandContact
      | null
      | undefined
    >;
  };

export type DamagesContactPageFragment_DamagesContactPage_startProblemsDesc2_DamagesContactPageStartProblemsDesc2 =
  { __typename?: 'DamagesContactPageStartProblemsDesc2'; json: any };

export type DamagesContactPageFragment_DamagesContactPage_seoMetadata_SeoMetadata =
  {
    __typename?: 'SeoMetadata';
    internalName?: string | null | undefined;
    title?: string | null | undefined;
    description?: string | null | undefined;
    keywords?: Array<string | null | undefined> | null | undefined;
    canonical?: string | null | undefined;
    noindex?: boolean | null | undefined;
    nofollow?: boolean | null | undefined;
    openGraphTitle?: string | null | undefined;
    openGraphDescription?: string | null | undefined;
    openGraphUrl?: string | null | undefined;
    openGraphType?: string | null | undefined;
    twitterCard?: string | null | undefined;
    twitterSite?: string | null | undefined;
    sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
    openGraphImage?:
      | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
      | null
      | undefined;
  };

export type DamagesContactPageFragment = {
  __typename?: 'DamagesContactPage';
  title?: string | null | undefined;
  header?: string | null | undefined;
  startProblemsHeader?: string | null | undefined;
  slug?: string | null | undefined;
  description?:
    | DamagesContactPageFragment_DamagesContactPage_description_DamagesContactPageDescription
    | null
    | undefined;
  damagesContactCardsCollection?:
    | DamagesContactPageFragment_DamagesContactPage_damagesContactCardsCollection_DamagesContactPageDamagesContactCardsCollection
    | null
    | undefined;
  accentBox?:
    | DamagesContactPageFragment_DamagesContactPage_accentBox_DamagesContactPageAccentBox
    | null
    | undefined;
  startProblemsDesc1?:
    | DamagesContactPageFragment_DamagesContactPage_startProblemsDesc1_DamagesContactPageStartProblemsDesc1
    | null
    | undefined;
  brandContactsCollection?:
    | DamagesContactPageFragment_DamagesContactPage_brandContactsCollection_DamagesContactPageBrandContactsCollection
    | null
    | undefined;
  startProblemsDesc2?:
    | DamagesContactPageFragment_DamagesContactPage_startProblemsDesc2_DamagesContactPageStartProblemsDesc2
    | null
    | undefined;
  seoMetadata?:
    | DamagesContactPageFragment_DamagesContactPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type DamagesPageFragment_DamagesPage_damagesCardsCollection_DamagesPageDamagesCardsCollection_items_DamagesCard_image_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type DamagesPageFragment_DamagesPage_damagesCardsCollection_DamagesPageDamagesCardsCollection_items_DamagesCard_description_DamagesCardDescription =
  { __typename?: 'DamagesCardDescription'; json: any };

export type DamagesPageFragment_DamagesPage_damagesCardsCollection_DamagesPageDamagesCardsCollection_items_DamagesCard_arrowLink_Link =
  {
    __typename?: 'Link';
    title?: string | null | undefined;
    url?: string | null | undefined;
  };

export type DamagesPageFragment_DamagesPage_damagesCardsCollection_DamagesPageDamagesCardsCollection_items_DamagesCard =
  {
    __typename?: 'DamagesCard';
    header?: string | null | undefined;
    image?:
      | DamagesPageFragment_DamagesPage_damagesCardsCollection_DamagesPageDamagesCardsCollection_items_DamagesCard_image_Asset
      | null
      | undefined;
    description?:
      | DamagesPageFragment_DamagesPage_damagesCardsCollection_DamagesPageDamagesCardsCollection_items_DamagesCard_description_DamagesCardDescription
      | null
      | undefined;
    arrowLink?:
      | DamagesPageFragment_DamagesPage_damagesCardsCollection_DamagesPageDamagesCardsCollection_items_DamagesCard_arrowLink_Link
      | null
      | undefined;
  };

export type DamagesPageFragment_DamagesPage_damagesCardsCollection_DamagesPageDamagesCardsCollection =
  {
    __typename?: 'DamagesPageDamagesCardsCollection';
    items: Array<
      | DamagesPageFragment_DamagesPage_damagesCardsCollection_DamagesPageDamagesCardsCollection_items_DamagesCard
      | null
      | undefined
    >;
  };

export type DamagesPageFragment_DamagesPage_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type DamagesPageFragment = {
  __typename?: 'DamagesPage';
  title?: string | null | undefined;
  header?: string | null | undefined;
  slug?: string | null | undefined;
  damagesCardsCollection?:
    | DamagesPageFragment_DamagesPage_damagesCardsCollection_DamagesPageDamagesCardsCollection
    | null
    | undefined;
  seoMetadata?:
    | DamagesPageFragment_DamagesPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type DamagesPolicyPageFragment_DamagesPolicyPage_policyContentCollection_DamagesPolicyPagePolicyContentCollection_items_DamagesPolicyPageContentBox_content_DamagesPolicyPageContentBoxContent =
  { __typename?: 'DamagesPolicyPageContentBoxContent'; json: any };

export type DamagesPolicyPageFragment_DamagesPolicyPage_policyContentCollection_DamagesPolicyPagePolicyContentCollection_items_DamagesPolicyPageContentBox_arrowLink_Link =
  {
    __typename?: 'Link';
    title?: string | null | undefined;
    url?: string | null | undefined;
  };

export type DamagesPolicyPageFragment_DamagesPolicyPage_policyContentCollection_DamagesPolicyPagePolicyContentCollection_items_DamagesPolicyPageContentBox =
  {
    __typename?: 'DamagesPolicyPageContentBox';
    header?: string | null | undefined;
    content?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_policyContentCollection_DamagesPolicyPagePolicyContentCollection_items_DamagesPolicyPageContentBox_content_DamagesPolicyPageContentBoxContent
      | null
      | undefined;
    arrowLink?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_policyContentCollection_DamagesPolicyPagePolicyContentCollection_items_DamagesPolicyPageContentBox_arrowLink_Link
      | null
      | undefined;
  };

export type DamagesPolicyPageFragment_DamagesPolicyPage_policyContentCollection_DamagesPolicyPagePolicyContentCollection =
  {
    __typename?: 'DamagesPolicyPagePolicyContentCollection';
    items: Array<
      | DamagesPolicyPageFragment_DamagesPolicyPage_policyContentCollection_DamagesPolicyPagePolicyContentCollection_items_DamagesPolicyPageContentBox
      | null
      | undefined
    >;
  };

export type DamagesPolicyPageFragment_DamagesPolicyPage_collapsableContentCollection_DamagesPolicyPageCollapsableContentCollection_items_CollapsableContainer_content_CollapsableContainerContent =
  { __typename?: 'CollapsableContainerContent'; json: any };

export type DamagesPolicyPageFragment_DamagesPolicyPage_collapsableContentCollection_DamagesPolicyPageCollapsableContentCollection_items_CollapsableContainer =
  {
    __typename?: 'CollapsableContainer';
    header?: string | null | undefined;
    content?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_collapsableContentCollection_DamagesPolicyPageCollapsableContentCollection_items_CollapsableContainer_content_CollapsableContainerContent
      | null
      | undefined;
  };

export type DamagesPolicyPageFragment_DamagesPolicyPage_collapsableContentCollection_DamagesPolicyPageCollapsableContentCollection =
  {
    __typename?: 'DamagesPolicyPageCollapsableContentCollection';
    items: Array<
      | DamagesPolicyPageFragment_DamagesPolicyPage_collapsableContentCollection_DamagesPolicyPageCollapsableContentCollection_items_CollapsableContainer
      | null
      | undefined
    >;
  };

export type DamagesPolicyPageFragment_DamagesPolicyPage_insuranceCoverDescription_DamagesPolicyPageInsuranceCoverDescription =
  { __typename?: 'DamagesPolicyPageInsuranceCoverDescription'; json: any };

export type DamagesPolicyPageFragment_DamagesPolicyPage_insuranceAccentBox_DamagesPolicyPageInsuranceAccentBox =
  { __typename?: 'DamagesPolicyPageInsuranceAccentBox'; json: any };

export type DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionScreenshot_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionDescription_DamagesPolicyPageAssistedInspectionDescription =
  { __typename?: 'DamagesPolicyPageAssistedInspectionDescription'; json: any };

export type DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionStepsCollection_DamagesPolicyPageAssistedInspectionStepsCollection_items_InspectionStep_stepDescription_InspectionStepStepDescription =
  { __typename?: 'InspectionStepStepDescription'; json: any };

export type DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionStepsCollection_DamagesPolicyPageAssistedInspectionStepsCollection_items_InspectionStep =
  {
    __typename?: 'InspectionStep';
    stepHeader?: string | null | undefined;
    stepDescription?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionStepsCollection_DamagesPolicyPageAssistedInspectionStepsCollection_items_InspectionStep_stepDescription_InspectionStepStepDescription
      | null
      | undefined;
  };

export type DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionStepsCollection_DamagesPolicyPageAssistedInspectionStepsCollection =
  {
    __typename?: 'DamagesPolicyPageAssistedInspectionStepsCollection';
    items: Array<
      | DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionStepsCollection_DamagesPolicyPageAssistedInspectionStepsCollection_items_InspectionStep
      | null
      | undefined
    >;
  };

export type DamagesPolicyPageFragment_DamagesPolicyPage_faqDescription_DamagesPolicyPageFaqDescription =
  { __typename?: 'DamagesPolicyPageFaqDescription'; json: any };

export type DamagesPolicyPageFragment_DamagesPolicyPage_faqButtonLink_Link = {
  __typename?: 'Link';
  title?: string | null | undefined;
  url?: string | null | undefined;
};

export type DamagesPolicyPageFragment_DamagesPolicyPage_seoMetadata_SeoMetadata =
  {
    __typename?: 'SeoMetadata';
    internalName?: string | null | undefined;
    title?: string | null | undefined;
    description?: string | null | undefined;
    keywords?: Array<string | null | undefined> | null | undefined;
    canonical?: string | null | undefined;
    noindex?: boolean | null | undefined;
    nofollow?: boolean | null | undefined;
    openGraphTitle?: string | null | undefined;
    openGraphDescription?: string | null | undefined;
    openGraphUrl?: string | null | undefined;
    openGraphType?: string | null | undefined;
    twitterCard?: string | null | undefined;
    twitterSite?: string | null | undefined;
    sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
    openGraphImage?:
      | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
      | null
      | undefined;
  };

export type DamagesPolicyPageFragment = {
  __typename?: 'DamagesPolicyPage';
  title?: string | null | undefined;
  collapseContainerHeader?: string | null | undefined;
  insuranceCoverHeader?: string | null | undefined;
  withAssistedInspection?: boolean | null | undefined;
  assistedInspectionHeader?: string | null | undefined;
  howItWorksHeader?: string | null | undefined;
  faqHeader?: string | null | undefined;
  slug?: string | null | undefined;
  policyContentCollection?:
    | DamagesPolicyPageFragment_DamagesPolicyPage_policyContentCollection_DamagesPolicyPagePolicyContentCollection
    | null
    | undefined;
  collapsableContentCollection?:
    | DamagesPolicyPageFragment_DamagesPolicyPage_collapsableContentCollection_DamagesPolicyPageCollapsableContentCollection
    | null
    | undefined;
  insuranceCoverDescription?:
    | DamagesPolicyPageFragment_DamagesPolicyPage_insuranceCoverDescription_DamagesPolicyPageInsuranceCoverDescription
    | null
    | undefined;
  insuranceAccentBox?:
    | DamagesPolicyPageFragment_DamagesPolicyPage_insuranceAccentBox_DamagesPolicyPageInsuranceAccentBox
    | null
    | undefined;
  assistedInspectionScreenshot?:
    | DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionScreenshot_Asset
    | null
    | undefined;
  assistedInspectionDescription?:
    | DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionDescription_DamagesPolicyPageAssistedInspectionDescription
    | null
    | undefined;
  assistedInspectionStepsCollection?:
    | DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionStepsCollection_DamagesPolicyPageAssistedInspectionStepsCollection
    | null
    | undefined;
  faqDescription?:
    | DamagesPolicyPageFragment_DamagesPolicyPage_faqDescription_DamagesPolicyPageFaqDescription
    | null
    | undefined;
  faqButtonLink?:
    | DamagesPolicyPageFragment_DamagesPolicyPage_faqButtonLink_Link
    | null
    | undefined;
  seoMetadata?:
    | DamagesPolicyPageFragment_DamagesPolicyPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type DemoPageFragment_DemoPage_initialProfileImage_Asset = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type DemoPageFragment_DemoPage_initialDescription_DemoPageInitialDescription =
  { __typename?: 'DemoPageInitialDescription'; json: any };

export type DemoPageFragment_DemoPage_startImage_Asset = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type DemoPageFragment_DemoPage_slidesCollection_DemoPageSlidesCollection_items_DemoSlide_image_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type DemoPageFragment_DemoPage_slidesCollection_DemoPageSlidesCollection_items_DemoSlide =
  {
    __typename?: 'DemoSlide';
    title?: string | null | undefined;
    description?: string | null | undefined;
    image?:
      | DemoPageFragment_DemoPage_slidesCollection_DemoPageSlidesCollection_items_DemoSlide_image_Asset
      | null
      | undefined;
  };

export type DemoPageFragment_DemoPage_slidesCollection_DemoPageSlidesCollection =
  {
    __typename?: 'DemoPageSlidesCollection';
    items: Array<
      | DemoPageFragment_DemoPage_slidesCollection_DemoPageSlidesCollection_items_DemoSlide
      | null
      | undefined
    >;
  };

export type DemoPageFragment_DemoPage_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type DemoPageFragment = {
  __typename?: 'DemoPage';
  title?: string | null | undefined;
  subTitle?: string | null | undefined;
  initialTitle?: string | null | undefined;
  slug?: string | null | undefined;
  initialProfileImage?:
    | DemoPageFragment_DemoPage_initialProfileImage_Asset
    | null
    | undefined;
  initialDescription?:
    | DemoPageFragment_DemoPage_initialDescription_DemoPageInitialDescription
    | null
    | undefined;
  startImage?: DemoPageFragment_DemoPage_startImage_Asset | null | undefined;
  slidesCollection?:
    | DemoPageFragment_DemoPage_slidesCollection_DemoPageSlidesCollection
    | null
    | undefined;
  seoMetadata?:
    | DemoPageFragment_DemoPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type DownloadPageFragment_DownloadPage_appPicture_Asset = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type DownloadPageFragment_DownloadPage_headerText_DownloadPageHeaderText =
  { __typename?: 'DownloadPageHeaderText'; json: any };

export type DownloadPageFragment_DownloadPage_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type DownloadPageFragment = {
  __typename?: 'DownloadPage';
  title?: string | null | undefined;
  stepperFindCar?: string | null | undefined;
  stepperScanQr?: string | null | undefined;
  stepperCreateAccount?: string | null | undefined;
  stepperBookCar?: string | null | undefined;
  downloadButton?: string | null | undefined;
  slug?: string | null | undefined;
  appPicture?:
    | DownloadPageFragment_DownloadPage_appPicture_Asset
    | null
    | undefined;
  headerText?:
    | DownloadPageFragment_DownloadPage_headerText_DownloadPageHeaderText
    | null
    | undefined;
  seoMetadata?:
    | DownloadPageFragment_DownloadPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type DynamicFormFragment = {
  __typename?: 'DynamicForm';
  formIdentifierTag?: string | null | undefined;
  submitButtonLabel?: string | null | undefined;
  formTextContent?:
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formTextContent_DynamicFormFormTextContent
    | null
    | undefined;
  formFieldsCollection?:
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection
    | null
    | undefined;
  successfulSubmitMessage?:
    | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_successfulSubmitMessage_DynamicFormSuccessfulSubmitMessage
    | null
    | undefined;
};

export type FaqEntryFragment_FaqEntry_sys_Sys = {
  __typename?: 'Sys';
  id: string;
};

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_assets_FaqEntryTextAssets_block_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_assets_FaqEntryTextAssets =
  {
    __typename?: 'FaqEntryTextAssets';
    block: Array<
      | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_assets_FaqEntryTextAssets_block_Asset
      | null
      | undefined
    >;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement =
  {
    __typename: 'Announcement';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_AvailableGaragesCard =
  {
    __typename: 'AvailableGaragesCard';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_B2BShowcaseModel =
  {
    __typename: 'B2BShowcaseModel';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_BlogEntry =
  {
    __typename: 'BlogEntry';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_BlogPage =
  {
    __typename: 'BlogPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_BlogTopics =
  {
    __typename: 'BlogTopics';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_BrandContact =
  {
    __typename: 'BrandContact';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_CampaignEntry =
  {
    __typename: 'CampaignEntry';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_CollapsableContainer =
  {
    __typename: 'CollapsableContainer';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_ComingSoonPage =
  {
    __typename: 'ComingSoonPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_ContactCard =
  {
    __typename: 'ContactCard';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_ContactInfoLink =
  {
    __typename: 'ContactInfoLink';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_ContactPage =
  {
    __typename: 'ContactPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_ContactPageB2B =
  {
    __typename: 'ContactPageB2B';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DamagesCard =
  {
    __typename: 'DamagesCard';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DamagesContactPage =
  {
    __typename: 'DamagesContactPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DamagesPage =
  {
    __typename: 'DamagesPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DamagesPolicyPage =
  {
    __typename: 'DamagesPolicyPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DamagesPolicyPageContentBox =
  {
    __typename: 'DamagesPolicyPageContentBox';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DemoPage =
  {
    __typename: 'DemoPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DemoSlide =
  {
    __typename: 'DemoSlide';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DownloadPage =
  {
    __typename: 'DownloadPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DribeIcon =
  {
    __typename: 'DribeIcon';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DynamicForm =
  {
    __typename: 'DynamicForm';
    formIdentifierTag?: string | null | undefined;
    submitButtonLabel?: string | null | undefined;
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
    formTextContent?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formTextContent_DynamicFormFormTextContent
      | null
      | undefined;
    formFieldsCollection?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection
      | null
      | undefined;
    successfulSubmitMessage?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_successfulSubmitMessage_DynamicFormSuccessfulSubmitMessage
      | null
      | undefined;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_FaqCategory =
  {
    __typename: 'FaqCategory';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_FaqEntry =
  {
    __typename: 'FaqEntry';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_FaqPage =
  {
    __typename: 'FaqPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Feature =
  {
    __typename: 'Feature';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Footer =
  {
    __typename: 'Footer';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_FormField =
  {
    __typename: 'FormField';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_GaragePage =
  {
    __typename: 'GaragePage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_InspectionStep =
  {
    __typename: 'InspectionStep';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_LandingPage =
  {
    __typename: 'LandingPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_LandingPageB2B =
  {
    __typename: 'LandingPageB2B';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_LegalEntry =
  {
    __typename: 'LegalEntry';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_LegalPage =
  {
    __typename: 'LegalPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Link =
  {
    __typename: 'Link';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_LinkGroup =
  {
    __typename: 'LinkGroup';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Menu =
  {
    __typename: 'Menu';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_NewsletterContent =
  {
    __typename: 'NewsletterContent';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_OutOfStockPage =
  {
    __typename: 'OutOfStockPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Partners =
  {
    __typename: 'Partners';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_PreviewLocations =
  {
    __typename: 'PreviewLocations';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_PricingPage =
  {
    __typename: 'PricingPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_PricingRideCard =
  {
    __typename: 'PricingRideCard';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_ProductPage =
  {
    __typename: 'ProductPage';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_SeoMetadata =
  {
    __typename: 'SeoMetadata';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_SiteSwitcher =
  {
    __typename: 'SiteSwitcher';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_TableRow =
  {
    __typename: 'TableRow';
    sys: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement_sys_Sys;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block =

    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Announcement
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_AvailableGaragesCard
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_B2BShowcaseModel
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_BlogEntry
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_BlogPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_BlogTopics
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_BrandContact
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_CampaignEntry
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_CollapsableContainer
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_ComingSoonPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_ContactCard
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_ContactInfoLink
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_ContactPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_ContactPageB2B
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DamagesCard
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DamagesContactPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DamagesPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DamagesPolicyPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DamagesPolicyPageContentBox
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DemoPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DemoSlide
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DownloadPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DribeIcon
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_DynamicForm
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_FaqCategory
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_FaqEntry
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_FaqPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Feature
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Footer
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_FormField
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_GaragePage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_InspectionStep
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_LandingPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_LandingPageB2B
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_LegalEntry
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_LegalPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Link
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_LinkGroup
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Menu
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_NewsletterContent
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_OutOfStockPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_Partners
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_PreviewLocations
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_PricingPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_PricingRideCard
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_ProductPage
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_SeoMetadata
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_SiteSwitcher
    | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block_TableRow;

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries =
  {
    __typename?: 'FaqEntryTextEntries';
    block: Array<
      | FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries_block
      | null
      | undefined
    >;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks =
  {
    __typename?: 'FaqEntryTextLinks';
    assets: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_assets_FaqEntryTextAssets;
    entries: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks_entries_FaqEntryTextEntries;
  };

export type FaqEntryFragment_FaqEntry_text_FaqEntryText = {
  __typename?: 'FaqEntryText';
  json: any;
  links: FaqEntryFragment_FaqEntry_text_FaqEntryText_links_FaqEntryTextLinks;
};

export type FaqEntryFragment_FaqEntry_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type FaqEntryFragment = {
  __typename?: 'FaqEntry';
  title?: string | null | undefined;
  path?: string | null | undefined;
  slug?: string | null | undefined;
  site?: Array<string | null | undefined> | null | undefined;
  sys: FaqEntryFragment_FaqEntry_sys_Sys;
  text?: FaqEntryFragment_FaqEntry_text_FaqEntryText | null | undefined;
  seoMetadata?:
    | FaqEntryFragment_FaqEntry_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type FaqPageFragment_FaqPage_sys_Sys = {
  __typename?: 'Sys';
  id: string;
};

export type FaqPageFragment_FaqPage_headerBodyText_FaqPageHeaderBodyText = {
  __typename?: 'FaqPageHeaderBodyText';
  json: any;
};

export type FaqPageFragment_FaqPage_headerImage_Asset = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection_items_FaqCategory_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection_items_FaqCategory_icon_DribeIcon =
  { __typename?: 'DribeIcon'; iconName?: string | null | undefined };

export type FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection_items_FaqCategory_faqEntriesCollection_FaqCategoryFaqEntriesCollection_items_FaqEntry_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection_items_FaqCategory_faqEntriesCollection_FaqCategoryFaqEntriesCollection_items_FaqEntry =
  {
    __typename?: 'FaqEntry';
    title?: string | null | undefined;
    slug?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
    sys: FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection_items_FaqCategory_faqEntriesCollection_FaqCategoryFaqEntriesCollection_items_FaqEntry_sys_Sys;
  };

export type FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection_items_FaqCategory_faqEntriesCollection_FaqCategoryFaqEntriesCollection =
  {
    __typename?: 'FaqCategoryFaqEntriesCollection';
    items: Array<
      | FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection_items_FaqCategory_faqEntriesCollection_FaqCategoryFaqEntriesCollection_items_FaqEntry
      | null
      | undefined
    >;
  };

export type FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection_items_FaqCategory =
  {
    __typename?: 'FaqCategory';
    title?: string | null | undefined;
    sys: FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection_items_FaqCategory_sys_Sys;
    icon?:
      | FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection_items_FaqCategory_icon_DribeIcon
      | null
      | undefined;
    faqEntriesCollection?:
      | FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection_items_FaqCategory_faqEntriesCollection_FaqCategoryFaqEntriesCollection
      | null
      | undefined;
  };

export type FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection =
  {
    __typename?: 'FaqPageFaqCategoriesCollection';
    items: Array<
      | FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection_items_FaqCategory
      | null
      | undefined
    >;
  };

export type FaqPageFragment_FaqPage_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type FaqPageFragment = {
  __typename?: 'FaqPage';
  title?: string | null | undefined;
  headerTitle?: string | null | undefined;
  slug?: string | null | undefined;
  sys: FaqPageFragment_FaqPage_sys_Sys;
  headerBodyText?:
    | FaqPageFragment_FaqPage_headerBodyText_FaqPageHeaderBodyText
    | null
    | undefined;
  headerImage?: FaqPageFragment_FaqPage_headerImage_Asset | null | undefined;
  faqCategoriesCollection?:
    | FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection
    | null
    | undefined;
  seoMetadata?:
    | FaqPageFragment_FaqPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type FooterFragment_Footer_customerCareContactInfo_FooterCustomerCareContactInfo =
  { __typename?: 'FooterCustomerCareContactInfo'; json: any };

export type FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_linksCollection_LinkGroupLinksCollection_items_Link_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_linksCollection_LinkGroupLinksCollection_items_Link =
  {
    __typename?: 'Link';
    title?: string | null | undefined;
    url?: string | null | undefined;
    sys: FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_linksCollection_LinkGroupLinksCollection_items_Link_sys_Sys;
  };

export type FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_linksCollection_LinkGroupLinksCollection =
  {
    __typename?: 'LinkGroupLinksCollection';
    items: Array<
      | FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_linksCollection_LinkGroupLinksCollection_items_Link
      | null
      | undefined
    >;
  };

export type FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_b2BLinksCollection_LinkGroupB2bLinksCollection_items_Link_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_b2BLinksCollection_LinkGroupB2bLinksCollection_items_Link =
  {
    __typename?: 'Link';
    title?: string | null | undefined;
    url?: string | null | undefined;
    sys: FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_b2BLinksCollection_LinkGroupB2bLinksCollection_items_Link_sys_Sys;
  };

export type FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_b2BLinksCollection_LinkGroupB2bLinksCollection =
  {
    __typename?: 'LinkGroupB2bLinksCollection';
    items: Array<
      | FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_b2BLinksCollection_LinkGroupB2bLinksCollection_items_Link
      | null
      | undefined
    >;
  };

export type FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup =
  {
    __typename?: 'LinkGroup';
    title?: string | null | undefined;
    sys: FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_sys_Sys;
    linksCollection?:
      | FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_linksCollection_LinkGroupLinksCollection
      | null
      | undefined;
    b2BLinksCollection?:
      | FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup_b2BLinksCollection_LinkGroupB2bLinksCollection
      | null
      | undefined;
  };

export type FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection =
  {
    __typename?: 'FooterLinkGroupsCollection';
    items: Array<
      | FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection_items_LinkGroup
      | null
      | undefined
    >;
  };

export type FooterFragment = {
  __typename?: 'Footer';
  instagramLink?: string | null | undefined;
  facebookLink?: string | null | undefined;
  linkedInLink?: string | null | undefined;
  trustpilotLink?: string | null | undefined;
  tikTokLink?: string | null | undefined;
  youTubeLink?: string | null | undefined;
  googleLink?: string | null | undefined;
  trustpilotRating?: number | null | undefined;
  facebookRating?: number | null | undefined;
  googleRating?: number | null | undefined;
  fixedDribeFooterContent?: string | null | undefined;
  customerCareContactInfo?:
    | FooterFragment_Footer_customerCareContactInfo_FooterCustomerCareContactInfo
    | null
    | undefined;
  linkGroupsCollection?:
    | FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection
    | null
    | undefined;
};

export type GaragePageFragment_GaragePage_announcement_Announcement_content_AnnouncementContent =
  { __typename?: 'AnnouncementContent'; json: any };

export type GaragePageFragment_GaragePage_announcement_Announcement = {
  __typename?: 'Announcement';
  content?:
    | GaragePageFragment_GaragePage_announcement_Announcement_content_AnnouncementContent
    | null
    | undefined;
};

export type GaragePageFragment_GaragePage_headerBodyText_GaragePageHeaderBodyText =
  { __typename?: 'GaragePageHeaderBodyText'; json: any };

export type GaragePageFragment_GaragePage_headerImage_Asset = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type GaragePageFragment_GaragePage_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type GaragePageFragment = {
  __typename?: 'GaragePage';
  title?: string | null | undefined;
  slug?: string | null | undefined;
  headerTitle?: string | null | undefined;
  whatIsIncluded?: Array<string | null | undefined> | null | undefined;
  priceText?: string | null | undefined;
  announcement?:
    | GaragePageFragment_GaragePage_announcement_Announcement
    | null
    | undefined;
  headerBodyText?:
    | GaragePageFragment_GaragePage_headerBodyText_GaragePageHeaderBodyText
    | null
    | undefined;
  headerImage?:
    | GaragePageFragment_GaragePage_headerImage_Asset
    | null
    | undefined;
  seoMetadata?:
    | GaragePageFragment_GaragePage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type LandingPageB2BFragment_LandingPageB2B_announcement_Announcement_content_AnnouncementContent =
  { __typename?: 'AnnouncementContent'; json: any };

export type LandingPageB2BFragment_LandingPageB2B_announcement_Announcement = {
  __typename?: 'Announcement';
  content?:
    | LandingPageB2BFragment_LandingPageB2B_announcement_Announcement_content_AnnouncementContent
    | null
    | undefined;
};

export type LandingPageB2BFragment_LandingPageB2B_heroImage_Asset = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type LandingPageB2BFragment_LandingPageB2B_partnerLogosCollection_AssetCollection_items_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type LandingPageB2BFragment_LandingPageB2B_partnerLogosCollection_AssetCollection =
  {
    __typename?: 'AssetCollection';
    items: Array<
      | LandingPageB2BFragment_LandingPageB2B_partnerLogosCollection_AssetCollection_items_Asset
      | null
      | undefined
    >;
  };

export type LandingPageB2BFragment_LandingPageB2B_showCaseSectionCollection_LandingPageB2BShowCaseSectionCollection_items_B2BShowcaseModel_image_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type LandingPageB2BFragment_LandingPageB2B_showCaseSectionCollection_LandingPageB2BShowCaseSectionCollection_items_B2BShowcaseModel =
  {
    __typename?: 'B2BShowcaseModel';
    title?: string | null | undefined;
    features?: Array<string | null | undefined> | null | undefined;
    urlText?: string | null | undefined;
    urlSlug?: string | null | undefined;
    image?:
      | LandingPageB2BFragment_LandingPageB2B_showCaseSectionCollection_LandingPageB2BShowCaseSectionCollection_items_B2BShowcaseModel_image_Asset
      | null
      | undefined;
  };

export type LandingPageB2BFragment_LandingPageB2B_showCaseSectionCollection_LandingPageB2BShowCaseSectionCollection =
  {
    __typename?: 'LandingPageB2BShowCaseSectionCollection';
    items: Array<
      | LandingPageB2BFragment_LandingPageB2B_showCaseSectionCollection_LandingPageB2BShowCaseSectionCollection_items_B2BShowcaseModel
      | null
      | undefined
    >;
  };

export type LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection_items_Feature_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection_items_Feature_defaultIcon_DribeIcon =
  { __typename?: 'DribeIcon'; iconName?: string | null | undefined };

export type LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection_items_Feature_defaultDescription_FeatureDefaultDescription =
  { __typename?: 'FeatureDefaultDescription'; json: any };

export type LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection_items_Feature_flipIcon_DribeIcon =
  { __typename?: 'DribeIcon'; iconName?: string | null | undefined };

export type LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection_items_Feature_flipDescription_FeatureFlipDescription =
  { __typename?: 'FeatureFlipDescription'; json: any };

export type LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection_items_Feature =
  {
    __typename?: 'Feature';
    title?: string | null | undefined;
    flipTitle?: string | null | undefined;
    visibleLinkTitle?: string | null | undefined;
    visibleLink?: string | null | undefined;
    flipVisibleLinkTitle?: string | null | undefined;
    flipVisibleLink?: string | null | undefined;
    sys: LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection_items_Feature_sys_Sys;
    defaultIcon?:
      | LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection_items_Feature_defaultIcon_DribeIcon
      | null
      | undefined;
    defaultDescription?:
      | LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection_items_Feature_defaultDescription_FeatureDefaultDescription
      | null
      | undefined;
    flipIcon?:
      | LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection_items_Feature_flipIcon_DribeIcon
      | null
      | undefined;
    flipDescription?:
      | LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection_items_Feature_flipDescription_FeatureFlipDescription
      | null
      | undefined;
  };

export type LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection =
  {
    __typename?: 'LandingPageB2BFeaturesFlipCardsCollection';
    items: Array<
      | LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection_items_Feature
      | null
      | undefined
    >;
  };

export type LandingPageB2BFragment_LandingPageB2B_hightlightedBlogEntriesCollection_LandingPageB2BHightlightedBlogEntriesCollection_items_BlogEntry =
  {
    __typename?: 'BlogEntry';
    title?: string | null | undefined;
    previewText?: string | null | undefined;
    titlePrefix?: string | null | undefined;
    slug?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
    path?: string | null | undefined;
    sys: BlogEntryFragment_BlogEntry_sys_Sys;
    titleImage?:
      | BlogEntryFragment_BlogEntry_titleImage_Asset
      | null
      | undefined;
    category?:
      | BlogEntryFragment_BlogEntry_category_BlogTopics
      | null
      | undefined;
    content?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent
      | null
      | undefined;
    newsletterSection?:
      | BlogEntryFragment_BlogEntry_newsletterSection_NewsletterContent
      | null
      | undefined;
    seoMetadata?:
      | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type LandingPageB2BFragment_LandingPageB2B_hightlightedBlogEntriesCollection_LandingPageB2BHightlightedBlogEntriesCollection =
  {
    __typename?: 'LandingPageB2BHightlightedBlogEntriesCollection';
    items: Array<
      | LandingPageB2BFragment_LandingPageB2B_hightlightedBlogEntriesCollection_LandingPageB2BHightlightedBlogEntriesCollection_items_BlogEntry
      | null
      | undefined
    >;
  };

export type LandingPageB2BFragment_LandingPageB2B_faqSectionItemsCollection_LandingPageB2BFaqSectionItemsCollection_items_FaqEntry_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type LandingPageB2BFragment_LandingPageB2B_faqSectionItemsCollection_LandingPageB2BFaqSectionItemsCollection_items_FaqEntry_text_FaqEntryText =
  { __typename?: 'FaqEntryText'; json: any };

export type LandingPageB2BFragment_LandingPageB2B_faqSectionItemsCollection_LandingPageB2BFaqSectionItemsCollection_items_FaqEntry =
  {
    __typename?: 'FaqEntry';
    title?: string | null | undefined;
    sys: LandingPageB2BFragment_LandingPageB2B_faqSectionItemsCollection_LandingPageB2BFaqSectionItemsCollection_items_FaqEntry_sys_Sys;
    text?:
      | LandingPageB2BFragment_LandingPageB2B_faqSectionItemsCollection_LandingPageB2BFaqSectionItemsCollection_items_FaqEntry_text_FaqEntryText
      | null
      | undefined;
  };

export type LandingPageB2BFragment_LandingPageB2B_faqSectionItemsCollection_LandingPageB2BFaqSectionItemsCollection =
  {
    __typename?: 'LandingPageB2BFaqSectionItemsCollection';
    items: Array<
      | LandingPageB2BFragment_LandingPageB2B_faqSectionItemsCollection_LandingPageB2BFaqSectionItemsCollection_items_FaqEntry
      | null
      | undefined
    >;
  };

export type LandingPageB2BFragment_LandingPageB2B_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type LandingPageB2BFragment = {
  __typename?: 'LandingPageB2B';
  title?: string | null | undefined;
  subText?: string | null | undefined;
  trustedPartnersTitle?: string | null | undefined;
  showCaseTitle?: string | null | undefined;
  showCaseSubTitle?: string | null | undefined;
  featuresTitle?: string | null | undefined;
  featuresSubTitle?: string | null | undefined;
  blogSectionTitle?: string | null | undefined;
  faqSectionTitle?: string | null | undefined;
  contactSectionTitle?: string | null | undefined;
  contactSectionSubtitle?: string | null | undefined;
  slug?: string | null | undefined;
  announcement?:
    | LandingPageB2BFragment_LandingPageB2B_announcement_Announcement
    | null
    | undefined;
  heroImage?:
    | LandingPageB2BFragment_LandingPageB2B_heroImage_Asset
    | null
    | undefined;
  partnerLogosCollection?:
    | LandingPageB2BFragment_LandingPageB2B_partnerLogosCollection_AssetCollection
    | null
    | undefined;
  showCaseSectionCollection?:
    | LandingPageB2BFragment_LandingPageB2B_showCaseSectionCollection_LandingPageB2BShowCaseSectionCollection
    | null
    | undefined;
  featuresFlipCardsCollection?:
    | LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection
    | null
    | undefined;
  hightlightedBlogEntriesCollection?:
    | LandingPageB2BFragment_LandingPageB2B_hightlightedBlogEntriesCollection_LandingPageB2BHightlightedBlogEntriesCollection
    | null
    | undefined;
  faqSectionItemsCollection?:
    | LandingPageB2BFragment_LandingPageB2B_faqSectionItemsCollection_LandingPageB2BFaqSectionItemsCollection
    | null
    | undefined;
  seoMetadata?:
    | LandingPageB2BFragment_LandingPageB2B_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type LandingPageFragment_LandingPage_sys_Sys = {
  __typename?: 'Sys';
  id: string;
};

export type LandingPageFragment_LandingPage_announcement_Announcement_content_AnnouncementContent =
  { __typename?: 'AnnouncementContent'; json: any };

export type LandingPageFragment_LandingPage_announcement_Announcement = {
  __typename?: 'Announcement';
  content?:
    | LandingPageFragment_LandingPage_announcement_Announcement_content_AnnouncementContent
    | null
    | undefined;
};

export type LandingPageFragment_LandingPage_heroBackgroundImage_Asset = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type LandingPageFragment_LandingPage_heroBackgroundImageMobile_Asset = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection_items_Feature_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection_items_Feature_defaultIcon_DribeIcon =
  { __typename?: 'DribeIcon'; iconName?: string | null | undefined };

export type LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection_items_Feature_defaultDescription_FeatureDefaultDescription =
  { __typename?: 'FeatureDefaultDescription'; json: any };

export type LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection_items_Feature_flipIcon_DribeIcon =
  { __typename?: 'DribeIcon'; iconName?: string | null | undefined };

export type LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection_items_Feature_flipDescription_FeatureFlipDescription =
  { __typename?: 'FeatureFlipDescription'; json: any };

export type LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection_items_Feature =
  {
    __typename?: 'Feature';
    title?: string | null | undefined;
    flipTitle?: string | null | undefined;
    sys: LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection_items_Feature_sys_Sys;
    defaultIcon?:
      | LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection_items_Feature_defaultIcon_DribeIcon
      | null
      | undefined;
    defaultDescription?:
      | LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection_items_Feature_defaultDescription_FeatureDefaultDescription
      | null
      | undefined;
    flipIcon?:
      | LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection_items_Feature_flipIcon_DribeIcon
      | null
      | undefined;
    flipDescription?:
      | LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection_items_Feature_flipDescription_FeatureFlipDescription
      | null
      | undefined;
  };

export type LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection =
  {
    __typename?: 'LandingPageFeaturesFlipCardsCollection';
    items: Array<
      | LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection_items_Feature
      | null
      | undefined
    >;
  };

export type LandingPageFragment_LandingPage_appImagesCollection_AssetCollection_items_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type LandingPageFragment_LandingPage_appImagesCollection_AssetCollection =
  {
    __typename?: 'AssetCollection';
    total: number;
    items: Array<
      | LandingPageFragment_LandingPage_appImagesCollection_AssetCollection_items_Asset
      | null
      | undefined
    >;
  };

export type LandingPageFragment_LandingPage_faqSectionItemsCollection_LandingPageFaqSectionItemsCollection_items_FaqEntry_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type LandingPageFragment_LandingPage_faqSectionItemsCollection_LandingPageFaqSectionItemsCollection_items_FaqEntry_text_FaqEntryText =
  { __typename?: 'FaqEntryText'; json: any };

export type LandingPageFragment_LandingPage_faqSectionItemsCollection_LandingPageFaqSectionItemsCollection_items_FaqEntry =
  {
    __typename?: 'FaqEntry';
    title?: string | null | undefined;
    sys: LandingPageFragment_LandingPage_faqSectionItemsCollection_LandingPageFaqSectionItemsCollection_items_FaqEntry_sys_Sys;
    text?:
      | LandingPageFragment_LandingPage_faqSectionItemsCollection_LandingPageFaqSectionItemsCollection_items_FaqEntry_text_FaqEntryText
      | null
      | undefined;
  };

export type LandingPageFragment_LandingPage_faqSectionItemsCollection_LandingPageFaqSectionItemsCollection =
  {
    __typename?: 'LandingPageFaqSectionItemsCollection';
    items: Array<
      | LandingPageFragment_LandingPage_faqSectionItemsCollection_LandingPageFaqSectionItemsCollection_items_FaqEntry
      | null
      | undefined
    >;
  };

export type LandingPageFragment_LandingPage_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type LandingPageFragment = {
  __typename?: 'LandingPage';
  title?: string | null | undefined;
  slug?: string | null | undefined;
  heroTitleText?: string | null | undefined;
  bookingFormTitle?: string | null | undefined;
  bookingFormSearchText?: string | null | undefined;
  locationDropdownLabel?: string | null | undefined;
  locationDropdownPlaceholder?: string | null | undefined;
  dateInputLabel?: string | null | undefined;
  dateInputPlaceholder?: string | null | undefined;
  garageLabel?: string | null | undefined;
  ageRequirementsAccentBox?: string | null | undefined;
  downloadAppTitle?: string | null | undefined;
  appFeatures?: Array<string | null | undefined> | null | undefined;
  appDescription?: string | null | undefined;
  cardPricesFromText?: string | null | undefined;
  budgetCategoryMaxPrice?: number | null | undefined;
  electricPropellantValue?: string | null | undefined;
  faqSectionTitle?: string | null | undefined;
  sys: LandingPageFragment_LandingPage_sys_Sys;
  announcement?:
    | LandingPageFragment_LandingPage_announcement_Announcement
    | null
    | undefined;
  heroBackgroundImage?:
    | LandingPageFragment_LandingPage_heroBackgroundImage_Asset
    | null
    | undefined;
  heroBackgroundImageMobile?:
    | LandingPageFragment_LandingPage_heroBackgroundImageMobile_Asset
    | null
    | undefined;
  featuresFlipCardsCollection?:
    | LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection
    | null
    | undefined;
  appImagesCollection?:
    | LandingPageFragment_LandingPage_appImagesCollection_AssetCollection
    | null
    | undefined;
  faqSectionItemsCollection?:
    | LandingPageFragment_LandingPage_faqSectionItemsCollection_LandingPageFaqSectionItemsCollection
    | null
    | undefined;
  seoMetadata?:
    | LandingPageFragment_LandingPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type LegalEntryFragment_LegalEntry_sys_Sys = {
  __typename?: 'Sys';
  id: string;
};

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_assets_LegalEntryTextAssets_block_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_assets_LegalEntryTextAssets =
  {
    __typename?: 'LegalEntryTextAssets';
    block: Array<
      | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_assets_LegalEntryTextAssets_block_Asset
      | null
      | undefined
    >;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement =
  {
    __typename: 'Announcement';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_AvailableGaragesCard =
  {
    __typename: 'AvailableGaragesCard';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_B2BShowcaseModel =
  {
    __typename: 'B2BShowcaseModel';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_BlogEntry =
  {
    __typename: 'BlogEntry';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_BlogPage =
  {
    __typename: 'BlogPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_BlogTopics =
  {
    __typename: 'BlogTopics';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_BrandContact =
  {
    __typename: 'BrandContact';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_CampaignEntry =
  {
    __typename: 'CampaignEntry';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_CollapsableContainer =
  {
    __typename: 'CollapsableContainer';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_ComingSoonPage =
  {
    __typename: 'ComingSoonPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_ContactCard =
  {
    __typename: 'ContactCard';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_ContactInfoLink =
  {
    __typename: 'ContactInfoLink';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_ContactPage =
  {
    __typename: 'ContactPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_ContactPageB2B =
  {
    __typename: 'ContactPageB2B';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DamagesCard =
  {
    __typename: 'DamagesCard';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DamagesContactPage =
  {
    __typename: 'DamagesContactPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DamagesPage =
  {
    __typename: 'DamagesPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DamagesPolicyPage =
  {
    __typename: 'DamagesPolicyPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DamagesPolicyPageContentBox =
  {
    __typename: 'DamagesPolicyPageContentBox';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DemoPage =
  {
    __typename: 'DemoPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DemoSlide =
  {
    __typename: 'DemoSlide';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DownloadPage =
  {
    __typename: 'DownloadPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DribeIcon =
  {
    __typename: 'DribeIcon';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DynamicForm =
  {
    __typename: 'DynamicForm';
    formIdentifierTag?: string | null | undefined;
    submitButtonLabel?: string | null | undefined;
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
    formTextContent?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formTextContent_DynamicFormFormTextContent
      | null
      | undefined;
    formFieldsCollection?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_formFieldsCollection_DynamicFormFormFieldsCollection
      | null
      | undefined;
    successfulSubmitMessage?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent_links_BlogEntryContentLinks_entries_BlogEntryContentEntries_block_DynamicForm_successfulSubmitMessage_DynamicFormSuccessfulSubmitMessage
      | null
      | undefined;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_FaqCategory =
  {
    __typename: 'FaqCategory';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_FaqEntry =
  {
    __typename: 'FaqEntry';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_FaqPage =
  {
    __typename: 'FaqPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Feature =
  {
    __typename: 'Feature';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Footer =
  {
    __typename: 'Footer';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_FormField =
  {
    __typename: 'FormField';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_GaragePage =
  {
    __typename: 'GaragePage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_InspectionStep =
  {
    __typename: 'InspectionStep';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_LandingPage =
  {
    __typename: 'LandingPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_LandingPageB2B =
  {
    __typename: 'LandingPageB2B';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_LegalEntry =
  {
    __typename: 'LegalEntry';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_LegalPage =
  {
    __typename: 'LegalPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Link =
  {
    __typename: 'Link';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_LinkGroup =
  {
    __typename: 'LinkGroup';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Menu =
  {
    __typename: 'Menu';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_NewsletterContent =
  {
    __typename: 'NewsletterContent';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_OutOfStockPage =
  {
    __typename: 'OutOfStockPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Partners =
  {
    __typename: 'Partners';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_PreviewLocations =
  {
    __typename: 'PreviewLocations';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_PricingPage =
  {
    __typename: 'PricingPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_PricingRideCard =
  {
    __typename: 'PricingRideCard';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_ProductPage =
  {
    __typename: 'ProductPage';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_SeoMetadata =
  {
    __typename: 'SeoMetadata';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_SiteSwitcher =
  {
    __typename: 'SiteSwitcher';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_TableRow =
  {
    __typename: 'TableRow';
    sys: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement_sys_Sys;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block =

    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Announcement
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_AvailableGaragesCard
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_B2BShowcaseModel
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_BlogEntry
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_BlogPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_BlogTopics
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_BrandContact
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_CampaignEntry
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_CollapsableContainer
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_ComingSoonPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_ContactCard
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_ContactInfoLink
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_ContactPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_ContactPageB2B
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DamagesCard
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DamagesContactPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DamagesPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DamagesPolicyPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DamagesPolicyPageContentBox
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DemoPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DemoSlide
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DownloadPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DribeIcon
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_DynamicForm
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_FaqCategory
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_FaqEntry
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_FaqPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Feature
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Footer
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_FormField
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_GaragePage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_InspectionStep
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_LandingPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_LandingPageB2B
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_LegalEntry
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_LegalPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Link
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_LinkGroup
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Menu
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_NewsletterContent
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_OutOfStockPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_Partners
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_PreviewLocations
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_PricingPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_PricingRideCard
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_ProductPage
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_SeoMetadata
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_SiteSwitcher
    | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block_TableRow;

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries =
  {
    __typename?: 'LegalEntryTextEntries';
    block: Array<
      | LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries_block
      | null
      | undefined
    >;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks =
  {
    __typename?: 'LegalEntryTextLinks';
    assets: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_assets_LegalEntryTextAssets;
    entries: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks_entries_LegalEntryTextEntries;
  };

export type LegalEntryFragment_LegalEntry_text_LegalEntryText = {
  __typename?: 'LegalEntryText';
  json: any;
  links: LegalEntryFragment_LegalEntry_text_LegalEntryText_links_LegalEntryTextLinks;
};

export type LegalEntryFragment_LegalEntry_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type LegalEntryFragment = {
  __typename?: 'LegalEntry';
  title?: string | null | undefined;
  tag?: string | null | undefined;
  site?: Array<string | null | undefined> | null | undefined;
  path?: string | null | undefined;
  slug?: string | null | undefined;
  sys: LegalEntryFragment_LegalEntry_sys_Sys;
  text?: LegalEntryFragment_LegalEntry_text_LegalEntryText | null | undefined;
  seoMetadata?:
    | LegalEntryFragment_LegalEntry_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type LegalPageFragment_LegalPage_sys_Sys = {
  __typename?: 'Sys';
  id: string;
};

export type LegalPageFragment_LegalPage_legalEntriesCollection_LegalPageLegalEntriesCollection_items_LegalEntry_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type LegalPageFragment_LegalPage_legalEntriesCollection_LegalPageLegalEntriesCollection_items_LegalEntry =
  {
    __typename?: 'LegalEntry';
    title?: string | null | undefined;
    description?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
    slug?: string | null | undefined;
    sys: LegalPageFragment_LegalPage_legalEntriesCollection_LegalPageLegalEntriesCollection_items_LegalEntry_sys_Sys;
  };

export type LegalPageFragment_LegalPage_legalEntriesCollection_LegalPageLegalEntriesCollection =
  {
    __typename?: 'LegalPageLegalEntriesCollection';
    items: Array<
      | LegalPageFragment_LegalPage_legalEntriesCollection_LegalPageLegalEntriesCollection_items_LegalEntry
      | null
      | undefined
    >;
  };

export type LegalPageFragment_LegalPage_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type LegalPageFragment = {
  __typename?: 'LegalPage';
  title?: string | null | undefined;
  headerTitle?: string | null | undefined;
  slug?: string | null | undefined;
  sys: LegalPageFragment_LegalPage_sys_Sys;
  legalEntriesCollection?:
    | LegalPageFragment_LegalPage_legalEntriesCollection_LegalPageLegalEntriesCollection
    | null
    | undefined;
  seoMetadata?:
    | LegalPageFragment_LegalPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type MenuFragment_Menu_linksCollection_MenuLinksCollection_items_Link_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type MenuFragment_Menu_linksCollection_MenuLinksCollection_items_Link = {
  __typename?: 'Link';
  title?: string | null | undefined;
  url?: string | null | undefined;
  sys: MenuFragment_Menu_linksCollection_MenuLinksCollection_items_Link_sys_Sys;
};

export type MenuFragment_Menu_linksCollection_MenuLinksCollection = {
  __typename?: 'MenuLinksCollection';
  items: Array<
    | MenuFragment_Menu_linksCollection_MenuLinksCollection_items_Link
    | null
    | undefined
  >;
};

export type MenuFragment_Menu_b2BLinksCollection_MenuB2bLinksCollection_items_Link_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type MenuFragment_Menu_b2BLinksCollection_MenuB2bLinksCollection_items_Link =
  {
    __typename?: 'Link';
    title?: string | null | undefined;
    url?: string | null | undefined;
    sys: MenuFragment_Menu_b2BLinksCollection_MenuB2bLinksCollection_items_Link_sys_Sys;
  };

export type MenuFragment_Menu_b2BLinksCollection_MenuB2bLinksCollection = {
  __typename?: 'MenuB2bLinksCollection';
  items: Array<
    | MenuFragment_Menu_b2BLinksCollection_MenuB2bLinksCollection_items_Link
    | null
    | undefined
  >;
};

export type MenuFragment = {
  __typename?: 'Menu';
  languageCode?: string | null | undefined;
  defaultPage?: string | null | undefined;
  redirectTimeout?: number | null | undefined;
  slug?: string | null | undefined;
  linksCollection?:
    | MenuFragment_Menu_linksCollection_MenuLinksCollection
    | null
    | undefined;
  b2BLinksCollection?:
    | MenuFragment_Menu_b2BLinksCollection_MenuB2bLinksCollection
    | null
    | undefined;
};

export type OutOfStockPageFragment_OutOfStockPage_mobileUiImageExample_Asset = {
  __typename?: 'Asset';
  title?: string | null | undefined;
  description?: string | null | undefined;
  width?: number | null | undefined;
  height?: number | null | undefined;
  url?: string | null | undefined;
  contentType?: string | null | undefined;
  sys: AssetFragment_Asset_sys_Sys;
  contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
};

export type OutOfStockPageFragment_OutOfStockPage_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type OutOfStockPageFragment = {
  __typename?: 'OutOfStockPage';
  title?: string | null | undefined;
  subTitle?: string | null | undefined;
  watchlistTitle?: string | null | undefined;
  watchlistDescription?: string | null | undefined;
  slug?: string | null | undefined;
  mobileUiImageExample?:
    | OutOfStockPageFragment_OutOfStockPage_mobileUiImageExample_Asset
    | null
    | undefined;
  seoMetadata?:
    | OutOfStockPageFragment_OutOfStockPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type PricingPageFragment_PricingPage_exampleDescription_PricingPageExampleDescription =
  { __typename?: 'PricingPageExampleDescription'; json: any };

export type PricingPageFragment_PricingPage_exampleRideCard_PricingRideCard_rideImage_Asset =
  {
    __typename?: 'Asset';
    title?: string | null | undefined;
    description?: string | null | undefined;
    width?: number | null | undefined;
    height?: number | null | undefined;
    url?: string | null | undefined;
    contentType?: string | null | undefined;
    sys: AssetFragment_Asset_sys_Sys;
    contentfulMetadata: AssetFragment_Asset_contentfulMetadata_ContentfulMetadata;
  };

export type PricingPageFragment_PricingPage_exampleRideCard_PricingRideCard = {
  __typename?: 'PricingRideCard';
  rideNameHeader?: string | null | undefined;
  rideVariantSubHeader?: string | null | undefined;
  rideImage?:
    | PricingPageFragment_PricingPage_exampleRideCard_PricingRideCard_rideImage_Asset
    | null
    | undefined;
};

export type PricingPageFragment_PricingPage_priceTable_PricingPagePriceTable = {
  __typename?: 'PricingPagePriceTable';
  json: any;
};

export type PricingPageFragment_PricingPage_accentBox_PricingPageAccentBox = {
  __typename?: 'PricingPageAccentBox';
  json: any;
};

export type PricingPageFragment_PricingPage_leftChecklist_PricingPageLeftChecklist =
  { __typename?: 'PricingPageLeftChecklist'; json: any };

export type PricingPageFragment_PricingPage_rightChecklist_PricingPageRightChecklist =
  { __typename?: 'PricingPageRightChecklist'; json: any };

export type PricingPageFragment_PricingPage_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type PricingPageFragment = {
  __typename?: 'PricingPage';
  title?: string | null | undefined;
  header?: string | null | undefined;
  exampleHeader?: string | null | undefined;
  checklistHeader?: string | null | undefined;
  slug?: string | null | undefined;
  exampleDescription?:
    | PricingPageFragment_PricingPage_exampleDescription_PricingPageExampleDescription
    | null
    | undefined;
  exampleRideCard?:
    | PricingPageFragment_PricingPage_exampleRideCard_PricingRideCard
    | null
    | undefined;
  priceTable?:
    | PricingPageFragment_PricingPage_priceTable_PricingPagePriceTable
    | null
    | undefined;
  accentBox?:
    | PricingPageFragment_PricingPage_accentBox_PricingPageAccentBox
    | null
    | undefined;
  leftChecklist?:
    | PricingPageFragment_PricingPage_leftChecklist_PricingPageLeftChecklist
    | null
    | undefined;
  rightChecklist?:
    | PricingPageFragment_PricingPage_rightChecklist_PricingPageRightChecklist
    | null
    | undefined;
  seoMetadata?:
    | PricingPageFragment_PricingPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type ProductPageFragment_ProductPage_sys_Sys = {
  __typename?: 'Sys';
  id: string;
};

export type ProductPageFragment_ProductPage_announcement_Announcement_content_AnnouncementContent =
  { __typename?: 'AnnouncementContent'; json: any };

export type ProductPageFragment_ProductPage_announcement_Announcement = {
  __typename?: 'Announcement';
  content?:
    | ProductPageFragment_ProductPage_announcement_Announcement_content_AnnouncementContent
    | null
    | undefined;
};

export type ProductPageFragment_ProductPage_seoMetadata_SeoMetadata = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type ProductPageFragment = {
  __typename?: 'ProductPage';
  title?: string | null | undefined;
  hideMsrpTaxContainer?: boolean | null | undefined;
  economyTableEntries?: Array<string | null | undefined> | null | undefined;
  economyTableBottomText?: string | null | undefined;
  includeLinkToPricingPage?: boolean | null | undefined;
  addOnsInfoText?: string | null | undefined;
  priceIncludesText?: string | null | undefined;
  slug?: string | null | undefined;
  sys: ProductPageFragment_ProductPage_sys_Sys;
  announcement?:
    | ProductPageFragment_ProductPage_announcement_Announcement
    | null
    | undefined;
  seoMetadata?:
    | ProductPageFragment_ProductPage_seoMetadata_SeoMetadata
    | null
    | undefined;
};

export type SeoFragment = {
  __typename?: 'SeoMetadata';
  internalName?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  keywords?: Array<string | null | undefined> | null | undefined;
  canonical?: string | null | undefined;
  noindex?: boolean | null | undefined;
  nofollow?: boolean | null | undefined;
  openGraphTitle?: string | null | undefined;
  openGraphDescription?: string | null | undefined;
  openGraphUrl?: string | null | undefined;
  openGraphType?: string | null | undefined;
  twitterCard?: string | null | undefined;
  twitterSite?: string | null | undefined;
  sys: BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_sys_Sys;
  openGraphImage?:
    | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata_openGraphImage_Asset
    | null
    | undefined;
};

export type SiteSwitcherFragment = {
  __typename?: 'SiteSwitcher';
  slug?: string | null | undefined;
  mode?: string | null | undefined;
};

export type TableRowFragment_TableRow_sys_Sys = {
  __typename?: 'Sys';
  id: string;
};

export type TableRowFragment_TableRow_icon_DribeIcon = {
  __typename?: 'DribeIcon';
  iconName?: string | null | undefined;
};

export type TableRowFragment = {
  __typename?: 'TableRow';
  title?: string | null | undefined;
  value?: string | null | undefined;
  lokaliseKey?: boolean | null | undefined;
  sys: TableRowFragment_TableRow_sys_Sys;
  icon?: TableRowFragment_TableRow_icon_DribeIcon | null | undefined;
};

export type GetBlogEntryQuery_blogTopicsCollection_BlogTopicsCollection_items_BlogTopics =
  { __typename?: 'BlogTopics'; title?: string | null | undefined };

export type GetBlogEntryQuery_blogTopicsCollection_BlogTopicsCollection = {
  __typename?: 'BlogTopicsCollection';
  items: Array<
    | GetBlogEntryQuery_blogTopicsCollection_BlogTopicsCollection_items_BlogTopics
    | null
    | undefined
  >;
};

export type GetBlogEntryQuery_blogEntryCollection_BlogEntryCollection_items_BlogEntry =
  {
    __typename?: 'BlogEntry';
    title?: string | null | undefined;
    previewText?: string | null | undefined;
    titlePrefix?: string | null | undefined;
    slug?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
    path?: string | null | undefined;
    sys: BlogEntryFragment_BlogEntry_sys_Sys;
    titleImage?:
      | BlogEntryFragment_BlogEntry_titleImage_Asset
      | null
      | undefined;
    category?:
      | BlogEntryFragment_BlogEntry_category_BlogTopics
      | null
      | undefined;
    content?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent
      | null
      | undefined;
    newsletterSection?:
      | BlogEntryFragment_BlogEntry_newsletterSection_NewsletterContent
      | null
      | undefined;
    seoMetadata?:
      | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetBlogEntryQuery_blogEntryCollection_BlogEntryCollection = {
  __typename?: 'BlogEntryCollection';
  total: number;
  items: Array<
    | GetBlogEntryQuery_blogEntryCollection_BlogEntryCollection_items_BlogEntry
    | null
    | undefined
  >;
};

export type GetBlogEntryQuery_Query = {
  __typename?: 'Query';
  blogTopicsCollection?:
    | GetBlogEntryQuery_blogTopicsCollection_BlogTopicsCollection
    | null
    | undefined;
  blogEntryCollection?:
    | GetBlogEntryQuery_blogEntryCollection_BlogEntryCollection
    | null
    | undefined;
};

export type GetBlogEntryQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
  order?: InputMaybe<
    Array<InputMaybe<BlogEntryOrder>> | InputMaybe<BlogEntryOrder>
  >;
  skip?: InputMaybe<Scalars['Int']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  site?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBlogEntryQuery = GetBlogEntryQuery_Query;

export type GetBlogEntryByPathQuery_blogEntryCollection_BlogEntryCollection_items_BlogEntry =
  {
    __typename?: 'BlogEntry';
    title?: string | null | undefined;
    previewText?: string | null | undefined;
    titlePrefix?: string | null | undefined;
    slug?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
    path?: string | null | undefined;
    sys: BlogEntryFragment_BlogEntry_sys_Sys;
    titleImage?:
      | BlogEntryFragment_BlogEntry_titleImage_Asset
      | null
      | undefined;
    category?:
      | BlogEntryFragment_BlogEntry_category_BlogTopics
      | null
      | undefined;
    content?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent
      | null
      | undefined;
    newsletterSection?:
      | BlogEntryFragment_BlogEntry_newsletterSection_NewsletterContent
      | null
      | undefined;
    seoMetadata?:
      | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetBlogEntryByPathQuery_blogEntryCollection_BlogEntryCollection = {
  __typename?: 'BlogEntryCollection';
  items: Array<
    | GetBlogEntryByPathQuery_blogEntryCollection_BlogEntryCollection_items_BlogEntry
    | null
    | undefined
  >;
};

export type GetBlogEntryByPathQuery_Query = {
  __typename?: 'Query';
  blogEntryCollection?:
    | GetBlogEntryByPathQuery_blogEntryCollection_BlogEntryCollection
    | null
    | undefined;
};

export type GetBlogEntryByPathQueryVariables = Exact<{
  path: Scalars['String']['input'];
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBlogEntryByPathQuery = GetBlogEntryByPathQuery_Query;

export type GetBlogEntryBySlugQuery_blogEntryCollection_BlogEntryCollection_items_BlogEntry =
  {
    __typename?: 'BlogEntry';
    title?: string | null | undefined;
    previewText?: string | null | undefined;
    titlePrefix?: string | null | undefined;
    slug?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
    path?: string | null | undefined;
    sys: BlogEntryFragment_BlogEntry_sys_Sys;
    titleImage?:
      | BlogEntryFragment_BlogEntry_titleImage_Asset
      | null
      | undefined;
    category?:
      | BlogEntryFragment_BlogEntry_category_BlogTopics
      | null
      | undefined;
    content?:
      | BlogEntryFragment_BlogEntry_content_BlogEntryContent
      | null
      | undefined;
    newsletterSection?:
      | BlogEntryFragment_BlogEntry_newsletterSection_NewsletterContent
      | null
      | undefined;
    seoMetadata?:
      | BlogEntryFragment_BlogEntry_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetBlogEntryBySlugQuery_blogEntryCollection_BlogEntryCollection = {
  __typename?: 'BlogEntryCollection';
  items: Array<
    | GetBlogEntryBySlugQuery_blogEntryCollection_BlogEntryCollection_items_BlogEntry
    | null
    | undefined
  >;
};

export type GetBlogEntryBySlugQuery_Query = {
  __typename?: 'Query';
  blogEntryCollection?:
    | GetBlogEntryBySlugQuery_blogEntryCollection_BlogEntryCollection
    | null
    | undefined;
};

export type GetBlogEntryBySlugQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBlogEntryBySlugQuery = GetBlogEntryBySlugQuery_Query;

export type GetBlogEntryPathsQuery_blogEntryCollection_BlogEntryCollection_items_BlogEntry =
  {
    __typename?: 'BlogEntry';
    path?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
  };

export type GetBlogEntryPathsQuery_blogEntryCollection_BlogEntryCollection = {
  __typename?: 'BlogEntryCollection';
  items: Array<
    | GetBlogEntryPathsQuery_blogEntryCollection_BlogEntryCollection_items_BlogEntry
    | null
    | undefined
  >;
};

export type GetBlogEntryPathsQuery_Query = {
  __typename?: 'Query';
  blogEntryCollection?:
    | GetBlogEntryPathsQuery_blogEntryCollection_BlogEntryCollection
    | null
    | undefined;
};

export type GetBlogEntryPathsQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBlogEntryPathsQuery = GetBlogEntryPathsQuery_Query;

export type GetBlogPageBySlugQuery_blogPageCollection_BlogPageCollection_items_BlogPage =
  {
    __typename?: 'BlogPage';
    title?: string | null | undefined;
    slug?: string | null | undefined;
    highlightedBlogPost?:
      | BlogPageFragment_BlogPage_highlightedBlogPost_BlogEntry
      | null
      | undefined;
    newsletterSection?:
      | BlogPageFragment_BlogPage_newsletterSection_NewsletterContent
      | null
      | undefined;
    seoMetadata?:
      | BlogPageFragment_BlogPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetBlogPageBySlugQuery_blogPageCollection_BlogPageCollection = {
  __typename?: 'BlogPageCollection';
  items: Array<
    | GetBlogPageBySlugQuery_blogPageCollection_BlogPageCollection_items_BlogPage
    | null
    | undefined
  >;
};

export type GetBlogPageBySlugQuery_Query = {
  __typename?: 'Query';
  blogPageCollection?:
    | GetBlogPageBySlugQuery_blogPageCollection_BlogPageCollection
    | null
    | undefined;
};

export type GetBlogPageBySlugQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBlogPageBySlugQuery = GetBlogPageBySlugQuery_Query;

export type GetCampaignEntryByPathQuery_campaignEntryCollection_CampaignEntryCollection_items_CampaignEntry =
  {
    __typename?: 'CampaignEntry';
    title?: string | null | undefined;
    path?: string | null | undefined;
    slug?: string | null | undefined;
    sys: CampaignEntryFragment_CampaignEntry_sys_Sys;
    content?:
      | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent
      | null
      | undefined;
    seoMetadata?:
      | CampaignEntryFragment_CampaignEntry_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetCampaignEntryByPathQuery_campaignEntryCollection_CampaignEntryCollection =
  {
    __typename?: 'CampaignEntryCollection';
    items: Array<
      | GetCampaignEntryByPathQuery_campaignEntryCollection_CampaignEntryCollection_items_CampaignEntry
      | null
      | undefined
    >;
  };

export type GetCampaignEntryByPathQuery_Query = {
  __typename?: 'Query';
  campaignEntryCollection?:
    | GetCampaignEntryByPathQuery_campaignEntryCollection_CampaignEntryCollection
    | null
    | undefined;
};

export type GetCampaignEntryByPathQueryVariables = Exact<{
  path: Scalars['String']['input'];
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetCampaignEntryByPathQuery = GetCampaignEntryByPathQuery_Query;

export type GetCampaignEntryBySlugQuery_campaignEntryCollection_CampaignEntryCollection_items_CampaignEntry =
  {
    __typename?: 'CampaignEntry';
    title?: string | null | undefined;
    path?: string | null | undefined;
    slug?: string | null | undefined;
    sys: CampaignEntryFragment_CampaignEntry_sys_Sys;
    content?:
      | CampaignEntryFragment_CampaignEntry_content_CampaignEntryContent
      | null
      | undefined;
    seoMetadata?:
      | CampaignEntryFragment_CampaignEntry_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetCampaignEntryBySlugQuery_campaignEntryCollection_CampaignEntryCollection =
  {
    __typename?: 'CampaignEntryCollection';
    items: Array<
      | GetCampaignEntryBySlugQuery_campaignEntryCollection_CampaignEntryCollection_items_CampaignEntry
      | null
      | undefined
    >;
  };

export type GetCampaignEntryBySlugQuery_Query = {
  __typename?: 'Query';
  campaignEntryCollection?:
    | GetCampaignEntryBySlugQuery_campaignEntryCollection_CampaignEntryCollection
    | null
    | undefined;
};

export type GetCampaignEntryBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetCampaignEntryBySlugQuery = GetCampaignEntryBySlugQuery_Query;

export type GetCampaignEntryPathsQuery_campaignEntryCollection_CampaignEntryCollection_items_CampaignEntry =
  { __typename?: 'CampaignEntry'; path?: string | null | undefined };

export type GetCampaignEntryPathsQuery_campaignEntryCollection_CampaignEntryCollection =
  {
    __typename?: 'CampaignEntryCollection';
    items: Array<
      | GetCampaignEntryPathsQuery_campaignEntryCollection_CampaignEntryCollection_items_CampaignEntry
      | null
      | undefined
    >;
  };

export type GetCampaignEntryPathsQuery_Query = {
  __typename?: 'Query';
  campaignEntryCollection?:
    | GetCampaignEntryPathsQuery_campaignEntryCollection_CampaignEntryCollection
    | null
    | undefined;
};

export type GetCampaignEntryPathsQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCampaignEntryPathsQuery = GetCampaignEntryPathsQuery_Query;

export type GetComingSoonPageQuery_comingSoonPageCollection_ComingSoonPageCollection_items_ComingSoonPage =
  {
    __typename?: 'ComingSoonPage';
    header?: string | null | undefined;
    slug?: string | null | undefined;
    headerImage?:
      | ComingSoonPageFragment_ComingSoonPage_headerImage_Asset
      | null
      | undefined;
    description?:
      | ComingSoonPageFragment_ComingSoonPage_description_ComingSoonPageDescription
      | null
      | undefined;
    previewLocationsCollection?:
      | ComingSoonPageFragment_ComingSoonPage_previewLocationsCollection_ComingSoonPagePreviewLocationsCollection
      | null
      | undefined;
  };

export type GetComingSoonPageQuery_comingSoonPageCollection_ComingSoonPageCollection =
  {
    __typename?: 'ComingSoonPageCollection';
    items: Array<
      | GetComingSoonPageQuery_comingSoonPageCollection_ComingSoonPageCollection_items_ComingSoonPage
      | null
      | undefined
    >;
  };

export type GetComingSoonPageQuery_Query = {
  __typename?: 'Query';
  comingSoonPageCollection?:
    | GetComingSoonPageQuery_comingSoonPageCollection_ComingSoonPageCollection
    | null
    | undefined;
};

export type GetComingSoonPageQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetComingSoonPageQuery = GetComingSoonPageQuery_Query;

export type GetContactPageQuery_contactPageCollection_ContactPageCollection_items_ContactPage =
  {
    __typename?: 'ContactPage';
    title?: string | null | undefined;
    contactHeader?: string | null | undefined;
    assistanceAndDamagesHeader?: string | null | undefined;
    findGaragesHeader?: string | null | undefined;
    slug?: string | null | undefined;
    contactSubDescription?:
      | ContactPageFragment_ContactPage_contactSubDescription_ContactPageContactSubDescription
      | null
      | undefined;
    contactCardsCollection?:
      | ContactPageFragment_ContactPage_contactCardsCollection_ContactPageContactCardsCollection
      | null
      | undefined;
    accentBox?:
      | ContactPageFragment_ContactPage_accentBox_ContactPageAccentBox
      | null
      | undefined;
    assistanceAndDamagesImage?:
      | ContactPageFragment_ContactPage_assistanceAndDamagesImage_Asset
      | null
      | undefined;
    assistanceAndDamagesSubDesc?:
      | ContactPageFragment_ContactPage_assistanceAndDamagesSubDesc_ContactPageAssistanceAndDamagesSubDesc
      | null
      | undefined;
    assistanceAndDamagesArrowLink?:
      | ContactPageFragment_ContactPage_assistanceAndDamagesArrowLink_Link
      | null
      | undefined;
    assistanceAndDamagesContactCardsCollection?:
      | ContactPageFragment_ContactPage_assistanceAndDamagesContactCardsCollection_ContactPageAssistanceAndDamagesContactCardsCollection
      | null
      | undefined;
    availableGaragesCardsCollection?:
      | ContactPageFragment_ContactPage_availableGaragesCardsCollection_ContactPageAvailableGaragesCardsCollection
      | null
      | undefined;
    seoMetadata?:
      | ContactPageFragment_ContactPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetContactPageQuery_contactPageCollection_ContactPageCollection = {
  __typename?: 'ContactPageCollection';
  items: Array<
    | GetContactPageQuery_contactPageCollection_ContactPageCollection_items_ContactPage
    | null
    | undefined
  >;
};

export type GetContactPageQuery_Query = {
  __typename?: 'Query';
  contactPageCollection?:
    | GetContactPageQuery_contactPageCollection_ContactPageCollection
    | null
    | undefined;
};

export type GetContactPageQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetContactPageQuery = GetContactPageQuery_Query;

export type GetContactPageB2BQuery_contactPageB2BCollection_ContactPageB2BCollection_items_ContactPageB2B =
  {
    __typename?: 'ContactPageB2B';
    title?: string | null | undefined;
    featureDescription?: string | null | undefined;
    featureList?: Array<string | null | undefined> | null | undefined;
    submitTitle?: string | null | undefined;
    companyIdInputLabel?: string | null | undefined;
    companyIdRequired?: string | null | undefined;
    slug?: string | null | undefined;
    contactInfoCollection?:
      | ContactPageB2BFragment_ContactPageB2B_contactInfoCollection_ContactPageB2BContactInfoCollection
      | null
      | undefined;
    submitDescription?:
      | ContactPageB2BFragment_ContactPageB2B_submitDescription_ContactPageB2BSubmitDescription
      | null
      | undefined;
    seoMetadata?:
      | ContactPageB2BFragment_ContactPageB2B_seoMetadata_SeoMetadata
      | null
      | undefined;
    contactForm?:
      | ContactPageB2BFragment_ContactPageB2B_contactForm_DynamicForm
      | null
      | undefined;
  };

export type GetContactPageB2BQuery_contactPageB2BCollection_ContactPageB2BCollection =
  {
    __typename?: 'ContactPageB2BCollection';
    items: Array<
      | GetContactPageB2BQuery_contactPageB2BCollection_ContactPageB2BCollection_items_ContactPageB2B
      | null
      | undefined
    >;
  };

export type GetContactPageB2BQuery_Query = {
  __typename?: 'Query';
  contactPageB2BCollection?:
    | GetContactPageB2BQuery_contactPageB2BCollection_ContactPageB2BCollection
    | null
    | undefined;
};

export type GetContactPageB2BQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetContactPageB2BQuery = GetContactPageB2BQuery_Query;

export type GetDamagesContactPageBySlugQuery_damagesContactPageCollection_DamagesContactPageCollection_items_DamagesContactPage =
  {
    __typename?: 'DamagesContactPage';
    title?: string | null | undefined;
    header?: string | null | undefined;
    startProblemsHeader?: string | null | undefined;
    slug?: string | null | undefined;
    description?:
      | DamagesContactPageFragment_DamagesContactPage_description_DamagesContactPageDescription
      | null
      | undefined;
    damagesContactCardsCollection?:
      | DamagesContactPageFragment_DamagesContactPage_damagesContactCardsCollection_DamagesContactPageDamagesContactCardsCollection
      | null
      | undefined;
    accentBox?:
      | DamagesContactPageFragment_DamagesContactPage_accentBox_DamagesContactPageAccentBox
      | null
      | undefined;
    startProblemsDesc1?:
      | DamagesContactPageFragment_DamagesContactPage_startProblemsDesc1_DamagesContactPageStartProblemsDesc1
      | null
      | undefined;
    brandContactsCollection?:
      | DamagesContactPageFragment_DamagesContactPage_brandContactsCollection_DamagesContactPageBrandContactsCollection
      | null
      | undefined;
    startProblemsDesc2?:
      | DamagesContactPageFragment_DamagesContactPage_startProblemsDesc2_DamagesContactPageStartProblemsDesc2
      | null
      | undefined;
    seoMetadata?:
      | DamagesContactPageFragment_DamagesContactPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetDamagesContactPageBySlugQuery_damagesContactPageCollection_DamagesContactPageCollection =
  {
    __typename?: 'DamagesContactPageCollection';
    items: Array<
      | GetDamagesContactPageBySlugQuery_damagesContactPageCollection_DamagesContactPageCollection_items_DamagesContactPage
      | null
      | undefined
    >;
  };

export type GetDamagesContactPageBySlugQuery_Query = {
  __typename?: 'Query';
  damagesContactPageCollection?:
    | GetDamagesContactPageBySlugQuery_damagesContactPageCollection_DamagesContactPageCollection
    | null
    | undefined;
};

export type GetDamagesContactPageBySlugQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDamagesContactPageBySlugQuery =
  GetDamagesContactPageBySlugQuery_Query;

export type GetDamagesPageBySlugQuery_damagesPageCollection_DamagesPageCollection_items_DamagesPage =
  {
    __typename?: 'DamagesPage';
    title?: string | null | undefined;
    header?: string | null | undefined;
    slug?: string | null | undefined;
    damagesCardsCollection?:
      | DamagesPageFragment_DamagesPage_damagesCardsCollection_DamagesPageDamagesCardsCollection
      | null
      | undefined;
    seoMetadata?:
      | DamagesPageFragment_DamagesPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetDamagesPageBySlugQuery_damagesPageCollection_DamagesPageCollection =
  {
    __typename?: 'DamagesPageCollection';
    items: Array<
      | GetDamagesPageBySlugQuery_damagesPageCollection_DamagesPageCollection_items_DamagesPage
      | null
      | undefined
    >;
  };

export type GetDamagesPageBySlugQuery_Query = {
  __typename?: 'Query';
  damagesPageCollection?:
    | GetDamagesPageBySlugQuery_damagesPageCollection_DamagesPageCollection
    | null
    | undefined;
};

export type GetDamagesPageBySlugQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDamagesPageBySlugQuery = GetDamagesPageBySlugQuery_Query;

export type GetDamagesPolicyPageBySlugQuery_damagesPolicyPageCollection_DamagesPolicyPageCollection_items_DamagesPolicyPage =
  {
    __typename?: 'DamagesPolicyPage';
    title?: string | null | undefined;
    collapseContainerHeader?: string | null | undefined;
    insuranceCoverHeader?: string | null | undefined;
    withAssistedInspection?: boolean | null | undefined;
    assistedInspectionHeader?: string | null | undefined;
    howItWorksHeader?: string | null | undefined;
    faqHeader?: string | null | undefined;
    slug?: string | null | undefined;
    policyContentCollection?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_policyContentCollection_DamagesPolicyPagePolicyContentCollection
      | null
      | undefined;
    collapsableContentCollection?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_collapsableContentCollection_DamagesPolicyPageCollapsableContentCollection
      | null
      | undefined;
    insuranceCoverDescription?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_insuranceCoverDescription_DamagesPolicyPageInsuranceCoverDescription
      | null
      | undefined;
    insuranceAccentBox?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_insuranceAccentBox_DamagesPolicyPageInsuranceAccentBox
      | null
      | undefined;
    assistedInspectionScreenshot?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionScreenshot_Asset
      | null
      | undefined;
    assistedInspectionDescription?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionDescription_DamagesPolicyPageAssistedInspectionDescription
      | null
      | undefined;
    assistedInspectionStepsCollection?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_assistedInspectionStepsCollection_DamagesPolicyPageAssistedInspectionStepsCollection
      | null
      | undefined;
    faqDescription?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_faqDescription_DamagesPolicyPageFaqDescription
      | null
      | undefined;
    faqButtonLink?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_faqButtonLink_Link
      | null
      | undefined;
    seoMetadata?:
      | DamagesPolicyPageFragment_DamagesPolicyPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetDamagesPolicyPageBySlugQuery_damagesPolicyPageCollection_DamagesPolicyPageCollection =
  {
    __typename?: 'DamagesPolicyPageCollection';
    items: Array<
      | GetDamagesPolicyPageBySlugQuery_damagesPolicyPageCollection_DamagesPolicyPageCollection_items_DamagesPolicyPage
      | null
      | undefined
    >;
  };

export type GetDamagesPolicyPageBySlugQuery_Query = {
  __typename?: 'Query';
  damagesPolicyPageCollection?:
    | GetDamagesPolicyPageBySlugQuery_damagesPolicyPageCollection_DamagesPolicyPageCollection
    | null
    | undefined;
};

export type GetDamagesPolicyPageBySlugQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetDamagesPolicyPageBySlugQuery =
  GetDamagesPolicyPageBySlugQuery_Query;

export type GetDemoPageQuery_demoPageCollection_DemoPageCollection_items_DemoPage =
  {
    __typename?: 'DemoPage';
    title?: string | null | undefined;
    subTitle?: string | null | undefined;
    initialTitle?: string | null | undefined;
    slug?: string | null | undefined;
    initialProfileImage?:
      | DemoPageFragment_DemoPage_initialProfileImage_Asset
      | null
      | undefined;
    initialDescription?:
      | DemoPageFragment_DemoPage_initialDescription_DemoPageInitialDescription
      | null
      | undefined;
    startImage?: DemoPageFragment_DemoPage_startImage_Asset | null | undefined;
    slidesCollection?:
      | DemoPageFragment_DemoPage_slidesCollection_DemoPageSlidesCollection
      | null
      | undefined;
    seoMetadata?:
      | DemoPageFragment_DemoPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetDemoPageQuery_demoPageCollection_DemoPageCollection = {
  __typename?: 'DemoPageCollection';
  items: Array<
    | GetDemoPageQuery_demoPageCollection_DemoPageCollection_items_DemoPage
    | null
    | undefined
  >;
};

export type GetDemoPageQuery_Query = {
  __typename?: 'Query';
  demoPageCollection?:
    | GetDemoPageQuery_demoPageCollection_DemoPageCollection
    | null
    | undefined;
};

export type GetDemoPageQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetDemoPageQuery = GetDemoPageQuery_Query;

export type GetDownloadPageBySlugQuery_downloadPageCollection_DownloadPageCollection_items_DownloadPage =
  {
    __typename?: 'DownloadPage';
    title?: string | null | undefined;
    stepperFindCar?: string | null | undefined;
    stepperScanQr?: string | null | undefined;
    stepperCreateAccount?: string | null | undefined;
    stepperBookCar?: string | null | undefined;
    downloadButton?: string | null | undefined;
    slug?: string | null | undefined;
    appPicture?:
      | DownloadPageFragment_DownloadPage_appPicture_Asset
      | null
      | undefined;
    headerText?:
      | DownloadPageFragment_DownloadPage_headerText_DownloadPageHeaderText
      | null
      | undefined;
    seoMetadata?:
      | DownloadPageFragment_DownloadPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetDownloadPageBySlugQuery_downloadPageCollection_DownloadPageCollection =
  {
    __typename?: 'DownloadPageCollection';
    items: Array<
      | GetDownloadPageBySlugQuery_downloadPageCollection_DownloadPageCollection_items_DownloadPage
      | null
      | undefined
    >;
  };

export type GetDownloadPageBySlugQuery_Query = {
  __typename?: 'Query';
  downloadPageCollection?:
    | GetDownloadPageBySlugQuery_downloadPageCollection_DownloadPageCollection
    | null
    | undefined;
};

export type GetDownloadPageBySlugQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
  slug: Scalars['String']['input'];
}>;

export type GetDownloadPageBySlugQuery = GetDownloadPageBySlugQuery_Query;

export type GetFaqEntryByPathQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections_faqCategoryCollection_FaqCategoryCollection_items_FaqCategory_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type GetFaqEntryByPathQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections_faqCategoryCollection_FaqCategoryCollection_items_FaqCategory =
  {
    __typename?: 'FaqCategory';
    title?: string | null | undefined;
    sys: GetFaqEntryByPathQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections_faqCategoryCollection_FaqCategoryCollection_items_FaqCategory_sys_Sys;
  };

export type GetFaqEntryByPathQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections_faqCategoryCollection_FaqCategoryCollection =
  {
    __typename?: 'FaqCategoryCollection';
    items: Array<
      | GetFaqEntryByPathQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections_faqCategoryCollection_FaqCategoryCollection_items_FaqCategory
      | null
      | undefined
    >;
  };

export type GetFaqEntryByPathQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections =
  {
    __typename?: 'FaqEntryLinkingCollections';
    faqCategoryCollection?:
      | GetFaqEntryByPathQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections_faqCategoryCollection_FaqCategoryCollection
      | null
      | undefined;
  };

export type GetFaqEntryByPathQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry =
  {
    __typename?: 'FaqEntry';
    title?: string | null | undefined;
    path?: string | null | undefined;
    slug?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
    linkedFrom?:
      | GetFaqEntryByPathQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections
      | null
      | undefined;
    sys: FaqEntryFragment_FaqEntry_sys_Sys;
    text?: FaqEntryFragment_FaqEntry_text_FaqEntryText | null | undefined;
    seoMetadata?:
      | FaqEntryFragment_FaqEntry_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetFaqEntryByPathQuery_faqEntryCollection_FaqEntryCollection = {
  __typename?: 'FaqEntryCollection';
  items: Array<
    | GetFaqEntryByPathQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry
    | null
    | undefined
  >;
};

export type GetFaqEntryByPathQuery_Query = {
  __typename?: 'Query';
  faqEntryCollection?:
    | GetFaqEntryByPathQuery_faqEntryCollection_FaqEntryCollection
    | null
    | undefined;
};

export type GetFaqEntryByPathQueryVariables = Exact<{
  path: Scalars['String']['input'];
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  allowedLocales?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  preview: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetFaqEntryByPathQuery = GetFaqEntryByPathQuery_Query;

export type GetFaqEntryBySlugQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections_faqCategoryCollection_FaqCategoryCollection_items_FaqCategory_sys_Sys =
  { __typename?: 'Sys'; id: string };

export type GetFaqEntryBySlugQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections_faqCategoryCollection_FaqCategoryCollection_items_FaqCategory =
  {
    __typename?: 'FaqCategory';
    title?: string | null | undefined;
    sys: GetFaqEntryBySlugQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections_faqCategoryCollection_FaqCategoryCollection_items_FaqCategory_sys_Sys;
  };

export type GetFaqEntryBySlugQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections_faqCategoryCollection_FaqCategoryCollection =
  {
    __typename?: 'FaqCategoryCollection';
    items: Array<
      | GetFaqEntryBySlugQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections_faqCategoryCollection_FaqCategoryCollection_items_FaqCategory
      | null
      | undefined
    >;
  };

export type GetFaqEntryBySlugQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections =
  {
    __typename?: 'FaqEntryLinkingCollections';
    faqCategoryCollection?:
      | GetFaqEntryBySlugQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections_faqCategoryCollection_FaqCategoryCollection
      | null
      | undefined;
  };

export type GetFaqEntryBySlugQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry =
  {
    __typename?: 'FaqEntry';
    title?: string | null | undefined;
    path?: string | null | undefined;
    slug?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
    linkedFrom?:
      | GetFaqEntryBySlugQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry_linkedFrom_FaqEntryLinkingCollections
      | null
      | undefined;
    sys: FaqEntryFragment_FaqEntry_sys_Sys;
    text?: FaqEntryFragment_FaqEntry_text_FaqEntryText | null | undefined;
    seoMetadata?:
      | FaqEntryFragment_FaqEntry_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetFaqEntryBySlugQuery_faqEntryCollection_FaqEntryCollection = {
  __typename?: 'FaqEntryCollection';
  items: Array<
    | GetFaqEntryBySlugQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry
    | null
    | undefined
  >;
};

export type GetFaqEntryBySlugQuery_Query = {
  __typename?: 'Query';
  faqEntryCollection?:
    | GetFaqEntryBySlugQuery_faqEntryCollection_FaqEntryCollection
    | null
    | undefined;
};

export type GetFaqEntryBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetFaqEntryBySlugQuery = GetFaqEntryBySlugQuery_Query;

export type GetFaqEntryPathsQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry =
  {
    __typename?: 'FaqEntry';
    path?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
  };

export type GetFaqEntryPathsQuery_faqEntryCollection_FaqEntryCollection = {
  __typename?: 'FaqEntryCollection';
  items: Array<
    | GetFaqEntryPathsQuery_faqEntryCollection_FaqEntryCollection_items_FaqEntry
    | null
    | undefined
  >;
};

export type GetFaqEntryPathsQuery_Query = {
  __typename?: 'Query';
  faqEntryCollection?:
    | GetFaqEntryPathsQuery_faqEntryCollection_FaqEntryCollection
    | null
    | undefined;
};

export type GetFaqEntryPathsQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetFaqEntryPathsQuery = GetFaqEntryPathsQuery_Query;

export type GetFaqPageBySlugQuery_faqPageCollection_FaqPageCollection_items_FaqPage =
  {
    __typename?: 'FaqPage';
    title?: string | null | undefined;
    headerTitle?: string | null | undefined;
    slug?: string | null | undefined;
    sys: FaqPageFragment_FaqPage_sys_Sys;
    headerBodyText?:
      | FaqPageFragment_FaqPage_headerBodyText_FaqPageHeaderBodyText
      | null
      | undefined;
    headerImage?: FaqPageFragment_FaqPage_headerImage_Asset | null | undefined;
    faqCategoriesCollection?:
      | FaqPageFragment_FaqPage_faqCategoriesCollection_FaqPageFaqCategoriesCollection
      | null
      | undefined;
    seoMetadata?:
      | FaqPageFragment_FaqPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetFaqPageBySlugQuery_faqPageCollection_FaqPageCollection = {
  __typename?: 'FaqPageCollection';
  items: Array<
    | GetFaqPageBySlugQuery_faqPageCollection_FaqPageCollection_items_FaqPage
    | null
    | undefined
  >;
};

export type GetFaqPageBySlugQuery_Query = {
  __typename?: 'Query';
  faqPageCollection?:
    | GetFaqPageBySlugQuery_faqPageCollection_FaqPageCollection
    | null
    | undefined;
};

export type GetFaqPageBySlugQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetFaqPageBySlugQuery = GetFaqPageBySlugQuery_Query;

export type GetGaragePageBySlugQuery_garagePageCollection_GaragePageCollection_items_GaragePage =
  {
    __typename?: 'GaragePage';
    title?: string | null | undefined;
    slug?: string | null | undefined;
    headerTitle?: string | null | undefined;
    whatIsIncluded?: Array<string | null | undefined> | null | undefined;
    priceText?: string | null | undefined;
    announcement?:
      | GaragePageFragment_GaragePage_announcement_Announcement
      | null
      | undefined;
    headerBodyText?:
      | GaragePageFragment_GaragePage_headerBodyText_GaragePageHeaderBodyText
      | null
      | undefined;
    headerImage?:
      | GaragePageFragment_GaragePage_headerImage_Asset
      | null
      | undefined;
    seoMetadata?:
      | GaragePageFragment_GaragePage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetGaragePageBySlugQuery_garagePageCollection_GaragePageCollection =
  {
    __typename?: 'GaragePageCollection';
    items: Array<
      | GetGaragePageBySlugQuery_garagePageCollection_GaragePageCollection_items_GaragePage
      | null
      | undefined
    >;
  };

export type GetGaragePageBySlugQuery_Query = {
  __typename?: 'Query';
  garagePageCollection?:
    | GetGaragePageBySlugQuery_garagePageCollection_GaragePageCollection
    | null
    | undefined;
};

export type GetGaragePageBySlugQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetGaragePageBySlugQuery = GetGaragePageBySlugQuery_Query;

export type GetLandingPageQuery_landingPageCollection_LandingPageCollection_items_LandingPage =
  {
    __typename?: 'LandingPage';
    title?: string | null | undefined;
    slug?: string | null | undefined;
    heroTitleText?: string | null | undefined;
    bookingFormTitle?: string | null | undefined;
    bookingFormSearchText?: string | null | undefined;
    locationDropdownLabel?: string | null | undefined;
    locationDropdownPlaceholder?: string | null | undefined;
    dateInputLabel?: string | null | undefined;
    dateInputPlaceholder?: string | null | undefined;
    garageLabel?: string | null | undefined;
    ageRequirementsAccentBox?: string | null | undefined;
    downloadAppTitle?: string | null | undefined;
    appFeatures?: Array<string | null | undefined> | null | undefined;
    appDescription?: string | null | undefined;
    cardPricesFromText?: string | null | undefined;
    budgetCategoryMaxPrice?: number | null | undefined;
    electricPropellantValue?: string | null | undefined;
    faqSectionTitle?: string | null | undefined;
    sys: LandingPageFragment_LandingPage_sys_Sys;
    announcement?:
      | LandingPageFragment_LandingPage_announcement_Announcement
      | null
      | undefined;
    heroBackgroundImage?:
      | LandingPageFragment_LandingPage_heroBackgroundImage_Asset
      | null
      | undefined;
    heroBackgroundImageMobile?:
      | LandingPageFragment_LandingPage_heroBackgroundImageMobile_Asset
      | null
      | undefined;
    featuresFlipCardsCollection?:
      | LandingPageFragment_LandingPage_featuresFlipCardsCollection_LandingPageFeaturesFlipCardsCollection
      | null
      | undefined;
    appImagesCollection?:
      | LandingPageFragment_LandingPage_appImagesCollection_AssetCollection
      | null
      | undefined;
    faqSectionItemsCollection?:
      | LandingPageFragment_LandingPage_faqSectionItemsCollection_LandingPageFaqSectionItemsCollection
      | null
      | undefined;
    seoMetadata?:
      | LandingPageFragment_LandingPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetLandingPageQuery_landingPageCollection_LandingPageCollection = {
  __typename?: 'LandingPageCollection';
  items: Array<
    | GetLandingPageQuery_landingPageCollection_LandingPageCollection_items_LandingPage
    | null
    | undefined
  >;
};

export type GetLandingPageQuery_Query = {
  __typename?: 'Query';
  landingPageCollection?:
    | GetLandingPageQuery_landingPageCollection_LandingPageCollection
    | null
    | undefined;
};

export type GetLandingPageQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetLandingPageQuery = GetLandingPageQuery_Query;

export type GetLandingPageB2BQuery_landingPageB2BCollection_LandingPageB2BCollection_items_LandingPageB2B =
  {
    __typename?: 'LandingPageB2B';
    title?: string | null | undefined;
    subText?: string | null | undefined;
    trustedPartnersTitle?: string | null | undefined;
    showCaseTitle?: string | null | undefined;
    showCaseSubTitle?: string | null | undefined;
    featuresTitle?: string | null | undefined;
    featuresSubTitle?: string | null | undefined;
    blogSectionTitle?: string | null | undefined;
    faqSectionTitle?: string | null | undefined;
    contactSectionTitle?: string | null | undefined;
    contactSectionSubtitle?: string | null | undefined;
    slug?: string | null | undefined;
    announcement?:
      | LandingPageB2BFragment_LandingPageB2B_announcement_Announcement
      | null
      | undefined;
    heroImage?:
      | LandingPageB2BFragment_LandingPageB2B_heroImage_Asset
      | null
      | undefined;
    partnerLogosCollection?:
      | LandingPageB2BFragment_LandingPageB2B_partnerLogosCollection_AssetCollection
      | null
      | undefined;
    showCaseSectionCollection?:
      | LandingPageB2BFragment_LandingPageB2B_showCaseSectionCollection_LandingPageB2BShowCaseSectionCollection
      | null
      | undefined;
    featuresFlipCardsCollection?:
      | LandingPageB2BFragment_LandingPageB2B_featuresFlipCardsCollection_LandingPageB2BFeaturesFlipCardsCollection
      | null
      | undefined;
    hightlightedBlogEntriesCollection?:
      | LandingPageB2BFragment_LandingPageB2B_hightlightedBlogEntriesCollection_LandingPageB2BHightlightedBlogEntriesCollection
      | null
      | undefined;
    faqSectionItemsCollection?:
      | LandingPageB2BFragment_LandingPageB2B_faqSectionItemsCollection_LandingPageB2BFaqSectionItemsCollection
      | null
      | undefined;
    seoMetadata?:
      | LandingPageB2BFragment_LandingPageB2B_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetLandingPageB2BQuery_landingPageB2BCollection_LandingPageB2BCollection =
  {
    __typename?: 'LandingPageB2BCollection';
    items: Array<
      | GetLandingPageB2BQuery_landingPageB2BCollection_LandingPageB2BCollection_items_LandingPageB2B
      | null
      | undefined
    >;
  };

export type GetLandingPageB2BQuery_Query = {
  __typename?: 'Query';
  landingPageB2BCollection?:
    | GetLandingPageB2BQuery_landingPageB2BCollection_LandingPageB2BCollection
    | null
    | undefined;
};

export type GetLandingPageB2BQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetLandingPageB2BQuery = GetLandingPageB2BQuery_Query;

export type GetLayoutQuery_siteSwitcherCollection_SiteSwitcherCollection_items_SiteSwitcher =
  {
    __typename?: 'SiteSwitcher';
    slug?: string | null | undefined;
    mode?: string | null | undefined;
  };

export type GetLayoutQuery_siteSwitcherCollection_SiteSwitcherCollection = {
  __typename?: 'SiteSwitcherCollection';
  items: Array<
    | GetLayoutQuery_siteSwitcherCollection_SiteSwitcherCollection_items_SiteSwitcher
    | null
    | undefined
  >;
};

export type GetLayoutQuery_footerCollection_FooterCollection_items_Footer = {
  __typename?: 'Footer';
  instagramLink?: string | null | undefined;
  facebookLink?: string | null | undefined;
  linkedInLink?: string | null | undefined;
  trustpilotLink?: string | null | undefined;
  tikTokLink?: string | null | undefined;
  youTubeLink?: string | null | undefined;
  googleLink?: string | null | undefined;
  trustpilotRating?: number | null | undefined;
  facebookRating?: number | null | undefined;
  googleRating?: number | null | undefined;
  fixedDribeFooterContent?: string | null | undefined;
  customerCareContactInfo?:
    | FooterFragment_Footer_customerCareContactInfo_FooterCustomerCareContactInfo
    | null
    | undefined;
  linkGroupsCollection?:
    | FooterFragment_Footer_linkGroupsCollection_FooterLinkGroupsCollection
    | null
    | undefined;
};

export type GetLayoutQuery_footerCollection_FooterCollection = {
  __typename?: 'FooterCollection';
  items: Array<
    | GetLayoutQuery_footerCollection_FooterCollection_items_Footer
    | null
    | undefined
  >;
};

export type GetLayoutQuery_menuCollection_MenuCollection_items_Menu = {
  __typename?: 'Menu';
  languageCode?: string | null | undefined;
  defaultPage?: string | null | undefined;
  redirectTimeout?: number | null | undefined;
  slug?: string | null | undefined;
  linksCollection?:
    | MenuFragment_Menu_linksCollection_MenuLinksCollection
    | null
    | undefined;
  b2BLinksCollection?:
    | MenuFragment_Menu_b2BLinksCollection_MenuB2bLinksCollection
    | null
    | undefined;
};

export type GetLayoutQuery_menuCollection_MenuCollection = {
  __typename?: 'MenuCollection';
  items: Array<
    GetLayoutQuery_menuCollection_MenuCollection_items_Menu | null | undefined
  >;
};

export type GetLayoutQuery_Query = {
  __typename?: 'Query';
  siteSwitcherCollection?:
    | GetLayoutQuery_siteSwitcherCollection_SiteSwitcherCollection
    | null
    | undefined;
  footerCollection?:
    | GetLayoutQuery_footerCollection_FooterCollection
    | null
    | undefined;
  menuCollection?:
    | GetLayoutQuery_menuCollection_MenuCollection
    | null
    | undefined;
};

export type GetLayoutQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetLayoutQuery = GetLayoutQuery_Query;

export type GetLegalEntryByPathQuery_legalEntryCollection_LegalEntryCollection_items_LegalEntry =
  {
    __typename?: 'LegalEntry';
    title?: string | null | undefined;
    tag?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
    path?: string | null | undefined;
    slug?: string | null | undefined;
    sys: LegalEntryFragment_LegalEntry_sys_Sys;
    text?: LegalEntryFragment_LegalEntry_text_LegalEntryText | null | undefined;
    seoMetadata?:
      | LegalEntryFragment_LegalEntry_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetLegalEntryByPathQuery_legalEntryCollection_LegalEntryCollection =
  {
    __typename?: 'LegalEntryCollection';
    items: Array<
      | GetLegalEntryByPathQuery_legalEntryCollection_LegalEntryCollection_items_LegalEntry
      | null
      | undefined
    >;
  };

export type GetLegalEntryByPathQuery_Query = {
  __typename?: 'Query';
  legalEntryCollection?:
    | GetLegalEntryByPathQuery_legalEntryCollection_LegalEntryCollection
    | null
    | undefined;
};

export type GetLegalEntryByPathQueryVariables = Exact<{
  path: Scalars['String']['input'];
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetLegalEntryByPathQuery = GetLegalEntryByPathQuery_Query;

export type GetLegalEntryBySlugQuery_legalEntryCollection_LegalEntryCollection_items_LegalEntry =
  {
    __typename?: 'LegalEntry';
    title?: string | null | undefined;
    tag?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
    path?: string | null | undefined;
    slug?: string | null | undefined;
    sys: LegalEntryFragment_LegalEntry_sys_Sys;
    text?: LegalEntryFragment_LegalEntry_text_LegalEntryText | null | undefined;
    seoMetadata?:
      | LegalEntryFragment_LegalEntry_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetLegalEntryBySlugQuery_legalEntryCollection_LegalEntryCollection =
  {
    __typename?: 'LegalEntryCollection';
    items: Array<
      | GetLegalEntryBySlugQuery_legalEntryCollection_LegalEntryCollection_items_LegalEntry
      | null
      | undefined
    >;
  };

export type GetLegalEntryBySlugQuery_Query = {
  __typename?: 'Query';
  legalEntryCollection?:
    | GetLegalEntryBySlugQuery_legalEntryCollection_LegalEntryCollection
    | null
    | undefined;
};

export type GetLegalEntryBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetLegalEntryBySlugQuery = GetLegalEntryBySlugQuery_Query;

export type GetLegalEntryPathsQuery_legalEntryCollection_LegalEntryCollection_items_LegalEntry =
  {
    __typename?: 'LegalEntry';
    path?: string | null | undefined;
    site?: Array<string | null | undefined> | null | undefined;
  };

export type GetLegalEntryPathsQuery_legalEntryCollection_LegalEntryCollection =
  {
    __typename?: 'LegalEntryCollection';
    items: Array<
      | GetLegalEntryPathsQuery_legalEntryCollection_LegalEntryCollection_items_LegalEntry
      | null
      | undefined
    >;
  };

export type GetLegalEntryPathsQuery_Query = {
  __typename?: 'Query';
  legalEntryCollection?:
    | GetLegalEntryPathsQuery_legalEntryCollection_LegalEntryCollection
    | null
    | undefined;
};

export type GetLegalEntryPathsQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetLegalEntryPathsQuery = GetLegalEntryPathsQuery_Query;

export type GetLegalPageBySlugQuery_legalPageCollection_LegalPageCollection_items_LegalPage =
  {
    __typename?: 'LegalPage';
    title?: string | null | undefined;
    headerTitle?: string | null | undefined;
    slug?: string | null | undefined;
    sys: LegalPageFragment_LegalPage_sys_Sys;
    legalEntriesCollection?:
      | LegalPageFragment_LegalPage_legalEntriesCollection_LegalPageLegalEntriesCollection
      | null
      | undefined;
    seoMetadata?:
      | LegalPageFragment_LegalPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetLegalPageBySlugQuery_legalPageCollection_LegalPageCollection = {
  __typename?: 'LegalPageCollection';
  items: Array<
    | GetLegalPageBySlugQuery_legalPageCollection_LegalPageCollection_items_LegalPage
    | null
    | undefined
  >;
};

export type GetLegalPageBySlugQuery_Query = {
  __typename?: 'Query';
  legalPageCollection?:
    | GetLegalPageBySlugQuery_legalPageCollection_LegalPageCollection
    | null
    | undefined;
};

export type GetLegalPageBySlugQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetLegalPageBySlugQuery = GetLegalPageBySlugQuery_Query;

export type GetOutOfStockPageQuery_outOfStockPageCollection_OutOfStockPageCollection_items_OutOfStockPage =
  {
    __typename?: 'OutOfStockPage';
    title?: string | null | undefined;
    subTitle?: string | null | undefined;
    watchlistTitle?: string | null | undefined;
    watchlistDescription?: string | null | undefined;
    slug?: string | null | undefined;
    mobileUiImageExample?:
      | OutOfStockPageFragment_OutOfStockPage_mobileUiImageExample_Asset
      | null
      | undefined;
    seoMetadata?:
      | OutOfStockPageFragment_OutOfStockPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetOutOfStockPageQuery_outOfStockPageCollection_OutOfStockPageCollection =
  {
    __typename?: 'OutOfStockPageCollection';
    items: Array<
      | GetOutOfStockPageQuery_outOfStockPageCollection_OutOfStockPageCollection_items_OutOfStockPage
      | null
      | undefined
    >;
  };

export type GetOutOfStockPageQuery_Query = {
  __typename?: 'Query';
  outOfStockPageCollection?:
    | GetOutOfStockPageQuery_outOfStockPageCollection_OutOfStockPageCollection
    | null
    | undefined;
};

export type GetOutOfStockPageQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetOutOfStockPageQuery = GetOutOfStockPageQuery_Query;

export type GetPricingPageQuery_pricingPageCollection_PricingPageCollection_items_PricingPage =
  {
    __typename?: 'PricingPage';
    title?: string | null | undefined;
    header?: string | null | undefined;
    exampleHeader?: string | null | undefined;
    checklistHeader?: string | null | undefined;
    slug?: string | null | undefined;
    exampleDescription?:
      | PricingPageFragment_PricingPage_exampleDescription_PricingPageExampleDescription
      | null
      | undefined;
    exampleRideCard?:
      | PricingPageFragment_PricingPage_exampleRideCard_PricingRideCard
      | null
      | undefined;
    priceTable?:
      | PricingPageFragment_PricingPage_priceTable_PricingPagePriceTable
      | null
      | undefined;
    accentBox?:
      | PricingPageFragment_PricingPage_accentBox_PricingPageAccentBox
      | null
      | undefined;
    leftChecklist?:
      | PricingPageFragment_PricingPage_leftChecklist_PricingPageLeftChecklist
      | null
      | undefined;
    rightChecklist?:
      | PricingPageFragment_PricingPage_rightChecklist_PricingPageRightChecklist
      | null
      | undefined;
    seoMetadata?:
      | PricingPageFragment_PricingPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetPricingPageQuery_pricingPageCollection_PricingPageCollection = {
  __typename?: 'PricingPageCollection';
  items: Array<
    | GetPricingPageQuery_pricingPageCollection_PricingPageCollection_items_PricingPage
    | null
    | undefined
  >;
};

export type GetPricingPageQuery_Query = {
  __typename?: 'Query';
  pricingPageCollection?:
    | GetPricingPageQuery_pricingPageCollection_PricingPageCollection
    | null
    | undefined;
};

export type GetPricingPageQueryVariables = Exact<{
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetPricingPageQuery = GetPricingPageQuery_Query;

export type GetProductPageBySlugQuery_productPageCollection_ProductPageCollection_items_ProductPage =
  {
    __typename?: 'ProductPage';
    title?: string | null | undefined;
    hideMsrpTaxContainer?: boolean | null | undefined;
    economyTableEntries?: Array<string | null | undefined> | null | undefined;
    economyTableBottomText?: string | null | undefined;
    includeLinkToPricingPage?: boolean | null | undefined;
    addOnsInfoText?: string | null | undefined;
    priceIncludesText?: string | null | undefined;
    slug?: string | null | undefined;
    sys: ProductPageFragment_ProductPage_sys_Sys;
    announcement?:
      | ProductPageFragment_ProductPage_announcement_Announcement
      | null
      | undefined;
    seoMetadata?:
      | ProductPageFragment_ProductPage_seoMetadata_SeoMetadata
      | null
      | undefined;
  };

export type GetProductPageBySlugQuery_productPageCollection_ProductPageCollection =
  {
    __typename?: 'ProductPageCollection';
    items: Array<
      | GetProductPageBySlugQuery_productPageCollection_ProductPageCollection_items_ProductPage
      | null
      | undefined
    >;
  };

export type GetProductPageBySlugQuery_Query = {
  __typename?: 'Query';
  productPageCollection?:
    | GetProductPageBySlugQuery_productPageCollection_ProductPageCollection
    | null
    | undefined;
};

export type GetProductPageBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  tagId?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview: Scalars['Boolean']['input'];
}>;

export type GetProductPageBySlugQuery = GetProductPageBySlugQuery_Query;

import { ReactNode } from 'react';

import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

import { trackEvent } from '@lib/rudderstack';
import { createLinkClickedSpec } from '@lib/rudderstack/EventSpecs';
import { generateAbsoluteURL } from '@utils/stringUtils';

export interface TrackedLinkProps
  extends LinkProps,
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> {
  title: string;
  children: ReactNode;
  extras?: { [key: string]: string };
}

const TrackedLink = (props: TrackedLinkProps) => {
  const { locale } = useRouter();

  const absoluteURL = generateAbsoluteURL(props.href.toString(), locale); // To always show absolute links in the DOM

  const trackOnCapture = () => {
    const event = createLinkClickedSpec(props.title, absoluteURL, props.extras);
    trackEvent(event);
  };

  return (
    <Link onClickCapture={trackOnCapture} {...props} href={absoluteURL}>
      {props.children}
    </Link>
  );
};

export default TrackedLink;

/* THIS FILE IS GENERATED WITH `pnpm watch` DO NOT EDIT MANUALLY */

import * as Apollo from '@apollo/client';

import { getApolloClient } from 'src/graphql/helpers/dribeAPIClient';
import * as D from './react-apollo';
import * as O from './operations';

type ClientOptionsParams = Parameters<typeof getApolloClient>;

export type GenericFetcher<QO = any, Res = any> = (
  queryOptions: QO,
  clientOptions: ClientOptionsParams
) => Promise<Apollo.ApolloQueryResult<Res>>;

export type ProductClusterAggregatesB2BQO = Omit<
  Apollo.QueryOptions<
    O.ProductClusterAggregatesB2BQueryVariables,
    O.ProductClusterAggregatesB2BQuery
  >,
  'query'
>;

export const getProductClusterAggregatesB2B = async (
  queryOptions: ProductClusterAggregatesB2BQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.ProductClusterAggregatesB2BQuery,
    O.ProductClusterAggregatesB2BQueryVariables
  >({
    query: D.ProductClusterAggregatesB2BDocument,
    ...queryOptions
  });

export type ProductClustersB2BQO = Omit<
  Apollo.QueryOptions<
    O.ProductClustersB2BQueryVariables,
    O.ProductClustersB2BQuery
  >,
  'query'
>;

export const getProductClustersB2B = async (
  queryOptions: ProductClustersB2BQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.ProductClustersB2BQuery,
    O.ProductClustersB2BQueryVariables
  >({
    query: D.ProductClustersB2BDocument,
    ...queryOptions
  });

export type ProductClusterAggregatesB2CQO = Omit<
  Apollo.QueryOptions<
    O.ProductClusterAggregatesB2CQueryVariables,
    O.ProductClusterAggregatesB2CQuery
  >,
  'query'
>;

export const getProductClusterAggregatesB2C = async (
  queryOptions: ProductClusterAggregatesB2CQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.ProductClusterAggregatesB2CQuery,
    O.ProductClusterAggregatesB2CQueryVariables
  >({
    query: D.ProductClusterAggregatesB2CDocument,
    ...queryOptions
  });

export type ProductClustersB2CQO = Omit<
  Apollo.QueryOptions<
    O.ProductClustersB2CQueryVariables,
    O.ProductClustersB2CQuery
  >,
  'query'
>;

export const getProductClustersB2C = async (
  queryOptions: ProductClustersB2CQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.ProductClustersB2CQuery,
    O.ProductClustersB2CQueryVariables
  >({
    query: D.ProductClustersB2CDocument,
    ...queryOptions
  });

export type GetDownloadLayoutDataQO = Omit<
  Apollo.QueryOptions<
    O.GetDownloadLayoutDataQueryVariables,
    O.GetDownloadLayoutDataQuery
  >,
  'query'
>;

export const getDownloadLayoutData = async (
  queryOptions: GetDownloadLayoutDataQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetDownloadLayoutDataQuery,
    O.GetDownloadLayoutDataQueryVariables
  >({
    query: D.GetDownloadLayoutDataDocument,
    ...queryOptions
  });

export type GetDribeConfigQO = Omit<
  Apollo.QueryOptions<O.GetDribeConfigQueryVariables, O.GetDribeConfigQuery>,
  'query'
>;

export const getDribeConfig = async (
  queryOptions: GetDribeConfigQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetDribeConfigQuery,
    O.GetDribeConfigQueryVariables
  >({
    query: D.GetDribeConfigDocument,
    ...queryOptions
  });

export type GetGarageSegmentsQO = Omit<
  Apollo.QueryOptions<
    O.GetGarageSegmentsQueryVariables,
    O.GetGarageSegmentsQuery
  >,
  'query'
>;

export const getGarageSegments = async (
  queryOptions: GetGarageSegmentsQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetGarageSegmentsQuery,
    O.GetGarageSegmentsQueryVariables
  >({
    query: D.GetGarageSegmentsDocument,
    ...queryOptions
  });

export type GetLocationsQO = Omit<
  Apollo.QueryOptions<O.GetLocationsQueryVariables, O.GetLocationsQuery>,
  'query'
>;

export const getLocations = async (
  queryOptions: GetLocationsQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetLocationsQuery,
    O.GetLocationsQueryVariables
  >({
    query: D.GetLocationsDocument,
    ...queryOptions
  });

export type GetProductPagePrefetchedDataQO = Omit<
  Apollo.QueryOptions<
    O.GetProductPagePrefetchedDataQueryVariables,
    O.GetProductPagePrefetchedDataQuery
  >,
  'query'
>;

export const getProductPagePrefetchedData = async (
  queryOptions: GetProductPagePrefetchedDataQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetProductPagePrefetchedDataQuery,
    O.GetProductPagePrefetchedDataQueryVariables
  >({
    query: D.GetProductPagePrefetchedDataDocument,
    ...queryOptions
  });

export type GetProductPagePreviewUrlPropsQO = Omit<
  Apollo.QueryOptions<
    O.GetProductPagePreviewUrlPropsQueryVariables,
    O.GetProductPagePreviewUrlPropsQuery
  >,
  'query'
>;

export const getProductPagePreviewUrlProps = async (
  queryOptions: GetProductPagePreviewUrlPropsQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetProductPagePreviewUrlPropsQuery,
    O.GetProductPagePreviewUrlPropsQueryVariables
  >({
    query: D.GetProductPagePreviewUrlPropsDocument,
    ...queryOptions
  });

export type GetProductPageProductClusterQO = Omit<
  Apollo.QueryOptions<
    O.GetProductPageProductClusterQueryVariables,
    O.GetProductPageProductClusterQuery
  >,
  'query'
>;

export const getProductPageProductCluster = async (
  queryOptions: GetProductPageProductClusterQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetProductPageProductClusterQuery,
    O.GetProductPageProductClusterQueryVariables
  >({
    query: D.GetProductPageProductClusterDocument,
    ...queryOptions
  });

export type GetProductStaticPathDataQO = Omit<
  Apollo.QueryOptions<
    O.GetProductStaticPathDataQueryVariables,
    O.GetProductStaticPathDataQuery
  >,
  'query'
>;

export const getProductStaticPathData = async (
  queryOptions: GetProductStaticPathDataQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetProductStaticPathDataQuery,
    O.GetProductStaticPathDataQueryVariables
  >({
    query: D.GetProductStaticPathDataDocument,
    ...queryOptions
  });

export type GetSitemapProductClustersQO = Omit<
  Apollo.QueryOptions<
    O.GetSitemapProductClustersQueryVariables,
    O.GetSitemapProductClustersQuery
  >,
  'query'
>;

export const getSitemapProductClusters = async (
  queryOptions: GetSitemapProductClustersQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetSitemapProductClustersQuery,
    O.GetSitemapProductClustersQueryVariables
  >({
    query: D.GetSitemapProductClustersDocument,
    ...queryOptions
  });

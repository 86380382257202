/* THIS FILE IS GENERATED WITH `pnpm watch` DO NOT EDIT MANUALLY */

import * as Apollo from '@apollo/client';

import { getApolloClient } from 'src/graphql/helpers/contentfulAPIClient';
import * as D from './react-apollo';
import * as O from './operations';

type ClientOptionsParams = Parameters<typeof getApolloClient>;

export type GenericFetcher<QO = any, Res = any> = (
  queryOptions: QO,
  clientOptions: ClientOptionsParams
) => Promise<Apollo.ApolloQueryResult<Res>>;

export type GetBlogEntryQO = Omit<
  Apollo.QueryOptions<O.GetBlogEntryQueryVariables, O.GetBlogEntryQuery>,
  'query'
>;

export const getBlogEntry = async (
  queryOptions: GetBlogEntryQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetBlogEntryQuery,
    O.GetBlogEntryQueryVariables
  >({
    query: D.GetBlogEntryDocument,
    ...queryOptions
  });

export type GetBlogEntryByPathQO = Omit<
  Apollo.QueryOptions<
    O.GetBlogEntryByPathQueryVariables,
    O.GetBlogEntryByPathQuery
  >,
  'query'
>;

export const getBlogEntryByPath = async (
  queryOptions: GetBlogEntryByPathQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetBlogEntryByPathQuery,
    O.GetBlogEntryByPathQueryVariables
  >({
    query: D.GetBlogEntryByPathDocument,
    ...queryOptions
  });

export type GetBlogEntryBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetBlogEntryBySlugQueryVariables,
    O.GetBlogEntryBySlugQuery
  >,
  'query'
>;

export const getBlogEntryBySlug = async (
  queryOptions: GetBlogEntryBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetBlogEntryBySlugQuery,
    O.GetBlogEntryBySlugQueryVariables
  >({
    query: D.GetBlogEntryBySlugDocument,
    ...queryOptions
  });

export type GetBlogEntryPathsQO = Omit<
  Apollo.QueryOptions<
    O.GetBlogEntryPathsQueryVariables,
    O.GetBlogEntryPathsQuery
  >,
  'query'
>;

export const getBlogEntryPaths = async (
  queryOptions: GetBlogEntryPathsQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetBlogEntryPathsQuery,
    O.GetBlogEntryPathsQueryVariables
  >({
    query: D.GetBlogEntryPathsDocument,
    ...queryOptions
  });

export type GetBlogPageBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetBlogPageBySlugQueryVariables,
    O.GetBlogPageBySlugQuery
  >,
  'query'
>;

export const getBlogPageBySlug = async (
  queryOptions: GetBlogPageBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetBlogPageBySlugQuery,
    O.GetBlogPageBySlugQueryVariables
  >({
    query: D.GetBlogPageBySlugDocument,
    ...queryOptions
  });

export type GetCampaignEntryByPathQO = Omit<
  Apollo.QueryOptions<
    O.GetCampaignEntryByPathQueryVariables,
    O.GetCampaignEntryByPathQuery
  >,
  'query'
>;

export const getCampaignEntryByPath = async (
  queryOptions: GetCampaignEntryByPathQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetCampaignEntryByPathQuery,
    O.GetCampaignEntryByPathQueryVariables
  >({
    query: D.GetCampaignEntryByPathDocument,
    ...queryOptions
  });

export type GetCampaignEntryBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetCampaignEntryBySlugQueryVariables,
    O.GetCampaignEntryBySlugQuery
  >,
  'query'
>;

export const getCampaignEntryBySlug = async (
  queryOptions: GetCampaignEntryBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetCampaignEntryBySlugQuery,
    O.GetCampaignEntryBySlugQueryVariables
  >({
    query: D.GetCampaignEntryBySlugDocument,
    ...queryOptions
  });

export type GetCampaignEntryPathsQO = Omit<
  Apollo.QueryOptions<
    O.GetCampaignEntryPathsQueryVariables,
    O.GetCampaignEntryPathsQuery
  >,
  'query'
>;

export const getCampaignEntryPaths = async (
  queryOptions: GetCampaignEntryPathsQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetCampaignEntryPathsQuery,
    O.GetCampaignEntryPathsQueryVariables
  >({
    query: D.GetCampaignEntryPathsDocument,
    ...queryOptions
  });

export type GetComingSoonPageQO = Omit<
  Apollo.QueryOptions<
    O.GetComingSoonPageQueryVariables,
    O.GetComingSoonPageQuery
  >,
  'query'
>;

export const getComingSoonPage = async (
  queryOptions: GetComingSoonPageQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetComingSoonPageQuery,
    O.GetComingSoonPageQueryVariables
  >({
    query: D.GetComingSoonPageDocument,
    ...queryOptions
  });

export type GetContactPageQO = Omit<
  Apollo.QueryOptions<O.GetContactPageQueryVariables, O.GetContactPageQuery>,
  'query'
>;

export const getContactPage = async (
  queryOptions: GetContactPageQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetContactPageQuery,
    O.GetContactPageQueryVariables
  >({
    query: D.GetContactPageDocument,
    ...queryOptions
  });

export type GetContactPageB2BQO = Omit<
  Apollo.QueryOptions<
    O.GetContactPageB2BQueryVariables,
    O.GetContactPageB2BQuery
  >,
  'query'
>;

export const getContactPageB2B = async (
  queryOptions: GetContactPageB2BQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetContactPageB2BQuery,
    O.GetContactPageB2BQueryVariables
  >({
    query: D.GetContactPageB2BDocument,
    ...queryOptions
  });

export type GetDamagesContactPageBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetDamagesContactPageBySlugQueryVariables,
    O.GetDamagesContactPageBySlugQuery
  >,
  'query'
>;

export const getDamagesContactPageBySlug = async (
  queryOptions: GetDamagesContactPageBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetDamagesContactPageBySlugQuery,
    O.GetDamagesContactPageBySlugQueryVariables
  >({
    query: D.GetDamagesContactPageBySlugDocument,
    ...queryOptions
  });

export type GetDamagesPageBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetDamagesPageBySlugQueryVariables,
    O.GetDamagesPageBySlugQuery
  >,
  'query'
>;

export const getDamagesPageBySlug = async (
  queryOptions: GetDamagesPageBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetDamagesPageBySlugQuery,
    O.GetDamagesPageBySlugQueryVariables
  >({
    query: D.GetDamagesPageBySlugDocument,
    ...queryOptions
  });

export type GetDamagesPolicyPageBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetDamagesPolicyPageBySlugQueryVariables,
    O.GetDamagesPolicyPageBySlugQuery
  >,
  'query'
>;

export const getDamagesPolicyPageBySlug = async (
  queryOptions: GetDamagesPolicyPageBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetDamagesPolicyPageBySlugQuery,
    O.GetDamagesPolicyPageBySlugQueryVariables
  >({
    query: D.GetDamagesPolicyPageBySlugDocument,
    ...queryOptions
  });

export type GetDemoPageQO = Omit<
  Apollo.QueryOptions<O.GetDemoPageQueryVariables, O.GetDemoPageQuery>,
  'query'
>;

export const getDemoPage = async (
  queryOptions: GetDemoPageQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetDemoPageQuery,
    O.GetDemoPageQueryVariables
  >({
    query: D.GetDemoPageDocument,
    ...queryOptions
  });

export type GetDownloadPageBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetDownloadPageBySlugQueryVariables,
    O.GetDownloadPageBySlugQuery
  >,
  'query'
>;

export const getDownloadPageBySlug = async (
  queryOptions: GetDownloadPageBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetDownloadPageBySlugQuery,
    O.GetDownloadPageBySlugQueryVariables
  >({
    query: D.GetDownloadPageBySlugDocument,
    ...queryOptions
  });

export type GetFaqEntryByPathQO = Omit<
  Apollo.QueryOptions<
    O.GetFaqEntryByPathQueryVariables,
    O.GetFaqEntryByPathQuery
  >,
  'query'
>;

export const getFaqEntryByPath = async (
  queryOptions: GetFaqEntryByPathQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetFaqEntryByPathQuery,
    O.GetFaqEntryByPathQueryVariables
  >({
    query: D.GetFaqEntryByPathDocument,
    ...queryOptions
  });

export type GetFaqEntryBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetFaqEntryBySlugQueryVariables,
    O.GetFaqEntryBySlugQuery
  >,
  'query'
>;

export const getFaqEntryBySlug = async (
  queryOptions: GetFaqEntryBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetFaqEntryBySlugQuery,
    O.GetFaqEntryBySlugQueryVariables
  >({
    query: D.GetFaqEntryBySlugDocument,
    ...queryOptions
  });

export type GetFaqEntryPathsQO = Omit<
  Apollo.QueryOptions<
    O.GetFaqEntryPathsQueryVariables,
    O.GetFaqEntryPathsQuery
  >,
  'query'
>;

export const getFaqEntryPaths = async (
  queryOptions: GetFaqEntryPathsQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetFaqEntryPathsQuery,
    O.GetFaqEntryPathsQueryVariables
  >({
    query: D.GetFaqEntryPathsDocument,
    ...queryOptions
  });

export type GetFaqPageBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetFaqPageBySlugQueryVariables,
    O.GetFaqPageBySlugQuery
  >,
  'query'
>;

export const getFaqPageBySlug = async (
  queryOptions: GetFaqPageBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetFaqPageBySlugQuery,
    O.GetFaqPageBySlugQueryVariables
  >({
    query: D.GetFaqPageBySlugDocument,
    ...queryOptions
  });

export type GetGaragePageBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetGaragePageBySlugQueryVariables,
    O.GetGaragePageBySlugQuery
  >,
  'query'
>;

export const getGaragePageBySlug = async (
  queryOptions: GetGaragePageBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetGaragePageBySlugQuery,
    O.GetGaragePageBySlugQueryVariables
  >({
    query: D.GetGaragePageBySlugDocument,
    ...queryOptions
  });

export type GetLandingPageQO = Omit<
  Apollo.QueryOptions<O.GetLandingPageQueryVariables, O.GetLandingPageQuery>,
  'query'
>;

export const getLandingPage = async (
  queryOptions: GetLandingPageQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetLandingPageQuery,
    O.GetLandingPageQueryVariables
  >({
    query: D.GetLandingPageDocument,
    ...queryOptions
  });

export type GetLandingPageB2BQO = Omit<
  Apollo.QueryOptions<
    O.GetLandingPageB2BQueryVariables,
    O.GetLandingPageB2BQuery
  >,
  'query'
>;

export const getLandingPageB2B = async (
  queryOptions: GetLandingPageB2BQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetLandingPageB2BQuery,
    O.GetLandingPageB2BQueryVariables
  >({
    query: D.GetLandingPageB2BDocument,
    ...queryOptions
  });

export type GetLayoutQO = Omit<
  Apollo.QueryOptions<O.GetLayoutQueryVariables, O.GetLayoutQuery>,
  'query'
>;

export const getLayout = async (
  queryOptions: GetLayoutQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetLayoutQuery,
    O.GetLayoutQueryVariables
  >({
    query: D.GetLayoutDocument,
    ...queryOptions
  });

export type GetLegalEntryByPathQO = Omit<
  Apollo.QueryOptions<
    O.GetLegalEntryByPathQueryVariables,
    O.GetLegalEntryByPathQuery
  >,
  'query'
>;

export const getLegalEntryByPath = async (
  queryOptions: GetLegalEntryByPathQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetLegalEntryByPathQuery,
    O.GetLegalEntryByPathQueryVariables
  >({
    query: D.GetLegalEntryByPathDocument,
    ...queryOptions
  });

export type GetLegalEntryBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetLegalEntryBySlugQueryVariables,
    O.GetLegalEntryBySlugQuery
  >,
  'query'
>;

export const getLegalEntryBySlug = async (
  queryOptions: GetLegalEntryBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetLegalEntryBySlugQuery,
    O.GetLegalEntryBySlugQueryVariables
  >({
    query: D.GetLegalEntryBySlugDocument,
    ...queryOptions
  });

export type GetLegalEntryPathsQO = Omit<
  Apollo.QueryOptions<
    O.GetLegalEntryPathsQueryVariables,
    O.GetLegalEntryPathsQuery
  >,
  'query'
>;

export const getLegalEntryPaths = async (
  queryOptions: GetLegalEntryPathsQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetLegalEntryPathsQuery,
    O.GetLegalEntryPathsQueryVariables
  >({
    query: D.GetLegalEntryPathsDocument,
    ...queryOptions
  });

export type GetLegalPageBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetLegalPageBySlugQueryVariables,
    O.GetLegalPageBySlugQuery
  >,
  'query'
>;

export const getLegalPageBySlug = async (
  queryOptions: GetLegalPageBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetLegalPageBySlugQuery,
    O.GetLegalPageBySlugQueryVariables
  >({
    query: D.GetLegalPageBySlugDocument,
    ...queryOptions
  });

export type GetOutOfStockPageQO = Omit<
  Apollo.QueryOptions<
    O.GetOutOfStockPageQueryVariables,
    O.GetOutOfStockPageQuery
  >,
  'query'
>;

export const getOutOfStockPage = async (
  queryOptions: GetOutOfStockPageQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetOutOfStockPageQuery,
    O.GetOutOfStockPageQueryVariables
  >({
    query: D.GetOutOfStockPageDocument,
    ...queryOptions
  });

export type GetPricingPageQO = Omit<
  Apollo.QueryOptions<O.GetPricingPageQueryVariables, O.GetPricingPageQuery>,
  'query'
>;

export const getPricingPage = async (
  queryOptions: GetPricingPageQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetPricingPageQuery,
    O.GetPricingPageQueryVariables
  >({
    query: D.GetPricingPageDocument,
    ...queryOptions
  });

export type GetProductPageBySlugQO = Omit<
  Apollo.QueryOptions<
    O.GetProductPageBySlugQueryVariables,
    O.GetProductPageBySlugQuery
  >,
  'query'
>;

export const getProductPageBySlug = async (
  queryOptions: GetProductPageBySlugQO,
  clientOptions: ClientOptionsParams = []
) =>
  await getApolloClient(...clientOptions).query<
    O.GetProductPageBySlugQuery,
    O.GetProductPageBySlugQueryVariables
  >({
    query: D.GetProductPageBySlugDocument,
    ...queryOptions
  });

import { useTranslation } from 'next-i18next';

import * as S from './MaintenanceLayout.styled';

const ComingSoonLayout = () => {
  const { t } = useTranslation();

  return (
    <S.OuterWrapper>
      <S.MaintenanceStack
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <S.MaintenanceImageDesktop
          src="/maintenance.webp"
          alt="Maintenance image"
        />
        <S.MaintenanceImageMobile
          src="/maintenance_mobile.webp"
          alt="Maintenance image"
        />
        <S.TitleText variant="h1" color="orange" element="h1">
          {t('maintenance.title')}
        </S.TitleText>
        <S.DescriptionText variant="body1" element="p">
          {t('maintenance.description')}
        </S.DescriptionText>
      </S.MaintenanceStack>
    </S.OuterWrapper>
  );
};

export default ComingSoonLayout;

import Image from 'next/image';

import { Inline, Typography } from '@dribe-io/ui-kit/components';

import { Maybe } from 'graphql/jsutils/Maybe';

import { TrackedLink } from '@components/Tracked';

type RatingProps = {
  title: string;
  rating: number;
  link: Maybe<string>;
};

const Rating = ({ title, rating, link }: RatingProps) => {
  const renderRating = () => (
    <Inline>
      <Inline flexDirection="column" flex={1} alignItems="stretch">
        <Inline
          space={8}
          pb={8}
          justifyContent="space-between"
          alignItems="center"
          flexGrow={1}
          flex={1}
        >
          <Typography variant="cta" color="secondary">
            {title}
          </Typography>
          <Typography variant="boldSM" color="secondary">
            {(Math.round(rating * 100) / 100).toFixed(1)}
          </Typography>
        </Inline>
        <Inline space={4}>
          {Array.from({ length: Math.ceil(rating) }).map((_, i) => (
            <Image
              key={`star-${i}`}
              src="/star.svg"
              alt="star"
              width={24}
              height={24}
              priority
            />
          ))}
        </Inline>
      </Inline>
    </Inline>
  );

  return link ? (
    <TrackedLink title={title} href={link}>
      {renderRating()}
    </TrackedLink>
  ) : (
    renderRating()
  );
};

export default Rating;

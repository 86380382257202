import * as C from '@dribe-io/ui-kit/components';

import styled from 'styled-components';

export const CategoryCardContainer = styled(C.Inline)`
  ${({ theme }) => theme.gloss.secondary};
  padding: ${({ theme }) => theme.spacing[24]};
  border-radius: 16px;
`;

export const IconContainer = styled(C.Inline)`
  padding: ${({ theme }) => theme.spacing[16]};
  border-radius: 6px;
  border: ${({ theme }) => theme.borders.regularM};

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        padding: ${theme.spacing[8]};
      `
    )};
`;

export const EntryTitle = styled(C.Typography)`
  font: ${({ theme }) => theme.typography['h4']};
  hyphens: auto;

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        font: ${theme.typography['h5']};
      `
    )};
`;

export const LinkText = styled(C.Typography)`
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.orange};
  }
`;

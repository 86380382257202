import dynamic from 'next/dynamic';

import { Typography } from '@dribe-io/ui-kit/components';
import { TypographyProps } from '@dribe-io/ui-kit/components/Typography/Typography';

import {
  Options,
  documentToReactComponents
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  MARKS,
  INLINES,
  Document,
  Block,
  Inline,
  Text
} from '@contentful/rich-text-types';
import { Maybe } from 'graphql/jsutils/Maybe';

import { TrackedLink } from '@components/Tracked';

import * as S from './RichText.styled';

type BlockTypes = Partial<Record<BLOCKS, Omit<TypographyProps, 'children'>>>;
type MarkTypes = Partial<Record<MARKS, Omit<TypographyProps, 'children'>>>;
type InlineTypes = Partial<Record<INLINES, Omit<TypographyProps, 'children'>>>;

type RichTextAsset = {
  sys: {
    id: string;
  };
  contentType?: Maybe<string>;
  url?: Maybe<string>;
  height?: Maybe<number>;
  width?: Maybe<number>;
  description?: Maybe<string>;
};

type RichTextProps = {
  json?: Document;
  className?: string;
  links?: {
    assets?: {
      block?: Maybe<RichTextAsset>[];
    };
    entries?: {
      block?: Maybe<RichTextAsset>[];
    };
  };
} & BlockTypes &
  MarkTypes &
  InlineTypes;

const defaultProps: RichTextProps = {
  paragraph: {
    variant: 'body1',
    element: 'p',
    color: 'petrol'
  },
  'heading-1': {
    variant: 'h1',
    element: 'h1',
    color: 'petrol'
  },
  'heading-2': {
    variant: 'h2',
    element: 'h2',
    color: 'petrol'
  },
  'heading-3': {
    variant: 'h3',
    element: 'h3',
    color: 'petrol'
  },
  'heading-4': {
    variant: 'h4',
    element: 'h4',
    color: 'petrol'
  },
  'heading-5': {
    variant: 'h5',
    element: 'h5',
    color: 'petrol'
  },
  'heading-6': {
    variant: 'sub1',
    element: 'h6',
    color: 'petrol'
  },
  hyperlink: {
    color: 'orange',
    textDecoration: 'underline'
  },
  underline: {
    textDecoration: 'underline'
  },
  blockquote: {
    variant: 'body1',
    color: 'orange'
  }
};

const RichText = (props: RichTextProps = defaultProps) => {
  const json = props.json;
  if (!json) {
    return <></>;
  }

  const assetMap = new Map<string, RichTextAsset>();
  const assetBlock = props.links?.assets?.block || [];
  for (const asset of assetBlock) {
    if (asset?.sys.id) {
      assetMap.set(asset.sys.id, asset);
    }
  }

  const entryMap = new Map();
  const entryBlock = props.links?.entries?.block || [];
  for (const entry of entryBlock) {
    if (entry?.sys.id) {
      entryMap.set(entry.sys.id, entry);
    }
  }

  const renderAsset = (node: Block | Inline) => {
    const asset = assetMap.get(node.data.target.sys.id);
    if (asset && asset.url) {
      switch (asset.contentType) {
        case 'video/ogg':
        case 'video/webm':
        case 'video/mp4':
          return (
            <S.RichTextVideo
              width="100%"
              height="100%"
              controls
              preload="metadata"
              playsInline
            >
              <source src={`${asset.url}#t=0.1`} type={asset.contentType} />
            </S.RichTextVideo>
          );
        case 'image/apng':
        case 'image/avif':
        case 'image/gif':
        case 'image/jpeg':
        case 'image/png':
        case 'image/webp':
          return (
            <S.RichTextImage
              src={asset.url}
              height={asset.height || 9}
              width={asset.width || 16}
              alt={asset.description || `rte-asset-${asset.contentType}`}
            />
          );
      }
    }
    return;
  };

  const options: Options = {
    renderMark: {
      [MARKS.BOLD]: text => (
        <Typography
          className="rich-text-bold"
          {...(props.bold || defaultProps.bold)}
        >
          {text}
        </Typography>
      ),
      [MARKS.UNDERLINE]: text => (
        <S.TypographyMark {...(props.underline || defaultProps.underline)}>
          {text}
        </S.TypographyMark>
      )
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => (
        <S.ParagraphBlock {...(props.paragraph || defaultProps.paragraph)}>
          {children}
        </S.ParagraphBlock>
      ),
      [BLOCKS.HEADING_1]: (_, children) => (
        <S.HeadingBlock {...(props['heading-1'] || defaultProps['heading-1'])}>
          {children}
        </S.HeadingBlock>
      ),
      [BLOCKS.HEADING_2]: (_, children) => (
        <S.HeadingBlock {...(props['heading-2'] || defaultProps['heading-2'])}>
          {children}
        </S.HeadingBlock>
      ),
      [BLOCKS.HEADING_3]: (_, children) => (
        <S.HeadingBlock {...(props['heading-3'] || defaultProps['heading-3'])}>
          {children}
        </S.HeadingBlock>
      ),
      [BLOCKS.HEADING_4]: (_, children) => (
        <S.HeadingBlock {...(props['heading-4'] || defaultProps['heading-4'])}>
          {children}
        </S.HeadingBlock>
      ),
      [BLOCKS.HEADING_5]: (_, children) => (
        <S.HeadingBlock {...(props['heading-5'] || defaultProps['heading-5'])}>
          {children}
        </S.HeadingBlock>
      ),
      [BLOCKS.HEADING_6]: (_, children) => (
        <S.HeadingBlock {...(props['heading-6'] || defaultProps['heading-6'])}>
          {children}
        </S.HeadingBlock>
      ),
      [BLOCKS.EMBEDDED_ASSET]: node => renderAsset(node),
      [BLOCKS.UL_LIST]: (_, children) => (
        <S.BulletList
          {...(props['unordered-list'] || defaultProps['unordered-list'])}
        >
          {children}
        </S.BulletList>
      ),
      [BLOCKS.LIST_ITEM]: (_, children) => (
        <S.ListItem {...(props['list-item'] || defaultProps['list-item'])}>
          <span>{children}</span>
        </S.ListItem>
      ),
      [BLOCKS.OL_LIST]: (_, children) => (
        <S.NumberList
          {...(props['ordered-list'] || defaultProps['ordered-list'])}
        >
          {children}
        </S.NumberList>
      ),
      [BLOCKS.TABLE]: (_, children) => (
        <S.RichTextTableOverflowWrapper>
          <S.RichTextTable
            {...(props['list-item'] || defaultProps['list-item'])}
          >
            <tbody>{children}</tbody>
          </S.RichTextTable>
        </S.RichTextTableOverflowWrapper>
      ),
      [INLINES.HYPERLINK]: ({ data, content }, children) => (
        <TrackedLink
          title={(content[0] as Text)?.value || ''}
          href={data.uri}
          prefetch={false}
        >
          <S.TypographyLink
            {...(props['hyperlink'] || defaultProps['hyperlink'])}
          >
            {children}
          </S.TypographyLink>
        </TrackedLink>
      ),
      [BLOCKS.QUOTE]: (_, children) => (
        <S.TypographyBlockQuote
          element="blockquote"
          {...(props['blockquote'] || defaultProps['blockquote'])}
        >
          {children}
        </S.TypographyBlockQuote>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const entry = entryMap.get(node.data.target.sys.id);

        switch (entry.__typename) {
          case 'DynamicForm': {
            // To avoid circular dependency issues between DynamicForm and RichText
            const LazyDynamicForm = dynamic(
              () => import('@components/DynamicForm')
            );
            return <LazyDynamicForm {...entry} />;
          }
          default:
            return;
        }
      }
    }
  };

  const richText = documentToReactComponents(json, options);
  return (
    <S.RichTextWrapper className={props.className}>
      {richText}
    </S.RichTextWrapper>
  );
};

export default RichText;

import { useCallback, useEffect, useRef, useState } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import { Button, Inline, IconButton } from '@dribe-io/ui-kit/components';

import { useTranslation } from 'next-i18next';

import { Maybe, MenuFragment } from '@api/contentful';
import { LanguageSelector } from '@components/LanguageSelector/LanguageSelector';
import Tracked, { TrackedLink } from '@components/Tracked';
import RouteNames from '@constants/RouteNames';
import ButtonActions from '@lib/rudderstack/ButtonActions';
import { createButtonClickedSpec } from '@lib/rudderstack/EventSpecs';
import LinkTitles from '@lib/rudderstack/LinkTitles';
import { IsB2B } from 'types/response';

import Menu from '../Menu';

import * as S from './Navbar.styled';

const DEBOUNCE_MS = 400;

type NavbarProps = {
  menuFragment: Maybe<MenuFragment>;
  navDisabled?: boolean;
  b2bUrl: Maybe<string>;
} & IsB2B;

export const Navbar = ({
  isB2B,
  menuFragment,
  navDisabled,
  b2bUrl
}: NavbarProps) => {
  const [showSwitch, setShowSwitch] = useState(false);
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  const { t } = useTranslation();

  const debounce = useRef<NodeJS.Timeout>();

  const router = useRouter();

  const handleScroll = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) {
        setShow(false);
        clearTimeout(debounce.current);
        debounce.current = setTimeout(() => {
          setShow(true);
        }, DEBOUNCE_MS);
      } else {
        setShow(true);
      }
      setLastScrollY(window.scrollY);
    }
  }, [lastScrollY]);

  useEffect(() => {
    return () => {
      clearTimeout(debounce.current);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    return;
  }, [lastScrollY, handleScroll]);

  const openMenu = (e: MouseEvent) => {
    setShowMenu(true);
    e.stopPropagation();
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;
  const B2B_SITE_URL = process.env.NEXT_PUBLIC_B2B_SITE_URL;

  const landingURL = isB2B
    ? `${RouteNames.B2B_LANDING}`
    : `${RouteNames.LANDING}`;
  const businessContactURL = `${RouteNames.B2B_CONTACT}`;

  const switcherUrl = isB2B
    ? `${RouteNames.LANDING}`
    : `${RouteNames.B2B_LANDING}`;

  const handleSwitcherClick = () => {
    router.push(switcherUrl);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setShowSwitch(true);
    }
  }, []);

  const renderLeftSideContent = () => {
    return (
      <Inline alignY="center" space={24}>
        <S.HideMobile alignItems="center">
          <TrackedLink title="Dribe logo" href={landingURL}>
            <Image
              src={isB2B ? '/logo-b2b.svg' : '/logo.svg'}
              alt="Dribe logo"
              width={141.3}
              height={36}
              priority
            />
          </TrackedLink>
          <S.HideTablet>
            {!!SITE_URL && !!B2B_SITE_URL && (
              <S.SwitcherTracked
                title={isB2B ? 'Dribe' : 'Dribe Business'}
                href={isB2B ? SITE_URL : B2B_SITE_URL} //TODO: Change to RouteNames once B2B is launching - remove env for B2B site URL! Also check bug with "/" at root
                prefetch={false}
              >
                <S.Switch
                  $willShow={showSwitch}
                  leftLabel={t('navbar.private')}
                  rightLabel={t('navbar.business')}
                  isActive={!!isB2B}
                  onClick={handleSwitcherClick}
                />
              </S.SwitcherTracked>
            )}
          </S.HideTablet>
        </S.HideMobile>
        <S.ShowMobileOnly alignItems="center">
          <TrackedLink title="Dee Logo" href={landingURL}>
            <Image
              src={isB2B ? '/deelogo-b2b.svg' : '/deelogo.svg'}
              alt="Dee Logo"
              width={31}
              height={31}
              priority
            />
          </TrackedLink>
        </S.ShowMobileOnly>
        <LanguageSelector />
      </Inline>
    );
  };

  const renderRightSideContent = () => {
    return (
      <S.NavbarRight
        space={40}
        alignY="center"
        justifyContent="flex-end"
        $navDisabled={navDisabled}
      >
        {isB2B ? (
          <>
            <S.HideMobile>
              <Inline alignY="center" space={24}>
                <TrackedLink
                  title={'B2B Garage'}
                  href={`${RouteNames.B2B_GARAGE}`}
                >
                  <S.MenuLink variant="cta" color={'primary'}>
                    {t('navbar.garage')}
                  </S.MenuLink>
                </TrackedLink>
                {!!b2bUrl && (
                  <TrackedLink
                    title={'B2B Portal'}
                    href={`https://${b2bUrl}`}
                    target="_blank"
                  >
                    <S.MenuLink variant="cta" color="petrol">
                      {t('navbar.login')}
                    </S.MenuLink>
                  </TrackedLink>
                )}
                <Button variant="secondary">
                  <S.ButtonLink
                    title={LinkTitles.CONTACT_PAGE_LINK}
                    href={businessContactURL}
                  >
                    <S.ButtonLinkText
                      color="petrol"
                      variant="sub1"
                      textTransform="uppercase"
                      textDecoration="none"
                    >
                      {t('navbar.contact')}
                    </S.ButtonLinkText>
                  </S.ButtonLink>
                </Button>
              </Inline>
            </S.HideMobile>
            <S.ShowMobileOnly>
              <Inline alignY="center" space={24}>
                <TrackedLink
                  title={LinkTitles.CONTACT_PAGE_LINK}
                  href={businessContactURL}
                >
                  <S.MenuLink variant="cta" color="petrol">
                    {t('navbar.contact')}
                  </S.MenuLink>
                </TrackedLink>
              </Inline>
            </S.ShowMobileOnly>
          </>
        ) : (
          <Inline alignY="center" space={24}>
            <TrackedLink
              title={LinkTitles.GARAGE_PAGE_LINK}
              href={`${RouteNames.GARAGE}`}
            >
              <S.MenuLink variant="cta" color={'primary'}>
                {t('navbar.garage')}
              </S.MenuLink>
            </TrackedLink>
            <S.HideMobile>
              <Button variant="secondary">
                <S.ButtonLink
                  title={LinkTitles.DOWNLOAD_APP_LINK}
                  href={`${RouteNames.DOWNLOAD}`}
                >
                  <S.ButtonLinkText
                    color="orange"
                    variant="sub1"
                    textTransform="uppercase"
                    textDecoration="none"
                  >
                    {t('navbar.downloadApp')}
                  </S.ButtonLinkText>
                </S.ButtonLink>
              </Button>
            </S.HideMobile>
          </Inline>
        )}
        <Inline pl={18}>
          <Tracked
            event={createButtonClickedSpec(ButtonActions.MENU_OPEN_CLICKED)}
          >
            <IconButton
              noHover={true}
              iconName={'menu'}
              iconStyle={{
                size: 32,
                color: 'primary'
              }}
              onClick={openMenu}
            />
          </Tracked>
        </Inline>
      </S.NavbarRight>
    );
  };

  return (
    <S.Transition>
      <S.Navbar className={show ? 'ease-in' : 'ease-out'}>
        <S.NavbarContentWrapper>
          {renderLeftSideContent()}
          {renderRightSideContent()}
        </S.NavbarContentWrapper>
      </S.Navbar>
      <S.MenuContainer
        className={showMenu ? 'fade-in' : 'hide'}
        onClick={closeMenu}
      >
        <Menu onClose={closeMenu} isB2B={isB2B} {...menuFragment} />
      </S.MenuContainer>
    </S.Transition>
  );
};

export default Navbar;

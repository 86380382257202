import { Inline, Stack, Typography } from '@dribe-io/ui-kit/components';

import { useTranslation } from 'next-i18next';

import { FooterFragment } from '@api/contentful';
import { TrackedLink } from '@components/Tracked';
import RouteNames from '@constants/RouteNames';
import RichText from '@utils/RichText';
import { IsB2B } from 'types/response';

import Download from './Download';
import * as S from './Footer.styled';
import Rating from './Rating';
import SocialLinks from './SocialLinks';

interface FooterProps extends FooterFragment {
  navDisabled?: boolean;
}

const Footer = ({
  linkGroupsCollection,
  instagramLink,
  facebookLink,
  linkedInLink,
  trustpilotLink,
  tikTokLink,
  youTubeLink,
  googleLink,
  trustpilotRating,
  facebookRating,
  googleRating,
  fixedDribeFooterContent,
  customerCareContactInfo,
  navDisabled,
  isB2B
}: FooterProps & IsB2B) => {
  const linkGroups = linkGroupsCollection?.items;
  const hasSocialLinks =
    !!instagramLink ||
    !!facebookLink ||
    !!linkedInLink ||
    !!tikTokLink ||
    !!youTubeLink;
  const hasRatings = !!trustpilotRating || !!facebookRating || !!googleRating;

  const { t } = useTranslation();

  const renderLink = () => {
    if (!linkGroups) return null;

    const linkType = isB2B ? 'b2BLinksCollection' : 'linksCollection';
    const defaultHref = isB2B ? RouteNames.B2B_LANDING : RouteNames.LANDING;

    return linkGroups.map(linkGroup => {
      const items = linkGroup?.[linkType]?.items;

      if (!items || items.length === 0) return null;

      return (
        <Stack key={linkGroup?.sys.id}>
          {!!linkGroup?.title && (
            <Typography variant="h5" element="p" color="secondary">
              {linkGroup.title}
            </Typography>
          )}
          <Stack flexDirection="column" space={16} pt={24}>
            {items.map(link => (
              <TrackedLink
                key={link?.sys.id}
                title={link?.title || ''}
                href={link?.url || `${defaultHref}`}
              >
                <Typography variant="cta" color="snow">
                  {link?.title || ''}
                </Typography>
              </TrackedLink>
            ))}
          </Stack>
        </Stack>
      );
    });
  };
  return (
    <S.FooterContainer $navDisabled={navDisabled}>
      <S.FooterContentWrapper>
        <S.TopContainer
          flexGrow={1}
          flexWrap="wrap"
          justifyItems="center"
          justifyContent="flex-start"
        >
          <Inline flex={1}>
            <Download downloadText={t('footer.downloadApp')} />
          </Inline>
          {hasRatings && (
            <S.RatingContainer>
              {!!trustpilotRating && (
                <Rating
                  title="Trustpilot"
                  rating={trustpilotRating}
                  link={trustpilotLink}
                />
              )}
              {!!googleRating && (
                <Rating
                  title="Google"
                  rating={googleRating}
                  link={googleLink}
                />
              )}
              {!!facebookRating && (
                <Rating
                  title="Facebook"
                  rating={facebookRating}
                  link={facebookLink}
                />
              )}
            </S.RatingContainer>
          )}
        </S.TopContainer>
        {!!linkGroups?.length && (
          <S.LinksContainer>{renderLink()}</S.LinksContainer>
        )}
        <S.Divider />
        <SocialLinks
          hasSocialLinks={hasSocialLinks}
          instagramLink={instagramLink}
          facebookLink={facebookLink}
          linkedInLink={linkedInLink}
          tikTokLink={tikTokLink}
          youTubeLink={youTubeLink}
        />
        <S.BottomContainer>
          <div>
            <Typography variant="cta" color="secondary">
              {fixedDribeFooterContent}&nbsp;
              <RichText
                json={customerCareContactInfo?.json}
                paragraph={{ variant: 'cta', color: 'secondary' }}
              />
            </Typography>
          </div>
        </S.BottomContainer>
      </S.FooterContentWrapper>
    </S.FooterContainer>
  );
};

export default Footer;

import { MouseEvent, useCallback } from 'react';

import { useRouter } from 'next/router';

import { IconButton } from '@dribe-io/ui-kit/components';

import { Maybe, MenuFragment } from '@api/contentful';
import Tracked, { TrackedLink } from '@components/Tracked';
import RouteNames from '@constants/RouteNames';
import ButtonActions from '@lib/rudderstack/ButtonActions';
import { createButtonClickedSpec } from '@lib/rudderstack/EventSpecs';
import { IsB2B } from 'types/response';

import * as S from './Menu.styled';

type MenuProps = {
  onClose: () => void;
} & Maybe<MenuFragment> &
  IsB2B;

export const Menu = ({
  onClose,
  isB2B,
  linksCollection,
  b2BLinksCollection
}: MenuProps) => {
  const router = useRouter();

  const preventDefault = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const renderMenuLinks = useCallback(
    (
      menuLinks:
        | Maybe<MenuFragment['b2BLinksCollection']>
        | Maybe<MenuFragment['linksCollection']>
    ) => {
      return menuLinks?.items?.map(menuLink => (
        <TrackedLink
          title={menuLink?.title || ''}
          key={menuLink?.sys.id}
          href={menuLink?.url || `${RouteNames.LANDING}`}
          onClick={onClose}
        >
          <S.MenuLink
            color={router.pathname === menuLink?.url ? 'orange' : 'petrol'}
            variant="h4"
          >
            {menuLink?.title}
          </S.MenuLink>
        </TrackedLink>
      ));
    },
    [onClose, router.pathname]
  );

  return (
    <S.Menu>
      <S.CloseButton>
        <S.HideMobile>
          <Tracked
            event={createButtonClickedSpec(ButtonActions.MENU_CLOSE_CLICKED)}
          >
            <IconButton
              noHover={true}
              iconName={'close'}
              iconStyle={{
                size: 32,
                color: 'petrol'
              }}
              onClick={onClose}
            />
          </Tracked>
        </S.HideMobile>
        <S.ShowMobileOnly>
          <Tracked
            event={createButtonClickedSpec(ButtonActions.MENU_CLOSE_CLICKED)}
          >
            <IconButton
              noHover={true}
              iconName={'close'}
              iconStyle={{
                size: 24,
                color: 'secondary'
              }}
              onClick={onClose}
            />
          </Tracked>
        </S.ShowMobileOnly>
      </S.CloseButton>
      <S.GlossyContainer onClick={preventDefault}>
        <S.MenuContentWrapper>
          <S.LinksContainer>
            {renderMenuLinks(isB2B ? b2BLinksCollection : linksCollection)}
          </S.LinksContainer>
        </S.MenuContentWrapper>
      </S.GlossyContainer>
    </S.Menu>
  );
};

export default Menu;

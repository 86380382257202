import { Stack, Typography } from '@dribe-io/ui-kit/components';

import { ComingSoonPageFragment } from '@api/contentful';

import * as S from './ComingSoonLayout.styled';

const ComingSoonLayout = ({
  headerImage,
  header,
  description,
  previewLocationsCollection
}: ComingSoonPageFragment) => {
  return (
    <S.OuterWrapper>
      <S.ComingSoonContainer>
        <S.ComingSoonContent
          flexDirection="column"
          alignItems="center"
          mx="auto"
        >
          <S.ComingSoonImage
            src={headerImage?.url ?? '/coming-soon.webp'}
            alt={headerImage?.description ?? 'Coming soon image'}
          />
          <S.Title variant="h1" color="orange" element="h1">
            {header}
          </S.Title>
          <Stack>
            <S.StyledRichText json={description?.json} />
          </Stack>
          <S.CardGrid>
            {previewLocationsCollection?.items?.map((location, idx) => (
              <S.LocationCard
                key={`${location?.city}-${idx}`}
                title={location?.city}
                iconName={'business'}
              >
                <Stack py={12}>
                  <Typography variant="body2" color="petrol" element="p">
                    {location?.address1}
                  </Typography>
                  <Typography variant="body2" color="petrol" element="p">
                    {location?.address2}
                  </Typography>
                </Stack>
              </S.LocationCard>
            ))}
          </S.CardGrid>
        </S.ComingSoonContent>
      </S.ComingSoonContainer>
    </S.OuterWrapper>
  );
};

export default ComingSoonLayout;

import * as C from '@dribe-io/ui-kit/components';

import styled from 'styled-components';

import { contentPadding } from '@theme/layout';

export const OuterWrapper = styled.div`
  min-height: 50vh;
  ${contentPadding}
`;

export const MaintenanceStack = styled(C.Stack)`
  display: flex;
`;

export const MaintenanceImageDesktop = styled(C.Image)`
  margin-top: ${({ theme }) => theme.spacing[18]};
  margin-bottom: ${({ theme }) => theme.spacing[64]};
  width: 100%;
  max-width: 43.4375rem;

  display: none;

  ${({ theme }) =>
    theme.breakpoints.up(
      'sm',
      `
        display: block;
      `
    )};
`;

export const MaintenanceImageMobile = styled(C.Image)`
  margin-top: ${({ theme }) => theme.spacing[18]};
  margin-bottom: ${({ theme }) => theme.spacing[64]};
  width: 100%;
  max-width: 22.9375rem;
  display: none;

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        display: block;
      `
    )};
`;

export const TitleText = styled(C.Typography)`
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        font-size: 3rem;
        line-height: 4.5rem;
      `
    )};
`;

export const DescriptionText = styled(C.Typography)`
  width: 50%;
  text-align: center;

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        width: 90%;
      `
    )};
`;

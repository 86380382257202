import * as C from '@dribe-io/ui-kit/components';

import styled, { css } from 'styled-components';

import {
  CONTENT_PADDING_HORIZONTAL,
  CONTENT_PADDING_HORIZONTAL_MD,
  CONTENT_PADDING_HORIZONTAL_SM,
  contentWidthBoundary,
  navbarHeight
} from '@theme/layout';

export const HideMobile = styled(C.Inline)`
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        display: none;
      `
    )}
`;

export const ShowMobileOnly = styled(C.Inline)`
  display: none;
  padding-right: ${({ theme }) => theme.spacing[16]};
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        display: flex;
      `
    )}
`;

export const Menu = styled(C.Inline)`
  flex-direction: column;
  height: 100%;
  width: 100%;

  border-left: ${({ theme }) => theme.spacing[CONTENT_PADDING_HORIZONTAL]} solid;
  border-right: ${({ theme }) => theme.spacing[CONTENT_PADDING_HORIZONTAL]}
    solid;
  border-bottom: ${({ theme }) => theme.spacing[CONTENT_PADDING_HORIZONTAL]}
    solid;
  border-image-source: linear-gradient(
    to bottom,
    ${({ theme }) => theme.palette.snow} 50%,
    ${({ theme }) => theme.palette.xLightGrey} 50%
  );
  border-image-slice: 1;
  border-image-width: ${({ theme }) =>
    theme.spacing[CONTENT_PADDING_HORIZONTAL]};

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        border-left: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_MD]} solid;
        border-right: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_MD]} solid;
        border-bottom: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_MD]} solid;
      `
    )};

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        border-left: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_SM]} solid;
        border-right: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_SM]} solid;
        border-bottom: ${theme.spacing[CONTENT_PADDING_HORIZONTAL_SM]} solid;
      `
    )};
`;

export const MenuContentWrapper = styled.div`
  ${contentWidthBoundary}
  width: 100%;
`;

export const GlossyContainer = styled(C.Inline)`
  overflow-y: auto;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.gloss.primary};
  outline: 6px solid ${({ theme }) => theme.palette.snow};
  padding: ${({ theme }) => `${theme.spacing[40]} ${theme.spacing[104]}`};
  border-radius: 16px;
  justify-content: flex-start;
  align-items: stretch;
  & > *:last-child {
    margin-right: auto;
  }

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        justify-content: flex-start;
        padding: ${theme.spacing[32]};
      `
    )};
`;

export const LinksContainer = styled(C.Inline)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[32]};
`;

export const CloseButton = styled(C.Inline)`
  padding-top: ${({ theme }) => theme.spacing[8]};
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.snow};
  ${contentWidthBoundary}
  ${navbarHeight}
`;

export const MenuLink = styled(C.Typography)`
  font: ${({ theme }) => theme.typography.h4};

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        font: ${theme.typography.h5};
      `
    )}

  &:hover {
    color: ${({ theme }) => theme.palette.orange};
  }
`;

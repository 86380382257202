/* THIS FILE IS GENERATED WITH `pnpm watch` DO NOT EDIT MANUALLY */

import * as O from './operations';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const CommonAggregatesFragmentDoc = gql`
  fragment CommonAggregates on AggregatesMeta {
    brand {
      key
    }
    fuelType {
      key
    }
    segment {
      key
    }
    transmission {
      key
    }
  }
`;
export const CommonProductClustersFragmentDoc = gql`
  fragment CommonProductClusters on ProductClusters {
    data {
      id
      available
      brand
      model
      images {
        tag
        url
      }
      reviewsInfo {
        rating
        totalReviews
      }
      pricing {
        rate
      }
    }
    meta {
      pagination {
        totalItems
      }
    }
  }
`;
export const ProductClusterAggregatesB2BDocument = gql`
  query ProductClusterAggregatesB2B {
    productClusters(viewCategory: B2B, page: 1, pageSize: 200) {
      meta {
        aggregates {
          ...CommonAggregates
          minB2BPrice {
            value
          }
          maxB2BPrice {
            value
          }
          minB2BPriceWithVAT {
            value
          }
          minMonthlyTaxExpense {
            value
          }
          maxMonthlyTaxExpense {
            value
          }
        }
      }
    }
  }
  ${CommonAggregatesFragmentDoc}
`;

/**
 * __useProductClusterAggregatesB2BQuery__
 *
 * To run a query within a React component, call `useProductClusterAggregatesB2BQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductClusterAggregatesB2BQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductClusterAggregatesB2BQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductClusterAggregatesB2BQuery(
  baseOptions?: Apollo.QueryHookOptions<
    O.ProductClusterAggregatesB2BQuery,
    O.ProductClusterAggregatesB2BQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    O.ProductClusterAggregatesB2BQuery,
    O.ProductClusterAggregatesB2BQueryVariables
  >(ProductClusterAggregatesB2BDocument, options);
}
export function useProductClusterAggregatesB2BLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.ProductClusterAggregatesB2BQuery,
    O.ProductClusterAggregatesB2BQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    O.ProductClusterAggregatesB2BQuery,
    O.ProductClusterAggregatesB2BQueryVariables
  >(ProductClusterAggregatesB2BDocument, options);
}
export function useProductClusterAggregatesB2BSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.ProductClusterAggregatesB2BQuery,
    O.ProductClusterAggregatesB2BQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.ProductClusterAggregatesB2BQuery,
    O.ProductClusterAggregatesB2BQueryVariables
  >(ProductClusterAggregatesB2BDocument, options);
}
export type ProductClusterAggregatesB2BQueryHookResult = ReturnType<
  typeof useProductClusterAggregatesB2BQuery
>;
export type ProductClusterAggregatesB2BLazyQueryHookResult = ReturnType<
  typeof useProductClusterAggregatesB2BLazyQuery
>;
export type ProductClusterAggregatesB2BSuspenseQueryHookResult = ReturnType<
  typeof useProductClusterAggregatesB2BSuspenseQuery
>;
export type ProductClusterAggregatesB2BQueryResult = Apollo.QueryResult<
  O.ProductClusterAggregatesB2BQuery,
  O.ProductClusterAggregatesB2BQueryVariables
>;
export const ProductClustersB2BDocument = gql`
  query ProductClustersB2B(
    $active: Boolean
    $available: Boolean
    $brand: [String!]
    $model: [String!]
    $sortBy: String
    $sortDir: String
    $minB2BPrice: Number
    $maxB2BPrice: Number
    $minMonthlyTaxExpense: Number
    $maxMonthlyTaxExpense: Number
    $pickupStart: String
    $pickupEnd: String
    $locationId: [String!]
    $fuelType: [String!]
    $transmission: [String!]
    $segment: [String!]
  ) {
    productClusters(
      page: 1
      pageSize: 200
      viewCategory: B2B
      active: $active
      available: $available
      brand: $brand
      model: $model
      sortBy: $sortBy
      sortDir: $sortDir
      minB2BPrice: $minB2BPrice
      maxB2BPrice: $maxB2BPrice
      minMonthlyTaxExpense: $minMonthlyTaxExpense
      maxMonthlyTaxExpense: $maxMonthlyTaxExpense
      pickupStart: $pickupStart
      pickupEnd: $pickupEnd
      locationId: $locationId
      propellant: { strings: $fuelType }
      transmission: { strings: $transmission }
      vehicleSegment: { strings: $segment }
    ) {
      ...CommonProductClusters
      data {
        pricing {
          monthlyTaxBasis
        }
      }
    }
  }
  ${CommonProductClustersFragmentDoc}
`;

/**
 * __useProductClustersB2BQuery__
 *
 * To run a query within a React component, call `useProductClustersB2BQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductClustersB2BQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductClustersB2BQuery({
 *   variables: {
 *      active: // value for 'active'
 *      available: // value for 'available'
 *      brand: // value for 'brand'
 *      model: // value for 'model'
 *      sortBy: // value for 'sortBy'
 *      sortDir: // value for 'sortDir'
 *      minB2BPrice: // value for 'minB2BPrice'
 *      maxB2BPrice: // value for 'maxB2BPrice'
 *      minMonthlyTaxExpense: // value for 'minMonthlyTaxExpense'
 *      maxMonthlyTaxExpense: // value for 'maxMonthlyTaxExpense'
 *      pickupStart: // value for 'pickupStart'
 *      pickupEnd: // value for 'pickupEnd'
 *      locationId: // value for 'locationId'
 *      fuelType: // value for 'fuelType'
 *      transmission: // value for 'transmission'
 *      segment: // value for 'segment'
 *   },
 * });
 */
export function useProductClustersB2BQuery(
  baseOptions?: Apollo.QueryHookOptions<
    O.ProductClustersB2BQuery,
    O.ProductClustersB2BQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    O.ProductClustersB2BQuery,
    O.ProductClustersB2BQueryVariables
  >(ProductClustersB2BDocument, options);
}
export function useProductClustersB2BLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.ProductClustersB2BQuery,
    O.ProductClustersB2BQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    O.ProductClustersB2BQuery,
    O.ProductClustersB2BQueryVariables
  >(ProductClustersB2BDocument, options);
}
export function useProductClustersB2BSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.ProductClustersB2BQuery,
    O.ProductClustersB2BQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.ProductClustersB2BQuery,
    O.ProductClustersB2BQueryVariables
  >(ProductClustersB2BDocument, options);
}
export type ProductClustersB2BQueryHookResult = ReturnType<
  typeof useProductClustersB2BQuery
>;
export type ProductClustersB2BLazyQueryHookResult = ReturnType<
  typeof useProductClustersB2BLazyQuery
>;
export type ProductClustersB2BSuspenseQueryHookResult = ReturnType<
  typeof useProductClustersB2BSuspenseQuery
>;
export type ProductClustersB2BQueryResult = Apollo.QueryResult<
  O.ProductClustersB2BQuery,
  O.ProductClustersB2BQueryVariables
>;
export const ProductClusterAggregatesB2CDocument = gql`
  query ProductClusterAggregatesB2C {
    productClusters(viewCategory: EVERYDAY) {
      meta {
        aggregates {
          ...CommonAggregates
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
      }
    }
  }
  ${CommonAggregatesFragmentDoc}
`;

/**
 * __useProductClusterAggregatesB2CQuery__
 *
 * To run a query within a React component, call `useProductClusterAggregatesB2CQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductClusterAggregatesB2CQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductClusterAggregatesB2CQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductClusterAggregatesB2CQuery(
  baseOptions?: Apollo.QueryHookOptions<
    O.ProductClusterAggregatesB2CQuery,
    O.ProductClusterAggregatesB2CQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    O.ProductClusterAggregatesB2CQuery,
    O.ProductClusterAggregatesB2CQueryVariables
  >(ProductClusterAggregatesB2CDocument, options);
}
export function useProductClusterAggregatesB2CLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.ProductClusterAggregatesB2CQuery,
    O.ProductClusterAggregatesB2CQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    O.ProductClusterAggregatesB2CQuery,
    O.ProductClusterAggregatesB2CQueryVariables
  >(ProductClusterAggregatesB2CDocument, options);
}
export function useProductClusterAggregatesB2CSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.ProductClusterAggregatesB2CQuery,
    O.ProductClusterAggregatesB2CQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.ProductClusterAggregatesB2CQuery,
    O.ProductClusterAggregatesB2CQueryVariables
  >(ProductClusterAggregatesB2CDocument, options);
}
export type ProductClusterAggregatesB2CQueryHookResult = ReturnType<
  typeof useProductClusterAggregatesB2CQuery
>;
export type ProductClusterAggregatesB2CLazyQueryHookResult = ReturnType<
  typeof useProductClusterAggregatesB2CLazyQuery
>;
export type ProductClusterAggregatesB2CSuspenseQueryHookResult = ReturnType<
  typeof useProductClusterAggregatesB2CSuspenseQuery
>;
export type ProductClusterAggregatesB2CQueryResult = Apollo.QueryResult<
  O.ProductClusterAggregatesB2CQuery,
  O.ProductClusterAggregatesB2CQueryVariables
>;
export const ProductClustersB2CDocument = gql`
  query ProductClustersB2C(
    $active: Boolean
    $available: Boolean
    $brand: [String!]
    $model: [String!]
    $sortBy: String
    $sortDir: String
    $minPrice: Number
    $maxPrice: Number
    $pickupStart: String
    $pickupEnd: String
    $locationId: [String!]
    $fuelType: [String!]
    $transmission: [String!]
    $segment: [String!]
  ) {
    productClusters(
      page: 1
      pageSize: 200
      viewCategory: EVERYDAY
      active: $active
      available: $available
      brand: $brand
      model: $model
      sortBy: $sortBy
      sortDir: $sortDir
      minPrice: $minPrice
      maxPrice: $maxPrice
      pickupStart: $pickupStart
      pickupEnd: $pickupEnd
      locationId: $locationId
      propellant: { strings: $fuelType }
      transmission: { strings: $transmission }
      vehicleSegment: { strings: $segment }
    ) {
      ...CommonProductClusters
    }
  }
  ${CommonProductClustersFragmentDoc}
`;

/**
 * __useProductClustersB2CQuery__
 *
 * To run a query within a React component, call `useProductClustersB2CQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductClustersB2CQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductClustersB2CQuery({
 *   variables: {
 *      active: // value for 'active'
 *      available: // value for 'available'
 *      brand: // value for 'brand'
 *      model: // value for 'model'
 *      sortBy: // value for 'sortBy'
 *      sortDir: // value for 'sortDir'
 *      minPrice: // value for 'minPrice'
 *      maxPrice: // value for 'maxPrice'
 *      pickupStart: // value for 'pickupStart'
 *      pickupEnd: // value for 'pickupEnd'
 *      locationId: // value for 'locationId'
 *      fuelType: // value for 'fuelType'
 *      transmission: // value for 'transmission'
 *      segment: // value for 'segment'
 *   },
 * });
 */
export function useProductClustersB2CQuery(
  baseOptions?: Apollo.QueryHookOptions<
    O.ProductClustersB2CQuery,
    O.ProductClustersB2CQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    O.ProductClustersB2CQuery,
    O.ProductClustersB2CQueryVariables
  >(ProductClustersB2CDocument, options);
}
export function useProductClustersB2CLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.ProductClustersB2CQuery,
    O.ProductClustersB2CQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    O.ProductClustersB2CQuery,
    O.ProductClustersB2CQueryVariables
  >(ProductClustersB2CDocument, options);
}
export function useProductClustersB2CSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.ProductClustersB2CQuery,
    O.ProductClustersB2CQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.ProductClustersB2CQuery,
    O.ProductClustersB2CQueryVariables
  >(ProductClustersB2CDocument, options);
}
export type ProductClustersB2CQueryHookResult = ReturnType<
  typeof useProductClustersB2CQuery
>;
export type ProductClustersB2CLazyQueryHookResult = ReturnType<
  typeof useProductClustersB2CLazyQuery
>;
export type ProductClustersB2CSuspenseQueryHookResult = ReturnType<
  typeof useProductClustersB2CSuspenseQuery
>;
export type ProductClustersB2CQueryResult = Apollo.QueryResult<
  O.ProductClustersB2CQuery,
  O.ProductClustersB2CQueryVariables
>;
export const GetDownloadLayoutDataDocument = gql`
  query GetDownloadLayoutData($id: String!) {
    productCluster(id: $id) {
      images {
        url
      }
    }
  }
`;

/**
 * __useGetDownloadLayoutDataQuery__
 *
 * To run a query within a React component, call `useGetDownloadLayoutDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDownloadLayoutDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDownloadLayoutDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDownloadLayoutDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    O.GetDownloadLayoutDataQuery,
    O.GetDownloadLayoutDataQueryVariables
  > &
    (
      | { variables: O.GetDownloadLayoutDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    O.GetDownloadLayoutDataQuery,
    O.GetDownloadLayoutDataQueryVariables
  >(GetDownloadLayoutDataDocument, options);
}
export function useGetDownloadLayoutDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.GetDownloadLayoutDataQuery,
    O.GetDownloadLayoutDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    O.GetDownloadLayoutDataQuery,
    O.GetDownloadLayoutDataQueryVariables
  >(GetDownloadLayoutDataDocument, options);
}
export function useGetDownloadLayoutDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.GetDownloadLayoutDataQuery,
    O.GetDownloadLayoutDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.GetDownloadLayoutDataQuery,
    O.GetDownloadLayoutDataQueryVariables
  >(GetDownloadLayoutDataDocument, options);
}
export type GetDownloadLayoutDataQueryHookResult = ReturnType<
  typeof useGetDownloadLayoutDataQuery
>;
export type GetDownloadLayoutDataLazyQueryHookResult = ReturnType<
  typeof useGetDownloadLayoutDataLazyQuery
>;
export type GetDownloadLayoutDataSuspenseQueryHookResult = ReturnType<
  typeof useGetDownloadLayoutDataSuspenseQuery
>;
export type GetDownloadLayoutDataQueryResult = Apollo.QueryResult<
  O.GetDownloadLayoutDataQuery,
  O.GetDownloadLayoutDataQueryVariables
>;
export const GetDribeConfigDocument = gql`
  query GetDribeConfig {
    dribeConfig {
      vat
      currency
      countryCode
      countryName
      B2BUrl
      commercialEnabledFeatures {
        cashback
        evChargingPackages
        winterTires
        dropoffInsurance
        b2bPriceFields
      }
    }
  }
`;

/**
 * __useGetDribeConfigQuery__
 *
 * To run a query within a React component, call `useGetDribeConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDribeConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDribeConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDribeConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    O.GetDribeConfigQuery,
    O.GetDribeConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<O.GetDribeConfigQuery, O.GetDribeConfigQueryVariables>(
    GetDribeConfigDocument,
    options
  );
}
export function useGetDribeConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.GetDribeConfigQuery,
    O.GetDribeConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    O.GetDribeConfigQuery,
    O.GetDribeConfigQueryVariables
  >(GetDribeConfigDocument, options);
}
export function useGetDribeConfigSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.GetDribeConfigQuery,
    O.GetDribeConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.GetDribeConfigQuery,
    O.GetDribeConfigQueryVariables
  >(GetDribeConfigDocument, options);
}
export type GetDribeConfigQueryHookResult = ReturnType<
  typeof useGetDribeConfigQuery
>;
export type GetDribeConfigLazyQueryHookResult = ReturnType<
  typeof useGetDribeConfigLazyQuery
>;
export type GetDribeConfigSuspenseQueryHookResult = ReturnType<
  typeof useGetDribeConfigSuspenseQuery
>;
export type GetDribeConfigQueryResult = Apollo.QueryResult<
  O.GetDribeConfigQuery,
  O.GetDribeConfigQueryVariables
>;
export const GetGarageSegmentsDocument = gql`
  query GetGarageSegments($propellant: [String!]) {
    budgets: productClusters(
      page: 1
      pageSize: 1
      sortBy: "price"
      sortDir: "asc"
      viewCategory: EVERYDAY
    ) {
      data {
        pricing {
          realWorldRate
        }
      }
      meta {
        aggregates {
          minPrice {
            value
          }
        }
      }
    }
    populars: productClusters(
      viewCategory: EVERYDAY
      sortBy: "popularity"
      sortDir: "asc"
      page: 1
      pageSize: 1
    ) {
      data {
        pricing {
          realWorldRate
        }
      }
    }
    electrics: productClusters(
      viewCategory: EVERYDAY
      sortBy: "price"
      sortDir: "asc"
      page: 1
      pageSize: 1
      propellant: { strings: $propellant }
    ) {
      data {
        pricing {
          realWorldRate
        }
      }
    }
  }
`;

/**
 * __useGetGarageSegmentsQuery__
 *
 * To run a query within a React component, call `useGetGarageSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGarageSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGarageSegmentsQuery({
 *   variables: {
 *      propellant: // value for 'propellant'
 *   },
 * });
 */
export function useGetGarageSegmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    O.GetGarageSegmentsQuery,
    O.GetGarageSegmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    O.GetGarageSegmentsQuery,
    O.GetGarageSegmentsQueryVariables
  >(GetGarageSegmentsDocument, options);
}
export function useGetGarageSegmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.GetGarageSegmentsQuery,
    O.GetGarageSegmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    O.GetGarageSegmentsQuery,
    O.GetGarageSegmentsQueryVariables
  >(GetGarageSegmentsDocument, options);
}
export function useGetGarageSegmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.GetGarageSegmentsQuery,
    O.GetGarageSegmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.GetGarageSegmentsQuery,
    O.GetGarageSegmentsQueryVariables
  >(GetGarageSegmentsDocument, options);
}
export type GetGarageSegmentsQueryHookResult = ReturnType<
  typeof useGetGarageSegmentsQuery
>;
export type GetGarageSegmentsLazyQueryHookResult = ReturnType<
  typeof useGetGarageSegmentsLazyQuery
>;
export type GetGarageSegmentsSuspenseQueryHookResult = ReturnType<
  typeof useGetGarageSegmentsSuspenseQuery
>;
export type GetGarageSegmentsQueryResult = Apollo.QueryResult<
  O.GetGarageSegmentsQuery,
  O.GetGarageSegmentsQueryVariables
>;
export const GetLocationsDocument = gql`
  query GetLocations {
    locations(page: 1, pageSize: 20) {
      data {
        id
        name
      }
    }
  }
`;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    O.GetLocationsQuery,
    O.GetLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<O.GetLocationsQuery, O.GetLocationsQueryVariables>(
    GetLocationsDocument,
    options
  );
}
export function useGetLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.GetLocationsQuery,
    O.GetLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<O.GetLocationsQuery, O.GetLocationsQueryVariables>(
    GetLocationsDocument,
    options
  );
}
export function useGetLocationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.GetLocationsQuery,
    O.GetLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.GetLocationsQuery,
    O.GetLocationsQueryVariables
  >(GetLocationsDocument, options);
}
export type GetLocationsQueryHookResult = ReturnType<
  typeof useGetLocationsQuery
>;
export type GetLocationsLazyQueryHookResult = ReturnType<
  typeof useGetLocationsLazyQuery
>;
export type GetLocationsSuspenseQueryHookResult = ReturnType<
  typeof useGetLocationsSuspenseQuery
>;
export type GetLocationsQueryResult = Apollo.QueryResult<
  O.GetLocationsQuery,
  O.GetLocationsQueryVariables
>;
export const GetProductPagePrefetchedDataDocument = gql`
  query GetProductPagePrefetchedData($id: String!) {
    productCluster(id: $id) {
      id
      brand
      model
      products {
        id
      }
    }
  }
`;

/**
 * __useGetProductPagePrefetchedDataQuery__
 *
 * To run a query within a React component, call `useGetProductPagePrefetchedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductPagePrefetchedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductPagePrefetchedDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductPagePrefetchedDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    O.GetProductPagePrefetchedDataQuery,
    O.GetProductPagePrefetchedDataQueryVariables
  > &
    (
      | {
          variables: O.GetProductPagePrefetchedDataQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    O.GetProductPagePrefetchedDataQuery,
    O.GetProductPagePrefetchedDataQueryVariables
  >(GetProductPagePrefetchedDataDocument, options);
}
export function useGetProductPagePrefetchedDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.GetProductPagePrefetchedDataQuery,
    O.GetProductPagePrefetchedDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    O.GetProductPagePrefetchedDataQuery,
    O.GetProductPagePrefetchedDataQueryVariables
  >(GetProductPagePrefetchedDataDocument, options);
}
export function useGetProductPagePrefetchedDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.GetProductPagePrefetchedDataQuery,
    O.GetProductPagePrefetchedDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.GetProductPagePrefetchedDataQuery,
    O.GetProductPagePrefetchedDataQueryVariables
  >(GetProductPagePrefetchedDataDocument, options);
}
export type GetProductPagePrefetchedDataQueryHookResult = ReturnType<
  typeof useGetProductPagePrefetchedDataQuery
>;
export type GetProductPagePrefetchedDataLazyQueryHookResult = ReturnType<
  typeof useGetProductPagePrefetchedDataLazyQuery
>;
export type GetProductPagePrefetchedDataSuspenseQueryHookResult = ReturnType<
  typeof useGetProductPagePrefetchedDataSuspenseQuery
>;
export type GetProductPagePrefetchedDataQueryResult = Apollo.QueryResult<
  O.GetProductPagePrefetchedDataQuery,
  O.GetProductPagePrefetchedDataQueryVariables
>;
export const GetProductPagePreviewUrlPropsDocument = gql`
  query GetProductPagePreviewUrlProps($viewCategory: ViewCategory!) {
    productClusters(
      page: 1
      pageSize: 1
      viewCategory: $viewCategory
      available: true
    ) {
      data {
        id
        brand
        model
      }
    }
  }
`;

/**
 * __useGetProductPagePreviewUrlPropsQuery__
 *
 * To run a query within a React component, call `useGetProductPagePreviewUrlPropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductPagePreviewUrlPropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductPagePreviewUrlPropsQuery({
 *   variables: {
 *      viewCategory: // value for 'viewCategory'
 *   },
 * });
 */
export function useGetProductPagePreviewUrlPropsQuery(
  baseOptions: Apollo.QueryHookOptions<
    O.GetProductPagePreviewUrlPropsQuery,
    O.GetProductPagePreviewUrlPropsQueryVariables
  > &
    (
      | {
          variables: O.GetProductPagePreviewUrlPropsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    O.GetProductPagePreviewUrlPropsQuery,
    O.GetProductPagePreviewUrlPropsQueryVariables
  >(GetProductPagePreviewUrlPropsDocument, options);
}
export function useGetProductPagePreviewUrlPropsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.GetProductPagePreviewUrlPropsQuery,
    O.GetProductPagePreviewUrlPropsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    O.GetProductPagePreviewUrlPropsQuery,
    O.GetProductPagePreviewUrlPropsQueryVariables
  >(GetProductPagePreviewUrlPropsDocument, options);
}
export function useGetProductPagePreviewUrlPropsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.GetProductPagePreviewUrlPropsQuery,
    O.GetProductPagePreviewUrlPropsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.GetProductPagePreviewUrlPropsQuery,
    O.GetProductPagePreviewUrlPropsQueryVariables
  >(GetProductPagePreviewUrlPropsDocument, options);
}
export type GetProductPagePreviewUrlPropsQueryHookResult = ReturnType<
  typeof useGetProductPagePreviewUrlPropsQuery
>;
export type GetProductPagePreviewUrlPropsLazyQueryHookResult = ReturnType<
  typeof useGetProductPagePreviewUrlPropsLazyQuery
>;
export type GetProductPagePreviewUrlPropsSuspenseQueryHookResult = ReturnType<
  typeof useGetProductPagePreviewUrlPropsSuspenseQuery
>;
export type GetProductPagePreviewUrlPropsQueryResult = Apollo.QueryResult<
  O.GetProductPagePreviewUrlPropsQuery,
  O.GetProductPagePreviewUrlPropsQueryVariables
>;
export const GetProductPageProductClusterDocument = gql`
  query GetProductPageProductCluster(
    $id: String!
    $active: Boolean
    $available: Boolean
    $carAttributes: [String!]
    $locationId: [String!]
    $maxB2BPrice: Number
    $maxMonthlyTaxExpense: Number
    $maxPrice: Number
    $minB2BPrice: Number
    $minMonthlyTaxExpense: Number
    $minPrice: Number
    $pickupEnd: String
    $pickupStart: String
    $fuelType: [String!]
    $transmission: [String!]
    $ymProhibited: Boolean
  ) {
    productCluster(
      id: $id
      active: $active
      available: $available
      carAttributes: { strings: $carAttributes }
      locationId: $locationId
      maxB2BPrice: $maxB2BPrice
      maxMonthlyTaxExpense: $maxMonthlyTaxExpense
      maxPrice: $maxPrice
      minB2BPrice: $minB2BPrice
      minMonthlyTaxExpense: $minMonthlyTaxExpense
      minPrice: $minPrice
      pickupEnd: $pickupEnd
      pickupStart: $pickupStart
      propellant: { strings: $fuelType }
      transmission: { strings: $transmission }
      ymProhibited: $ymProhibited
    ) {
      id
      available
      reviewsInfo {
        rating
        totalReviews
      }
      pricing {
        realWorldRate
      }
      products {
        id
        active
        variant
        color
        colorCodeHex
        ratingSafety
        ratingComfort
        ratingFuelEfficiency
        ratingEcoFriendly
        available
        availableDates
        vehicleSegment
        locationIds {
          id
        }
        pricing {
          realWorldRate
        }
        images {
          id
          url
          tag
        }
        transmission
        propellant
        openDescription
        vehicles {
          seats
          doors
          carbonEmission
          energiklasse
          drivetrain
          fuelConsumption
          ncapRating
          horsepower
          openDescriptionEnglish
        }
      }
    }
  }
`;

/**
 * __useGetProductPageProductClusterQuery__
 *
 * To run a query within a React component, call `useGetProductPageProductClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductPageProductClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductPageProductClusterQuery({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *      available: // value for 'available'
 *      carAttributes: // value for 'carAttributes'
 *      locationId: // value for 'locationId'
 *      maxB2BPrice: // value for 'maxB2BPrice'
 *      maxMonthlyTaxExpense: // value for 'maxMonthlyTaxExpense'
 *      maxPrice: // value for 'maxPrice'
 *      minB2BPrice: // value for 'minB2BPrice'
 *      minMonthlyTaxExpense: // value for 'minMonthlyTaxExpense'
 *      minPrice: // value for 'minPrice'
 *      pickupEnd: // value for 'pickupEnd'
 *      pickupStart: // value for 'pickupStart'
 *      fuelType: // value for 'fuelType'
 *      transmission: // value for 'transmission'
 *      ymProhibited: // value for 'ymProhibited'
 *   },
 * });
 */
export function useGetProductPageProductClusterQuery(
  baseOptions: Apollo.QueryHookOptions<
    O.GetProductPageProductClusterQuery,
    O.GetProductPageProductClusterQueryVariables
  > &
    (
      | {
          variables: O.GetProductPageProductClusterQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    O.GetProductPageProductClusterQuery,
    O.GetProductPageProductClusterQueryVariables
  >(GetProductPageProductClusterDocument, options);
}
export function useGetProductPageProductClusterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.GetProductPageProductClusterQuery,
    O.GetProductPageProductClusterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    O.GetProductPageProductClusterQuery,
    O.GetProductPageProductClusterQueryVariables
  >(GetProductPageProductClusterDocument, options);
}
export function useGetProductPageProductClusterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.GetProductPageProductClusterQuery,
    O.GetProductPageProductClusterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.GetProductPageProductClusterQuery,
    O.GetProductPageProductClusterQueryVariables
  >(GetProductPageProductClusterDocument, options);
}
export type GetProductPageProductClusterQueryHookResult = ReturnType<
  typeof useGetProductPageProductClusterQuery
>;
export type GetProductPageProductClusterLazyQueryHookResult = ReturnType<
  typeof useGetProductPageProductClusterLazyQuery
>;
export type GetProductPageProductClusterSuspenseQueryHookResult = ReturnType<
  typeof useGetProductPageProductClusterSuspenseQuery
>;
export type GetProductPageProductClusterQueryResult = Apollo.QueryResult<
  O.GetProductPageProductClusterQuery,
  O.GetProductPageProductClusterQueryVariables
>;
export const GetProductStaticPathDataDocument = gql`
  query GetProductStaticPathData($viewCategory: ViewCategory!) {
    productClusters(
      page: 1
      pageSize: 200
      viewCategory: $viewCategory
      sortBy: "price"
      sortDir: "asc"
    ) {
      data {
        brand
        model
        id
      }
    }
  }
`;

/**
 * __useGetProductStaticPathDataQuery__
 *
 * To run a query within a React component, call `useGetProductStaticPathDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductStaticPathDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductStaticPathDataQuery({
 *   variables: {
 *      viewCategory: // value for 'viewCategory'
 *   },
 * });
 */
export function useGetProductStaticPathDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    O.GetProductStaticPathDataQuery,
    O.GetProductStaticPathDataQueryVariables
  > &
    (
      | { variables: O.GetProductStaticPathDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    O.GetProductStaticPathDataQuery,
    O.GetProductStaticPathDataQueryVariables
  >(GetProductStaticPathDataDocument, options);
}
export function useGetProductStaticPathDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.GetProductStaticPathDataQuery,
    O.GetProductStaticPathDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    O.GetProductStaticPathDataQuery,
    O.GetProductStaticPathDataQueryVariables
  >(GetProductStaticPathDataDocument, options);
}
export function useGetProductStaticPathDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.GetProductStaticPathDataQuery,
    O.GetProductStaticPathDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.GetProductStaticPathDataQuery,
    O.GetProductStaticPathDataQueryVariables
  >(GetProductStaticPathDataDocument, options);
}
export type GetProductStaticPathDataQueryHookResult = ReturnType<
  typeof useGetProductStaticPathDataQuery
>;
export type GetProductStaticPathDataLazyQueryHookResult = ReturnType<
  typeof useGetProductStaticPathDataLazyQuery
>;
export type GetProductStaticPathDataSuspenseQueryHookResult = ReturnType<
  typeof useGetProductStaticPathDataSuspenseQuery
>;
export type GetProductStaticPathDataQueryResult = Apollo.QueryResult<
  O.GetProductStaticPathDataQuery,
  O.GetProductStaticPathDataQueryVariables
>;
export const GetSitemapProductClustersDocument = gql`
  query GetSitemapProductClusters {
    b2c: productClusters(
      viewCategory: EVERYDAY
      sortBy: "price"
      sortDir: "asc"
      page: 1
      pageSize: 200
    ) {
      data {
        id
        brand
        model
      }
    }
    b2b: productClusters(
      viewCategory: B2B
      sortBy: "price"
      sortDir: "asc"
      page: 1
      pageSize: 200
    ) {
      data {
        id
        brand
        model
      }
    }
  }
`;

/**
 * __useGetSitemapProductClustersQuery__
 *
 * To run a query within a React component, call `useGetSitemapProductClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitemapProductClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitemapProductClustersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitemapProductClustersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    O.GetSitemapProductClustersQuery,
    O.GetSitemapProductClustersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    O.GetSitemapProductClustersQuery,
    O.GetSitemapProductClustersQueryVariables
  >(GetSitemapProductClustersDocument, options);
}
export function useGetSitemapProductClustersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    O.GetSitemapProductClustersQuery,
    O.GetSitemapProductClustersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    O.GetSitemapProductClustersQuery,
    O.GetSitemapProductClustersQueryVariables
  >(GetSitemapProductClustersDocument, options);
}
export function useGetSitemapProductClustersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    O.GetSitemapProductClustersQuery,
    O.GetSitemapProductClustersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    O.GetSitemapProductClustersQuery,
    O.GetSitemapProductClustersQueryVariables
  >(GetSitemapProductClustersDocument, options);
}
export type GetSitemapProductClustersQueryHookResult = ReturnType<
  typeof useGetSitemapProductClustersQuery
>;
export type GetSitemapProductClustersLazyQueryHookResult = ReturnType<
  typeof useGetSitemapProductClustersLazyQuery
>;
export type GetSitemapProductClustersSuspenseQueryHookResult = ReturnType<
  typeof useGetSitemapProductClustersSuspenseQuery
>;
export type GetSitemapProductClustersQueryResult = Apollo.QueryResult<
  O.GetSitemapProductClustersQuery,
  O.GetSitemapProductClustersQueryVariables
>;

const BUSINESS_ROUTE = '/business';

enum RouteNames {
  // B2C
  API_EXIT_PREVIEW = '/api/exit-preview',
  BLOG = '/blog',
  CONTACT = '/contact',
  DAMAGES = '/damages',
  DAMAGES_CONTACT = '/damages/contact',
  DAMAGES_POLICY = '/damages/policy',
  DOWNLOAD = '/download',
  FAQ = '/faq',
  GARAGE = '/garage',
  LANDING = '/',
  LEGAL = '/legal',
  OUTOFSTOCK = '/outofstock',
  PRICING = '/pricing',
  REDIRECT = '/redirect',

  // B2B
  B2B_BLOG = `${BUSINESS_ROUTE}/blog`,
  B2B_CONTACT = `${BUSINESS_ROUTE}/contact`,
  B2B_DAMAGES = `${BUSINESS_ROUTE}/damages`,
  B2B_DAMAGES_CONTACT = `${BUSINESS_ROUTE}/damages/contact`,
  B2B_DAMAGES_POLICY = `${BUSINESS_ROUTE}/damages/policy`,
  B2B_DEMO = `${BUSINESS_ROUTE}/demo`,
  B2B_DOWNLOAD = `${BUSINESS_ROUTE}/download`,
  B2B_FAQ = `${BUSINESS_ROUTE}/faq`,
  B2B_GARAGE = `${BUSINESS_ROUTE}/garage`,
  B2B_LANDING = `${BUSINESS_ROUTE}`,
  B2B_LEGAL = `${BUSINESS_ROUTE}/legal`,
  B2B_OUTOFSTOCK = `${BUSINESS_ROUTE}/outofstock`,
  B2B_PRICING = `${BUSINESS_ROUTE}/pricing`
}

export default RouteNames;

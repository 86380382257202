/**
 * Guide: while adding new action to enum, add interface to TrackButtonEventDataMap!
 * */
enum ButtonActions {
  LANDING_GARAGE_CLICKED = 'Landing Garage Clicked',
  LANDING_SEARCH_CLICKED = 'Landing Search Clicked',
  LANDING_DOWNLOAD_APP_CLICKED = 'Landing Download App Clicked',
  LANDING_FEATURE_CARD_CLICKED = 'Landing Feature Card Clicked',
  LANDING_FAQ_ACCORDION_CLICKED = 'Landing FAQ Accordion Clicked',
  LANDING_B2B_CONTACT_CLICKED = 'Landing Contact Button Clicked',
  NAVBAR_DOWNLOAD_CLICKED = 'Navbar Download Clicked',
  NAVBAR_LOGO_CLICKED = 'Navbar Logo Clicked',
  NAVBAR_DRIBEATWORK_CLICKED = 'Navbar Dribeatwork Clicked',
  NAVBAR_GARAGE_CLICKED = 'Navbar Garage Clicked',
  FOOTER_LOGO_CLICKED = 'Footer Logo Clicked',
  FOOTER_DOWNLOAD_ANDROID_CLICKED = 'Footer Download Android Clicked',
  FOOTER_DOWNLOAD_IOS_CLICKED = 'Footer Download iOS Clicked',
  MENU_CLOSE_CLICKED = 'Menu Close Clicked',
  MENU_OPEN_CLICKED = 'Menu Open Clicked',
  GARAGE_RESET_FILTER_CLICKED = 'Garage Reset Filter Clicked',
  GARAGE_SHOW_CARS_FILTER_CLICKED = 'Garage Show Cars Filter Clicked',
  B2B_GARAGE_SHOW_CARS_FILTER_CLICKED = 'B2B Garage Show Cars Filter Clicked',
  GARAGE_BACK_CLICKED = 'Garage Back Clicked',
  GARAGE_DOWNLOAD_APP_CLICKED = 'Garage Dwonload App Clicked',
  CATEGORY_SHOW_MORE_CLICKED = 'Category Show More Clicked',
  GARAGE_TOGGLE_DRAWER_CLICKED = 'Garage Toggle Drawer Clicked',
  GARAGE_SELECT_MODEL_CLICKED = 'Garage Select Model Clicked',
  GARAGE_SELECT_COLOR_CLICKED = 'Garage Select Color Clicked',
  GARAGE_SELECT_KM_CLICKED = 'Garage Select Km Clicked',
  GARAGE_FILTER_CLICKED = 'Garage Filter Clicked',
  GARAGE_FILTER_APPLIED = 'Garage Filter Applied',
  OUTOFSTOCK_WATCHLIST_CLICKED = 'Out Of Stock Watchlist Clicked',
  CONTACT_FORM_SUBMIT_CLICKED = 'Contact Form Submit Clicked',
  DEMO_SLIDE_START_CLICKED = 'Demo Slide Start Clicked',
  DEMO_CONTACT_CLICKED = 'Demo Contact Button Clicked'
}

export default ButtonActions;

import * as C from '@dribe-io/ui-kit/components';

import styled, { css } from 'styled-components';

import Tracked, { TrackedLink } from '@components/Tracked';
import {
  contentPaddingHorizontal,
  contentWidthBoundary,
  navbarHeight
} from '@theme/layout';

export const Transition = styled.section`
  ${({ theme }) =>
    theme.breakpoints.up(
      'md',
      `
        .ease-in {
          visibility: visible;
          transition: ${theme.transition('all').ease.xxs};
        }

        .ease-out {
          visibility: hidden;
          transition: ${theme.transition('all').ease.xxs};
          transform: translate(0, -100%);
        }

        .fade-in {
          visibility: visible;
          animation: fadeIn 0.1s;

          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      `
    )}
  .hide {
    visibility: hidden;
    transform: translate(0, -100%);
  }
`;

export const Navbar = styled(C.Inline)`
  position: fixed;
  box-shadow: ${({ theme }) => theme.shadows.tertiary};
  width: 100%;
  ${({ theme }) => theme.gloss.primary};
  justify-content: space-between;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex[9]};
  & > *:last-child {
    margin-right: auto;
  }

  ${navbarHeight};
  ${contentPaddingHorizontal};

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        padding-right: ${theme.spacing[8]};
      `
    )}
`;

export const NavbarContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${contentWidthBoundary}
  padding-left: ${({ theme }) => theme.spacing[16]};
`;

export const HideMobile = styled(C.Inline)`
  height: 100%;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        display: none;
      `
    )}
`;

export const HideTablet = styled(C.Inline)`
  height: 100%;
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      css`
        display: none;
      `
    )}
`;

export const ShowMobileOnly = styled(C.Inline)`
  display: none;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        display: flex;
      `
    )}
`;

export const NavbarRight = styled(C.Inline)<{ $navDisabled?: boolean }>`
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        * {
          margin-right: ${theme.spacing[4]};
        }
      `
    )}
  ${({ $navDisabled }) =>
    $navDisabled &&
    css`
      filter: blur(4px);
    `}
  & > * {
    pointer-events: ${({ $navDisabled }) => ($navDisabled ? 'none' : 'auto')};
    user-select: ${({ $navDisabled }) => ($navDisabled ? 'none' : 'auto')};
  }
`;

export const MenuLink = styled(C.Typography)`
  white-space: nowrap;
  &:hover {
    color: ${({ theme }) => theme.palette.orange};
  }
`;

export const ButtonLink = styled(TrackedLink)`
  color: inherit;
`;

export const ButtonLinkText = styled(C.Typography)`
  color: inherit;
`;

export const MenuContainer = styled(C.Stack)`
  position: fixed;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex[9]};
`;

export const SwitcherTracked = styled(TrackedLink)`
  height: 100%;
`;

export const Switch = styled(C.PillSwitch2)<{ $willShow: boolean }>`
  opacity: ${({ $willShow }) => ($willShow ? 1 : 0)};
`;

export const RandomlyOrderedTrackedComponent = styled(Tracked)<{
  $order: number;
}>`
  order: ${({ $order }) => $order};
`;

export const SignUpButtonContainer = styled(C.Inline)`
  gap: ${({ theme }) => theme.spacing[16]};
`;

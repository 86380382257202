import * as C from '@dribe-io/ui-kit/components';

import styled from 'styled-components';

import CategoryCard from '@components/CategoryCard';
import { contentPadding } from '@theme/layout';
import RichText from '@utils/RichText';

export const OuterWrapper = styled.div`
  ${contentPadding}

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        padding: 0;
      `
    )};
`;

export const ComingSoonContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.lightGrey};
  border-radius: 16px;
  padding-bottom: ${({ theme }) => theme.spacing[104]};

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        border-radius: 0;
      `
    )};
`;

export const ComingSoonContent = styled(C.Stack)`
  display: flex;
  width: 60%;

  & > * {
    text-align: center;
  }
  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      `
        width: 80%;

      `
    )};
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        width: 90%;

      `
    )};
`;

export const CardGrid = styled.div`
  gap: ${({ theme }) => theme.spacing[40]};
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  margin-top: ${({ theme }) => theme.spacing[40]};
  grid-gap: ${({ theme }) => theme.spacing[24]};
  text-align: left;
  align-items: stretch;
  overflow: hidden;

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        width: 100%;
        flex-direction: column;
        grid-template-columns: repeat(1, 1fr);
      `
    )};
`;

export const LocationCard = styled(CategoryCard)`
  width: 100%;
`;

export const Title = styled(C.Typography)`
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        font-size: 3rem;
        line-height: 4.5rem;
      `
    )};
`;

export const StyledRichText = styled(RichText)`
  width: 100%;
`;

export const ComingSoonImage = styled(C.Image)`
  margin-top: 20px;
  width: 100%;
  max-width: 548px;
`;

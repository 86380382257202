import React from 'react';

import Image from 'next/image';

import { Maybe } from 'graphql/jsutils/Maybe';

import { TrackedLink } from '@components/Tracked';

import * as S from './Footer.styled';

type SocialLinksProps = {
  hasSocialLinks: boolean;
  instagramLink: Maybe<string>;
  facebookLink: Maybe<string>;
  linkedInLink: Maybe<string>;
  tikTokLink: Maybe<string>;
  youTubeLink: Maybe<string>;
};

const SocialLinks = ({
  hasSocialLinks,
  instagramLink,
  facebookLink,
  linkedInLink,
  tikTokLink,
  youTubeLink
}: SocialLinksProps) => {
  if (!hasSocialLinks) {
    return null;
  }

  const socialMediaLinks = [
    { link: instagramLink, icon: '/Instagram.svg', alt: 'Instagram' },
    { link: facebookLink, icon: '/Facebook.svg', alt: 'Facebook' },
    { link: linkedInLink, icon: '/LinkedIn.svg', alt: 'LinkedIn' },
    { link: tikTokLink, icon: '/TikTok.svg', alt: 'TikTok' },
    { link: youTubeLink, icon: '/YouTube.svg', alt: 'YouTube' }
  ];

  return (
    <S.SocialContainer>
      {socialMediaLinks.map(
        (socialMediaLink, i) =>
          !!socialMediaLink.link && (
            <TrackedLink
              key={`${i}_${socialMediaLink.link}`}
              href={socialMediaLink.link}
              prefetch={false}
              title={socialMediaLink.alt}
            >
              <Image
                src={socialMediaLink.icon}
                alt={socialMediaLink.alt}
                width={32}
                height={32}
                priority
              />
            </TrackedLink>
          )
      )}
    </S.SocialContainer>
  );
};

export default SocialLinks;

/**
 * Guide: while adding new action to enum, add interface to TrackLinkEventDataMap!
 * */
enum LinkTitles {
  LANDING_HERO_GARAGE_LINK = 'Landing Hero Garage Link',
  LANDING_HERO_SEARCH_LINK = 'Landing Hero Search Link',
  DOWNLOAD_APP_LINK = 'Download App Link',
  GARAGE_RIDE_LINK = 'Garage Ride Link',
  GARAGE_PAGE_LINK = 'Garage Page Link',
  CARD_LINK = 'Card Link',
  CONTACT_PAGE_LINK = 'Contact Page',
  BOOKING_SUPPORT_NUMBER_LINK = 'Booking Support Number Opened'
}
export default LinkTitles;

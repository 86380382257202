import * as C from '@dribe-io/ui-kit/components';
import { SpacingValues } from '@dribe-io/ui-kit/theme/tokens';

import styled from 'styled-components';

export const FlagIcon = styled(C.Icon)<{ $size?: number | SpacingValues }>`
  border-radius: ${({ $size }) => $size}px;

  // Not in UI-kit because it is a special box-shadow only used for the FlagIcon
  box-shadow: 0px 2px 4px 0px rgba(205, 205, 205, 0.77);
`;

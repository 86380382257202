import { useMemo } from 'react';

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import merge from 'deepmerge';

import { createErrorLink, createSentryLink } from './apollo';

const SPACE = process.env.CONTENTFUL_SPACE_ID;
const ENVIRONMENT = process.env.CONTENTFUL_ENVIRONMENT;
const SENTRY_DSN = process.env.SENTRY_DSN;
const CONTENTFUL_URL = `https://graphql.contentful.com/content/v1/spaces/${SPACE}/environments/${ENVIRONMENT}`;
const PREVIEW_TOKEN = process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN;
const TOKEN = process.env.CONTENTFUL_ACCESS_TOKEN;

let isPreview = false;

const getToken = () => {
  return isPreview ? PREVIEW_TOKEN : TOKEN;
};

const authLink = setContext(async (_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const createContentfulLink = () => {
  const httpLink = new HttpLink({
    uri: CONTENTFUL_URL
  });

  return authLink.concat(httpLink);
};

const createApolloClient = () => {
  const contentfulLink = createContentfulLink();
  const errorLink = SENTRY_DSN ? createSentryLink() : createErrorLink();

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, contentfulLink])
  });
};

let apolloClient: ApolloClient<NormalizedCacheObject>;

export const getContentfulApollo = (
  preview = false,
  initialState: NormalizedCacheObject | null = null
) => {
  isPreview = preview;
  const _apolloClient: ApolloClient<NormalizedCacheObject> =
    apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const useContentfulApollo = (
  preview: boolean,
  initialState: NormalizedCacheObject
) => {
  const store = useMemo(
    () => getContentfulApollo(preview, initialState),
    [preview, initialState]
  );
  return store;
};

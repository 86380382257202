import { css } from 'styled-components';

export enum CommercialBackground {
  LandingPage = 'landing',
  DividedGrey = 'dividedGrey',
  FullGrey = 'fullGrey',
  PatternGrey = 'patternGrey'
}

const landingPageBackground = css`
  background: ${({ theme }) => `linear-gradient(
    to bottom,
    ${theme.palette.snow} 455px,
    ${theme.palette.xLightGrey} 455px
  )`};

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
      background: linear-gradient(
        to bottom,
        ${theme.palette.snow} 300px,
        ${theme.palette.xLightGrey} 300px
      );
      `
    )};
`;

const dividedGreyBackground = css`
  background: ${({ theme }) => `linear-gradient(
    to bottom,
    ${theme.palette.snow} 195px,
    ${theme.palette.xLightGrey} 195px
  )`};

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
      background: linear-gradient(
        to bottom,
        ${theme.palette.snow} 392px,
        ${theme.palette.xLightGrey} 392px
      );
      `
    )};
`;
const patternGreyBackground = css`
  background-image: ${({
    theme
  }) => `url('/dribe-pattern.svg') , linear-gradient(
    to bottom,
    ${theme.palette.snow} 195px,
    ${theme.palette.xLightGrey} 195px
  )`};
  background-repeat: no-repeat;
  background-position: bottom right;

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
      background: linear-gradient(
        to bottom,
        ${theme.palette.snow} 392px,
        ${theme.palette.xLightGrey} 392px
      );
      `
    )};
`;

const fullGreyBackground = css`
  background: ${({ theme }) => theme.palette.xLightGrey};
`;

export const renderBackground = (
  commercialBackground?: CommercialBackground
) => {
  switch (commercialBackground) {
    case CommercialBackground.LandingPage:
      return landingPageBackground;
    case CommercialBackground.FullGrey:
      return fullGreyBackground;
    case CommercialBackground.DividedGrey:
      return dividedGreyBackground;
    case CommercialBackground.PatternGrey:
      return patternGreyBackground;
    default:
      return;
  }
};

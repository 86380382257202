import { ContactFormType } from '@components/B2B/ContactForm/constants';
import { apiObject } from 'rudder-sdk-js';

import EventNames from './EventNames';

export interface EventSpec extends apiObject {
  'market-id'?: string;
}

export interface PageEventSpec<T extends EventSpec> {
  title: string;
  category?: string;
  spec: T;
}

export interface TrackEventSpec<T extends EventSpec> {
  name: EventNames;
  spec: T;
}

interface LinkClickedSpec extends EventSpec {
  title: string;
  destination: string;
}

interface ButtonClickedSpec extends EventSpec {
  action: string;
}
interface ContactFormSubmitedSpec extends EventSpec {
  payload: ContactFormType;
}

export const createLinkClickedSpec = (
  title: string,
  destination: string,
  extras?: apiObject
) => {
  const spec = {
    title,
    destination,
    ...(extras || {})
  };

  const event: TrackEventSpec<LinkClickedSpec> = {
    name: EventNames.LINK_CLICKED,
    spec
  };
  return event;
};

export const createButtonClickedSpec = (action: string, extras?: apiObject) => {
  const spec = {
    action,
    ...(extras || {})
  };

  const event: TrackEventSpec<ButtonClickedSpec> = {
    name: EventNames.BUTTON_CLICKED,
    spec
  };

  return event;
};

export const contactFormSubmittedSpec = (
  payload: ContactFormType,
  extras?: apiObject
) => {
  const spec = {
    payload,
    ...(extras || {})
  };

  const event: TrackEventSpec<ContactFormSubmitedSpec> = {
    name: EventNames.FORM_SUBMITTED,
    spec
  };

  return event;
};
